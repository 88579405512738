import React from 'react';
//import './assets/css/footer.css'; // Import CSS from the theme

function Footer() {
  const isAdmin = window.location.pathname.includes('/admin');

  if(!isAdmin){
    return (
      <footer class="footer -type-1">
        <div class="container">
          <div class="pt-60 pb-60">
            <div class="row y-gap-40 justify-between xl:justify-start">
              <div class="col-xl-2 col-lg-4 col-sm-6">
                <h5 class="text-16 fw-500 mb-30">Contact Us</h5>

                <div class="mt-30">
                  {/*
                  <div class="text-14 mt-30">WhatsApp</div>
                  <a href="https://wa.me/918452040404" class="text-18 fw-500 text-blue-1 mt-5">8452 040 404</a><br />
                  <a href="https://wa.me/919224444455" class="text-18 fw-500 text-blue-1 mt-5">9224 444 455</a><br />
                  <a href="https://wa.me/919987404440" class="text-18 fw-500 text-blue-1 mt-5">9987 404 440</a>
                  */}
                  <div class="text-14 mt-30">Call Us</div>
                  <a href="https://wa.me/918452040404" class="text-18 fw-500 text-blue-1 mt-5">8452 040 404</a><br />
                  <a href="https://wa.me/919224444455" class="text-18 fw-500 text-blue-1 mt-5">9224 444 455</a><br />
                  <a href="https://wa.me/919987404440" class="text-18 fw-500 text-blue-1 mt-5">9987 404 440</a>
                </div>

                <div class="mt-35">
                  <div class="text-14 mt-30">Our Email Address</div>
                  <a href="mailto:travel@blessingsonthenet.com" class="text-18 fw-500 text-blue-1 mt-5">travel@blessingsonthenet.com</a>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-sm-6">
                <h5 class="text-16 fw-500 mb-30">Company</h5>
                <div class="d-flex y-gap-10 flex-column">
                  <a href="/aboutus">About Us</a>
                  <a href="/disclaimer">Disclaimer</a>
                  <a href="/terms">Terms & Conditions</a>
                  <a href="/privacy">Privacy Policy</a>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-sm-6">
                <h5 class="text-16 fw-500 mb-30">Support</h5>
                <div class="d-flex y-gap-10 flex-column">
                  <a href="/contact">Contact</a>
                  <a href="/refund">Refund Policy</a>
                  <a href="/advertise">Advertise</a>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-sm-6">
                <h5 class="text-16 fw-500 mb-30">Other Services</h5>
                <div class="d-flex y-gap-10 flex-column">
                  <a href="/travels/travel/page/1">Holidays</a>
                  <a href="/hotels/page/1">Hotels</a>
                  <a href="/products/page/1">Shop</a>
                  <a href="/homam-astrology/page/1">Homam/Astrology</a>
                  
                </div>

                <div class="mt-30 ml-10">
                  <div class="col-auto">
                    <div class="d-flex x-gap-20 items-center">
                      Follow Us:
                      <a target="_blank" href="https://www.facebook.com/NirvanaIndiaEnterprise"><i class="icon-facebook text-14"></i></a>
                      <a target="_blank" href="https://www.instagram.com/nirvana.india.enterprise/"><i class="icon-instagram text-14"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>

          <div class="py-20 border-top-light">
            <div class="row justify-between items-center y-gap-10">
              <div class="col-auto">
                <div class="row x-gap-30 y-gap-10">
                  <div class="col-auto">
                    <div class="d-flex items-center">
                      © {new Date().getFullYear()} Blessingsonthenet.com, All rights reserved.
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;