import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import 'react-quill/dist/quill.snow.css';
import "cropperjs/dist/cropper.min.css";
import { isAuthenticated } from '../middleware/admin_auth';

function AdminPackageDelete() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);
    
    const { id } = useParams();
    const [isDeleted, setIsDeleted] = useState(false);

    useEffect(() => {
        const deletePage = async () => {
            try {
                const apiURL = `${process.env.REACT_APP_API_ENDPOINT}admin_package_delete`;
                const response = await fetch(apiURL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ id: id })
                });
                if (response.ok) {
                    setIsDeleted(true);
                }
            } catch (error) {
                console.error(error);
            }
        };
        deletePage();
    }, [id]);

    return (
        <div class="dashboard__main">

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Page Delete Status</h1>

                    </div>
                </div>
                <div class="py-80 px-30 rounded-4 bg-white shadow-3 text-center" style={{marginBottom:300}}>
                    {isDeleted ? (
                            <h3>Page Deleted Successfully!</h3>
                    ) : (
                            <h3>Deleting page...</h3>
                    )}
                    <br /><a href="/admin/packages">Return to Tour Packages</a>
                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminPackageDelete;
