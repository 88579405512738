import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminHomamCreate() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];

    const [idFetch, setIdFetch] = useState([]);

    const lastIdURL = `${process.env.REACT_APP_API_ENDPOINT}last_page_id?cat=homam`;
    console.log("lastIdURL", lastIdURL);
    useEffect(() => {
    fetch(lastIdURL)
        .then(res => res.json())
        .then(data => setIdFetch(data))
        .catch(err => console.log(err));
    }, []);

    console.log("idFetch", idFetch);

    const id = idFetch.length > 0 ? idFetch[0].id + 1 : undefined;
    console.log("id: ", id);
    
    const [homamData, setHomamData] = useState({});
    const { 
        name
        ,description
        ,deity
        ,category
        ,category_type
        ,featured
        ,price
        ,no_of_priests_1
        ,no_of_chants_1
        ,duration_1
        ,charges_1
        ,no_of_priests_2
        ,no_of_chants_2
        ,duration_2
        ,charges_2
        ,no_of_priests_3
        ,no_of_chants_3
        ,duration_3
        ,charges_3
        ,delete_status
    } = homamData;

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    

    const handleSaveDetails = () => {
        const updatedHomamData = {
            ...homamData,
            name
            ,description
            ,deity
            ,featured
            ,price
            ,category
            ,category_type: categoryType.category_type
            ,no_of_priests_1
            ,no_of_chants_1
            ,duration_1
            ,charges_1
            ,no_of_priests_2
            ,no_of_chants_2
            ,duration_2
            ,charges_2
            ,no_of_priests_3
            ,no_of_chants_3
            ,duration_3
            ,charges_3
        };
        console.log("updatedHomamData: ",JSON.stringify(updatedHomamData));
        const homamUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_homam_create`;
        fetch(homamUpdate1, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedHomamData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
                window.location = "/admin/homam-astrology/edit/"+id;
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };


    // 2nd Tab
    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const cropperRef = useRef();
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setCroppedImage(null);
    };

    const handleCrop = () => {
        if (!cropperRef.current || !cropperRef.current.cropper) {
            setError("Cropper reference not properly defined");
            return;
        }
        const canvas = cropperRef.current.cropper.getCroppedCanvas({ width: 250, height: 250 });
        setCroppedImage(canvas.toDataURL("image/jpeg", 0.8));
    };

    const handleUpload = async () => {
        if (!croppedImage) {
            setError("Please crop an image first");
            return;
        }
        setLoading(true);
        setStatus(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'thumb');
        formData.append("image", dataURItoBlob(croppedImage), `${Date.now()}-${selectedFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_homam_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessage("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessage("");
                }, 5000);
                setSelectedFile(null);
                setCroppedImage(null);
                window.location.reload();
            } else {
                setError("There was an error uploading the image");
            }
        } catch (error) {
            setError("There was an error uploading the image");
            setStatus("error");
        }
        setLoading(false);
    };    

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    const handleImageLoad = () => {
        if (cropperRef.current && !cropperRef.current.cropper) {
            const cropper = new Cropper(cropperRef.current, {
                aspectRatio: 1 / 1,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setError(null);
                },
            });
            cropperRef.current.cropper = cropper;
        }
    };

    // Thumbs Retrieval
    const [thumbImages, setThumbImages] = useState([]);

    useEffect(() => {
      const imageAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=thumb&site_category=homam`;
      fetch(imageAPIURL)
        .then(response => response.json())
        .then(data => setThumbImages(data))
        .catch(error => console.error(error));
    }, []);

    function handleImageDelete(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=thumb&site_category=homam`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(error => {
              console.error(error);
              alert("Failed to delete image");
            });
        }
      }


    //Gallery items

    const [selectedGalleryFile, setSelectedGalleryFile] = useState(null);
    const [croppedGalleryImage, setCroppedGalleryImage] = useState(null);
    const [errorGallery, setErrorGallery] = useState(null);
    const [loadingGallery, setLoadingGallery] = useState(false);
    const [statusGallery, setStatusGallery] = useState(null);
    const cropperRefGallery = useRef();
    const [successMessageGallery, setSuccessMessageGallery] = useState("");

    const handleFileChangeGallery = (event) => {
        setSelectedGalleryFile(event.target.files[0]);
        setCroppedGalleryImage(null);
    };

    const handleCropGallery = () => {
        if (!cropperRefGallery.current || !cropperRefGallery.current.cropper) {
            setErrorGallery("Cropper reference not properly defined");
            return;
        }
        const canvasGallery = cropperRefGallery.current.cropper.getCroppedCanvas({ width: 1200, height: 1200 });
        setCroppedGalleryImage(canvasGallery.toDataURL("image/jpeg", 0.8));
    };

    const handleUploadGallery = async () => {
        if (!croppedGalleryImage) {
            setErrorGallery("Please crop an image first");
            return;
        }
        setLoadingGallery(true);
        setStatusGallery(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'gallery');
        formData.append("image", dataURItoBlob(croppedGalleryImage), `${Date.now()}-${selectedGalleryFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_homam_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessageGallery("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessageGallery("");
                }, 5000);
                setSelectedGalleryFile(null);
                setCroppedGalleryImage(null);
                window.location.reload();
            } else {
                setErrorGallery("There was an error uploading Gallery image");
            }
        } catch (errorGallery) {
            setErrorGallery("There was an error uploading Gallery image");
            setStatusGallery("errorGallery");
        }
        setLoadingGallery(false);
    };

    const handleImageLoadGallery = () => {
        if (cropperRefGallery.current && !cropperRefGallery.current.cropper) {
            const cropper = new Cropper(cropperRefGallery.current, {
                aspectRatio: 1 / 1,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setErrorGallery(null);
                },
            });
            cropperRefGallery.current.cropper = cropper;
        }
    };

    // Gallery Retrieval
    const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
    const [captionInput, setCaptionInput] = useState('');

    useEffect(() => {
        const imageAPIURLGallery = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=homam`;
        console.log("imageAPIURLGallery", imageAPIURLGallery);
        fetch(imageAPIURLGallery)
          .then(response => response.json())
          .then(data => setThumbImagesGallery(data))
          .catch(errorGallery => console.errorGallery(errorGallery));
      }, []);
      
      const handleCaptionChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].caption = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleFeatureIdChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].feature_id = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleSaveCaption = (index) => {
        const image = thumbImagesGallery[index];
        const updateCaptionURL = `${process.env.REACT_APP_API_ENDPOINT}update_caption`;
        fetch(updateCaptionURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: image.id,
            caption: image.caption,
            feature_id: image.feature_id,
          }),
        })
          .then(response => response.json())
          .then(data => {
            // Handle the response, show success message, update status, etc.
            console.log('Caption saved:', data);
            alert("Caption Saved Successfully!");
          })
          .catch(error => {
            // Handle the error, show error message, update status, etc.
            console.error('Error saving caption:', error);
            alert("There was a problem, contact your dev team!");
          });
      };

    function handleImageDeleteGallery(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=gallery&site_category=homam`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(errorGallery => {
              console.errorGallery(errorGallery);
              alert("Failed to delete image");
            });
        }
    }


    const [categoryType, setCategoryType] = useState({});   
    
    useEffect(() => {
        if (category_type) {
          const categoryTypeAPIURL = `${process.env.REACT_APP_API_ENDPOINT}admin_homam_category_types?category_type_id=${category_type}`;
          //console.log("categoryTypeAPIURL: ", categoryTypeAPIURL);
          fetch(categoryTypeAPIURL)
            .then(response => response.json())
            .then(data => {
              const [category_typeData] = data; // Extract the first element from the array
              setCategoryType(category_typeData || {});
            })
            .catch(error => console.error(error));
        }
    }, [category_type]); 

    const [allCategoryTypes, setAllCategoryTypes] = useState([]);

    useEffect(() => {
        const categoryTypesUrl = `${process.env.REACT_APP_API_ENDPOINT}admin_homam_category_types?category_types=all`;
        //console.log("categoryTypesUrl: ", categoryTypesUrl);
        fetch(categoryTypesUrl)
          .then(response => response.json())
          .then(data => setAllCategoryTypes(data))
          .catch(error => console.log(error));
    }, []);

    const [showOptions, setShowOptions] = useState(false);


    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">

                    <h1 class="text-30 lh-14 fw-600">Add New Homam / Astrology</h1>

                </div>

                <div class="col-auto">
                    <a target="_blank" href={`/homam-astrology/${id}/${name ? name.toLowerCase().replace(/\s+/g, '-') : '' }`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        View Page <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">
                <div class="tabs -underline-2 js-tabs">
                    <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">1. Content</button>
                    </div>

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-2">2. Images</button>
                    </div>

                    </div>

                    <div class="tabs__content pt-30 js-tabs-content">
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                        <div class="col-xl-10">
                            <div class="text-18 fw-500 mb-10">Homam/Astrology Content</div>
                            <div class="row x-gap-20 y-gap-20">

                                <div class="col-12">
                                    <div class="form-input ">
                                        <input type="text" value={name} name="homam_name"
                                        onChange={(event) => setHomamData(prevState => ({...prevState, name: event.target.value}))}
                                        required />
                                        <label class="lh-1 text-16 text-light-1">Homam Name</label>
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Description/Itinerary</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={description}
                                            onChange={(value) => setHomamData(prevState => ({...prevState, description: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-input ">
                                        <input type="text" value={deity} name="deity"
                                        onChange={(event) => setHomamData(prevState => ({...prevState, deity: event.target.value}))}
                                        required />
                                        <label class="lh-1 text-16 text-light-1">Deity</label>
                                    </div>
                                </div>

                                <div class="col-xl-9 col-lg-11">
                                    <div class="row x-gap-100 y-gap-15">
                                        <div class="col-lg-3 col-sm-6">
                                            <div class="row y-gap-15">
                                                <div class="col-12">
                                                    <div class="d-flex items-center">
                                                        <div class="form-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name="featured"
                                                            onChange={(event) => setHomamData(prevState => ({...prevState, featured: event.target.checked ? 1 : 0}))}
                                                            checked={featured === 1 ? true : false}
                                                        />
                                                            <div class="form-checkbox__mark">
                                                                <div class="form-checkbox__icon icon-check"></div>
                                                            </div>
                                                        </div>
                                                        <div class="text-15 lh-11 ml-10">Featured</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-input">
                                        
                                        <input
                                            type="text"
                                            value={categoryType.category_type}
                                            name="category_type"
                                            onChange={event => {
                                            const category_typeValue = event.target.value;
                                            setCategoryType(prevState => ({
                                                ...prevState,
                                                category_type: category_typeValue
                                            }));
                                            setShowOptions(true); // Show options when input value changes
                                            }}
                                            onFocus={() => setShowOptions(true)} // Show options when input is focused
                                            onBlur={() => setShowOptions(false)} // Hide options when input loses focus
                                            required
                                        />

                                        {showOptions && categoryType.category_type && ( // Render options only if showOptions is true and input value is not empty
                                            <ul className="hotel_edit_autocomplete">
                                            {allCategoryTypes
                                                .filter(category_type => category_type.category_type && categoryType.category_type && category_type.category_type.toLowerCase().includes(categoryType.category_type.toLowerCase()))
                                                .map(category_type => (
                                                <li
                                                    key={category_type.id}
                                                    onMouseDown={event => {
                                                    event.preventDefault();
                                                    setCategoryType(prevState => ({
                                                        ...prevState,
                                                        category_type: category_type.category_type
                                                    }));
                                                    setShowOptions(false); // Hide options after selecting an option
                                                    }}
                                                >
                                                    {`${category_type.category_type}`}
                                                </li>
                                                ))}
                                            </ul>
                                        )}

                                        <label className="lh-1 text-16 text-light-1">Category Type</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="fw-500 mb-10">Category</div>
                                    <div class="form-input ">
                                        <select className="hselect" 
                                        onChange={(event) => setHomamData(prevState => ({...prevState, category: event.target.value}))}
                                        value={category}>
                                            <option value="">Select Category</option>
                                            <option value="homam">Homam</option>
                                            <option value="astrology">Astrology</option>
                                        </select>
                                    </div>
                                </div>

                                {category === "homam" && (
                                <>
                                    <h5>Option 1</h5>

                                    <div class="col-12">
                                        <div class="form-input ">
                                            <input type="text" value={no_of_priests_1} name="no_of_priests_1" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, no_of_priests_1: event.target.value}))}
                                            />
                                            <label class="lh-1 text-16 text-light-1">Number of Priests</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={no_of_chants_1} name="no_of_chants_1" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, no_of_chants_1: event.target.value}))}
                                            />
                                            <label class="lh-1 text-16 text-light-1">Number of Chants</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={duration_1} name="duration_1" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, duration_1: event.target.value}))} />
                                            <label class="lh-1 text-16 text-light-1">Duration</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={charges_1} name="charges_1" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, charges_1: event.target.value}))} />
                                            <label class="lh-1 text-16 text-light-1">Charges</label>
                                        </div>
                                    </div>

                                    <h5>Option 2</h5>

                                    <div class="col-12">
                                        <div class="form-input ">
                                            <input type="text" value={no_of_priests_2} name="no_of_priests_2" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, no_of_priests_2: event.target.value}))}
                                            />
                                            <label class="lh-1 text-16 text-light-1">Number of Priests</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={no_of_chants_2} name="no_of_chants_2" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, no_of_chants_2: event.target.value}))}
                                            />
                                            <label class="lh-1 text-16 text-light-1">Number of Chants</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={duration_2} name="duration_2" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, duration_2: event.target.value}))} />
                                            <label class="lh-1 text-16 text-light-1">Duration</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={charges_2} name="charges_2" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, charges_2: event.target.value}))} />
                                            <label class="lh-1 text-16 text-light-1">Charges</label>
                                        </div>
                                    </div>

                                    <h5>Option 3</h5>

                                    <div class="col-12">
                                        <div class="form-input ">
                                            <input type="text" value={no_of_priests_3} name="no_of_priests_3" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, no_of_priests_3: event.target.value}))}
                                            />
                                            <label class="lh-1 text-16 text-light-1">Number of Priests</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={no_of_chants_3} name="no_of_chants_3" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, no_of_chants_3: event.target.value}))}
                                            />
                                            <label class="lh-1 text-16 text-light-1">Number of Chants</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={duration_3} name="duration_3" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, duration_3: event.target.value}))} />
                                            <label class="lh-1 text-16 text-light-1">Duration</label>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-input ">
                                            <input type="text" value={charges_3} name="charges_3" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, charges_3: event.target.value}))} />
                                            <label class="lh-1 text-16 text-light-1">Charges</label>
                                        </div>
                                    </div>
                                </>
                                )}

                                {category === "astrology" && (
                                <>
                                    <div class="col-12">
                                        <div class="form-input ">
                                            <input type="text" value={price} name="price" 
                                            onChange={(event) => setHomamData(prevState => ({...prevState, price: event.target.value}))}
                                            />
                                            <label class="lh-1 text-16 text-light-1">Price</label>
                                        </div>
                                    </div>
                                </>
                                )}
                            </div>

                        </div>

                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>

                    </div>

                    <div class="tabs__pane -tab-item-2">
                        <div class="col-xl-12 col-lg-12">
                            <div class="col-12">

                                

                                <div>
                                    <div class="fw-500">Upload New Thumbnail Images (These would be 250 x 250 px)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChange} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedFile && (
                                        <div>
                                            <img onLoad={handleImageLoad} ref={cropperRef} src={URL.createObjectURL(selectedFile)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCrop}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedImage && (
                                        <div>
                                            <img src={croppedImage} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedImage(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUpload} disabled={loading}>{loading ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessage && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessage}
                                            </div>
                                            )}

                                            {error && <div class="alert alert-danger">{error}</div>}
                                            {status && <div class="alert alert-success">{status}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30">
                                    <div class="fw-500">Existing Thumbnail Images</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">
                                    {thumbImages.length > 0 ? (
                                        thumbImages.map((image, index) => (
                                            <div className="col-auto" key={index}>
                                            <div className="d-flex ratio ratio-1:1 w-200">
                                                <img
                                                src={`${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${image.image_src}`}
                                                alt="image"
                                                className="img-ratio rounded-4"
                                                />
                                                <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                    <div style={{cursor:'pointer'}} className="size-40 bg-white rounded-4" onClick={() => handleImageDelete(image.id)}>
                                                        <i className="icon-trash text-16 image_trash_icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        ))
                                        ) : (
                                        <p>No Images Found</p>
                                    )}

                                    </div>
                                </div>

                                <div class="border-top-light mt-30 mb-30"></div>

                                <div>
                                    <div class="fw-500">Upload New Gallery Images (These would be 1:1 ratio, 1200 x 1200 px recommended)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChangeGallery} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedGalleryFile && (
                                        <div>
                                            <img onLoad={handleImageLoadGallery} ref={cropperRefGallery} src={URL.createObjectURL(selectedGalleryFile)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCropGallery}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedGalleryImage && (
                                        <div>
                                            <img src={croppedGalleryImage} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedGalleryImage(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUploadGallery} disabled={loadingGallery}>{loadingGallery ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessageGallery && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessageGallery}
                                            </div>
                                            )}

                                            {errorGallery && <div class="alert alert-danger">{errorGallery}</div>}
                                            {statusGallery && <div class="alert alert-success">{statusGallery}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30">
                                    <div class="fw-500">Existing Gallery Images</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">
                                    {thumbImagesGallery.length > 0 ? (
                                        thumbImagesGallery.map((image, index) => (
                                            <div className="col-auto" key={index}>
                                            <div className="d-flex ratio ratio-1:1 w-200" style={{ marginBottom: 10 }}>
                                                <img
                                                style={{ height: 'unset' }}
                                                src={`${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${image.image_src}`}
                                                alt="image"
                                                className="img-ratio rounded-4"
                                                />
                                                <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    className="size-40 bg-white rounded-4"
                                                    onClick={() => handleImageDeleteGallery(image.id)}
                                                >
                                                    <i className="icon-trash text-16 image_trash_icon"></i>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="form-input">
                                                <input
                                                type="text"
                                                name="caption"
                                                placeholder="Caption"
                                                title="Caption"
                                                className="ReactTags__tagInputField caption_input"
                                                value={image.caption || ''}
                                                onChange={e => handleCaptionChange(e, index)}
                                                />
                                                <input
                                                    type="text"
                                                    name="feature_id"
                                                    title="Feature ID [Add numerical values 1 to 10]"
                                                    className="ReactTags__tagInputField feature_input"
                                                    value={image.feature_id || ''}
                                                    onChange={e => handleFeatureIdChange(e, index)}
                                                />
                                                <button onClick={() => handleSaveCaption(index)}>
                                                    <img className='caption_save' src={`/assets/img/general/save-green.png`} alt='Save' title='Save' />
                                                </button>
                                            </div>
                                            </div>
                                        ))
                                        ) : (
                                        <p>No Images Found</p>
                                    )}

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    </div>
                </div>
                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminHomamCreate;