import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import 'react-quill/dist/quill.snow.css';
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminWidgetEdit() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);
    
    const { id } = useParams();
    const [widget, setWidget] = useState({ id: null, type: '', packagename: '', featured: '' });

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}admin_homepage_widgets/${id}`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data) && data.length > 0) {
              setWidget(data[0]);
            }
          })
          .catch(error => console.error(error));
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');


    const [widgetCategories, setWidgetCategories] = useState([]);

    useEffect(() => {
        const widgetUrl = `${process.env.REACT_APP_API_ENDPOINT}admin_homepage_widgets?cat=all`;
        fetch(widgetUrl)
        .then(response => response.json())
        .then(data => setWidgetCategories(data))
        .catch(error => console.log(error));
    }, []);
    
    
    const handleSaveDetails = () => {
        const packageUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_home_widget_update`;
        const tagIds = shoppingTags.map(tag => tag.id.replace(/'/g, '')).join(',');
      
        const requestBody = { ...widget, widget_ids: tagIds };
        console.log('API Request:', requestBody);
      
        fetch(packageUpdate1, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            setModalMessage('Data saved successfully!');
            setShowModal(true);
            setTimeout(() => setShowModal(false), 5000);
            window.location.href = '/admin/homepage-widgets';
          })
          .catch(error => {
            console.error('There was an error!', error);
            setModalMessage('There was an error while saving data');
            setShowModal(true);
            setTimeout(() => setShowModal(false), 5000);
          });
    };      

    const KeyCodes = {
        comma: 188,
        enter: 13
    }

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const [shoppingTags, setShoppingTags] = React.useState([]);
    const [tagShopSuggestions, setTagShopSuggestions] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [apiCatUrl, setApiCatUrl] = useState('');

    console.log("widget_category: ", widget?.widget_category);

    useEffect(() => {
        let newApiUrl = '';
        if (widget?.widget_category === 'packages') {
            newApiUrl = 'tour_listings';
        } else if (widget?.widget_category === 'hotels') {
            newApiUrl = 'hotel_listings';
        } else if (widget?.widget_category === 'products') {
            newApiUrl = 'product_listings';
        } else if (widget?.widget_category === 'homam') {
            newApiUrl = 'homam_listings';
        } else if (widget?.widget_category === 'astrology') {
            newApiUrl = 'homam_listings';
        }
        setApiCatUrl(newApiUrl);
    }, [widget?.widget_category]);

    useEffect(() => {
        const shoppingUrl = `${process.env.REACT_APP_API_ENDPOINT}${apiCatUrl}?page=1&keyword=${keyword}`;
        console.log("shoppingUrl: ", shoppingUrl);
        fetch(shoppingUrl)
            .then(response => response.json())
            .then(data => {

                let shoppingSuggestions = '';

                // transform the data into the format expected by the ReactTags component
                if (widget?.widget_category === 'products') {
                    shoppingSuggestions = data.map(item => ({
                        id: item.id.toString(),
                        text: item.product_name
                    }));
                }
                else{
                    shoppingSuggestions = data.map(item => ({
                        id: item.id.toString(),
                        text: item.name
                    }));
                }
                
                // update the state variable with the suggestions
                setTagShopSuggestions(shoppingSuggestions);
            })
            .catch(error => {
                console.error('Error fetching suggestions:', error);
            });
    }, [keyword]);

    const handleKeywordChange = (input) => {
        setKeyword(input);
    };

    useEffect(() => {
        if (widget?.widget_ids) {
            const shoppingFindUrl = `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=${widget?.widget_category}&id=${widget?.widget_ids}`;
            //console.log("touristAttractionsFindUrl: ", touristAttractionsFindUrl);
            fetch(shoppingFindUrl)
            .then(response => response.json())
            .then(data => {

                let shoppingformattedData = '';

                if (widget?.widget_category === 'products') {
                    shoppingformattedData = data.map(item => ({
                        id: "'"+item.id+"'",
                        text: item.product_name
                    }));
                } else{
                    shoppingformattedData = data.map(item => ({
                        id: "'"+item.id+"'",
                        text: item.name
                    }));
                }

                //console.log('formattedData:', formattedData);
                setShoppingTags(shoppingformattedData);
            })
            .catch(error => console.error(error));
        }
    }, [widget?.widget_ids]);

    const handleShoppingDelete = i => {
        const newTags = shoppingTags.filter((tag, index) => index !== i);
        const newTagIds = newTags.map(tag => tag.id.replace(/'/g, '')).join(',');
      
        setShoppingTags(newTags);
        setWidget(prevState => ({
          ...prevState,
          widget_ids: newTagIds // Update the widget_ids with the newTagIds
        }));
    };

    const handleShoppingAddition = tag => {
        const newTags = [...shoppingTags, tag];
        const newTagIds = newTags.map(tag => tag.id.replace(/'/g, '')).join(',');
      
        setShoppingTags(newTags);
        setWidget(prevState => ({
          ...prevState,
          widget_ids: newTagIds // Update the widget_ids with the newTagIds
        }));
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = shoppingTags.slice();
    
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
    
        // re-render
        setShoppingTags(newTags);
      };
    
    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Edit Homepage Widget</h1>

                    </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:200}}>

                    <div class="col-12">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={widget?.widget_name || ''}
                            name="widget"
                            onChange={(event) => setWidget(prevState => ({ ...prevState, widget_name: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Widget Name</label>
                        </div>
                    </div>

                    <div class="col-12 room_input mt-20">
                        <div className='border-light rounded-4 admin_dropdown_inner'>
                            <h4 class="text-15 fw-500 ls-2 lh-16">Widget Category</h4>
                            <div class="text-15 text-light-1 ls-2 lh-16">
                                <select
                                value={widget?.widget_category}
                                onChange={(event) =>
                                    setWidget((prevState) => ({
                                    ...prevState,
                                    widget_category: event.target.value,
                                    }))
                                }
                                >
                                <option value="" disabled>Select an option</option>
                                {widgetCategories.map((option, index) =>
                                    option.widget_category !== "" ? (
                                    <option key={index} value={option.widget_category}>
                                        {option.widget_category.charAt(0).toUpperCase() + option.widget_category.slice(1)}
                                    </option>
                                    ) : null
                                )}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={widget?.widget_position || ''}
                            name="featured_position"
                            onChange={(event) => setWidget(prevState => ({ ...prevState, widget_position: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Widget Position (Enter a numerical value)</label>
                        </div>
                    </div>

                    <div className="col-12 mb-50 mt-20">
                        <div className="fw-500">Current Items</div>
                        <div className="form-input">
                            <ReactTags
                                tags={shoppingTags}
                                suggestions={tagShopSuggestions}
                                delimiters={delimiters}
                                handleDelete={handleShoppingDelete}
                                handleAddition={handleShoppingAddition}
                                handleDrag={handleDrag}
                                inputFieldPosition="bottom"
                                autocomplete
                                handleInputChange={handleKeywordChange}
                            />
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                            <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                            </a>
                        </div>
                        <div style={{clear:'both'}}></div>
                    </div>

                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminWidgetEdit;