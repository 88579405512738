import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import PrimarySearchBox from './PrimarySearchBox';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function Home() {

  const [widgetData, setWidgetData] = useState([]);

  useEffect(() => {
    const setCustomAttributes = () => {
      const sectionSliders = document.querySelectorAll('.js-section-slider');
      sectionSliders.forEach((slider) => {
        if (!slider.getAttribute('data-gap')) {
          slider.setAttribute('data-gap', '30');
        }
        if (!slider.getAttribute('data-scrollbar')) {
          slider.setAttribute('data-scrollbar', '');
        }
        if (!slider.getAttribute('data-slider-cols')) {
          slider.setAttribute('data-slider-cols', 'base-2 xl-4 lg-3 md-2 sm-2 base-1');
        }
        if (!slider.getAttribute('data-anim')) {
          slider.setAttribute('data-anim', 'slide-up');
        }
      });
    };
  
    setCustomAttributes();
  
    /*
    const initializeSwiper = () => {
      new Swiper('.js-section-slider', {
        // Swiper configuration options
        loop: true,
        slidesPerView:4,
        spaceBetween:30
      });
    };
  
    // Wait for the DOM elements to render before initializing Swiper
    setTimeout(initializeSwiper, 50);
    */
  }, [widgetData]);

  useEffect(() => {
    const fetchWidgetData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}admin_homepage_widgets`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData(data);
      } catch (error) {
        console.error('Error fetching widget data:', error);
      }
    };
  
    fetchWidgetData();
  }, []);
  
  const fetchHotelData = async (ids, widgetId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?id=${ids}`;
      console.log("apiUrl:", apiUrl);
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWidgetData((prevData) =>
        prevData.map((widget) =>
          widget.id === widgetId ? { ...widget, hotelData: data } : widget
        )
      );
    } catch (error) {
      console.error('Error fetching hotel data:', error);
    }
  };
  
  const fetchTourData = async (ids, widgetId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour_listings?id=${ids}`;
      console.log("Tour API: ", apiUrl);
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWidgetData((prevData) =>
        prevData.map((widget) =>
          widget.id === widgetId ? { ...widget, packageData: data } : widget
        )
      );
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };

  const fetchProductData = async (ids, widgetId) => {
    try {
      if(ids){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}product_listings?id=${ids}`;
        console.log("product API: ", apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData((prevData) =>
          prevData.map((widget) =>
            widget.id === widgetId ? { ...widget, productData: data } : widget
          )
        );
      }
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };

  const fetchHomamData = async (ids, widgetId) => {
    try {
      if(ids){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?id=${ids}`;
        console.log("Homam API", apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData((prevData) =>
          prevData.map((widget) =>
            widget.id === widgetId ? { ...widget, homamData: data } : widget
          )
        );
      }
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };
  
  useEffect(() => {
    const fetchDataForWidgets = async () => {
      for (const widget of widgetData) {
        if (widget.widget_category === 'hotels' && !widget.hotelData) {
          await fetchHotelData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'packages' && !widget.packageData) {
          await fetchTourData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'homam' && !widget.homamData) {
          await fetchHomamData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'astrology' && !widget.homamData) {
          await fetchHomamData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'products' && !widget.productData) {
          await fetchProductData(widget.widget_ids, widget.id);
        } 
      }
    };
  
    fetchDataForWidgets();
  }, [widgetData]);

  const renderWidgetSections = () => {
    return widgetData.map((widget) => {
      if (widget.widget_category === 'packages' && widget.packageData) {
        return (
          <section key={widget.id} className="layout-pt-lg layout-pb-md">
            <div className="container">
              <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>

                <div className="col-auto md:d-none">

                  <a href="/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Holidays <div className="icon-arrow-top-right ml-15"></div>
                  </a>

                </div>
              </div>

              <div className="relative pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden is-in-view">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    slidesPerView={4}
                    loop={true}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                  >
                  {widget.packageData.map((hpackage) => (
                    <SwiperSlide>
                      <div className="swiper-slide swiper-slide-visible swiper-slide-active" key={hpackage.id} 
                      style={{width: '300px', marginRight: '30px'}}>

                        <a href={`/travel-india/tour-package/id/${hpackage.id}/${hpackage.name.replace(/\s+/g, '-')}`} className="citiesCard -type-1 d-block rounded-4 ">
                          <div className="citiesCard__image ratio ratio-1:1">
                            <img
                              src={hpackage.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `/img/uploads/tour/thumbs/${hpackage.thumb}`}
                              data-src={hpackage.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `/img/uploads/tour/thumbs/${hpackage.thumb}`}
                              alt="image"
                            />
                          </div>

                          <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                            <div className="citiesCard__bg"></div>

                            <div className="citiesCard__top">
                              <div className="text-14 text-white">{hpackage.nights} Night {hpackage.days} Days</div>
                            </div>

                            <div className="citiesCard__bottom">
                              <h4 className="text-26 md:text-20 lh-13 text-white mb-20">{hpackage.name}</h4>
                              <p className="starts_from">Starts from ₹{hpackage.price}/-</p>
                              <button className="button col-12 h-60 -blue-1 bg-white text-dark-1">View Details</button>
                            </div>
                          </div>
                        </a>

                      </div>
                    </SwiperSlide>
                  ))}
                  </Swiper>
                </div>
                

                {/*
                <button className="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-prev">
                  <i className="icon icon-chevron-left text-12"></i>
                </button>

                <button className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-next">
                  <i className="icon icon-chevron-right text-12"></i>
                </button>


                <div className="slider-scrollbar bg-light-2 mt-40 sm:d-none js-scrollbar"></div>
                */}

                <div className="row pt-20 d-none md:d-block">
                  <div className="col-auto">
                    <div className="d-inline-block">

                      <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                        View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else if (widget.widget_category === 'hotels' && widget.hotelData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/hotels/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Hotels <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    slidesPerView={4}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                  {widget.hotelData.map((hotel) => (
                    <SwiperSlide>
                      <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{width:"300px", marginRight:"30px"}} key={hotel.id}>
          
                        <a href={`/hotel/${hotel.name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} className="hotelsCard -type-1 ">
                          <div className="hotelsCard__image">
          
                            <div className="cardImage ratio ratio-1:1">
                              <div className="cardImage__content">
                                <img
                                src={
                                  hotel.hotel_image_new.includes('aim_')
                                    ? `/img/uploads/hotels/roomgallery/${hotel.hotel_image_new.replace(/ /g, '%20')}`
                                    : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${hotel.hotel_image_new.replace(/ /g, '%20')}`
                                }
                                alt="image"
                                />
          
                              </div>
          
                            </div>
          
                          </div>
          
                          <div className="hotelsCard__content mt-10">
                            <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                              <span>{hotel.name}</span>
                            </h4>
          
                            <p className="text-light-1 lh-14 text-14 mt-5">{hotel.city ? hotel.city : ''}{hotel.state ? ', '+hotel.state : ''}</p>
          
                            <div className="mt-5">
                              <div className="fw-500">
                                {!hotel.price || hotel.price === 0
                                  ? ""
                                  : 'Starting from '}
                                <span className="text-blue-1">
                                {!hotel.price || hotel.price === 0
                                  ? "Contact us for Price"
                                  : `₹${hotel.price}/-`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
          
                      </div>
                    </SwiperSlide>
                  ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
          </section>
        );
      } else if (widget.widget_category === 'products' && widget.productData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/products/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Products <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    slidesPerView={4}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {widget.productData.map((hotel) => (
                      <SwiperSlide>
                        <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{width:"300px", marginRight:"30px"}} key={hotel.id}>
            
                          <a href={`/product/${hotel.product_name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} className="hotelsCard -type-1 ">
                            <div className="hotelsCard__image">
            
                              <div className="cardImage ratio ratio-1:1">
                                <div className="cardImage__content">
                                  <img
                                  src={`${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${hotel.image_src.replace(/ /g, '%20')}`}
                                  alt="image"
                                  />
            
                                </div>
            
                              </div>
            
                            </div>
            
                            <div className="hotelsCard__content mt-10 text-center">
                              <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                <span>{hotel.product_name}</span>
                              </h4>
            
                              <div className="mt-5">
                                <div className="fw-500">
                                  {!hotel.price || hotel.price === 0
                                    ? ""
                                    : ''}
                                  <span className="text-blue-1">
                                  {!hotel.price || hotel.price === 0
                                    ? "Contact us for Price"
                                    : `₹${hotel.price}/-`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
            
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
          </section>
        );
      } else if (widget.widget_category === 'homam' && widget.homamData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/homam-astrology/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Homam <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    slidesPerView={4}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {widget.homamData.map((hotel) => (
                      <SwiperSlide>
                        <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{width:"300px", marginRight:"30px"}} key={hotel.id}>
            
                          <a href={`/homam-astrology/${hotel.id}/${hotel.name.toLowerCase().replace(/\s+/g, '-')}`} className="hotelsCard -type-1 ">
                            <div className="hotelsCard__image">
            
                              <div className="cardImage ratio ratio-1:1">
                                <div className="cardImage__content">
                                  <img
                                  src={`${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${hotel.thumb_new.replace(/ /g, '%20')}`}
                                  alt="image"
                                  />
            
                                </div>
            
                              </div>
            
                            </div>
            
                            <div className="hotelsCard__content mt-10 text-center">
                              <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                <span>{hotel.name}</span>
                              </h4>
            
                              <div className="mt-5">
                                <div className="fw-500">
                                  {!hotel.charges_1 || hotel.charges_1 === 0
                                    ? "Starts from"
                                    : ''}
                                  <span className="text-blue-1">
                                  {!hotel.charges_1 || hotel.charges_1 === 0
                                    ? "Contact us for Price"
                                    : `₹${hotel.charges_1}/-`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
            
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
            
          </section>
        );
      } else if (widget.widget_category === 'astrology' && widget.homamData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/homam-astrology/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Astrology <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    slidesPerView={4}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {widget.homamData.map((hotel) => (
                      <SwiperSlide>
                        <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{width:"300px", marginRight:"30px"}} key={hotel.id}>
            
                          <a href={`/homam-astrology/${hotel.id}/${hotel.name.toLowerCase().replace(/\s+/g, '-')}`} className="hotelsCard -type-1 ">
                            <div className="hotelsCard__image">
            
                              <div className="cardImage ratio ratio-1:1">
                                <div className="cardImage__content">
                                  <img
                                  src={`${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${hotel.thumb_new.replace(/ /g, '%20')}`}
                                  alt="image"
                                  />
            
                                </div>
            
                              </div>
            
                            </div>
            
                            <div className="hotelsCard__content mt-10 text-center">
                              <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                <span>{hotel.name}</span>
                              </h4>
            
                              <div className="mt-5">
                                <div className="fw-500">
                                  {!hotel.price || hotel.price === 0
                                    ? "Starts from"
                                    : ''}
                                  <span className="text-blue-1">
                                  {!hotel.price || hotel.price === 0
                                    ? "Contact us for Price"
                                    : `₹${hotel.price}/-`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
            
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
            
          </section>
        );
      } else if (widget.widget_category === 'ecommerce') {
        return (
          <section key={widget.id} className="layout-pt-lg layout-pb-md">
            <div className="ecommerce_container"></div>
          </section>
        );
      } else {
        return null; // Widget category not recognized
      }
    });
  };

  return (
    <><section data-anim-wrap className="masthead -type-1 z-5">
      <div data-anim-child="fade" className="masthead__bg">
        <img src="#" alt="image" data-src="/assets/img/masthead/1/bg.webp" className="js-lazy" />
      </div>
      
      <div className="container">
        <div className="row justify-center">
          <div className="col-auto">
            <div className="text-center mb-40">
              <h1 data-anim-child="slide-up delay-1" className="text-40 lg:text-40 md:text-30 text-white">The Most Trusted Travel And Spiritual Website In India</h1>
              <p data-anim-child="slide-up delay-2" className="text-white mt-6 md:mt-10">Crafting Your Unforgettable Travel Experiences</p>
            </div>

            <PrimarySearchBox />
          </div>
        </div>
      </div>
      
    </section>

    {widgetData.length > 0 ? renderWidgetSections() : <p className='text-center'>Loading data, please wait...</p>}

    <section className="layout-pt-md layout-pb-md">
      <div className="container">
        <div className="row y-gap-20">
          <div data-anim="slide-up" className="col-md-6">

            <div className="ctaCard -type-1 rounded-4 ">
              <div className="ctaCard__image ratio ratio-63:55">
                <img className="img-ratio js-lazy" src="#" data-src="/assets/img/backgrounds/1.png" alt="image" />
              </div>

              <div className="ctaCard__content py-70 px-70 lg:py-30 lg:px-30">


                <h4 className="text-40 lg:text-26 text-white">Hotel Sun & Sand<br /> Shirdi</h4>

                <div className="d-inline-block mt-30">
                  <a href="#" className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1">View Details</a>
                </div>
              </div>
            </div>

          </div>

          <div data-anim="slide-up delay-1" className="col-md-6">

            <div className="ctaCard -type-1 rounded-4 ">
              <div className="ctaCard__image ratio ratio-63:55">
                <img className="img-ratio js-lazy" src="#" data-src="/assets/img/holidays/2.png" alt="image" />
              </div>

              <div className="ctaCard__content py-70 px-70 lg:py-30 lg:px-30">

                <div className="text-15 fw-500 text-white mb-10">Enjoy Summer Deals</div>


                <h4 className="text-40 lg:text-26 text-white">Up to 70% Discount!</h4>

                <div className="d-inline-block mt-30">
                  <a href="#" className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1">Learn More</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


    <section style={{paddingTop:120}} className="layout-pt-lg layout-pb-lg bg-blue-2">
      <div data-anim-wrap className="container">
        <div className="row y-gap-40 justify-between">
          <div data-anim-child="slide-up delay-1" className="col-xl-5 col-lg-6">
            <h2 className="text-30">What our customers are<br /> saying about us?</h2>
            <p className="mt-20">We have been serving holiday packages on various categories for over 15 years now with most of the client with happy faces.</p>

            <div className="row y-gap-30 pt-60 lg:pt-40">
              <div className="col-sm-5 col-6">
                <div className="text-30 lh-15 fw-600">5k+</div>
                <div className="text-light-1 lh-15">Happy Customers</div>
              </div>

              <div className="col-sm-5 col-6">
                <div className="text-30 lh-15 fw-600">4.88</div>
                <div className="text-light-1 lh-15">Overall rating</div>

                <div className="d-flex x-gap-5 items-center pt-10">

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                </div>
              </div>
            </div>
          </div>

          <div data-anim-child="slide-up delay-1" className="col-lg-6">
            <div className="overflow-hidden js-testimonials-slider-3" data-scrollbar>
              <div className="swiper-wrapper">

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatars/1.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Anitha Rajan</h5>
                      <div className="text-15 text-light-1 lh-15">Working Profession</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">Blessingsonthenet team helped us getting best holiday package and we are very happy with their service. I would highly recommend to my friends. Give it a try and experience their service once.</p>
                </div>

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatars/1.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Annette Black</h5>
                      <div className="text-15 text-light-1 lh-15">UX / UI Designer</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">The place is in a great location in Gumbet. The area is safe and beautiful. The apartment was comfortable and the host was kind and responsive to our requests.</p>
                </div>

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatars/1.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Annette Black</h5>
                      <div className="text-15 text-light-1 lh-15">UX / UI Designer</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">The place is in a great location in Gumbet. The area is safe and beautiful. The apartment was comfortable and the host was kind and responsive to our requests.</p>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="layout-pt-md layout-pb-md bg-dark-2">
      <div className="container">
        <div className="row y-gap-30 justify-between items-center">
          <div className="col-auto">
            <div className="row y-gap-20  flex-wrap items-center">
              <div className="col-auto">
                <div className="icon-newsletter text-60 sm:text-40 text-white"></div>
              </div>

              <div className="col-auto">
                <h4 className="text-26 text-white fw-600">Your Travel Journey Starts Here</h4>
                <div className="text-white">Sign up and we'll send the best deals to you</div>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="single-field -w-410 d-flex x-gap-10 y-gap-20">
              <div>
                <input className="bg-white h-60" type="text" placeholder="Your Email" />
              </div>

              <div>
                <button className="button -md h-60 bg-blue-1 text-white">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    </>
  );
}

export default Home;