import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSearch from './HeaderSearch';
import { SubscribeRibbon } from './SubscribeRibbon';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import PersonDetail from './PersonDetail';
import {Helmet} from "react-helmet";

function PayOnline() {

    const [guestName, setGuestName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [paidFor, setPaidFor] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [bookingDetails, setBookingDetails] = useState('');
    const [idolDetails, setIdolDetails] = useState('');

    // Get the current year (last two digits)
    const currentYear = new Date().getFullYear().toString().slice(-2);

    // Generate a random 16-digit number
    const rand = Math.floor(1000000000000000 + Math.random() * 9000000000000000);

    // Concatenate the current year and random number to create Order_Id
    const order_id = currentYear + rand.toString();

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission here
        console.log('Form submitted');

        // Construct the URL with form data
        const url = `https://blessingsonthenet.com/pay/pay-direct.php?guestName=${encodeURIComponent(guestName)}&email=${encodeURIComponent(email)}&mobileNumber=${encodeURIComponent(mobileNumber)}&amount=${encodeURIComponent(amount)}&paidFor=${encodeURIComponent(paidFor)}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&bookingDetails=${encodeURIComponent(bookingDetails)}&idolDetails=${encodeURIComponent(idolDetails)}&order_id=${encodeURIComponent(order_id)}`;

        //const url = `http://localhost/projects/cc_avenue_iframe/pay-direct.php?guestName=${encodeURIComponent(guestName)}&email=${encodeURIComponent(email)}&mobileNumber=${encodeURIComponent(mobileNumber)}&amount=${encodeURIComponent(amount)}&paidFor=${encodeURIComponent(paidFor)}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&bookingDetails=${encodeURIComponent(bookingDetails)}&idolDetails=${encodeURIComponent(idolDetails)}&order_id=${encodeURIComponent(order_id)}`;

        // Redirect to the constructed URL
        window.location.href = url;
    };


  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Pay Online | Blessingsonthenet.com</title>
    </Helmet> 
    <div>

      <section class="py-10 d-flex items-center bg-light-2 mt-20">
        <div class="container">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div class="col-auto">
                  <div class="">Home</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="text-dark-1">Pay Online</div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              {/*<a href="#" class="text-14 text-blue-1 underline">View Commons Involving Dwarka</a>*/}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="relative container">
            <div class="row mt-30 mb-50">
                <div class="col-sm-12 col-xl-6 col-lg-6">
                    <div class="px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4 mt-20 mb-20">
                        <div class="text-22 fw-500">
                            For Online Payments
                        </div>

                        <form onSubmit={handleSubmit} action="https://api.example.com" method="GET">
                            <div className="row y-gap-20 pt-20">
                                <div className="col-12">
                                    <div className="form-input">
                                        <input type="text" name="guestName" value={guestName} onChange={(e) => setGuestName(e.target.value)} required />
                                        <label className="lh-1 text-16 text-light-1">Guest Name*</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-input">
                                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        <label className="lh-1 text-16 text-light-1">Email Address*</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-input">
                                        <input type="tel" name="mobileNumber" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} required />
                                        <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-input">
                                        <input type="number" name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} required />
                                        <label className="lh-1 text-16 text-light-1">Amount (INR)*</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-input">
                                        <select class="hselect pt-40" name="paidFor" value={paidFor} onChange={(e) => setPaidFor(e.target.value)} required>
                                            <option value="">-- Select --</option>
                                            <option value="Hotel">Hotel</option>
                                            <option value="Tour Package">Tour Package</option>
                                            <option value="Store">Store</option>
                                        </select>
                                        <label className="lh-1 text-16 text-light-1">Paid For*</label>
                                    </div>
                                </div>
                                {paidFor === 'Hotel' || paidFor === 'Tour Package' ?
                                    <>
                                        <div className="col-12">
                                            <div className="form-input">
                                                <input type="date" class="pt-40" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
                                                <label className="lh-1 text-16 text-light-1">Start Date*</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-input">
                                                <input type="date" class="pt-40" name="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
                                                <label className="lh-1 text-16 text-light-1">End Date*</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-input">
                                                <textarea name="bookingDetails" value={bookingDetails} onChange={(e) => setBookingDetails(e.target.value)} required />
                                                <label className="lh-1 text-16 text-light-1">Booking Details*</label>
                                            </div>
                                        </div>
                                    </>
                                :
                                    paidFor === 'Store' &&
                                    <div className="col-12">
                                        <div className="form-input">
                                            <textarea name="idolDetails" value={idolDetails} onChange={(e) => setIdolDetails(e.target.value)} required />
                                            <label className="lh-1 text-16 text-light-1">Idol Details*</label>
                                        </div>
                                    </div>
                                }
                                <input type="hidden" name="order_id" value={order_id} />
                                <div className="col-auto">
                                    <button type="submit" className="button px-40 h-50 -dark-1 bg-blue-1 text-white">Pay Now <div className="icon-arrow-top-right ml-15"></div></button>
                                    <br />
                                    <p className="mb-5">Powered By:</p>
                                    <img src="https://blessingsonthenet.com/img/cca.png" alt="CCAvenue" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-sm-12 col-xl-6 col-lg-6">
                    <div class="px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4 mt-20 mb-20">
                            <div class="text-22 fw-500">
                                To Transfer Funds
                            </div>

                            <div class="row y-gap-20 pt-20">
                                <div class="col-12">
                                    <div style={{width:'100%'}}>
                                        <span><b>Yes Bank Details</b></span>
                                        <table>
                                        <tbody><tr>
                                            <td>Bank Name</td>
                                            <td> : </td>
                                            <td> &nbsp;Yes Bank,</td>
                                        </tr>
                                        <tr>
                                            <td>Account Name</td>
                                            <td> : </td>
                                            <td> &nbsp;Nirvana India Enterprise</td>
                                        </tr>
                                        <tr>
                                            <td>Account Number</td>
                                            <td> : </td>
                                            <td><b> &nbsp;021263300001742</b></td>
                                        </tr>
                                        <tr>
                                            <td>IFC Code</td>
                                            <td> : </td>
                                            <td>&nbsp;YESB0000212</td>
                                        </tr>
                                        <tr>
                                            <td>Swift Code</td>
                                            <td> : </td>
                                            <td>&nbsp;YESBINBB</td>
                                        </tr>
                                        <tr>
                                            <td style={{verticalAlign:'top'}}>Branch Name</td>
                                            <td> &nbsp; </td>
                                            <td>&nbsp;Ground Floor, Ganpati Tower, Shyamnarayan Thakur Marg,<br />&nbsp;Kandivali ( East ) Mumbai,<br />&nbsp;Maharashtra - 400101, India.</td>
                                        </tr>
                                        </tbody></table>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>

      <SubscribeRibbon />
    </div>
    </>
  );
}

export default PayOnline;
