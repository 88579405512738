import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../middleware/admin_auth';

function Sidebar() {
  const isAdmin = window.location.pathname.includes('/admin');

  if(isAdmin){
    const handleLogout = () => {
      logout();
    };
    return (
      <div class="dashboard" data-x="dashboard" data-x-toggle="-is-sidebar-open">
        <div class="dashboard__sidebar bg-white scroll-bar-1">

          <div class="sidebar -dashboard">

            <div class="sidebar__item ">


              <a href="/admin" class="sidebar__button d-flex items-center text-15 lh-1 fw-500">
                <img src="/assets/img/dashboard/sidebar/compass.svg" alt="image" class="mr-15" />
                Dashboard
              </a>


            </div>

            <div class="sidebar__item ">
              <a href="/admin/enquiries" class="sidebar__button d-flex items-center text-15 lh-1 fw-500">
                <img src="/assets/img/dashboard/sidebar/sneakers.svg" alt="image" class="mr-15" />
                Form Enquiries
              </a>
            </div>

            <div class="sidebar__item ">
              <a href="/admin/orders" class="sidebar__button d-flex items-center text-15 lh-1 fw-500">
                <img src="/assets/img/dashboard/sidebar/booking.svg" alt="image" class="mr-15" />
                Orders
              </a>
            </div>

            <div class="sidebar__item ">
              <a href="/admin/direct-payments" class="sidebar__button d-flex items-center text-15 lh-1 fw-500">
                <img src="/assets/img/dashboard/sidebar/taxi.svg" alt="image" class="mr-15" />
                Direct Payments
              </a>
            </div>

            <div class="sidebar__item ">

            <div class="accordion -db-sidebar js-accordion">
              <div class="accordion__item">
                <div class="accordion__button">
                  <div class="sidebar__button col-12 d-flex items-center justify-between">
                    <div class="d-flex items-center text-15 lh-1 fw-500">
                      <img src="/assets/img/dashboard/sidebar/map.svg" alt="image" class="mr-10" />
                      Tour Packages
                    </div>
                    <div class="icon-chevron-sm-down text-7"></div>
                  </div>
                </div>

                <div class="accordion__content">
                  <ul class="list-disc pt-15 pb-5 pl-40">

                    <li>
                      <a href="/admin/packages" class="text-15">View All Packages</a>
                    </li>

                    <li>
                      <a href="/admin/package/create" class="text-15">Add New Package</a>
                    </li>

                    <li>
                      <a href="#" class="text-15">View Package Bookings</a>
                    </li>

                    <li>
                      <a href="/admin/cities" class="text-15">Cities</a>
                    </li>

                    <li>
                      <a href="/admin/interests" class="text-15">Interests</a>
                    </li>

                    <li>
                      <a href="/admin/attractions" class="text-15">Attractions</a>
                    </li>

                    <li>
                      <a href="/admin/eateries" class="text-15">Eateries</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>


            </div>

            <div class="sidebar__item ">


              <div class="accordion -db-sidebar js-accordion">
                <div class="accordion__item">
                  <div class="accordion__button">
                    <div class="sidebar__button col-12 d-flex items-center justify-between">
                      <div class="d-flex items-center text-15 lh-1 fw-500">
                        <img src="/assets/img/dashboard/sidebar/hotel.svg" alt="image" class="mr-10" />
                        Hotels
                      </div>
                      <div class="icon-chevron-sm-down text-7"></div>
                    </div>
                  </div>

                  <div class="accordion__content">
                    <ul class="list-disc pt-15 pb-5 pl-40">

                      <li>
                        <a href="/admin/hotels" class="text-15">View All Hotels</a>
                      </li>

                      <li>
                        <a href="/admin/hotel/create" class="text-15">Add New Hotel</a>
                      </li>

                      <li>
                        <a href="/admin/hotel-categories" class="text-15">Hotel Categories</a>
                      </li>

                      <li>
                        <a href="/admin/hotel-groups" class="text-15">Hotel Groups</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>


            </div>


            <div class="sidebar__item ">


              <div class="accordion -db-sidebar js-accordion">
                <div class="accordion__item">
                  <div class="accordion__button">
                    <div class="sidebar__button col-12 d-flex items-center justify-between">
                      <div class="d-flex items-center text-15 lh-1 fw-500">
                        <img src="/assets/img/dashboard/sidebar/canoe.svg" alt="image" class="mr-10" />
                        Homams / Astrology
                      </div>
                      <div class="icon-chevron-sm-down text-7"></div>
                    </div>
                  </div>

                  <div class="accordion__content">
                    <ul class="list-disc pt-15 pb-5 pl-40">

                      <li>
                        <a href="/admin/homam-astrology" class="text-15">View Homams / Astrology</a>
                      </li>

                      <li>
                        <a href="#" class="text-15">Add New</a>
                      </li>

                      <li>
                        <a href="/admin/homam-widgets" class="text-15">Homam Homepage</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>


            </div>

            

            <div class="sidebar__item ">


              <div class="accordion -db-sidebar js-accordion">
                <div class="accordion__item">
                  <div class="accordion__button">
                    <div class="sidebar__button col-12 d-flex items-center justify-between">
                      <div class="d-flex items-center text-15 lh-1 fw-500">
                        <img src="/assets/img/dashboard/sidebar/sneakers.svg" alt="image" class="mr-10" />
                        E-commerce
                      </div>
                      <div class="icon-chevron-sm-down text-7"></div>
                    </div>
                  </div>

                  <div class="accordion__content">
                    <ul class="list-disc pt-15 pb-5 pl-40">

                      <li>
                        <a href="/admin/products" class="text-15">View All Products</a>
                      </li>

                      <li>
                        <a href="/admin/product/create" class="text-15">Add Product</a>
                      </li>

                      <li>
                        <a href="/admin/product_categories" class="text-15">Product Categories</a>
                      </li>

                      <li>
                        <a href="/admin/store-widgets" class="text-15">Store Homepage</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>


            </div>

            <div class="sidebar__item ">


              <div class="accordion -db-sidebar js-accordion">
                <div class="accordion__item">
                  <div class="accordion__button">
                    <div class="sidebar__button col-12 d-flex items-center justify-between">
                      <div class="d-flex items-center text-15 lh-1 fw-500">
                        <img src="/assets/img/dashboard/sidebar/house.svg" alt="image" class="mr-10" />
                        Temples
                      </div>
                      <div class="icon-chevron-sm-down text-7"></div>
                    </div>
                  </div>

                  <div class="accordion__content">
                    <ul class="list-disc pt-15 pb-5 pl-40">

                      <li>
                        <a href="/admin/temples" class="text-15">View All Temples</a>
                      </li>

                      <li>
                        <a href="/admin/temple/create" class="text-15">Add New Temple</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>


            </div>

            <div class="sidebar__item ">


              <div class="accordion -db-sidebar js-accordion">
                <div class="accordion__item">
                  <div class="accordion__button">
                    <div class="sidebar__button col-12 d-flex items-center justify-between">
                      <div class="d-flex items-center text-15 lh-1 fw-500">
                        <img src="/assets/img/dashboard/sidebar/taxi.svg" alt="image" class="mr-10" />
                        Microsites
                      </div>
                      <div class="icon-chevron-sm-down text-7"></div>
                    </div>
                  </div>

                  <div class="accordion__content">
                    <ul class="list-disc pt-15 pb-5 pl-40">

                      <li>
                        <a href="/admin/individual-microsites" class="text-15">Individual Microsites</a>
                      </li>

                      <li>
                        <a href="/admin/group-microsites" class="text-15">Group Microsites</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>


            </div>

            

            <div class="sidebar__item ">


              <div class="accordion -db-sidebar js-accordion">
                <div class="accordion__item">
                  <div class="accordion__button">
                    <div class="sidebar__button col-12 d-flex items-center justify-between">
                      <div class="d-flex items-center text-15 lh-1 fw-500">
                        <img src="/assets/img/dashboard/sidebar/booking.svg" alt="image" class="mr-15" />
                        Settings
                      </div>
                      <div class="icon-chevron-sm-down text-7"></div>
                    </div>
                  </div>

                  <div class="accordion__content">
                    <ul class="list-disc pt-15 pb-5 pl-40">

                      <li>
                        <a href="/admin/homepage-widgets" class="text-15">Homepage Widgets</a>
                      </li>

                      <li>
                        <a href="/admin/homepage-banners" class="text-15">Homepage Banners</a>
                      </li>

                      <li>
                        <a href="/admin/common-pages" class="text-15">Common Pages</a>
                      </li>

                      <li>
                        <a href="/admin/menu-banners" class="text-15">Menu Banners</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>


            </div>

            <div class="sidebar__item ">


              <a onClick={handleLogout} href="#" class="sidebar__button d-flex items-center text-15 lh-1 fw-500">
                <img src="/assets/img/dashboard/sidebar/log-out.svg" alt="image" class="mr-15" />
                Logout
              </a>


            </div>

          </div>

        </div>

      </div>
    );
  }
}

export default Sidebar;