import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import 'react-quill/dist/quill.snow.css';
import "cropperjs/dist/cropper.min.css";
import { isAuthenticated } from '../middleware/admin_auth';

function AdminProductCategoryEdit() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);
    
    const { id } = useParams();
    const [productCategory, setProductCategory] = useState({ id: null, type: '', packagename: '', featured: '' });

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}product_categories/${id}`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data) && data.length > 0) {
              setProductCategory(data[0]);
            }
          })
          .catch(error => console.error(error));
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    
    
    const handleSaveDetails = () => {
        console.log("productCategory", productCategory);
        const packageUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_product_category_update`;
        fetch(packageUpdate1, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(productCategory),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };
    
    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Edit Product Category</h1>

                    </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:200}}>

                    <div class="col-12">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={productCategory?.category_name || ''}
                            name="productCategory"
                            onChange={(event) => setProductCategory(prevState => ({ ...prevState, category_name: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">ProductCategory</label>
                        </div>
                    </div>

                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={productCategory?.featured || ''}
                            name="featured_position"
                            onChange={(event) => setProductCategory(prevState => ({ ...prevState, featured: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Featured Position (Enter by number to prioritize, not mandatory)</label>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                            <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                            </a>
                        </div>
                        <div style={{clear:'both'}}></div>
                    </div>

                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminProductCategoryEdit;