import React from 'react';

function AdminFooter() {

    return (
        <footer class="footer -dashboard mt-60">
            <div class="footer__row row y-gap-10 items-center justify-between">
            <div class="col-auto">
                <div class="row y-gap-20 items-center">
                    <div class="col-auto">
                        <div class="text-14 lh-14 mr-30">© 2023 Blessingsonthenet.com, All rights reserved.</div>
                    </div>

                    {/*
                    <div class="col-auto">
                        <div class="row x-gap-20 y-gap-10 items-center text-14">
                        <div class="col-auto">
                            <a href="#" class="text-13 lh-1">Privacy</a>
                        </div>
                        <div class="col-auto">
                            <a href="#" class="text-13 lh-1">Terms</a>
                        </div>
                        <div class="col-auto">
                            <a href="#" class="text-13 lh-1">Site Map</a>
                        </div>
                        </div>
                    </div>
                    */}
                </div>
            </div>
        
            </div>
        </footer>
    );
}

export default AdminFooter;