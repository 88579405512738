import React, { useState, useEffect } from 'react';
import AdminFooter from './AdminFooter';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminCities() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const [citiesData, setCities] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Define totalPages variable

    useEffect(() => {
        // Fetch cities data from API based on the current page
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?page=${currentPage}`;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setCities(data);
                setTotalPages(data.length); // Update totalPages based on data length
            })
            .catch((error) => console.log(error));
    }, [currentPage]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <div class="dashboard__main">
            <div class="dashboard__content bg-light-2">
            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">
    
                <h1 class="text-30 lh-14 fw-600">Cities</h1>
    
                </div>
    
                <div class="col-auto">
    
    
                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                    Add New City <div class="icon-arrow-top-right ml-15"></div>
                </a>
    
    
                </div>
            </div>
    
    
            <div class="py-30 px-30 rounded-4 bg-white shadow-3">
    
                <div class="tabs__content pt-30 js-tabs-content">
    
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <div class="overflow-scroll scroll-bar-1">
                        <table class="table-4 -border-bottom col-12">
                        <thead class="bg-light-2">
                            <tr>
                            <th>City</th>
                            <th>State</th>
                            <th>Region</th>
                            <th>Country</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
    
                        {citiesData.map(city => (
                            <tr key={city.id}>
                            <td className="text-blue-1 fw-500">{city.city}</td>
                            <td>{city.state}</td>
                            <td>{city.region}</td>
                            <td>
                                <span class="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">{city.country}</span>
                            </td>
                            <td>
                                <div className="row x-gap-10 y-gap-10 items-center">
                                <div className="col-auto">
                                    <button className="flex-center bg-light-2 rounded-4 size-35">
                                    <i className="icon-edit text-16 text-light-1"></i>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button className="flex-center bg-light-2 rounded-4 size-35">
                                    <i className="icon-trash-2 text-16 text-light-1"></i>
                                    </button>
                                </div>
                                </div>
                            </td>
                            </tr>
                        ))}
    
                        </tbody>
                        </table>
                    </div>
                    </div>
    
                </div>
    
                <div class="pt-30">
                    <div class="row justify-between">
                        <div class="col-auto">

                        </div>

                        <div class="col-auto">
                            <div className="row x-gap-20 y-gap-20 items-center pagination">
                                {currentPage > 4 &&
                                    <div
                                        className="col-auto pagination_numbers"
                                        onClick={() => handlePageClick(1)}
                                    >
                                        <div className="size-40 flex-center rounded-full">
                                            1
                                        </div>
                                    </div>
                                }
                                {currentPage > 5 &&
                                    <div className="col-auto">
                                        <div className="size-40 flex-center rounded-full">
                                            ...
                                        </div>
                                    </div>
                                }
                                {Array.from({ length: 7 }, (_, index) => {
                                    const page = index + currentPage - 3;
                                    if (page <= 0) return null; // Skip negative and zero pages
                                    //if (page > totalPages) return null; // Skip pages beyond total
                                    return (
                                        <div
                                        key={index}
                                        className={`col-auto pagination_numbers ${
                                            page === currentPage ? "bg-dark-1 text-white" : ""
                                        }`}
                                        >
                                        <div
                                            className="size-40 flex-center rounded-full"
                                            onClick={() => handlePageClick(page)}
                                        >
                                            {page}
                                        </div>
                                        </div>
                                    );
                                })}
                                {currentPage < totalPages - 4 &&
                                    <div className="col-auto">
                                        <div className="size-40 flex-center rounded-full">
                                            ...
                                        </div>
                                    </div>
                                }
                                {currentPage < totalPages - 3 &&
                                    <div
                                        className="col-auto pagination_numbers"
                                        onClick={() => handlePageClick(totalPages)}
                                    >
                                        <div className="size-40 flex-center rounded-full">
                                            {totalPages}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div class="col-auto">
                            
                        </div>
                    </div>
                </div>
            </div>
    
    
            <AdminFooter />
            </div>
        </div>
    );
}

export default AdminCities;