import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminHomamPlans() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const { id } = useParams();
    const [homamsData, setHomams] = useState([]);

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}plans/${id}`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
        .then(response => response.json())
        .then(data => setHomams(data))
        .catch(error => console.error(error));
    }, []);

    return (
        <div class="dashboard__main">
            <div class="dashboard__content bg-light-2">
            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">
    
                    <h1 class="text-30 lh-14 fw-600">Plans Available for Page ID {id}</h1>
    
                </div>
                
                <div class="col-auto">
                    <a href={`/admin/homam/create/child/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        Add New Plan <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
            </div>
    
    
            <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:250}}>
    
                <div class="tabs__content pt-30 js-tabs-content">
    
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <div class="overflow-scroll scroll-bar-1">
                        <table class="table-4 -border-bottom col-12">
                        <thead class="bg-light-2">
                            <tr>
                            {/*<th>Title</th>*/}
                            <th>Plan</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
    
                        {homamsData.length > 0 ? (
                            homamsData.map(hhomam => (
                                <tr key={hhomam.id}>
                                {/*
                                <td className="text-blue-1 fw-500">
                                    <a
                                    target="_blank"
                                    href={`/travel-india/tour-homam/id/${hhomam.id}/${hhomam.name.replace(/\s+/g, '-')}`}
                                    >
                                    {hhomam.name}
                                    </a>
                                </td>
                                */}
                                <td>
                                    <p>{hhomam.plan_name}</p>
                                </td>
                                <td>
                                    <div className="row x-gap-10 y-gap-10 items-center">
                                    <div className="col-auto">
                                        <a href={`/admin/homam/edit/${hhomam.id}`}>
                                        <button title="Edit" className="flex-center bg-light-2 rounded-4 size-35">
                                            <i className="icon-edit text-16 text-light-1"></i>
                                        </button>
                                        </a>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            title="Delete"
                                            className="flex-center bg-light-2 rounded-4 size-35"
                                            onClick={() => {
                                            if (window.confirm("Are you sure you want to delete this homam?")) {
                                                window.location.href = `/admin/homam/delete/${hhomam.id}`;
                                            }
                                            }}
                                        >
                                            <i className="icon-trash-2 text-16 text-light-1"></i>
                                        </button>
                                    </div>
                                    </div>
                                </td>
                                </tr>
                            ))
                            ) : (
                            <tr>
                                <td colSpan="3" className="text-center">
                                No Plans Found
                                </td>
                            </tr>
                            )}
    
                        </tbody>
                        </table>
                    </div>
                    </div>
    
                </div>

            </div>
    
    
            <AdminFooter />
            </div>
        </div>
    );
}

export default AdminHomamPlans;