// routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Update import statement
import Home from './components/Home';
import Holiday from './components/Holiday';
import HolidayListings from './components/HolidayListings';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import AdminCities from './components/AdminCities';
import AdminPackageListings from './components/AdminPackageListings';
import AdminPackagePlans from './components/AdminPackagePlans';
import AdminPackageEdit from './components/AdminPackageEdit';
import AdminPackageCreate from './components/AdminPackageCreate';
import AdminPackageDelete from './components/AdminPackageDelete';
import AdminInterestDelete from './components/AdminInterestDelete';
import AdminInterests from './components/AdminInterests';
import AdminInterestEdit from './components/AdminInterestEdit';
import AdminInterestCreate from './components/AdminInterestCreate';
import Hotel from './components/Hotel';
import HotelListings from './components/HotelListings';
import AdminHotelListings from './components/AdminHotelListings';
import AdminHotelEdit from './components/AdminHotelEdit';
import AdminHotelRoomTypes from './components/AdminHotelRoomTypes';
import AdminRoomTypeEdit from './components/AdminRoomTypeEdit';
import AdminRoomTypeCreate from './components/AdminRoomTypeCreate';
import AdminRoomTypeDelete from './components/AdminRoomTypeDelete';
import AdminHotelCreate from './components/AdminHotelCreate';
import AdminHomepageWidgets from './components/AdminHomepageWidgets';
import AdminHomeWidgetEdit from './components/AdminHomeWidgetEdit';
import AdminHomeWidgetCreate from './components/AdminHomeWidgetCreate';
import AdminAttractionsListings from './components/AdminAttractionsListings';
import AdminEateriesListings from './components/AdminEateriesListings';
import AdminAttractionEdit from './components/AdminAttractionEdit';
import AdminEateryEdit from './components/AdminEateryEdit';
import AdminAttractionCreate from './components/AdminAttractionCreate';
import AdminEateryCreate from './components/AdminEateryCreate';
import AdminAttractionEateryDelete from './components/AdminAttractionEateryDelete';
import Attraction from './components/Attraction';
import Eatery from './components/Eateries';
import AdminProductsListings from './components/AdminProductsListings';
import AdminProductEdit from './components/AdminProductEdit';
import AdminProductCreate from './components/AdminProductCreate';
import ProductListings from './components/ProductListings';
import Product from './components/Product';
import Cart from './components/Cart';
import Cart3 from './components/Cart3';
import Cart3PaymentCancelled from './components/Cart3PaymentCancelled';
import AdminProductCategories from './components/AdminProductCategories';
import AdminProductCategoryEdit from './components/AdminProductCategoryEdit';
import AdminProductCategoryCreate from './components/AdminProductCategoryCreate';
import AdminProductCategoryDelete from './components/AdminProductCategoryDelete';
import Homam from './components/Homam';
import HomamListings from './components/HomamListings';
import AdminHomamListings from './components/AdminHomamListings';
import AdminHomamPlans from './components/AdminHomamPlans';
import AdminHomamEdit from './components/AdminHomamEdit';
import AdminHomamCreate from './components/AdminHomamCreate';
import AdminHomamDelete from './components/AdminHomamDelete';
import Home2 from './components/Home2';
import UserLogin from './components/Login';
import AdminOrdersListings from './components/AdminOrdersListings';
import AdminEnquiryListings from './components/AdminEnquiryListings';
import AdminOrdersView from './components/AdminOrdersView';
import AdminIndividualMicrositesListings from './components/AdminIndividualMicrositesListings';
import AdminIndividualMicrositeEdit from './components/AdminIndividualMicrositeEdit';
import AdminMicroArticleEdit from './components/AdminMicroArticleEdit';
import AdminMicroArticleDelete from './components/AdminMicroArticleDelete';
import AdminGroupMicrositesListings from './components/AdminGroupMicrositesListings';
import AdminMicroGroupEdit from './components/AdminMicroGroupEdit';
import AdminMicroGroupTemples from './components/AdminMicroGroupTemples';
import AdminMicroTempleDelete from './components/AdminMicroTempleDelete';
import AdminMicroArticleCreate from './components/AdminMicroArticleCreate';
import AdminHomeBanners from './components/AdminHomeBanners';
import HomeStore from './components/HomeStore';
import AdminCommonPages from './components/AdminCommonPages';
import AdminCommonPageEdit from './components/AdminCommonPageEdit';
import CommonPage from './components/CommonPage';
import AdminHotelDelete from './components/AdminHotelDelete';
import AdminHotelCategories from './components/AdminHotelCategories';
import AdminHotelCategoryEdit from './components/AdminHotelCategoryEdit';
import AdminHotelCategoryCreate from './components/AdminHotelCategoryCreate';
import AdminHotelCategoryDelete from './components/AdminHotelCategoryDelete';
import HomeHomam from './components/HomeHomam';
import AdminStoreWidgets from './components/AdminStoreWidgets';
import AdminProductWidgetEdit from './components/AdminProductWidgetEdit';
import AdminWidgetDelete from './components/AdminWidgetDelete';
import AdminProductWidgetCreate from './components/AdminProductWidgetCreate';
import AdminHomamWidgets from './components/AdminHomamWidgets';
import AdminHomamWidgetEdit from './components/AdminHomamWidgetEdit';
import AdminHomamWidgetCreate from './components/AdminHomamWidgetCreate';
import TempleListings from './components/TempleListings';
import Temple from './components/Temple';
import TempleArticle from './components/TempleArticle';
import AdminTempleListings from './components/AdminTempleListings';
import AdminTempleEdit from './components/AdminTempleEdit';
import AdminHotelGroupListings from './components/AdminHotelGroupListings';
import AdminHotelGroupEdit from './components/AdminHotelGroupEdit';
import AdminMenuBanners from './components/AdminMenuBanners';
import AdminTempleCreate from './components/AdminTempleCreate';
import PayOnline from './components/PayOnline';
import AdminDirectPayments from './components/AdminDirectPayments';
import AdminDirectPaymentView from './components/AdminDirectPaymentView';
import HotelContactInfo from './components/HotelContactInfo';
import AdminEnquiryView from './components/AdminEnquiryView';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home2" element={<Home2 />} />
      <Route path="/store-home" element={<HomeStore />} />
      <Route path="/homam-home" element={<HomeHomam />} />
      <Route path="/:name" element={<CommonPage />} />
      <Route path="/payonline" element={<PayOnline />} />

      {/* Holiday Packages Routes */}
      <Route path="/travel-india/tour-package/id/:id/:title" element={<Holiday />} />
      <Route path="/travels/travel/page/:id" element={<HolidayListings />} />
      <Route path="/travel-india/tour-package/activity/id/:categoryId/:title/page/:id" element={<HolidayListings />} />
      <Route path="/travel-india/tour-package/activity/id/:categoryId/:title" element={<HolidayListings />} />
      <Route path="/travel-india/destination/state/:state/page/:id" element={<HolidayListings />} />
      <Route path="/travel-india/destination/state/:state" element={<HolidayListings />} />

      {/* Hotel Routes */}
      <Route path="/hotel/:name/:id" element={<Hotel />} />
      <Route path="/hotels/gallery/:name/:id" element={<Hotel />} />
      <Route path="/hotels/page/:id" element={<HotelListings />} />
      <Route path="/hotel-india" element={<HotelListings />} />
      <Route path="/hotel-india/page/:id" element={<HotelListings />} />
      <Route path="/hotels/type/:name/:categoryId/0" element={<HotelListings />} />
      <Route path="/hotels/type/:name/:categoryId/0/page/:id" element={<HotelListings />} />
      <Route path="/hotels/state/:state" element={<HotelListings />} />
      <Route path="/hotels/state/:state/page/:id" element={<HotelListings />} />
      <Route path="/hotels/group/:hgroup" element={<HotelListings />} />
      <Route path="/hotels/group/:hgroup/page/:id" element={<HotelListings />} />

      {/* Hotel Routes for SEO*/}
      <Route path="/hotels/accommodation-enquiry/:name/:id" element={<Hotel />} />
      <Route path="/hotels/facility/:name/:cityName/:id" element={<Hotel />} />
      <Route path="/hotels/room-type/:name/:id" element={<Hotel />} />
      {/*<Route path="/hotels/contactinfo/:name/:id" element={<Hotel />} />*/}
      <Route path="/hotels/contactinfo/:name/:id" element={<HotelContactInfo />} />

      {/* Attractions/Eateries Routes */}
      <Route path="/attractions/:name/:id" element={<Attraction />} />  
      <Route path="/eateries/:name/:id" element={<Eatery />} />

      {/* Shopping Routes */}
      <Route path="/admin/product/edit/:id" element={<AdminProductEdit />} />
      <Route path="/products/page/:id" element={<ProductListings />} />
      <Route path="/products/type/:name/:categoryId" element={<ProductListings />} />
      <Route path="/products/type/:name/:categoryId/page/:id" element={<ProductListings />} />
      <Route path="/products/material/:material/:materialId" element={<ProductListings />} />
      <Route path="/products/material/:material/page/:id" element={<ProductListings />} />
      <Route path="/products/material/:material/page/:materialId/:id" element={<ProductListings />} />
      <Route path="/products/material/:material/:materialId/page/:id" element={<ProductListings />} />
      <Route path="/product/:name/:id" element={<Product />} />

      {/* Homam Packages Routes */}
      <Route path="/homam-astrology/:id/:title" element={<Homam />} />
      <Route path="/homam-astrology/page/:id" element={<HomamListings />} />
      <Route path="/homam-astrology/:title/:id" element={<HomamListings />} />
      <Route path="/homam-astrology/category/:categoryId/:title/page/:id" element={<HomamListings />} />
      <Route path="/homam-astrology/category/:categoryId/:title" element={<HomamListings />} />
      <Route path="/homam-astrology/destination/state/:state/page/:id" element={<HomamListings />} />
      <Route path="/homam-astrology/destination/state/:state" element={<HomamListings />} />

      {/* Temple Routes */}
      <Route path="/indian-temple" element={<TempleListings />} />
      <Route path="/indian-temple/page/:id" element={<TempleListings />} />
      <Route path="/temples/state/:state" element={<TempleListings />} />
      <Route path="/temples/state/:state/page/:id" element={<TempleListings />} />
      <Route path="/indian-temple/id/:id/:title" element={<Temple />} />
      <Route path="/indian-temple/article/:id/:title" element={<TempleArticle />} />

      {/* Booking Routes */}
      <Route path="/cart" element={<Cart />} />
      <Route path="/order-confirmed" element={<Cart3 />} />
      <Route path="/payment-cancelled" element={<Cart3PaymentCancelled />} />

      {/* Admin Routes */}
      <Route path="/guru" element={<AdminLogin />} />
      <Route path="/admin" element={<AdminDashboard />} />
      <Route path="/admin/cities" element={<AdminCities />} />
      <Route path="/admin/packages" element={<AdminPackageListings />} />
      <Route path="/admin/package/plan/:id" element={<AdminPackagePlans />} />
      <Route path="/admin/package/edit/:id" element={<AdminPackageEdit />} />
      <Route path="/admin/package/create" element={<AdminPackageCreate />} />
      <Route path="/admin/interest/create" element={<AdminInterestCreate />} />
      <Route path="/admin/hotel-category/create" element={<AdminHotelCategoryCreate />} />
      <Route path="/admin/package/delete/:id" element={<AdminPackageDelete />} />
      <Route path="/admin/interest/delete/:id" element={<AdminInterestDelete />} />
      <Route path="/admin/hotel-category/delete/:id" element={<AdminHotelCategoryDelete />} />
      <Route path="/admin/package/create/child/:parentId" element={<AdminPackageCreate />} />
      <Route path="/admin/interests" element={<AdminInterests />} />
      <Route path="/admin/hotel-categories" element={<AdminHotelCategories />} />
      <Route path="/admin/interest/edit/:id" element={<AdminInterestEdit />} />
      <Route path="/admin/hotel-category/edit/:id" element={<AdminHotelCategoryEdit />} />
      <Route path="/admin/hotel/edit/:id" element={<AdminHotelEdit />} />
      <Route path="/admin/hotels" element={<AdminHotelListings />} />
      <Route path="/admin/hotels/page/:id" element={<AdminHotelListings />} />
      <Route path="/admin/hotel/room/types/:id" element={<AdminHotelRoomTypes />} />
      <Route path="/admin/hotel/room/type/edit/:id" element={<AdminRoomTypeEdit />} />
      <Route path="/admin/hotel/create/room/:parentId" element={<AdminRoomTypeCreate />} />
      <Route path="/admin/hotel/room/delete/:id" element={<AdminRoomTypeDelete />} />
      <Route path="/admin/widget/delete/:id/:widget_type" element={<AdminWidgetDelete />} />
      <Route path="/admin/hotel/delete/:id" element={<AdminHotelDelete />} />
      <Route path="/admin/hotel/create" element={<AdminHotelCreate />} />
      <Route path="/admin/homepage-widgets" element={<AdminHomepageWidgets />} />
      <Route path="/admin/store-widgets" element={<AdminStoreWidgets />} />
      <Route path="/admin/homam-widgets" element={<AdminHomamWidgets />} />
      <Route path="/admin/widget/edit/:id" element={<AdminHomeWidgetEdit />} />
      <Route path="/admin/product-widget/edit/:id" element={<AdminProductWidgetEdit />} />
      <Route path="/admin/homam-widget/edit/:id" element={<AdminHomamWidgetEdit />} />
      <Route path="/admin/widget/create" element={<AdminHomeWidgetCreate />} />
      <Route path="/admin/product-widget/create" element={<AdminProductWidgetCreate />} />
      <Route path="/admin/homam-widget/create" element={<AdminHomamWidgetCreate />} />
      <Route path="/admin/attractions" element={<AdminAttractionsListings />} />
      <Route path="/admin/attractions/page/:id" element={<AdminAttractionsListings />} />
      <Route path="/admin/eateries" element={<AdminEateriesListings />} />
      <Route path="/admin/eateries/page/:id" element={<AdminEateriesListings />} />
      <Route path="/admin/attraction/edit/:id" element={<AdminAttractionEdit />} />
      <Route path="/admin/eatery/edit/:id" element={<AdminEateryEdit />} />
      <Route path="/admin/attraction/create" element={<AdminAttractionCreate />} />
      <Route path="/admin/eateries/create" element={<AdminEateryCreate />} />
      <Route path="/admin/attraction_eatery/delete/:id" element={<AdminAttractionEateryDelete />} />
      <Route path="/admin/products" element={<AdminProductsListings />} />
      <Route path="/admin/products/page/:id" element={<AdminProductsListings />} />
      <Route path="/admin/product/create" element={<AdminProductCreate />} />
      <Route path="/admin/product_categories" element={<AdminProductCategories />} />
      <Route path="/admin/product_category/edit/:id" element={<AdminProductCategoryEdit />} />
      <Route path="/admin/product_category/create" element={<AdminProductCategoryCreate />} />
      <Route path="/admin/product_category/delete/:id" element={<AdminProductCategoryDelete />} />
      <Route path="/admin/homam-astrology" element={<AdminHomamListings />} />
      <Route path="/admin/homam-astrology/plan/:id" element={<AdminHomamPlans />} />
      <Route path="/admin/homam-astrology/edit/:id" element={<AdminHomamEdit />} />
      <Route path="/admin/homam-astrology/create" element={<AdminHomamCreate />} />
      <Route path="/admin/homam-astrology/delete/:id" element={<AdminHomamDelete />} />
      <Route path="/admin/homam-astrology/create/child/:parentId" element={<AdminHomamCreate />} />
      <Route path="/admin/homam-astrology/page/:id" element={<AdminHomamListings />} />
      <Route path="/login" element={<UserLogin />} />
      <Route path="/admin/orders" element={<AdminOrdersListings />} />
      <Route path="/admin/orders/page/:id" element={<AdminOrdersListings />} />
      <Route path="/admin/orders/:id" element={<AdminOrdersView />} />
      <Route path="/admin/enquiries" element={<AdminEnquiryListings />} />
      <Route path="/admin/enquiries/page/:id" element={<AdminEnquiryListings />} />
      <Route path="/admin/enquiry/:id" element={<AdminEnquiryView />} />
      <Route path="/admin/direct-payment/:id" element={<AdminDirectPaymentView />} />
      <Route path="/admin/individual-microsites" element={<AdminIndividualMicrositesListings />} />
      <Route path="/admin/individual-microsites/page/:id" element={<AdminIndividualMicrositesListings />} />
      <Route path="/admin/micro/editsettings/:id" element={<AdminIndividualMicrositeEdit />} />
      <Route path="/admin/micro/editsettings/:id/page/:pageId" element={<AdminIndividualMicrositeEdit />} />
      <Route path="/admin/micro/article/edit/:id" element={<AdminMicroArticleEdit />} />
      <Route path="/admin/micro/article/delete/:id" element={<AdminMicroArticleDelete />} />
      <Route path="/admin/group-microsites" element={<AdminGroupMicrositesListings />} />
      <Route path="/admin/micro/group/edit/:id" element={<AdminMicroGroupEdit />} />
      <Route path="/admin/micro/group/temples/:id" element={<AdminMicroGroupTemples />} />
      <Route path="/admin/micro/group/temples/:id/page/:pageId" element={<AdminMicroGroupTemples />} />
      <Route path="/admin/micro/temple/delete/:id" element={<AdminMicroTempleDelete />} />
      <Route path="/admin/micro/article/create/:id" element={<AdminMicroArticleCreate />} />
      <Route path="/admin/homepage-banners" element={<AdminHomeBanners />} />
      <Route path="/admin/common-pages" element={<AdminCommonPages />} />
      <Route path="/admin/common/edit/:id" element={<AdminCommonPageEdit />} />
      <Route path="/admin/temples" element={<AdminTempleListings />} />
      <Route path="/admin/temples/page/:id" element={<AdminTempleListings />} />
      <Route path="/admin/temple/edit/:id" element={<AdminTempleEdit />} />
      <Route path="/admin/hotel-groups" element={<AdminHotelGroupListings />} />
      <Route path="/admin/hotel-groups/page/:id" element={<AdminHotelGroupListings />} />
      <Route path="/admin/hotel-group/edit/:id" element={<AdminHotelGroupEdit />} />
      <Route path="/admin/menu-banners" element={<AdminMenuBanners />} />
      <Route path="/admin/temple/create" element={<AdminTempleCreate />} />
      <Route path="/admin/direct-payments" element={<AdminDirectPayments />} />
    </Routes>
  );
}

export default AppRoutes;