import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import PrimarySearchBox from './PrimarySearchBox';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {Helmet} from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { SubscribeRibbon } from './SubscribeRibbon';

function Home() {

  const [widgetData, setWidgetData] = useState([]);

  useEffect(() => {
    const setCustomAttributes = () => {
      const sectionSliders = document.querySelectorAll('.js-section-slider');
      sectionSliders.forEach((slider) => {
        if (!slider.getAttribute('data-gap')) {
          slider.setAttribute('data-gap', '30');
        }
        if (!slider.getAttribute('data-scrollbar')) {
          slider.setAttribute('data-scrollbar', '');
        }
        if (!slider.getAttribute('data-slider-cols')) {
          slider.setAttribute('data-slider-cols', 'base-2 xl-4 lg-3 md-2 sm-2 base-1');
        }
        if (!slider.getAttribute('data-anim')) {
          slider.setAttribute('data-anim', 'slide-up');
        }
      });
    };
  
    setCustomAttributes();
  
    /*
    const initializeSwiper = () => {
      new Swiper('.js-section-slider', {
        // Swiper configuration options
        loop: true,
        slidesPerView:4,
        spaceBetween:30
      });
    };
  
    // Wait for the DOM elements to render before initializing Swiper
    setTimeout(initializeSwiper, 50);
    */
  }, [widgetData]);

  useEffect(() => {
    const fetchWidgetData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}admin_homepage_widgets`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData(data);
      } catch (error) {
        console.error('Error fetching widget data:', error);
      }
    };
  
    fetchWidgetData();
  }, []);
  
  const fetchHotelData = async (ids, widgetId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?id=${ids}`;
      console.log("apiUrl:", apiUrl);
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWidgetData((prevData) =>
        prevData.map((widget) =>
          widget.id === widgetId ? { ...widget, hotelData: data } : widget
        )
      );
    } catch (error) {
      console.error('Error fetching hotel data:', error);
    }
  };
  
  const fetchTourData = async (ids, widgetId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour_listings?id=${ids}`;
      console.log("Tour API: ", apiUrl);
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWidgetData((prevData) =>
        prevData.map((widget) =>
          widget.id === widgetId ? { ...widget, packageData: data } : widget
        )
      );
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };

  const fetchProductData = async (ids, widgetId) => {
    try {
      if(ids){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}product_listings?id=${ids}`;
        console.log("product API: ", apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData((prevData) =>
          prevData.map((widget) =>
            widget.id === widgetId ? { ...widget, productData: data } : widget
          )
        );
      }
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };

  const fetchHomamData = async (ids, widgetId) => {
    try {
      if(ids){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?id=${ids}`;
        console.log("Homam API", apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData((prevData) =>
          prevData.map((widget) =>
            widget.id === widgetId ? { ...widget, homamData: data } : widget
          )
        );
      }
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };
  
  useEffect(() => {
    const fetchDataForWidgets = async () => {
      for (const widget of widgetData) {
        if (widget.widget_category === 'hotels' && !widget.hotelData) {
          await fetchHotelData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'packages' && !widget.packageData) {
          await fetchTourData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'homam' && !widget.homamData) {
          await fetchHomamData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'astrology' && !widget.homamData) {
          await fetchHomamData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'products' && !widget.productData) {
          await fetchProductData(widget.widget_ids, widget.id);
        } 
      }
    };
  
    fetchDataForWidgets();
  }, [widgetData]);

  const renderWidgetSections = () => {
    return widgetData.map((widget) => {
      if (widget.widget_category === 'packages' && widget.packageData) {
        return (
          <section key={widget.id} className="layout-pt-lg layout-pb-md">
            <div className="container">
              <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>

                <div className="col-auto md:d-none">

                  <a href="/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Holidays <div className="icon-arrow-top-right ml-15"></div>
                  </a>

                </div>
              </div>

              <div className="relative pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden is-in-view">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      // When window width is <= 767px (typically mobile phones), show 2 slides
                      767: {
                        slidesPerView: 2,
                      },
                      // Default: When window width is > 767px, show 4 slides
                      // You can adjust this breakpoint based on your design requirements
                      768: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                  {widget.packageData.map((hpackage) => (
                    <SwiperSlide>
                      <div className="swiper-slide swiper-slide-visible swiper-slide-active" key={hpackage.id} 
                      style={{marginRight: '30px'}}>

                        <a href={`/travel-india/tour-package/id/${hpackage.id}/${hpackage.name.replace(/\s+/g, '-')}`} className="citiesCard -type-1 d-block rounded-4 ">
                          <div className="citiesCard__image ratio ratio-1:1">
                            <img
                              src={hpackage.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `/img/uploads/tour/thumbs/${hpackage.thumb}`}
                              data-src={hpackage.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `/img/uploads/tour/thumbs/${hpackage.thumb}`}
                              alt="image"
                            />
                          </div>

                          <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                            <div className="citiesCard__bg"></div>

                            <div className="citiesCard__top">
                              <div className="text-14 text-white">{hpackage.nights} Night {hpackage.days} Days</div>
                            </div>

                            <div className="citiesCard__bottom">
                              <h4 className="text-20 md:text-20 lh-13 text-white mb-20">{hpackage.name}</h4>
                              {hpackage.price !== '' && hpackage.price !== 0 ? (
                                <>
                                  <p className="starts_from">Starts from ₹{hpackage.price}/-</p>
                                </>
                              ) : (
                                <>
                                  <p className="starts_from">Contact Us for Pricing</p>
                                </>
                              )}
                              <button className="button col-12 h-60 -blue-1 bg-white text-dark-1">View Details</button>
                            </div>
                          </div>
                        </a>

                      </div>
                    </SwiperSlide>
                  ))}
                  </Swiper>
                </div>
                

                {/*
                <button className="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-prev">
                  <i className="icon icon-chevron-left text-12"></i>
                </button>

                <button className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-next">
                  <i className="icon icon-chevron-right text-12"></i>
                </button>


                <div className="slider-scrollbar bg-light-2 mt-40 sm:d-none js-scrollbar"></div>
                */}

                <div className="row pt-20 d-none md:d-block">
                  <div className="col-auto">
                    <div className="d-inline-block">

                      <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                        View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else if (widget.widget_category === 'hotels' && widget.hotelData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/hotels/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Hotels <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      // When window width is <= 767px (typically mobile phones), show 2 slides
                      767: {
                        slidesPerView: 2,
                      },
                      // Default: When window width is > 767px, show 4 slides
                      // You can adjust this breakpoint based on your design requirements
                      768: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                  {widget.hotelData.map((hotel) => (
                    <SwiperSlide>
                      <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{marginRight:"30px"}} key={hotel.id}>
          
                        <a href={`/hotel/${hotel.name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} className="hotelsCard -type-1 ">
                          <div className="hotelsCard__image">
          
                            <div className="cardImage ratio ratio-1:1">
                              <div className="cardImage__content">
                                <img
                                src={
                                  hotel.hotel_image_new.includes('aim_')
                                    ? `/img/uploads/hotels/roomgallery/${hotel.hotel_image_new.replace(/ /g, '%20')}`
                                    : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${hotel.hotel_image_new.replace(/ /g, '%20')}`
                                }
                                alt="image"
                                />
          
                              </div>
          
                            </div>
          
                          </div>
          
                          <div className="hotelsCard__content mt-10">
                            <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                              <span>{hotel.name}</span>
                            </h4>
          
                            <p className="text-light-1 lh-14 text-14 mt-5">{hotel.city ? hotel.city : ''}{hotel.state ? ', '+hotel.state : ''}</p>
          
                            <div className="mt-5">
                              <div className="fw-500">
                                {!hotel.price || hotel.price === 0
                                  ? ""
                                  : 'Starting from '}
                                <span className="text-blue-1">
                                {!hotel.price || hotel.price === 0
                                  ? "Contact us for Price"
                                  : `₹${hotel.price}/-`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
          
                      </div>
                    </SwiperSlide>
                  ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
          </section>
        );
      } else if (widget.widget_category === 'products' && widget.productData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/products/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Products <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      // When window width is <= 767px (typically mobile phones), show 2 slides
                      767: {
                        slidesPerView: 2,
                      },
                      // Default: When window width is > 767px, show 4 slides
                      // You can adjust this breakpoint based on your design requirements
                      768: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {widget.productData.map((hotel) => (
                      <SwiperSlide>
                        <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{marginRight:"30px"}} key={hotel.id}>
            
                          <a href={`/product/${hotel.product_name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} className="hotelsCard -type-1 ">
                            <div className="hotelsCard__image">
            
                              <div className="cardImage ratio ratio-1:1">
                                <div className="cardImage__content">
                                  <img
                                  src={`${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${hotel.image_src !== null ? hotel.image_src.replace(/ /g, '%20') : ''}`}
                                  alt="image"
                                  />
            
                                </div>
            
                              </div>
            
                            </div>
            
                            <div className="hotelsCard__content mt-10 text-center">
                              <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                <span>{hotel.product_name}</span>
                              </h4>
            
                              <div className="mt-5">
                                <div className="fw-500">
                                  {!hotel.price || hotel.price === 0
                                    ? ""
                                    : ''}
                                  <span className="text-blue-1">
                                  {!hotel.price || hotel.price === 0
                                    ? "Contact us for Price"
                                    : `₹${hotel.price}/-`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
            
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
          </section>
        );
      } else if (widget.widget_category === 'homam' && widget.homamData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/homam-astrology/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Homam <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      // When window width is <= 767px (typically mobile phones), show 2 slides
                      767: {
                        slidesPerView: 2,
                      },
                      // Default: When window width is > 767px, show 4 slides
                      // You can adjust this breakpoint based on your design requirements
                      768: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {widget.homamData.map((hotel) => (
                      <SwiperSlide>
                        <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{marginRight:"30px"}} key={hotel.id}>
            
                          <a href={`/homam-astrology/${hotel.id}/${hotel.name.toLowerCase().replace(/\s+/g, '-')}`} className="hotelsCard -type-1 ">
                            <div className="hotelsCard__image">
            
                              <div className="cardImage ratio ratio-1:1">
                                <div className="cardImage__content">
                                  <img
                                  src={`${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${hotel.thumb_new.replace(/ /g, '%20')}`}
                                  alt="image"
                                  />
            
                                </div>
            
                              </div>
            
                            </div>
            
                            <div className="hotelsCard__content mt-10 text-center">
                              <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                <span>{hotel.name}</span>
                              </h4>
            
                              <div className="mt-5">
                                <div className="fw-500">
                                  {!hotel.charges_1 || hotel.charges_1 === 0
                                    ? "Starts from"
                                    : ''}
                                  <span className="text-blue-1">
                                  {!hotel.charges_1 || hotel.charges_1 === 0
                                    ? "Contact us for Price"
                                    : `₹${hotel.charges_1}/-`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
            
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
            
          </section>
        );
      } else if (widget.widget_category === 'astrology' && widget.homamData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/homam-astrology/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Astrology <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      // When window width is <= 767px (typically mobile phones), show 2 slides
                      767: {
                        slidesPerView: 2,
                      },
                      // Default: When window width is > 767px, show 4 slides
                      // You can adjust this breakpoint based on your design requirements
                      768: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {widget.homamData.map((hotel) => (
                      <SwiperSlide>
                        <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{marginRight:"30px"}} key={hotel.id}>
            
                          <a href={`/homam-astrology/${hotel.id}/${hotel.name.toLowerCase().replace(/\s+/g, '-')}`} className="hotelsCard -type-1 ">
                            <div className="hotelsCard__image">
            
                              <div className="cardImage ratio ratio-1:1">
                                <div className="cardImage__content">
                                  <img
                                  src={`${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${hotel.thumb_new.replace(/ /g, '%20')}`}
                                  alt="image"
                                  />
            
                                </div>
            
                              </div>
            
                            </div>
            
                            <div className="hotelsCard__content mt-10 text-center">
                              <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                <span>{hotel.name}</span>
                              </h4>
            
                              <div className="mt-5">
                                <div className="fw-500">
                                  {!hotel.price || hotel.price === 0
                                    ? "Starts from"
                                    : ''}
                                  <span className="text-blue-1">
                                  {!hotel.price || hotel.price === 0
                                    ? "Contact us for Price"
                                    : `₹${hotel.price}/-`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
            
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
            
          </section>
        );
      } else if (widget.widget_category === 'ecommerce') {
        return (
          <section key={widget.id} className="layout-pt-lg layout-pb-md">
            <div className="ecommerce_container"></div>
          </section>
        );
      } else {
        return null; // Widget category not recognized
      }
    });
  };


  //Header image slider
  const [swiperData, setSwiperData] = useState([]);
  
  useEffect(() => {
    const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?site_category=home_banners&id=1`;
    console.log('microDataUrl:', microDataUrl);
    // Replace with your API URL
    fetch(microDataUrl)
      .then((response) => response.json())
      .then((data) => setSwiperData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Tour Packages, Holidays, Hotels & Homam in India | Blessingsonthenet.com</title>
    </Helmet>
    <section data-anim-wrap className="masthead -type-1 z-5">
      <div data-anim-child="fade" className="swiper-wrapper masthead__bg">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img src={`${process.env.REACT_APP_API_ENDPOINT}uploads/home_banners/${item.image_path}`} alt={`Slide ${item.id}`} className="js-lazy" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      
      <div className="container">
        <div className="row justify-center">
          <div className="col-auto">
            <div className="text-center mb-40">
              <h1 data-anim-child="slide-up delay-1" className="text-30 lg:text-40 md:text-30 text-white">The Most Trusted Travel And Spiritual Website In India</h1>
              <p data-anim-child="slide-up delay-2" className="text-white mt-6 md:mt-10 text-22">Crafting Your Unforgettable Travel Experiences</p>
            </div>

            <PrimarySearchBox />
          </div>
        </div>
      </div>
      
    </section>
    <section className="z-5 mb-50 mobile_hidden" style={{position:'relative', marginTop:'-80px'}}>
      <div style={{height:'60px'}} className="swiper-wrapper masthead__bg">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img style={{display:'none'}} src={`${process.env.REACT_APP_API_ENDPOINT}uploads/home_banners/${item.image_path}`} alt={`Slide ${item.id}`} className="js-lazy" />
              {item.caption != null ?
              (<center>
                <a target="_blank" href={item.caption}>
                  <button class="view_offer_btn float-right mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white">View this Offer &nbsp;<span className='icon-play'></span></button>
                </a>
              </center>) : null
              }
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      
    </section>

    {widgetData.length > 0 ? renderWidgetSections() : <p className='text-center'>Loading data, please wait...</p>}


    <section class="section-bg layout-pt-lg layout-pb-lg">
      <div class="section-bg__item -mx-20 bg-light-2"></div>

      <div data-anim-wrap class="container">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center">
          <div class="col-auto pt-40">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Customer Testimonials</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">When our guests experienced their dream come true</p>
            </div>
          </div>
        </div>

        <div class="relative mt-40 md:mt-40 js-section-slider" data-gap="30" data-slider-cols="xl-3 lg-3 md-2 sm-1 base-1">
          <div class="swiper-wrapper">

            <div data-anim-child="slide-up delay-2" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">21 Day India Tour – Sole Female Tourist from UK Travelling across
Maharashtra – Rajasthan – Himachal Pradesh</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;Hi Ramesh,
                  I personally want to thank you for all your hard work & support you gave me during my yatra.
                  All your team were great too, everyone looked after me.
                  If you or your team need anything from UK, just drop me a line, I will be happy to help.
                  My trip has encouraged me to travel more with your company as I have received a lot of personnel attention & care.
                  I can’t thank you enough
                  &quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">Vidi</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">Customer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-3" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">Second Tour of Vidi - Jammu & Kashmir, Amarnath, Vaishno Devi</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;Dear Ramesh,
Once again Many Thanks for organizing my 2nd Yatra, which was a success by the grace of god.
Again I can’t thank you enough for looking after me & organizing my trip, you have wonderful contacts/agents which were so helpful,
informative & looked after me as well.
Kashmir is definitely heaven on earth, very beautiful, Amarnath was breath taking, Katra again was so peaceful & Shiv Khori was a
wonderful spiritual experience I had.
Deepa & Dinesh were very helpful too, Thank you. Look forward to plan more yatra with wonderful company & people in future.
God bless you with all the happiness & prosperity.
Best Wishes
Vidi&quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">Vidi</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">Customer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-3" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">Senior Citizens on their Pilgrimage Tour - Pune Pandharpur Tuljapur
Nashik</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;Hi Mr.Sekar,
My mom and her friends returned to chennai on Saturday night.
All three were quite happy about their tour. They felt the trip was so nice and comfortable from the beginning to end.
I would like to thank you for the wonderful arrangements and prompt responses through email.
Thank you once again.&quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">Shree</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">Customer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-4" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">Guest from Dubai –Tour of Ashtavinayak Pandharpur Mahalaxmi Temple
Kolhapur</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;Dear rajeshree
We had a good tour of AshtaVinayak and Pandarpur and all the rest of the itinery as planned Had a wonderful trip and great darshans.
Feeling happy and blessed.
We have been dropped off at Pune airport, to catch our flight to a Dubai.
Thanks for all your efforts and planning.
Looking forward to doing yet another tour with BLESSINGS ON THE NET
&quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">JANAKI GOPALA</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">Customer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-5" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">Guest from Coimbatore on Shirdi Tour</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;Hello Pallavi,
I would like to thank you for all the help you have done to make our travel " The best experience" for us! The driver you have
arranged Mr. Suresh was knowledgeable and very helpful even though we had some language issues, we are extremely happy with the
service and totally recommend your agency to our friends and family. &quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">Courtney Henry</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">Web Designer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-6" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">Guest from London – WomenTraveller Tour of Mumbai - Shirdi</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;Thank You For Your Assistance , It was the Best Experience ever , Still Need to Book Extra Luggage for flight to Delhi, Did Some Shopping in Shirdi&quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">Guest from London</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">Customer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-6" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">Guest from Coimbatore on Shirdi Tour</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;I would like to convey our sincere thanks for Nirvana India Enterprise,Yourself,Dinesh sharma,and our cab driver Ganesh for all the
arrangements for our Pune-Bheema sankar-Pandarpur-solapur-tuljapur-pune tour for 3 day from 28th Feb to 2nd Marc2016.The stay ,
itinerary, vehicle and the friendliness of all of you was very nice and entire tour was trouble free by God's grace and the involvement
of all.of you. My thanks.
Kindly convey this to all concerned who are part of this tour. I look forward for many such tours with you.
When you are free please suggest such 2nights/3days tour of certain places If there is any feed back form pl send i will fill up.
&quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">L.SUNDARARAMAN</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">Customer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-6" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">Business Partner ( Travel Agent) on a tour organized by Nirvana</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;Dear Ramesh,
Greetings from Goodness!
My apology to send my feedback bit late as was traveling.
The client whom you handled through us rated your service as ‘Very Good'. Am too happy that we have chosen a right partner
for this service and look forward for a rewarding relationship.
&quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">Lawrence M Rosario</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">Business Partner</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-6" class="swiper-slide">
              <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40">
                <h4 class="text-16 fw-500 text-blue-1 mb-30">Business Partner ( Travel Agent) on a tour organized by Nirvana</h4>
                <p class="testimonials__text lh-18 fw-500 text-dark-1">&quot;1Night/2 Days Dear All, First of all please accept my sincere thanks to everyone those who ever dedicated in this particular
booking. The client had confirmed that the trip was excellent. Review : Mr,Yasunathan who had taken care the guest very
well right from the Pune airport pick up till the drop point end to end which is really amazing. Over and above you have
upgraded the car category as Innova through out the trip simply superb! Also booked hotel and the Deluxe category room was
really excellent. I am so glad really i found the best professionals in the market.... Thank you all......... With immediate effect
I will make sure that whatever packages within India will reach you by default from the undersigned. Once again my sincere
thanks to all and take care!
&quot;</p>

                <div class="pt-20 mt-28 border-top-light">
                  <div class="row x-gap-20 y-gap-20 items-center">
                    <div class="col-auto">
                      <img class="size-60" src="img/avatar.png" alt="image" />
                    </div>

                    <div class="col-auto">
                      <div class="text-15 fw-500 lh-14">K.SHANKAR</div>
                      <div class="text-14 lh-14 text-light-1 mt-5">NATURE TOURS AND AIR TRAVEL SERVICES</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <button class="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-prev">
            <i class="icon icon-chevron-left text-12"></i>
          </button>

          <button class="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-next">
            <i class="icon icon-chevron-right text-12"></i>
          </button>

        </div>
      </div>
    </section>
    
    {/*
    <section className="layout-pt-md layout-pb-md">
      <div className="container">
        <div className="row y-gap-20">
          <div data-anim="slide-up" className="col-md-6">

            <div className="ctaCard -type-1 rounded-4 ">
              <div className="ctaCard__image ratio ratio-63:55">
                <img className="img-ratio js-lazy" src="#" data-src="/assets/img/backgrounds/1.png" alt="image" />
              </div>

              <div className="ctaCard__content py-70 px-70 lg:py-30 lg:px-30">


                <h4 className="text-40 lg:text-26 text-white">Hotel Sun & Sand<br /> Shirdi</h4>

                <div className="d-inline-block mt-30">
                  <a href="#" className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1">View Details</a>
                </div>
              </div>
            </div>

          </div>

          <div data-anim="slide-up delay-1" className="col-md-6">

            <div className="ctaCard -type-1 rounded-4 ">
              <div className="ctaCard__image ratio ratio-63:55">
                <img className="img-ratio js-lazy" src="#" data-src="/assets/img/holidays/2.png" alt="image" />
              </div>

              <div className="ctaCard__content py-70 px-70 lg:py-30 lg:px-30">

                <div className="text-15 fw-500 text-white mb-10">Enjoy Summer Deals</div>


                <h4 className="text-40 lg:text-26 text-white">Up to 70% Discount!</h4>

                <div className="d-inline-block mt-30">
                  <a href="#" className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1">Learn More</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


    <section style={{paddingTop:120}} className="layout-pt-lg layout-pb-lg bg-blue-2">
      <div data-anim-wrap className="container">
        <div className="row y-gap-40 justify-between">
          <div data-anim-child="slide-up delay-1" className="col-xl-5 col-lg-6">
            <h2 className="text-30">What our customers are<br /> saying about us?</h2>
            <p className="mt-20">We have been serving holiday packages on various categories for over 15 years now with most of the client with happy faces.</p>

            <div className="row y-gap-30 pt-60 lg:pt-40">
              <div className="col-sm-5 col-6">
                <div className="text-30 lh-15 fw-600">5k+</div>
                <div className="text-light-1 lh-15">Happy Customers</div>
              </div>

              <div className="col-sm-5 col-6">
                <div className="text-30 lh-15 fw-600">4.88</div>
                <div className="text-light-1 lh-15">Overall rating</div>

                <div className="d-flex x-gap-5 items-center pt-10">

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                </div>
              </div>
            </div>
          </div>

          <div data-anim-child="slide-up delay-1" className="col-lg-6">
            <div className="overflow-hidden js-testimonials-slider-3" data-scrollbar>
              <div className="swiper-wrapper">

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatar.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Anitha Rajan</h5>
                      <div className="text-15 text-light-1 lh-15">Working Profession</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">Blessingsonthenet team helped us getting best holiday package and we are very happy with their service. I would highly recommend to my friends. Give it a try and experience their service once.</p>
                </div>

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatar.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Annette Black</h5>
                      <div className="text-15 text-light-1 lh-15">UX / UI Designer</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">The place is in a great location in Gumbet. The area is safe and beautiful. The apartment was comfortable and the host was kind and responsive to our requests.</p>
                </div>

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatar.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Annette Black</h5>
                      <div className="text-15 text-light-1 lh-15">UX / UI Designer</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">The place is in a great location in Gumbet. The area is safe and beautiful. The apartment was comfortable and the host was kind and responsive to our requests.</p>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    */}
    <SubscribeRibbon />

    </>
  );
}

export default Home;