import React, { useState, useEffect } from 'react';
import AdminFooter from './AdminFooter';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminHomepageWidgets() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const [widgetsData, setWidgets] = useState([]);

    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}admin_homepage_widgets?admin=1`;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setWidgets(data);
            })
            .catch((error) => console.log(error));
    });


    return (
        <div class="dashboard__main">
            <div class="dashboard__content bg-light-2">
            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">
    
                    <h1 class="text-30 lh-14 fw-600">Homepage Widgets</h1>
    
                </div>
                
                <div class="col-auto">
                    <a href="/admin/widget/create" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        Add New Widget <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
            </div>
    
    
            <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:'200px'}}>
    
                <div class="tabs__content pt-30 js-tabs-content">
    
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <div class="overflow-scroll scroll-bar-1">
                        <table class="table-4 -border-bottom col-12">
                        <thead class="bg-light-2">
                            <tr>
                            <th>Widget Name</th>
                            <th>Category</th>
                            <th>Position</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
    
                        {widgetsData.map(widget => (
                            <tr key={widget.id}>
                            <td>{widget.widget_name}</td>
                            <td>{widget.widget_category.charAt(0).toUpperCase() + widget.widget_category.slice(1)}</td>
                            <td>{widget.widget_position}</td>
                            <td>
                                <div className="row x-gap-10 y-gap-10 items-center">
                                    {/*
                                    <div className="col-auto">
                                        <a target="_blank" href={`/admin/widget/room/types/${widget.id}`} >
                                            <button title="Plans" className="flex-center bg-light-2 rounded-4 size-35">
                                            <i className="icon-globe text-16 text-light-1"></i>
                                            </button>
                                        </a>
                                    </div>
                                    */}
                                    <div className="col-auto">
                                        <a href={`/admin/widget/edit/${widget.id}`} >
                                            <button title="Edit" className="flex-center bg-light-2 rounded-4 size-35">
                                            <i className="icon-edit text-16 text-light-1"></i>
                                            </button>
                                        </a>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            title="Delete"
                                            className="flex-center bg-light-2 rounded-4 size-35"
                                            onClick={() => {
                                            if (window.confirm("Are you sure you want to delete this widget?")) {
                                                window.location.href = `/admin/widget/delete/${widget.id}/homepage`;
                                            }
                                            }}
                                        >
                                            <i className="icon-trash-2 text-16 text-light-1"></i>
                                        </button>
                                    </div>

                                </div>
                            </td>
                            </tr>
                        ))}
    
                        </tbody>
                        </table>
                    </div>
                    </div>
    
                </div>
            </div>
    
    
            <AdminFooter />
            </div>
        </div>
    );
}

export default AdminHomepageWidgets;