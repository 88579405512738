import React, { useState, useEffect } from 'react';
import { SubscribeRibbon } from './SubscribeRibbon';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { isAuthenticated } from '../middleware/user_auth';

function Cart3PaymentCancelled() {

  return (
    <div>
        <section class="pt-40">
            <div class="container">
                <div class="row x-gap-40 y-gap-30 items-center">
                <div class="col-auto">
                    <div class="d-flex items-center">
                    <div class="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">1</div>
                    <div class="text-18 fw-500 ml-10">Your Details</div>
                    </div>
                </div>

                <div class="col">
                    <div class="w-full h-1 bg-border"></div>
                </div>

                <div class="col-auto">
                    <div class="d-flex items-center">
                    <div class="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">2</div>
                    <div class="text-18 fw-500 ml-10">Payment Method</div>
                    </div>
                </div>

                <div class="col">
                    <div class="w-full h-1 bg-border"></div>
                </div>

                <div class="col-auto">
                    <div class="d-flex items-center">
                    <div class="size-40 rounded-full flex-center bg-blue-1">
                        <i class="icon-check text-16 text-white"></i>
                    </div>
                    <div class="text-18 fw-500 ml-10">Confirmation</div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <section class="pt-40 layout-pb-md">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 mt-30 mb-80">
                        <center>
                            <h2 class="text-22 fw-500 mt-40 md:mt-24" style={{color:'red'}}>Something went wrong!</h2><br />
                            <p>There seems to be some issue in processing your payment, please try after some time.</p>
                            <p>Feel free to contact us for any help at travel@blessingsonthenet.com</p>
                        </center>
                    </div>
                </div>
            </div>
        </section>




      <SubscribeRibbon />
    </div>
  );
}

export default Cart3PaymentCancelled;
