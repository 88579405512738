import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import PrimarySearchBox from './PrimarySearchBox';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {Helmet} from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { SubscribeRibbon } from './SubscribeRibbon';

function HomeHomam() {

  const [widgetData, setWidgetData] = useState([]);

  useEffect(() => {
    const setCustomAttributes = () => {
      const sectionSliders = document.querySelectorAll('.js-section-slider');
      sectionSliders.forEach((slider) => {
        if (!slider.getAttribute('data-gap')) {
          slider.setAttribute('data-gap', '30');
        }
        if (!slider.getAttribute('data-scrollbar')) {
          slider.setAttribute('data-scrollbar', '');
        }
        if (!slider.getAttribute('data-slider-cols')) {
          slider.setAttribute('data-slider-cols', 'base-2 xl-4 lg-3 md-2 sm-2 base-1');
        }
        if (!slider.getAttribute('data-anim')) {
          slider.setAttribute('data-anim', 'slide-up');
        }
      });
    };
  
    setCustomAttributes();
  
  }, [widgetData]);

  useEffect(() => {
    const fetchWidgetData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}admin_homam_widgets`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData(data);
      } catch (error) {
        console.error('Error fetching widget data:', error);
      }
    };
  
    fetchWidgetData();
  }, []);
  
  const fetchHotelData = async (ids, widgetId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?id=${ids}`;
      console.log("apiUrl:", apiUrl);
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWidgetData((prevData) =>
        prevData.map((widget) =>
          widget.id === widgetId ? { ...widget, hotelData: data } : widget
        )
      );
    } catch (error) {
      console.error('Error fetching hotel data:', error);
    }
  };
  
  const fetchTourData = async (ids, widgetId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour_listings?id=${ids}`;
      console.log("Tour API: ", apiUrl);
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWidgetData((prevData) =>
        prevData.map((widget) =>
          widget.id === widgetId ? { ...widget, packageData: data } : widget
        )
      );
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };

  const fetchProductData = async (ids, widgetId) => {
    try {
      if(ids){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}product_listings?id=${ids}`;
        console.log("product API: ", apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData((prevData) =>
          prevData.map((widget) =>
            widget.id === widgetId ? { ...widget, productData: data } : widget
          )
        );
      }
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };

  const fetchHomamData = async (ids, widgetId) => {
    try {
      if(ids){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?id=${ids}`;
        console.log("Homam API", apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData((prevData) =>
          prevData.map((widget) =>
            widget.id === widgetId ? { ...widget, homamData: data } : widget
          )
        );
      }
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };
  
  useEffect(() => {
    const fetchDataForWidgets = async () => {
      for (const widget of widgetData) {
        if (widget.widget_category === 'homam' && !widget.homamData) {
            await fetchHomamData(widget.widget_ids, widget.id);
        } else if (widget.widget_category === 'astrology' && !widget.homamData) {
        await fetchHomamData(widget.widget_ids, widget.id);
        }
      }
    };
  
    fetchDataForWidgets();
  }, [widgetData]);

  const renderWidgetSections = () => {
    return widgetData.map((widget) => {
        if (widget.widget_category === 'homam' && widget.homamData) {
            return (
              <section key={widget.id} className="layout-pt-md layout-pb-md">
                <div data-anim="slide-up delay-1" className="container is-in-view">
                  <div className="row y-gap-10 justify-between items-end">
                    <div className="col-auto">
                      <div className="sectionTitle -md">
                        <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                      </div>
                    </div>
          
                    <div className="col-auto md:d-none">
          
                      <a href="/homam-astrology/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                        View All Homam <div className="icon-arrow-top-right ml-15"></div>
                      </a>
          
                    </div>
                  </div>
          
                  <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                    <div className="swiper-wrapper">
                      <Swiper
                        spaceBetween={30}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        loop={true}
                        navigation={true}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper"
                        breakpoints={{
                          // When window width is <= 767px (typically mobile phones), show 2 slides
                          767: {
                            slidesPerView: 2,
                          },
                          // Default: When window width is > 767px, show 4 slides
                          // You can adjust this breakpoint based on your design requirements
                          768: {
                            slidesPerView: 4,
                          },
                        }}
                      >
                        {widget.homamData.map((hotel) => (
                          <SwiperSlide>
                            <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{marginRight:"30px"}} key={hotel.id}>
                
                              <a href={`/homam-astrology/${hotel.id}/${hotel.name.toLowerCase().replace(/\s+/g, '-')}`} className="hotelsCard -type-1 ">
                                <div className="hotelsCard__image">
                
                                  <div className="cardImage ratio ratio-1:1">
                                    <div className="cardImage__content">
                                      <img
                                      src={`${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${hotel.thumb_new.replace(/ /g, '%20')}`}
                                      alt="image"
                                      />
                
                                    </div>
                
                                  </div>
                
                                </div>
                
                                <div className="hotelsCard__content mt-10 text-center">
                                  <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                    <span>{hotel.name}</span>
                                  </h4>
                
                                  <div className="mt-5">
                                    <div className="fw-500">
                                      {!hotel.charges_1 || hotel.charges_1 === 0
                                        ? "Starts from"
                                        : ''}
                                      <span className="text-blue-1">
                                      {!hotel.charges_1 || hotel.charges_1 === 0
                                        ? "Contact us for Price"
                                        : `₹${hotel.charges_1}/-`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
          
                  </div>
                </div>
                
              </section>
            );
        } else if (widget.widget_category === 'astrology' && widget.homamData) {
            return (
              <section key={widget.id} className="layout-pt-md layout-pb-md">
                <div data-anim="slide-up delay-1" className="container is-in-view">
                  <div className="row y-gap-10 justify-between items-end">
                    <div className="col-auto">
                      <div className="sectionTitle -md">
                        <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                      </div>
                    </div>
          
                    <div className="col-auto md:d-none">
          
                      <a href="/homam-astrology/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                        View All Astrology <div className="icon-arrow-top-right ml-15"></div>
                      </a>
          
                    </div>
                  </div>
          
                  <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                    <div className="swiper-wrapper">
                      <Swiper
                        spaceBetween={30}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        loop={true}
                        navigation={true}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper"
                        breakpoints={{
                          // When window width is <= 767px (typically mobile phones), show 2 slides
                          767: {
                            slidesPerView: 2,
                          },
                          // Default: When window width is > 767px, show 4 slides
                          // You can adjust this breakpoint based on your design requirements
                          768: {
                            slidesPerView: 4,
                          },
                        }}
                      >
                        {widget.homamData.map((hotel) => (
                          <SwiperSlide>
                            <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{marginRight:"30px"}} key={hotel.id}>
                
                              <a href={`/homam-astrology/${hotel.id}/${hotel.name.toLowerCase().replace(/\s+/g, '-')}`} className="hotelsCard -type-1 ">
                                <div className="hotelsCard__image">
                
                                  <div className="cardImage ratio ratio-1:1">
                                    <div className="cardImage__content">
                                      <img
                                      src={`${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${hotel.thumb_new.replace(/ /g, '%20')}`}
                                      alt="image"
                                      />
                
                                    </div>
                
                                  </div>
                
                                </div>
                
                                <div className="hotelsCard__content mt-10 text-center">
                                  <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                    <span>{hotel.name}</span>
                                  </h4>
                
                                  <div className="mt-5">
                                    <div className="fw-500">
                                      {!hotel.price || hotel.price === 0
                                        ? "Starts from"
                                        : ''}
                                      <span className="text-blue-1">
                                      {!hotel.price || hotel.price === 0
                                        ? "Contact us for Price"
                                        : `₹${hotel.price}/-`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
          
                  </div>
                </div>
                
              </section>
            );
        } else {
        return null; // Widget category not recognized
      }
    });
  };


  //Header image slider
  const [swiperData, setSwiperData] = useState([]);
  
  useEffect(() => {
    const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?site_category=home_banners&id=1`;
    console.log('microDataUrl:', microDataUrl);
    // Replace with your API URL
    fetch(microDataUrl)
      .then((response) => response.json())
      .then((data) => setSwiperData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Book Homam Online | Blessingsonthenet.com</title>
    </Helmet> 
    <section data-anim-wrap className="masthead -type-1 z-5">
      <div data-anim-child="fade" className="swiper-wrapper masthead__bg">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img src={`${process.env.REACT_APP_API_ENDPOINT}uploads/home_banners/${item.image_path}`} alt={`Slide ${item.id}`} className="js-lazy" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      
      <div className="container">
        <div className="row justify-center">
          <div className="col-auto">
            <div className="text-center mb-40">
              <h1 data-anim-child="slide-up delay-1" className="text-40 lg:text-40 md:text-30 text-white">Acquire Punyam & Spiritual Desires</h1>
              <p data-anim-child="slide-up delay-2" className="text-white mt-6 md:mt-10 text-22">Discover various rituals that we offer Lord Agni</p>
            </div>

            <PrimarySearchBox />
          </div>
        </div>
      </div>
      
    </section>
    <section className="z-5" style={{position:'relative', marginTop:'-80px'}}>
      <div style={{height:'60px'}} className="swiper-wrapper masthead__bg">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img style={{display:'none'}} src={`${process.env.REACT_APP_API_ENDPOINT}uploads/home_banners/${item.image_path}`} alt={`Slide ${item.id}`} className="js-lazy" />
              {item.caption != null ?
              (<center>
                <a target="_blank" href={item.caption}>
                  <button class="view_offer_btn float-right mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white">View this Offer &nbsp;<span className='icon-play'></span></button>
                </a>
              </center>) : null
              }
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      
    </section>

    {widgetData.length > 0 ? renderWidgetSections() : <p className='text-center'>Loading data, please wait...</p>}

    
        <SubscribeRibbon />
    </>
  );
}

export default HomeHomam;