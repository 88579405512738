import React, { useState, useEffect } from 'react';
import AdminFooter from './AdminFooter';
import { useParams } from 'react-router-dom';
import { isAuthenticated } from '../middleware/admin_auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function AdminEateriesListings() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const { id, keyword, city } = useParams();
    const [eateriesListings, setEateriesListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Define totalPages variable

    const location = useLocation();
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState(0);

    const [searchValue, setSearchValue] = useState([]);

    useEffect(() => {
        let page = id ? parseInt(id) : currentPage; // Use the value from the URL if present
        setCurrentPage(page);
      
        const searchParams = new URLSearchParams(location.search);
      
        if (keyword) {
          searchParams.set('keyword', keyword);
          searchParams.set('city', keyword);
        }
  
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}attractions_eateries?page=${page}&cat=eatery&${searchParams.toString()}`;
        console.log("apiUrl: ", apiUrl);      
      
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setEateriesListings(data);
              setTotalCount(data.length > 0 ? data[0].totalCount : 0);
              const totalPages = Math.ceil(data[0].totalCount / 20);
              setTotalPages(totalPages);
          })
          .catch((error) => console.log(error));
    }, [id, currentPage, keyword, totalCount]);
  
    const handlePageClick = (page) => {
        setCurrentPage(page);
      
        const path = location.pathname;
        const currentQueryParams = new URLSearchParams(location.search);
        if(searchValue){
            currentQueryParams.set('keyword', searchValue); // Append the keyword to the URL
            currentQueryParams.set('city', searchValue); // Append the keyword to the URL
        }
      
        let newPath = path;
      
        if (path.includes('/page/')) {
          newPath = path.replace(/\/page\/\d+/, `/page/${page}`);
        } else {
          newPath = `${path}/page/${page}`;
        }
      
        const newURL = `${newPath}?${currentQueryParams.toString()}`;
        navigate(newURL);
      
        // Scroll to the top of the page
        window.scrollTo({
          top: 200,
          behavior: 'smooth',
        });
    };      


    const appendFilterToURL = (filterType, value) => {
        const searchParams = new URLSearchParams(location.search);
      
        if (filterType === 'page') {
          searchParams.set(filterType, value);
        } else {
          if (searchParams.has(filterType)) {
            searchParams.delete(filterType);
          }
      
          searchParams.set(filterType, value);
        }
      
        const newURL = `${location.pathname}?${searchParams.toString()}`;
        navigate(newURL);
    };
    
    function updateURLParameter(url, param, value) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(param);
        searchParams.append(param, value);
        return `${url.split('?')[0]}?${searchParams.toString()}`;
    } 


    const [searchResults, setSearchResults] = useState([]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';
      
        if (isBackspace) {
          setSearchValue('');
          window.location.href = '/admin/eateries/page/1';
        } else {
          const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}attractions_eateries?page=${currentPage}&cat=eatery&keyword=${inputValue}&city=${inputValue}`;
          console.log("apiSearchURL:", apiUrl);
          fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
              setEateriesListings(data);
            })
            .catch(error => console.error(error));
          setSearchValue(inputValue);
          //console.log("searchValue: ", searchValue);
        }
    };      

    


    return (
        <div class="dashboard__main">
            <div class="dashboard__content bg-light-2">
            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">
    
                    <h1 class="text-30 lh-14 fw-600">All Eateries</h1>
    
                </div>

                <div class="col-auto">
                    <div class="w-230 single-field relative d-flex items-center">
                        <input
                        className="pl-50 bg-white text-dark-1 h-50 rounded-8"
                        type="text"
                        placeholder="Search"
                        onChange={handleInputChange}
                        value={searchValue}
                        />
                        <ul className="autocomplete-results">
                        {searchResults.map(result => (
                            <li key={result.name}>{result.name}</li>
                        ))}
                        </ul>
                        <button class="absolute d-flex items-center h-full">
                            <i class="icon-search text-20 px-15 text-dark-1"></i>
                        </button>
                    </div>
                </div>
                
                <div class="col-auto">
                    <a href="/admin/eateries/create" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        Add New Eateries <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
            </div>
    
    
            <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:'250px'}}>
    
                <div class="tabs__content pt-30 js-tabs-content">
    
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <div class="overflow-scroll scroll-bar-1">
                        <table class="table-4 -border-bottom col-12">
                        <thead class="bg-light-2">
                            <tr>
                            <th>Eateries Name</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
    
                        {eateriesListings.map(heateries => (
                            <tr key={heateries.id}>
                            <td className="text-blue-1 fw-500"><a target="_blank" 
                            href={`/eateries/${heateries.title.toLowerCase().replace(/\s+/g, '-')}/${heateries.id}`}
                            >{heateries.title}</a></td>
                            <td>{heateries.city_name}</td>
                            <td>{heateries.state_name}</td>
                            <td>
                                <div className="row x-gap-10 y-gap-10 items-center">
                                
                                <div className="col-auto">
                                    <a href={`/admin/eatery/edit/${heateries.id}`} >
                                        <button title="Edit" className="flex-center bg-light-2 rounded-4 size-35">
                                        <i className="icon-edit text-16 text-light-1"></i>
                                        </button>
                                    </a>
                                </div>
                                <div className="col-auto">
                                    <button
                                        title="Delete"
                                        className="flex-center bg-light-2 rounded-4 size-35"
                                        onClick={() => {
                                        if (window.confirm("Are you sure you want to delete this eateries?")) {
                                            window.location.href = `/admin/attraction_eatery/delete/${heateries.id}`;
                                        }
                                        }}
                                    >
                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                    </button>
                                </div>

                                </div>
                            </td>
                            </tr>
                        ))}
    
                        </tbody>
                        </table>
                    </div>
                    </div>
    
                </div>

                <div class="pt-30">
                    <div class="row justify-between">
                        <div class="col-auto">

                        </div>

                        <div class="col-auto">
                            <div className="row x-gap-20 y-gap-20 items-center pagination">
                            {currentPage !== 1 && (
                                <div
                                    className="col-auto pagination_numbers"
                                    onClick={() => handlePageClick(1)}
                                >
                                    <div className="size-40 flex-center rounded-full">1</div>
                                </div>
                                )}
                                {currentPage > 3 && (
                                <div className="col-auto">
                                    <div className="size-40 flex-center rounded-full">...</div>
                                </div>
                                )}
                                {Array.from({ length: totalPages }, (_, index) => {
                                const page = index + 1;
                                if (page === totalPages) return null; // Skip the last page number
                                if (
                                    page < currentPage - 1 ||
                                    page > currentPage + 1 ||
                                    (currentPage === 2 && page === 1) // Skip displaying 1 when on the second page
                                )
                                    return null;
                                return (
                                    <div
                                    key={index}
                                    className={`col-auto pagination_numbers ${
                                        page === currentPage ? "bg-dark-1 text-white" : ""
                                    }`}
                                    onClick={() => handlePageClick(page)}
                                    >
                                    <div className="size-40 flex-center rounded-full">{page}</div>
                                    </div>
                                );
                                })}
                                {currentPage < totalPages - 2 && (
                                <div className="col-auto">
                                    <div className="size-40 flex-center rounded-full">...</div>
                                </div>
                                )}
                                {currentPage !== totalPages && (
                                <div
                                    className="col-auto pagination_numbers"
                                    onClick={() => handlePageClick(totalPages)}
                                >
                                    <div className="size-40 flex-center rounded-full">{totalPages}</div>
                                </div>
                                )}
                            </div>
                        </div>

                        <div class="col-auto">
                            
                        </div>
                    </div>
                </div>
            </div>
    
    
            <AdminFooter />
            </div>
        </div>
    );
}

export default AdminEateriesListings;