import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

const Row = ({ index, rowData, onChange, onDelete }) => {
    const handleChange = (e) => {
      onChange(index, e.target.name, e.target.value);
    };
  
    const handleDelete = () => {
      onDelete(index);
    };
  
    return (
        <>
        <div class="row py-5">
            <div class="col-lg-2 col-md-2">
                <select name="no_of_people" value={rowData.no_of_people} onChange={handleChange}>
                <option value="1">Single Pax</option>
                <option value="2">02 Pax</option>
                <option value="4">04 Pax</option>
                <option value="6">06 Pax</option>
                <option value="8">08 Pax</option>
                <option value="10">10 Pax</option>
                <option value="12">12 Pax</option>
                <option value="14">14 Pax</option>
                <option value="16">16 Pax</option>
                <option value="18">18 Pax</option>
                <option value="20">20 Pax</option>
                <option value="22">22 Pax</option>
                <option value="24">24 Pax</option>
                <option value="26">26 Pax</option>
                <option value="28">28 Pax</option>
                <option value="30">30 Pax</option>
                <option value="Extra Person">Extra Person</option>
                <option value="Child With Bed">Child With Bed</option>
                <option value="Child Without Bed">Child Without Bed</option>
                <option value="Single Room Supp">Single Room Supp</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-2">
                <select name="vehicle_type" value={rowData.vehicle_type} onChange={handleChange}>
                <option value="Sedan">Sedan</option>
                <option value="Ertiga">Ertiga</option>
                <option value="Innova">Innova</option>
                <option value="Innova Crysta">Innova Crysta</option>
                <option value="Tempo">Tempo</option>
                <option value="Tempo Traveller (14)">Tempo Traveller (14)</option>
                <option value="Tempo Traveller (17)">Tempo Traveller (17)</option>
                <option value="Mini Coach (21)">Mini Coach (21)</option>
                </select>
            </div>
            <div class="col-lg-1 col-md-1">
                <input
                type="text"
                name="budget_plan"
                placeholder="Budget Plan"
                value={rowData.budget_plan}
                onChange={handleChange}
                title="Budget Plan"
                />
            </div>
            <div class="col-lg-1 col-md-1">
                <input
                type="text"
                name="standard_plan"
                placeholder="Standard Plan"
                value={rowData.standard_plan}
                onChange={handleChange}
                title="Standard Plan"
                />
            </div>
            <div class="col-lg-1 col-md-1">
                <input
                type="text"
                name="semi_deluxe_plan"
                placeholder="Semi-Deluxe Plan"
                value={rowData.semi_deluxe_plan}
                onChange={handleChange}
                title="Semi-Deluxe Plan"
                />
            </div>
            <div class="col-lg-1 col-md-1">
                <input
                type="text"
                name="deluxe_plan"
                placeholder="Deluxe Plan"
                value={rowData.deluxe_plan}
                onChange={handleChange}
                title="Deluxe Plan"
                />
            </div>
            <div class="col-lg-1 col-md-1">
                <input
                type="text"
                name="semi_luxury_plan"
                placeholder="Semi-Luxury Plan"
                value={rowData.semi_luxury_plan}
                onChange={handleChange}
                title="Semi-Luxury Plan"
                />
            </div>
            <div class="col-lg-1 col-md-1">
                <input
                type="text"
                name="luxury_plan"
                placeholder="Luxury Plan"
                value={rowData.luxury_plan}
                onChange={handleChange}
                title="Luxury Plan"
                />
            </div>
            <div class="col-lg-1 col-md-1">
                <input
                type="text"
                name="premium_plan"
                placeholder="Premium Plan"
                value={rowData.premium_plan}
                onChange={handleChange}
                title="Premium Plan"
                />
            </div>
            <div class="col-lg-1 col-md-1">
                <div onClick={handleDelete} class="d-inline-block">
                    <a href="#" style={{padding:'5px 10px'}} class="button -dark-1 bg-yellow-1 text-dark-1">
                        <div class="icon-trash px-5 py-5"></div>
                    </a>
                </div>
            </div>
        </div>
        <hr />
      </>
    );
};

function AdminPackageEdit() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];
    
    const { id } = useParams();
    const [packageData, setPackageData] = useState({});
    const { 
        name
        ,subtitle
        ,brief
        ,description
        ,featured
        ,temples
        ,hoteles
        ,cities
        ,state
        ,timeoftravel
        ,tour_type
        ,interests
        ,days
        ,nights
        ,tourtype
        ,price
        ,use_price
        ,tour_metakey
        ,tour_metadesc
        ,highlights
        ,transport_details
        ,includes_excludes
        ,reporting_dropping
        ,cancellation_policy
        ,important_info
        ,special_notes
        ,tourist_attractions
        ,eateries_shopping
        ,plan_name
        ,drive_kms
    } = packageData;

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour/${id}`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
        .then(response => response.json())
        .then(data => setPackageData(data))
        .catch(error => console.error(error));
    }, []);

    const [tourTypes, setTourTypes] = useState([]);

    const tourTypesUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_interest_types`;
    useEffect(() => {
        fetch(tourTypesUrl)
          .then(res => res.json())
          .then(data => setTourTypes(data))
          .catch(err => console.log(err));
    }, []);


    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    

    const handleSaveDetails = () => {
        const updatedPackageData = {
            ...packageData,
            plan_name,
            brief,
            description,
            tour_metadesc,
            tour_metakey,
            featured,
            timeoftravel,
            cities: checkedCities.join(','),
            state: checkedStates.join(','),
            hoteles: checkedHotels.join(','),
            temples: checkedTemples.join(','),
            interests: checkedInterests.join(','),
            highlights: checkedHighlights.join(','),
            tourist_attractions: tags.map(tag => tag.id.replace(/'/g, '')).join(','),
            eateries_shopping: shoppingTags.map(tag => tag.id.replace(/'/g, '')).join(',')
            
        };
        console.log("updatedPackageData: ",JSON.stringify(updatedPackageData));
        const packageUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_package_update`;
        fetch(packageUpdate1, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedPackageData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };


    // Second Tab

    const [secondTabCities, setSecondTabCities] = useState([]);

    useEffect(() => {
        const citiesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?cities=all`;
        fetch(citiesUrl)
          .then(response => response.json())
          .then(data => setSecondTabCities(data))
          .catch(error => console.log(error));
      }, []);

    const [secondTabStates, setSecondTabStates] = useState([]);

    useEffect(() => {
        const statesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?states=all`;
        fetch(statesUrl)
          .then(response => response.json())
          .then(data => setSecondTabStates(data))
          .catch(error => console.log(error));
      }, []);

    const [secondTabHotels, setSecondTabHotels] = useState([]);

    useEffect(() => {
        const hotelsUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?hotels=all`;
        fetch(hotelsUrl)
          .then(response => response.json())
          .then(data => setSecondTabHotels(data))
          .catch(error => console.log(error));
      }, []);

    const [secondTabTemples, setSecondTabTemples] = useState([]);

    useEffect(() => {
        const templesUrl = `${process.env.REACT_APP_API_ENDPOINT}temples_listings?temples=all`;
        fetch(templesUrl)
          .then(response => response.json())
          .then(data => setSecondTabTemples(data))
          .catch(error => console.log(error));
      }, []);

    const [secondTabInterests, setSecondTabInterests] = useState([]);

    useEffect(() => {
        const interestsUrl = `${process.env.REACT_APP_API_ENDPOINT}interest_types`;
        fetch(interestsUrl)
          .then(response => response.json())
          .then(data => setSecondTabInterests(data))
          .catch(error => console.log(error));
      }, []);

    const [checkedCities, setCheckedCities] = useState([]);

    useEffect(() => {
        if (cities) {
            const cityIds = cities.split(',');
            setCheckedCities(cityIds);
        }
    }, [cities]);

    const handleCityCheckboxChange = (e, cityId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
          setCheckedCities(prevState => [...prevState, cityId.toString()]);
        } else {
          setCheckedCities(prevState => prevState.filter(id => id !== cityId.toString()));
        }
    };

    const [checkedStates, setCheckedStates] = useState([]);

    useEffect(() => {
        if (state) {
            const stateIds = state.split(',');
            setCheckedStates(stateIds.map(id => id.toString())); // convert each id to string
        }
    }, [state]);

    const handleStateCheckboxChange = (e, stateId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedStates(prevState => [...prevState, stateId.toString()]);
        } else {
            setCheckedStates(prevState => prevState.filter(id => id !== stateId.toString()));
        }
    };

    const [checkedHotels, setCheckedHotels] = useState([]);

    useEffect(() => {
        if (hoteles) {
            const hotelIds = hoteles.split(',');
            setCheckedHotels(hotelIds);
        }
    }, [hoteles]);

    const handleHotelCheckboxChange = (e, hotelId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
          setCheckedHotels(prevState => [...prevState, hotelId.toString()]);
        } else {
          setCheckedHotels(prevState => prevState.filter(id => id !== hotelId.toString()));
        }
    };


    const [checkedTemples, setCheckedTemples] = useState([]);

    useEffect(() => {
        if (temples) {
            const templeIds = temples.split(',');
            setCheckedTemples(templeIds);
        }
    }, [temples]);

    const handleTempleCheckboxChange = (e, templeId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
          setCheckedTemples(prevState => [...prevState, templeId.toString()]);
        } else {
          setCheckedTemples(prevState => prevState.filter(id => id !== templeId.toString()));
        }
    };

    const [checkedInterests, setCheckedInterests] = useState([]);

    useEffect(() => {
        if (interests) {
            const interestIds = interests.split(',');
            setCheckedInterests(interestIds);
        }
    }, [interests]);

    const handleInterestCheckboxChange = (e, interestId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
          setCheckedInterests(prevState => [...prevState, interestId.toString()]);
        } else {
          setCheckedInterests(prevState => prevState.filter(id => id !== interestId.toString()));
        }
    };


    // 3rd Tab
    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const cropperRef = useRef();
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setCroppedImage(null);
    };

    const handleCrop = () => {
        if (!cropperRef.current || !cropperRef.current.cropper) {
            setError("Cropper reference not properly defined");
            return;
        }
        const canvas = cropperRef.current.cropper.getCroppedCanvas({ width: 250, height: 250 });
        setCroppedImage(canvas.toDataURL("image/jpeg", 0.8));
    };

    const handleUpload = async () => {
        if (!croppedImage) {
            setError("Please crop an image first");
            return;
        }
        setLoading(true);
        setStatus(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'thumb');
        formData.append("image", dataURItoBlob(croppedImage), `${Date.now()}-${selectedFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_package_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessage("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessage("");
                }, 5000);
                setSelectedFile(null);
                setCroppedImage(null);
                window.location.reload();
            } else {
                setError("There was an error uploading the image");
            }
        } catch (error) {
            setError("There was an error uploading the image");
            setStatus("error");
        }
        setLoading(false);
    };    

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    const handleImageLoad = () => {
        if (cropperRef.current && !cropperRef.current.cropper) {
            const cropper = new Cropper(cropperRef.current, {
                aspectRatio: 1 / 1,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setError(null);
                },
            });
            cropperRef.current.cropper = cropper;
        }
    };

    // Thumbs Retrieval
    const [thumbImages, setThumbImages] = useState([]);

    useEffect(() => {
      const imageAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=thumb&site_category=tour`;
      fetch(imageAPIURL)
        .then(response => response.json())
        .then(data => setThumbImages(data))
        .catch(error => console.error(error));
    }, []);

    function handleImageDelete(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=thumb&site_category=tour`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(error => {
              console.error(error);
              alert("Failed to delete image");
            });
        }
      }


    //Gallery items

    const [selectedGalleryFile, setSelectedGalleryFile] = useState(null);
    const [croppedGalleryImage, setCroppedGalleryImage] = useState(null);
    const [errorGallery, setErrorGallery] = useState(null);
    const [loadingGallery, setLoadingGallery] = useState(false);
    const [statusGallery, setStatusGallery] = useState(null);
    const cropperRefGallery = useRef();
    const [successMessageGallery, setSuccessMessageGallery] = useState("");

    const handleFileChangeGallery = (event) => {
        setSelectedGalleryFile(event.target.files[0]);
        setCroppedGalleryImage(null);
    };

    const handleCropGallery = () => {
        if (!cropperRefGallery.current || !cropperRefGallery.current.cropper) {
            setErrorGallery("Cropper reference not properly defined");
            return;
        }
        const canvasGallery = cropperRefGallery.current.cropper.getCroppedCanvas({ width: 1200, height: 900 });
        setCroppedGalleryImage(canvasGallery.toDataURL("image/jpeg", 0.8));
    };

    const handleUploadGallery = async () => {
        if (!croppedGalleryImage) {
            setErrorGallery("Please crop an image first");
            return;
        }
        setLoadingGallery(true);
        setStatusGallery(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'gallery');
        formData.append("image", dataURItoBlob(croppedGalleryImage), `${Date.now()}-${selectedGalleryFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_package_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessageGallery("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessageGallery("");
                }, 5000);
                setSelectedGalleryFile(null);
                setCroppedGalleryImage(null);
                window.location.reload();
            } else {
                setErrorGallery("There was an error uploading Gallery image");
            }
        } catch (errorGallery) {
            setErrorGallery("There was an error uploading Gallery image");
            setStatusGallery("errorGallery");
        }
        setLoadingGallery(false);
    };

    const handleImageLoadGallery = () => {
        if (cropperRefGallery.current && !cropperRefGallery.current.cropper) {
            const cropper = new Cropper(cropperRefGallery.current, {
                aspectRatio: 4 / 3,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setErrorGallery(null);
                },
            });
            cropperRefGallery.current.cropper = cropper;
        }
    };

    // Gallery Retrieval
    const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
    const [captionInput, setCaptionInput] = useState('');

    useEffect(() => {
        const imageAPIURLGallery = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=tour`;
        console.log("imageAPIURLGallery", imageAPIURLGallery);
        fetch(imageAPIURLGallery)
          .then(response => response.json())
          .then(data => setThumbImagesGallery(data))
          .catch(errorGallery => console.errorGallery(errorGallery));
      }, []);
      
      const handleCaptionChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].caption = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleFeatureIdChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].feature_id = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleSaveCaption = (index) => {
        const image = thumbImagesGallery[index];
        const updateCaptionURL = `${process.env.REACT_APP_API_ENDPOINT}update_caption`;
        fetch(updateCaptionURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: image.id,
            caption: image.caption,
            feature_id: image.feature_id,
          }),
        })
          .then(response => response.json())
          .then(data => {
            // Handle the response, show success message, update status, etc.
            console.log('Caption saved:', data);
            alert("Caption Saved Successfully!");
          })
          .catch(error => {
            // Handle the error, show error message, update status, etc.
            console.error('Error saving caption:', error);
            alert("There was a problem, contact your dev team!");
          });
      };

    function handleImageDeleteGallery(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=gallery&site_category=tour`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(errorGallery => {
              console.errorGallery(errorGallery);
              alert("Failed to delete image");
            });
        }
      }


    // Old Database Images Retrieval
    const [oldimages, setOldImages] = useState([]);

    useEffect(() => {
      const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=old&site_category=tour`;
      fetch(imageAPIURLGallery)
        .then(response => response.json())
        .then(data => setOldImages(data))
        .catch(errorOldImages => console.errorOldImages(errorOldImages));
    }, []);

    function handleOldImageDelete(id, imageSec) {
        if (window.confirm("Are you sure you want to delete this image?")) {
            // Call API to delete the image
            fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=old&image_sec=${imageSec}&site_category=tour`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Failed to delete image");
                    }
                    // Reload the page to refresh the images
                    window.location.reload();
                })
                .catch(errorGallery => {
                    console.errorGallery(errorGallery);
                    alert("Failed to delete image");
                });
        }
    }    


    // Fourth Tab

    const [fourthTabHighlights, setFourthTabHighlights] = useState([]);

    useEffect(() => {
        const highlightsUrl = `${process.env.REACT_APP_API_ENDPOINT}highlights_list`;
        fetch(highlightsUrl)
          .then(response => response.json())
          .then(data => setFourthTabHighlights(data))
          .catch(error => console.log(error));
      }, []);

    const [checkedHighlights, setCheckedHighlights] = useState([]);

    useEffect(() => {
        if (highlights) {
            const highlightIds = highlights.split(',');
            setCheckedHighlights(highlightIds);
        }
    }, [highlights]);

    const handleHighlightCheckboxChange = (e, highlightId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
        setCheckedHighlights(prevState => [...prevState, highlightId.toString()]);
        } else {
        setCheckedHighlights(prevState => prevState.filter(id => id !== highlightId.toString()));
        }
    };



    const [tagSuggestions, setTagSuggestions] = useState([]);

    useEffect(() => {
        const touristAttractionsUrl = `${process.env.REACT_APP_API_ENDPOINT}tourist_attractions`;
        fetch(touristAttractionsUrl)
          .then(response => response.json())
          .then(data => {
            // transform the data into the format expected by the ReactTags component
            const suggestions = data.map(item => ({
                id: item.id.toString(),
                text: item.attraction_name + ', ' + item.city
              }));
              // update the state variable with the suggestions
              setTagSuggestions(suggestions);
          })
          .catch(error => {
            console.error('Error fetching suggestions:', error);
          });
      }, []);     
      
    const [tagShopSuggestions, setTagShopSuggestions] = useState([]);
    
    useEffect(() => {
        const shoppingUrl = `${process.env.REACT_APP_API_ENDPOINT}eateries_shopping`;
        fetch(shoppingUrl)
          .then(response => response.json())
          .then(data => {
            // transform the data into the format expected by the ReactTags component
            const shoppingSuggestions = data.map(item => ({
                id: item.id.toString(),
                text: item.spot_name + ', ' + item.city
              }));
              // update the state variable with the suggestions
              setTagShopSuggestions(shoppingSuggestions);
          })
          .catch(error => {
            console.error('Error fetching suggestions:', error);
          });
    }, []);

    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    
    const [tags, setTags] = React.useState([
        //{ id: 'Thailand', text: 'Thailand' },
        //{ id: 'India', text: 'India' },
        //{ id: 'Vietnam', text: 'Vietnam' },
        //{ id: 'Turkey', text: 'Turkey' }
    ]);

    useEffect(() => {
        //const attractionIds = tourist_attractions.split(',').map(id => parseInt(id.trim(), 10));
        if (tourist_attractions) {
            const touristAttractionsFindUrl = `${process.env.REACT_APP_API_ENDPOINT}tourist_attractions?id=${tourist_attractions}`;
            //console.log("touristAttractionsFindUrl: ", touristAttractionsFindUrl);
            fetch(touristAttractionsFindUrl)
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(item => ({
                    id: "'"+item.id+"'",
                    text: item.attraction_name + ', ' + item.city
                }));
                console.log('formattedData:', formattedData);
                setTags(formattedData);
            })
            .catch(error => console.error(error));
        }
    }, [tourist_attractions]);

    const [shoppingTags, setShoppingTags] = React.useState([]);

    useEffect(() => {
        if (eateries_shopping) {
            const shoppingFindUrl = `${process.env.REACT_APP_API_ENDPOINT}eateries_shopping?id=${eateries_shopping}`;
            //console.log("touristAttractionsFindUrl: ", touristAttractionsFindUrl);
            fetch(shoppingFindUrl)
            .then(response => response.json())
            .then(data => {
                const shoppingformattedData = data.map(item => ({
                    id: "'"+item.id+"'",
                    text: item.spot_name + ', ' + item.city
                }));
                //console.log('formattedData:', formattedData);
                setShoppingTags(shoppingformattedData);
            })
            .catch(error => console.error(error));
        }
    }, [eateries_shopping]);
    
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleShoppingDelete = i => {
        setShoppingTags(shoppingTags.filter((tag, index) => index !== i));
    };
    
    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleShoppingAddition = tag => {
        setShoppingTags([...shoppingTags, tag]);
    };

    /*
    useEffect(() => {
        console.log("Selected tag ids:", tags.map(tag => tag.id));
      }, [tags]);     
      
    useEffect(() => {
        console.log("Selected shopping tag ids:", shoppingTags.map(tag => tag.id));
    }, [shoppingTags]);  
    */


    const [rows, setRows] = useState([{ no_of_people: '', vehicle_type: 'Sedan', package_id: id }]);

    const addRow = () => {
        setRows([...rows, { no_of_people: '', vehicle_type: 'Sedan', package_id: id }]);
    };

    const deleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    const updateVendorPricing = () => {
        console.log("Vendors Data: ", rows);
        // Send rows data to your API in JSON format
        const vendorPriceUpdate = `${process.env.REACT_APP_API_ENDPOINT}vendor_price_update`;
        fetch(vendorPriceUpdate, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(rows),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(body => {
            console.log('Data sent successfully:', body);
            alert("Data Saved Successfully!");
        })
        .catch(error => {
            console.error('There was a problem sending the data:', error);
            alert("There was a problem, contact your dev team!");
        });
    };


    useEffect(() => {
        fetchVendorPriceList();
    }, []);

    const fetchVendorPriceList = () => {
        const vendorPriceList = `${process.env.REACT_APP_API_ENDPOINT}vendor_price_list?package_id=${id}`;
        // Fetch data from backend
        fetch(vendorPriceList)
            .then(response => response.json())
            .then(data => {
                setRows(data);
            })
            .catch(error => {
                console.error('Error fetching vendor price list:', error);
            });
    };
    

    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">

                    <h1 class="text-30 lh-14 fw-600">Edit Package</h1>

                </div>

                <div class="col-auto">
                    <a target="_blank" style={{float:'left'}} href={`/admin/package/plan/${id}`}
                    class="button h-50 px-24 -blue-1 bg-dark-3 text-white pull-left mr-10">
                        View Plans <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                    <a target="_blank" href={`/travel-india/tour-package/id/${id}/${name ? name.replace(/\s+/g, '-') : '' }`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        View Page <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">
                <div class="tabs -underline-2 js-tabs">
                    <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">1. Content</button>
                    </div>

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-2">2. Relations</button>
                    </div>

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-3">3. Images</button>
                    </div>

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-4">4. Attributes</button>
                    </div>

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-5">5. Vendor Pricing</button>
                    </div>

                    </div>

                    <div class="tabs__content pt-30 js-tabs-content">
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                        <div class="col-xl-10">
                            <div class="text-18 fw-500 mb-10">Tour Package Content</div>
                            <div class="row x-gap-20 y-gap-20">

                                <div class="col-12">
                                    <div class="form-input ">
                                        <input type="text" value={name} name="package_name"
                                        onChange={(event) => setPackageData(prevState => ({...prevState, name: event.target.value}))}
                                        required />
                                        <label class="lh-1 text-16 text-light-1">Package Name</label>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-input ">
                                        <input type="text" value={subtitle} name="package_sub_title" 
                                        onChange={(event) => setPackageData(prevState => ({...prevState, subtitle: event.target.value}))}
                                        required />
                                        <label class="lh-1 text-16 text-light-1">Sub Title</label>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-input ">
                                        <input type="text" value={plan_name} name="package_sub_title" 
                                        onChange={(event) => setPackageData(prevState => ({...prevState, plan_name: event.target.value}))}
                                        required />
                                        <label class="lh-1 text-16 text-light-1">Plan Name (Leave it empty if its a parent page)</label>
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Brief</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                    <ReactQuill
                                        value={brief}
                                        onChange={(value) => setPackageData(prevState => ({...prevState, brief: value}))}
                                        modules={{ toolbar: toolbarOptions }}
                                    />
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Description/Itinerary</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={description}
                                            onChange={(value) => setPackageData(prevState => ({...prevState, description: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Meta Description</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={tour_metadesc}
                                            onChange={(value) => setPackageData(prevState => ({...prevState, tour_metadesc: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Meta Keywords</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={tour_metakey}
                                            onChange={(value) => setPackageData(prevState => ({...prevState, tour_metakey: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-xl-9 col-lg-11">
                                    <div class="row x-gap-100 y-gap-15">
                                        <div class="col-12">
                                            <div class="text-18 fw-500">Feature, Time of Travel & Duration</div>
                                        </div>
                                        <div class="col-lg-3 col-sm-6">
                                            <div class="row y-gap-15">
                                                <div class="col-12">
                                                    <div class="d-flex items-center">
                                                        <div class="form-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name="featured"
                                                            onChange={(event) => setPackageData(prevState => ({...prevState, featured: event.target.checked ? 1 : 0}))}
                                                            checked={featured === 1 ? true : false}
                                                        />
                                                            <div class="form-checkbox__mark">
                                                                <div class="form-checkbox__icon icon-check"></div>
                                                            </div>
                                                        </div>
                                                        <div class="text-15 lh-11 ml-10">Featured Package</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-input ">
                                        <input type="text" value={timeoftravel} name="timeoftravel" 
                                        onChange={(event) => setPackageData(prevState => ({...prevState, timeoftravel: event.target.value}))}
                                        />
                                        <label class="lh-1 text-16 text-light-1">Time of Travel</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="form-input ">
                                        <input type="text" value={price} name="price" 
                                        onChange={(event) => setPackageData(prevState => ({...prevState, price: event.target.value}))}
                                        />
                                        <label class="lh-1 text-16 text-light-1">Price per Person</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="form-input ">
                                        <input type="text" value={days} name="days" 
                                        onChange={(event) => setPackageData(prevState => ({...prevState, days: event.target.value}))} />
                                        <label class="lh-1 text-16 text-light-1">Duration Days</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="form-input ">
                                        <input type="text" value={nights} name="nights" 
                                        onChange={(event) => setPackageData(prevState => ({...prevState, nights: event.target.value}))} />
                                        <label class="lh-1 text-16 text-light-1">Duration Nights</label>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12">
                                    <div class="row y-gap-15">
                                        <div class="col-12">
                                            <div class="d-flex items-center">
                                                <div class="form-checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="use_price"
                                                    onChange={(event) => setPackageData(prevState => ({...prevState, use_price: event.target.checked ? 1 : 0}))}
                                                    checked={use_price === 1 ? true : false}
                                                />
                                                    <div class="form-checkbox__mark">
                                                        <div class="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>
                                                <div class="text-15 lh-11 ml-10">This is Vendor Tour Package</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="fw-500 mb-10">Tour Type</div>
                                    <div class="form-input ">
                                    <select className="hselect" 
                                    onChange={(event) => setPackageData(prevState => ({...prevState, tour_type: event.target.value}))}
                                    value={tour_type}>
                                    {tourTypes.map(type => (
                                        <option key={type.id} value={type.id}>
                                        {type.type}
                                        </option>
                                    ))}
                                    </select>
                                        
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>

                    </div>

                    <div class="tabs__pane -tab-item-2">
                        <div class="col-xl-10">
                            <div class="row x-gap-20 y-gap-20">
                                <div class="col-lg-5 col-sm-6 offset-lg-1">
                                    <div class="text-18 fw-500 mb-10">Cities related to this Package</div>
                                    <div className='scrollable_checkboxes'>
                                        <div className="row y-gap-15">
                                        {secondTabCities.map(item => (
                                            <div className="col-12" key={item.id}>
                                                <div className="d-flex items-center">
                                                <div className="form-checkbox ">
                                                    <input
                                                    type="checkbox"
                                                    value={item.id}
                                                    name="cities"
                                                    checked={checkedCities.includes(item.id.toString())}
                                                    onChange={(e) => handleCityCheckboxChange(e, item.id)}
                                                    />
                                                    <div className="form-checkbox__mark">
                                                    <div className="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>
                                                <div className="text-15 lh-11 ml-10">{item.city}</div>
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-5 col-sm-6 offset-lg-1">
                                    <div class="text-18 fw-500 mb-10">States related to this Package</div>
                                    <div className='scrollable_checkboxes'>
                                        <div className="row y-gap-15">
                                            {secondTabStates.map(item => (
                                                <div className="col-12" key={item.state}>
                                                    <div className="d-flex items-center">
                                                        <div className="form-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                value={item.state}
                                                                name="states"
                                                                checked={checkedStates.includes(item.state.toString())}
                                                                onChange={(e) => handleStateCheckboxChange(e, item.state)}
                                                            />
                                                            <div className="form-checkbox__mark">
                                                                <div className="form-checkbox__icon icon-check"></div>
                                                            </div>
                                                        </div>
                                                        <div className="text-15 lh-11 ml-10">{item.state}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-sm-6 offset-lg-1">
                                    <div class="text-18 fw-500 mb-10">Hotels related to this Package</div>
                                    <div className='scrollable_checkboxes'>
                                        <div class="row y-gap-15">
                                            {secondTabHotels.map(item => (
                                                <div className="col-12" key={item.id}>
                                                    <div className="d-flex items-center">
                                                    <div className="form-checkbox ">
                                                        <input
                                                        type="checkbox"
                                                        value={item.id}
                                                        name="hotels"
                                                        checked={checkedHotels.includes(item.id.toString())}
                                                        onChange={(e) => handleHotelCheckboxChange(e, item.id)}
                                                        />
                                                        <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                        </div>
                                                    </div>
                                                    <div className="text-15 lh-11 ml-10">{item.name}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-sm-6 offset-lg-1">
                                    <div class="text-18 fw-500 mb-10">Temples related to this Package</div>
                                    <div className='scrollable_checkboxes'>
                                        <div class="row y-gap-15">
                                            {secondTabTemples.map(item => (
                                                <div className="col-12" key={item.id}>
                                                    <div className="d-flex items-center">
                                                    <div className="form-checkbox ">
                                                        <input
                                                        type="checkbox"
                                                        value={item.id}
                                                        name="temples"
                                                        checked={checkedTemples.includes(item.id.toString())}
                                                        onChange={(e) => handleTempleCheckboxChange(e, item.id)}
                                                        />
                                                        <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                        </div>
                                                    </div>
                                                    <div className="text-15 lh-11 ml-10">{item.name}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-sm-6 offset-lg-1">
                                    <div class="text-18 fw-500 mb-10">Interests related to this Package</div>
                                    <div className='scrollable_checkboxes'>
                                        <div class="row y-gap-15">
                                            {secondTabInterests.map(item => (
                                                <div className="col-12" key={item.id}>
                                                    <div className="d-flex items-center">
                                                    <div className="form-checkbox ">
                                                        <input
                                                        type="checkbox"
                                                        value={item.id}
                                                        name="interests"
                                                        checked={checkedInterests.includes(item.id.toString())}
                                                        onChange={(e) => handleInterestCheckboxChange(e, item.id)}
                                                        />
                                                        <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                        </div>
                                                    </div>
                                                    <div className="text-15 lh-11 ml-10">{item.type}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-sm-6 offset-lg-1">
                                    <div class="text-18 fw-500 mb-10">Highlights of this Package</div>
                                    <div className='scrollable_checkboxes'>
                                        <div className="row y-gap-15">
                                        {fourthTabHighlights.map(item => (
                                            <div className="col-12" key={item.id}>
                                                <div className="d-flex items-center">
                                                <div className="form-checkbox ">
                                                    <input
                                                    type="checkbox"
                                                    value={item.id}
                                                    name="highlights"
                                                    checked={checkedHighlights.includes(item.id.toString())}
                                                    onChange={(e) => handleHighlightCheckboxChange(e, item.id)}
                                                    />
                                                    <div className="form-checkbox__mark">
                                                    <div className="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>
                                                <div className="text-15 lh-11 ml-10">{item.highlight_name}</div>
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>
                    </div>

                    <div class="tabs__pane -tab-item-3">
                        <div class="col-xl-12 col-lg-12">
                            <div class="col-12">

                                

                                <div>
                                    <div class="fw-500">Upload New Thumbnail Images (These would be 250 x 250 px)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChange} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedFile && (
                                        <div>
                                            <img onLoad={handleImageLoad} ref={cropperRef} src={URL.createObjectURL(selectedFile)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCrop}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedImage && (
                                        <div>
                                            <img src={croppedImage} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedImage(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUpload} disabled={loading}>{loading ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessage && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessage}
                                            </div>
                                            )}

                                            {error && <div class="alert alert-danger">{error}</div>}
                                            {status && <div class="alert alert-success">{status}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30">
                                    <div class="fw-500">Existing Thumbnail Images</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">
                                    {thumbImages.length > 0 ? (
                                        thumbImages.map((image, index) => (
                                            <div className="col-auto" key={index}>
                                            <div className="d-flex ratio ratio-1:1 w-200">
                                                <img
                                                src={`${process.env.REACT_APP_API_ENDPOINT}uploads/${image.image_path}`}
                                                alt="image"
                                                className="img-ratio rounded-4"
                                                />
                                                <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                    <div style={{cursor:'pointer'}} className="size-40 bg-white rounded-4" onClick={() => handleImageDelete(image.id)}>
                                                        <i className="icon-trash text-16 image_trash_icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        ))
                                        ) : (
                                        <p>No Images Found</p>
                                    )}

                                    </div>
                                </div>

                                <div class="border-top-light mt-30 mb-30"></div>

                                <div>
                                    <div class="fw-500">Upload New Gallery Images (These would be 4:3 ratio, 1200 x 900 px recommended)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChangeGallery} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedGalleryFile && (
                                        <div>
                                            <img onLoad={handleImageLoadGallery} ref={cropperRefGallery} src={URL.createObjectURL(selectedGalleryFile)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCropGallery}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedGalleryImage && (
                                        <div>
                                            <img src={croppedGalleryImage} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedGalleryImage(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUploadGallery} disabled={loadingGallery}>{loadingGallery ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessageGallery && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessageGallery}
                                            </div>
                                            )}

                                            {errorGallery && <div class="alert alert-danger">{errorGallery}</div>}
                                            {statusGallery && <div class="alert alert-success">{statusGallery}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30">
                                    <div class="fw-500">Existing Gallery Images</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">
                                    {thumbImagesGallery.length > 0 ? (
                                        thumbImagesGallery.map((image, index) => (
                                            <div className="col-auto" key={index}>
                                            <div className="d-flex ratio ratio-1:1 w-200" style={{ marginBottom: -35 }}>
                                                <img
                                                style={{ height: 'unset' }}
                                                src={`${process.env.REACT_APP_API_ENDPOINT}uploads/${image.image_path}`}
                                                alt="image"
                                                className="img-ratio rounded-4"
                                                />
                                                <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    className="size-40 bg-white rounded-4"
                                                    onClick={() => handleImageDeleteGallery(image.id)}
                                                >
                                                    <i className="icon-trash text-16 image_trash_icon"></i>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="form-input">
                                                <input
                                                type="text"
                                                name="caption"
                                                placeholder="Caption"
                                                title="Caption"
                                                className="ReactTags__tagInputField caption_input"
                                                value={image.caption || ''}
                                                onChange={e => handleCaptionChange(e, index)}
                                                />
                                                <input
                                                    type="text"
                                                    name="feature_id"
                                                    title="Feature ID [Add numerical values 1 to 10]"
                                                    className="ReactTags__tagInputField feature_input"
                                                    value={image.feature_id || ''}
                                                    onChange={e => handleFeatureIdChange(e, index)}
                                                />
                                                <button onClick={() => handleSaveCaption(index)}>
                                                    <img className='caption_save' src={`/assets/img/general/save-green.png`} alt='Save' title='Save' />
                                                </button>
                                            </div>
                                            </div>
                                        ))
                                        ) : (
                                        <p>No Images Found</p>
                                    )}

                                    </div>
                                </div>


                                <div class="border-top-light mt-30 mb-30"></div>

                                <div class="mt-30">
                                    <div class="fw-500">Old Database Images</div>

                                    
                                    
                                    {oldimages.length > 0 ? (
                                        oldimages.map((image, index) => (
                                            <div className="row x-gap-20 y-gap-20 pt-15" key={index}>
                                                {image.image !== "" && (
                                                <div className="col-auto">
                                                    <div className="d-flex ratio ratio-1:1 w-200">
                                                        <img
                                                            style={{ height: "unset" }}
                                                            src={`/img/uploads/tour/${image.image}`}
                                                            alt="image"
                                                            className="img-ratio rounded-4"
                                                        />
                                                        <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                className="size-40 bg-white rounded-4"
                                                                onClick={() => handleOldImageDelete(image.id, 'image')}
                                                            >
                                                                <i className="icon-trash text-16 image_trash_icon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )}
                                                {image.thumb !== "" && (
                                                <div className="col-auto">
                                                    <div className="d-flex ratio ratio-1:1 w-200">
                                                        <img
                                                            style={{ height: "unset" }}
                                                            src={`/img/uploads/tour/${image.thumb}`}
                                                            alt="image"
                                                            className="img-ratio rounded-4"
                                                        />
                                                        <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                className="size-40 bg-white rounded-4"
                                                                onClick={() => handleOldImageDelete(image.id, 'thumb')}
                                                            >
                                                                <i className="icon-trash text-16 image_trash_icon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No Images Found</p>
                                    )} 


                                </div>


                            </div>

                        </div>
                    </div>

                    <div class="tabs__pane -tab-item-4">
                        <div class="col-xl-10">
                            <div class="text-18 fw-500 mb-10">Tour Package Content</div>
                            <div class="row x-gap-20 y-gap-20">

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Transport Details</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                    <ReactQuill
                                        value={transport_details}
                                        onChange={(value) => setPackageData(prevState => ({...prevState, transport_details: value}))}
                                        modules={{ toolbar: toolbarOptions }}
                                    />
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Includes/Excludes</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={includes_excludes}
                                            onChange={(value) => setPackageData(prevState => ({...prevState, includes_excludes: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Reporting / Dropping</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={reporting_dropping}
                                            onChange={(value) => setPackageData(prevState => ({...prevState, reporting_dropping: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Cancellation Policy</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={cancellation_policy}
                                            onChange={(value) => setPackageData(prevState => ({...prevState, cancellation_policy: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Important Info</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={important_info}
                                            onChange={(value) => setPackageData(prevState => ({...prevState, important_info: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Special Notes</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={special_notes}
                                            onChange={(value) => setPackageData(prevState => ({...prevState, special_notes: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>

                                <div class="col-12 mb-30 mt-10">
                                    <div class="form-input ">
                                        <input type="text" value={drive_kms} name="package_sub_title" 
                                        onChange={(event) => setPackageData(prevState => ({...prevState, drive_kms: event.target.value}))}
                                        required />
                                        <label class="lh-1 text-16 text-light-1">Drive Kms</label>
                                    </div>
                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Tourist Attractions</div>

                                        <div class="form-input ">
                                            <ReactTags
                                            tags={tags}
                                            suggestions={tagSuggestions}
                                            delimiters={delimiters}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            inputFieldPosition="bottom"
                                            autocomplete
                                            />
                                        </div>

                                </div>

                                <div class="col-12 mb-50">
                                    <div class="fw-500">Eateries / Shopping </div>

                                        <div class="form-input ">
                                            <ReactTags
                                            tags={shoppingTags}
                                            suggestions={tagShopSuggestions}
                                            delimiters={delimiters}
                                            handleDelete={handleShoppingDelete}
                                            handleAddition={handleShoppingAddition}
                                            inputFieldPosition="bottom"
                                            autocomplete
                                            />
                                        </div>

                                </div>

                            </div>

                        </div>
                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>
                    </div>

                    <div class="tabs__pane -tab-item-5">
                        <div class="col-xl-12">
                            <div class="text-18 fw-500 mb-10">Vendor Price List</div>
                            <div class="row x-gap-20 y-gap-20">
                                
                                <div>
                                    {rows.map((rowData, index) => (
                                        <Row
                                        key={index}
                                        index={index}
                                        rowData={rowData}
                                        onChange={handleChange}
                                        onDelete={deleteRow}
                                        />
                                    ))}
                                    <div onClick={addRow} class="d-inline-block pt-30">
                                        <a href="#" class="button -md -blue-1 bg-blue-1-05 text-blue-1">
                                        Add Row
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='col-md-12'>
                            <div onClick={() => updateVendorPricing(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>
                    </div><br /><br />
                    </div>
                </div>
                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminPackageEdit;