import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminRoomTypeCreate() {

    const { parentId } = useParams();

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];
    
    const [roomData, setRoomData] = useState({});
    const { 
        room_type
        ,description
        ,price
        ,discount_price
        ,extra_person_charge
        ,child_price
        ,tax
        ,available_facility
        ,available
        ,meal_types
        ,other_facility
        ,keywords
        ,metadesc
    } = roomData;


    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    

    const handleSaveDetails = () => {
        const updatedRoomData = {
            ...roomData,
            parentId
        };
        console.log("updatedRoomData: ",JSON.stringify(updatedRoomData));
        const packageUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_room_create`;
        fetch(packageUpdate1, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedRoomData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };

    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">

                    <h1 class="text-30 lh-14 fw-600">Create New Room for Hotel ID {parentId} </h1>

                </div>

                <div class="col-auto">
                    <a href={`/admin/hotel/room/types/${parentId}`} 
                    class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        Room Types <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">

                    <div class="col-12 mb-30">
                        <div class="form-input ">
                            <input type="text" value={room_type}
                            onChange={(event) => setRoomData(prevState => ({...prevState, room_type: event.target.value}))}
                            required />
                            <label class="lh-1 text-16 text-light-1">Room Type</label>
                        </div>
                    </div>

                    <div class="col-12 mb-80">
                        <div class="fw-500">Description</div>
                        <div class="form-input" style={{maxHeight:300,}}>
                        <ReactQuill
                            value={description}
                            onChange={(value) => setRoomData(prevState => ({...prevState, description: value}))}
                            modules={{ toolbar: toolbarOptions }}
                        />
                        </div>
                    </div>

                    <div class="col-12 mb-30">
                        <div class="form-input ">
                            <input type="text" value={price}
                            onChange={(event) => setRoomData(prevState => ({...prevState, price: event.target.value}))}
                            required />
                            <label class="lh-1 text-16 text-light-1">Price</label>
                        </div>
                    </div>

                    <div class="col-12 mb-30">
                        <div class="form-input ">
                            <input type="text" value={discount_price}
                            onChange={(event) => setRoomData(prevState => ({...prevState, discount_price: event.target.value}))}
                            required />
                            <label class="lh-1 text-16 text-light-1">Discrount Price</label>
                        </div>
                    </div>

                    <div class="col-12 mb-30">
                        <div class="form-input ">
                            <input type="text" value={extra_person_charge}
                            onChange={(event) => setRoomData(prevState => ({...prevState, extra_person_charge: event.target.value}))}
                            required />
                            <label class="lh-1 text-16 text-light-1">Extra Person Charge</label>
                        </div>
                    </div>

                    <div class="col-12 mb-30">
                        <div class="form-input ">
                            <input type="text" value={child_price}
                            onChange={(event) => setRoomData(prevState => ({...prevState, child_price: event.target.value}))}
                            required />
                            <label class="lh-1 text-16 text-light-1">Child Price</label>
                        </div>
                    </div>

                    <div class="col-12 mb-30">
                        <div class="form-input ">
                            <input type="text" value={tax}
                            onChange={(event) => setRoomData(prevState => ({...prevState, tax: event.target.value}))}
                            required />
                            <label class="lh-1 text-16 text-light-1">Tax %</label>
                        </div>
                    </div>

                    <div class="col-12 mb-30">
                        <div class="form-input ">
                            <input type="text" value={available_facility}
                            onChange={(event) => setRoomData(prevState => ({...prevState, available_facility: event.target.value}))}
                            required />
                            <label class="lh-1 text-16 text-light-1">Facilities Available (Separate items by comma)</label>
                        </div>
                    </div>

                    <div class="col-12 mb-30">
                        <div class="form-input ">
                            <input type="text" value={other_facility}
                            onChange={(event) => setRoomData(prevState => ({...prevState, other_facility: event.target.value}))}
                            required />
                            <label class="lh-1 text-16 text-light-1">Other Facilities (Separate items by comma)</label>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div onClick={() => handleSaveDetails()} class="d-inline-block pt-30" style={{float:'right'}}>
                            <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                            </a>
                        </div>
                        <div style={{clear:'both'}}></div>
                    </div>

                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminRoomTypeCreate;