import React, { useState } from 'react';
import axios from 'axios';

function UserLogin() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + 'user_auth', {username, password,});
            const { token, user } = response.data;
            // Save token and user data to local storage or session storage
            localStorage.setItem('user_token', token);
            //localStorage.setItem('user', JSON.stringify(user));

            // Redirect to the home page or dashboard
            window.location.href = '/cart';
        } catch (error) {
            setError('Invalid username or password');
        }
    };

  return (
    <section class="layout-pt-lg layout-pb-lg" style={{
        backgroundImage: `url('./assets/img/masthead/1/bg.webp')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop:70,
      }}      
    >
      <div class="container">
        <div class="row justify-center">
          <div class="col-xl-6 col-lg-7 col-md-9">
            <div class="px-50 py-50 sm:px-20 sm:py-20 bg-white shadow-4 rounded-4">
              <div class="row y-gap-20">
                <div class="col-12">
                  <h1 class="text-22 fw-500">Login</h1>
                </div>

                    <div className="col-12">
                <div className="form-input ">
                    <input type="text" name="username" required value={username} onChange={(e) => setUsername(e.target.value)} />
                    <label className="lh-1 text-14 text-light-1">Username</label>
                </div>
                </div>

                <div className="col-12">
                <div className="form-input ">
                    <input type="password" name="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                    <label className="lh-1 text-14 text-light-1">Password</label>
                </div>
                </div>

                <div className="col-12">
                <button style={{width:'100%',}} className="button py-20 -dark-1 bg-blue-1 text-white" onClick={handleLogin}>
                    Sign In <div className="icon-arrow-top-right ml-15"></div>
                </button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>
  );
}

export default UserLogin;
