import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSearch from './HeaderSearch';
import { SubscribeRibbon } from './SubscribeRibbon';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {Helmet} from "react-helmet";

function Product() {      

  const { id, title } = useParams();
  const [productData, setProductData] = useState({});
  const { 
    product_name
    ,weight
    ,short_description
    ,product_description
    ,category
    ,material
    ,category_name
    ,height
    ,width
    ,sku
    ,url
    ,meta_description
    ,price
    ,featured
    ,qty_available
    ,display_status
   } = productData;

  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}product/${id}`;
  console.log("api_url", apiUrl);

  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setProductData(data))
      .catch(error => console.error(error));
  }, []);  


  const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
  useEffect(() => {
    const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=product`;
    console.log("imageAPIURLGallery: ", imageAPIURLGallery);
    fetch(imageAPIURLGallery)
      .then(response => response.json())
      .then(data => setThumbImagesGallery(data))
      .catch(errorGallery => console.errorGallery(errorGallery));
  }, []);

  const images = thumbImagesGallery.map((image) => ({
    original: `${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${image.image_src}`,
    thumbnail: `${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${image.image_src}`,
  }));  

    
    const [similarProducts, setSimilarProducts] = useState([]);
    useEffect(() => {
        const similarProductsUrl = `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${category}&item=products&neglect=${id}`;
        console.log("similarProductsUrl: ", similarProductsUrl);
        fetch(similarProductsUrl)
          .then(response => response.json())
          .then(data => setSimilarProducts(data))
          .catch(error => console.error(error));
    }, [id, category]);
    
    //console.log("tourPlans: ", tourPlans);

    const [materialName, setMaterialName] = useState([]);

    useEffect(() => {
    if (material) {
        const materialsUrl = `${process.env.REACT_APP_API_ENDPOINT}shopping_materials?material_id=${material}`;
        console.log("materialsUrl: ", materialsUrl);
        fetch(materialsUrl)
        .then(response => response.json())
        .then(data => setMaterialName(data))
        .catch(error => console.log(error));
    }
    }, [material]);


    const [successMessage, setSuccessMessage] = useState('');

    const handleAddToCart = () => {
        const selectElement = document.getElementById('quantity');
        const quantity = selectElement.value;

        const newItem = {
            id: id,
            quantity: quantity,
            category: 'ecommerce',
            price: price
        };

        // Retrieve existing cart items from local storage or initialize an empty array
        const existingItems = JSON.parse(localStorage.getItem('cartItems')) || [];

        // Add the new item to the existing array of cart items
        const updatedItems = [...existingItems, newItem];

        // Store the updated array back into local storage
        localStorage.setItem('cartItems', JSON.stringify(updatedItems));

        setSuccessMessage('Item successfully added to cart.');
    };

    const handleBuyNow = () => {
        const selectElement = document.getElementById('quantity');
        const quantity = selectElement.value;

        const newItem = {
            id: id,
            quantity: quantity,
            category: 'ecommerce',
            price: price
        };

        // Retrieve existing cart items from local storage or initialize an empty array
        const existingItems = JSON.parse(localStorage.getItem('cartItems')) || [];

        // Add the new item to the existing array of cart items
        const updatedItems = [...existingItems, newItem];

        // Store the updated array back into local storage
        localStorage.setItem('cartItems', JSON.stringify(updatedItems));

        window.location.href = "/cart";
    };




    const [formData, setFormData] = useState({
      fullName: '',
      email: '',
      mobileNumber: '',
      noOfPersons: '',
      hotelType: '',
      startDate: '',
      endDate: '',
      message: '',
      graduationDay: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    
    const handleEnquirySubmit = (e) => {
      e.preventDefault();
    
      if (formData.graduationDay !== '') {
        // A value is present in the honeypot field, likely a bot
        return;
      }
    
      if (
        formData.fullName.trim() === '' ||
        formData.email.trim() === '' ||
        formData.mobileNumber.trim() === ''
      ) {
        alert('Please fill in all required fields.');
      } else {
        // Prepare the form data for submission
        const data = {
          fullName: formData.fullName,
          email: formData.email,
          mobileNumber: formData.mobileNumber,
          noOfPersons: formData.noOfPersons,
          hotelType: formData.hotelType,
          startDate: formData.startDate,
          endDate: formData.endDate,
          message: formData.message,
          website: 'Blessingsonthenet.com',
          page_title: product_name
        };
    
        console.log("enquiry data: ", data);
    
        // Replace 'your-api-endpoint' with your actual API endpoint
        fetch(`${process.env.REACT_APP_API_ENDPOINT}enquiry_submission`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.ok) {
              // Handle a successful response here
              setIsSubmitted(true);
            } else {
              // Handle errors here
              console.error('Form submission failed');
            }
          })
          .catch((error) => {
            console.error('Error submitting the form:', error);
          });
      }
    };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch shopping categories
    fetch(`${process.env.REACT_APP_API_ENDPOINT}shopping_categories`)
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error(error));
  }, []);

  const [productListings, setProductListings] = useState({});

  const getProductListings = (categoryId) => {
    // Fetch product listings for a specific category
    fetch(`${process.env.REACT_APP_API_ENDPOINT}product_listings?cat=${categoryId}&limit=3`)
      .then(response => response.json())
      .then(data => {
        // Update the product listings state
        setProductListings(prevState => ({ ...prevState, [categoryId]: data }));
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    // Run the second API for all categories when categories change
    categories.forEach(category => getProductListings(category.id));
  }, [categories]);

  const renderProducts = (category) => {
    const products = productListings[category.id];

    if (!products) {
      // Product listings are not yet available
      return <p>Loading...</p>;
    }

    return (
      <div>
        {products.map(product => (
          <a key={product.id} href={`/product/${product.product_name.toLowerCase().replace(/\s+/g, '-')}/${product.id}`} className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-5 py-5 bg-light-2 rounded-4 mb-10">
            <div className="ml-10">
              <p className="text-16">{product.product_name}</p>
            </div>
          </a>
        ))}
        <a href={`/products/type/${category.category_name.toLowerCase().replace(/\s+/g, '-')}/${category.id}`} className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-5 py-5 bg-light-2 rounded-4 mb-10">
          <div className="ml-10">
            <p className="text-16">View All Products</p>
          </div>
        </a>
      </div>
    );
  };

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${product_name} | Blessingsonthenet.com`}</title>
    </Helmet> 
    <div>
      <HeaderSearch />

      <section class="py-10 d-flex items-center bg-light-2">
        <div class="container">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div class="col-auto">
                  <div class="">Home</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="">Products</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="text-dark-1">{product_name}</div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              {/*<a href="#" class="text-14 text-blue-1 underline">View Products Involving Dwarka</a>*/}
            </div>
          </div>
        </div>
      </section>


      <section class="pt-40">
      <div class="container">
        <div class="row y-gap-30">
            <div class="col-lg-12">
                <div class="row y-gap-20 justify-between items-end">
                    <div class="col-auto">
                        <h1 class="text-30 sm:text-24 fw-600">{product_name}</h1>

                        <div class="row x-gap-10 items-center pt-10">
                        <div class="col-auto">
                            <div class="d-flex x-gap-5 items-center">
                            <div class="text-15 text-light-1"><b>Category:</b> {category_name}{sku ? (', Product ID: '+sku) : ''}</div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
          <div class="col-lg-6">
            <ImageGallery items={images} slideInterval={6000} thumbnailPosition="left" />
          </div>

          <div class="col-lg-6">
            {short_description?.length > 200 ? 
            (<div class="d-flex justify-end" style={{height:300, overflowY:'scroll'}} dangerouslySetInnerHTML={{ __html: `${short_description}` }}></div>) : 
            (<div class="d-flex justify-end" style={{height:300}} dangerouslySetInnerHTML={{ __html: `${short_description}` }}></div>)
            }

            <div class="col-auto border-top-light mt-20">
              <div class="row x-gap-15 y-gap-15 items-center">
                <div class="col-auto">
                  <div class="text-14">
                    <span className="text-30 text-dark-1 fw-500">₹{price}</span>
                  
                  </div>
                </div>


              </div>
            </div>

            <div class="col-auto border-top-light">
              <div class="row x-gap-15 y-gap-15 items-center">
                
                {price !== null && price !== '0' ? (
                    <div className="row">
                        <div className="col-auto pt-40">
                            <b className='text-18'>Quantity: </b>
                            <select name="quantity" id="quantity" className='text-20' style={{ marginLeft: 5, width: '50px' }}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="col-auto pt-30">
                            <button className="button h-50 px-24 -blue-1 bg-dark-3 text-white" onClick={handleAddToCart}>
                                Add to Cart <img src="/assets/img/general/lang.png" alt="image" style={{ marginLeft: 8, width: '18px', marginTop: '-2px' }}></img>
                            </button>
                            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                        </div>
                        
                        <div class="col-auto pt-30">
                            <button class="button h-50 px-24 -dark-1 bg-yellow-1 text-dark-1" onClick={handleBuyNow}>
                                Buy Now <div class="icon-arrow-top-right ml-15"></div>
                            </button>
                        </div>
                    </div>
                ) : ''}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    

    <section class="pt-30">
        <div class="container">
          <div class="row y-gap-30">
            <div class="col-xl-9">
              <div class="row y-gap-40 dark_text">

                <div id="overview" class="col-12">
                  <h3 class="text-22 fw-500 pt-40 border-top-light">Overview</h3>
                  <div className='mt-20' dangerouslySetInnerHTML={{ __html: product_description }} /> 
                </div>

              </div>
            </div>
            <div class="col-xl-3">
              <div class="row y-gap-40 dark_text">

                <div id="overview" class="col-12">
                  <h3 class="text-22 fw-500 pt-40 border-top-light">Specifications</h3>
                  <div className='mt-20'>
                  <b>Material:</b> {materialName[0]?.material_name}<br />
                    <b>Weight:</b> {weight}<br />
                    <b>Width:</b> {width}<br />
                    <b>Height:</b> {height}<br />
                    <b>Category:</b> {category_name}<br />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
    </section>

    <section class="layout-pt-md">
        <div class="container border-top-light pt-30">
          <div class="row justify-center text-center">
            <div class="col-auto">
              <div class="sectionTitle -md">
                <h2 class="sectionTitle__title">Other Products that might interest you</h2>
                <p class=" sectionTitle__text mt-5 sm:mt-0">Some of the exciting products that are similar</p>
              </div>
            </div>
          </div>

          <div class="row y-gap-30 pt-40 sm:pt-20">

            {similarProducts.map(list => (
                <div class="col-xl-3 col-lg-3 col-sm-6 mt-10">

                <a href={`/product/${list.product_name.toLowerCase().replace(/\s+/g, '-')}/${list.id}`} class="hotelsCard -type-1">

                <div class="hotelsCard__image">

                    <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">

                        <img class="rounded-4 col-12" src={`${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${list.image_src}`} alt="image" />

                    </div>

                    </div>

                </div>

                <div class="hotelsCard__content mt-10 text-center">
                    <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>{list.product_name}
                        </span>
                    </h4>

                    {/*<p class="text-light-1 lh-14 text-14 mt-5">{list.category_name}</p>*/}

                    <div>
                        <div class="fw-500">
                            {list.price > 0 ?
                            <span dangerouslySetInnerHTML={{ __html: `<span class="text-blue-1">₹${list.price}</span>` }}></span> : 'Contact us for Price'
                            }
                        </div>
                    </div>
                </div>
                </a>

            </div>
            ))}

          </div>
        </div>
      </section>

    <section class="layout-pt-lg layout-pb-md relative" id="secondSection">
      <div data-anim-wrap="" class="container animated border-top-light pt-30">
        <div data-anim-child="slide-up delay-1" class="row y-gap-20 justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">OUR CATALOG</h2>
              {/*<p class="sectionTitle__text mt-5 sm:mt-0">Here are list of nearby places that you might be intersted in</p>*/}
            </div>
          </div>
        </div>

        <div class="row x-gap-10 y-gap-10 pt-40 sm:pt-20">
            {categories.map(category => (
              <div key={category.id} className="col-xl-6 col-lg-3 col-sm-12 is-in-view px-20">
                <div className="col-12 mb-10 catalog_heading">
                  <h5>{category.category_name}</h5>
                </div>
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                    {renderProducts(category)}
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-md-block d-sm-none d-xs-none">
                    {category.thumb && <img src={`${process.env.REACT_APP_API_ENDPOINT}uploads/others/${category.thumb}`} alt={`${category.category_name} thumb`} />}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>

      <section class="layout-pt-lg layout-pb-md">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Enquire with Us</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">Have any questions? feel free to get in touch with us and we will answer all your questions.</p>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div style={{backgroundColor: '#13357B', color: '#fff',}} class="mt-50 px-40 pt-20 pb-20 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color: '#fff',}} class="card-title">WhatsApp Us</h4><br />
                  <p style={{color: '#fff',}} class="card-text">Request a quote, or just chat about this product. We're always happy to help!</p><br />
                  {/*
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                  */}
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                </div>
              </div>
              <div style={{backgroundColor: '#ff894c', color:'#051036'}} class="mt-50 px-40 pt-20 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color:'#051036'}} class="card-title">Write to Us</h4><br />
                  <p style={{color:'#051036'}} class="card-text">Do you have any question or be it an enquiry, feedback or a simple suggestion, write to us.</p><br />
                  <a target="_blank" href="mailto:travel@blessingsonthenet.com"><h5 style={{color:'#051036'}} class="card-text"><img src="/assets/img/icons/email.png" style={{width:'25px'}} /> travel@blessingsonthenet.com</h5></a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
            <div className="mt-50 px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Enter your details for enquiry</div>
                <div className="row y-gap-15 pt-20">
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    name="noOfPersons"
                    value="1"
                    onChange={handleChange}
                    required
                  />
                  {/*
                  <div className="col-6">
                    <div className="form-input" style={{minHeight:'70px'}}>
                    <label className="lh-1 text-16 text-light-1" style={{top:'12px'}}>Hotel Type</label>
                      <select
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius:'4px',
                          padding: '0 15px',
                          paddingTop:'25px'}}
                        name="hotelType"
                        value={formData.hotelType}
                        onChange={handleChange}
                      >
                      <option value="3 Star">3 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="5 Star">5 Star</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  */}
                  <div className="col-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="3"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Your Question / Message</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Send Message
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>




      <SubscribeRibbon />
    </div>
    </>
  );
}

export default Product;
