import jwt_decode from 'jwt-decode';

export const isAuthenticated = () => {
  const token = localStorage.getItem('user_token');
  if (token) {
    // Check if the token has expired
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      // Token has expired, redirect to the login page
      return false;
    }
    // Token is valid
    return true;
  }
  // No token found, redirect to the login page
  return false;
};

export const logout = () => {
  // Remove token from local storage
  localStorage.removeItem('user_token');
  
  // Redirect to login page
  window.location.href = '/guru';
};