import React from 'react';

const PersonDetail = ({ index, onPersonChange }) => {

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onPersonChange(index, name, value);
      };

  return (
    <>
        <div class="col-4">
            <div class="px-20 border-light rounded-4 -right" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
            <div>
                <h4 class="text-15 fw-500 ls-2 lh-16">Enter Person Name to be performed Homam</h4>

                <div class="text-15 text-light-1 ls-2 lh-16">
                <span class="js-first-date">
                    <input
                    type="text"
                    placeholder="Your Name"
                    id={`homam_for_name_${index}`}
                    name={`name_${index}`}
                    onChange={handleInputChange}
                    required
                    />
                </span>
                </div>
            </div>
            </div>
        </div>

        <div class="col-4">
            <div class="border-light rounded-4 admin_dropdown_inner">
                <h4 class="text-15 fw-500 ls-2 lh-16">Select Nakshatra</h4>
                <div class="text-15 text-light-1 ls-2 lh-16">
                    <select name={`nakshatra_${index}`} class="field-drop-bg" id={`sel_star_${index}`} onChange={handleInputChange}>
                        <option value="">Nakshatra</option>
                        <option value="Not Known">Not Known</option> 
                        <option value="Aswini/Aswathi/Aswathi">Aswini/Aswathi/Aswathi</option>
                        <option value="Bharani">Bharani</option> 
                        <option value="Krithika/Karthigai/Kaarthika">Krithika/Karthigai/Kaarthika</option> 
                        <option value="Rohini">Rohini</option> 
                        <option value="Mrigashiras/Mrigashireesham/Makeeryam">Mrigashiras/Mrigashireesham/Makeeryam</option> 
                        <option value="Aardhra/Thiruvaathirai/Thiruvaathira">Aardhra/Thiruvaathirai/Thiruvaathira</option> 
                        <option value="Punarvasu/Punarpoosam/Punartham">Punarvasu/Punarpoosam/Punartham</option> 
                        <option value="Pushyami/Poosam/Pooyyam">Pushyami/Poosam/Pooyyam</option> 
                        <option value="Ashlesha/Aayilyam/Aayilyam">Ashlesha/Aayilyam/Aayilyam</option> 
                        <option value="Magha/Makha/Makam/Makham">Magha/Makha/Makam/Makham</option> 
                        <option value="Poorvaphalguni/Pooram">Poorvaphalguni/Pooram</option> 
                        <option value="Uthraphalguni/Uthiram/Uthram">Uthraphalguni/Uthiram/Uthram</option> 
                        <option value="Hastha/Hastham/Atham">Hastha/Hastham/Atham</option> 
                        <option value="Chitra/Chithirai">Chitra/Chithirai</option> 
                        <option value="Swaathi/Chothi">Swaathi/Chothi</option> 
                        <option value="Vishaakha/Visaakam">Vishaakha/Visaakam</option> 
                        <option value="Anuraadha/Anusham/Anizham">Anuraadha/Anusham/Anizham</option> 
                        <option value="Jyeshta/Kettai/Thrikketta">Jyeshta/Kettai/Thrikketta</option> 
                        <option value="Moola/Moolam">Moola/Moolam</option> 
                        <option value="Poorvashaada/Pooraadam">Poorvashaada/Pooraadam</option> 
                        <option value="Uthrashaada/Uthiraadam/Uthraadam">Uthrashaada/Uthiraadam/Uthraadam</option> 
                        <option value="Shraavan/Thiruvonam/Thiruvonam">Shraavan/Thiruvonam/Thiruvonam</option> 
                        <option value="Dhanishta/Avittam">Dhanishta/Avittam</option> 
                        <option value="Shathabhisha/Chathayam/Sadayam">Shathabhisha/Chathayam/Sadayam</option> 
                        <option value="Poorvabhadra/Poorattadhi">Poorvabhadra/Poorattadhi</option> 
                        <option value="Uthrabhadra/Uthrattathi">Uthrabhadra/Uthrattathi</option> 
                        <option value="Revathi">Revathi</option> 
                    </select>
                </div>
            </div>
        </div>


        <div class="col-4">
            <div class="border-light rounded-4 admin_dropdown_inner">
                <h4 class="text-15 fw-500 ls-2 lh-16">Select Rasi</h4>
                <div class="text-15 text-light-1 ls-2 lh-16">
                    <select name={`rasi_${index}`} class="field-drop-bg" id={`sel_rasi_${index}`} onChange={handleInputChange}>
                        <option value="">Rasi (Moon Sign)</option>
                        <option value="Not Known">Not Known</option> 
                        <option value="Mesha/Mesham (Aries)">Mesha/Mesham (Aries)</option> 
                        <option value="Vrishabha/Virshabham (Taurus)">Vrishabha/Virshabham (Taurus)</option> 
                        <option value="Mithuna/Mithunam (Gemini)">Mithuna/Mithunam (Gemini)</option> 
                        <option value="Kataka/Katakam/Karkata(Cancer)">Kataka (Cancer)</option> 
                        <option value="Simha/Simham (Leo)">Simha/Simham (Leo)</option> 
                        <option value="Kanya/Kanni (Virgo)">Kanya/Kanni (Virgo)</option> 
                        <option value="Tula/Thulam/Thula (Libra)">Tula/Thulam/Thula (Libra)</option> 
                        <option value="Vrischika/Vrischikam (Scorpio)">Vrischika/Vrischikam (Scorpio)</option> 
                        <option value="Dhanus/Dhanur (Sagitarius)">Dhanus/Dhanur (Sagitarius)</option> 
                        <option value="Makara/Makaram (Capricorn)">Makara/Makaram (Capricorn)</option> 
                        <option value="Kumbha/Kumbham (Aquarius)">Kumbha/Kumbham (Aquarius)</option> 
                        <option value="Meena/Meenam (Pisces)">Meena/Meenam (Pisces)</option> 
                    </select>
                </div>
            </div>
        </div>
    </>
  );
};

export default PersonDetail;
