import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminMenuBanners() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    const [selectedGalleryFile, setSelectedGalleryFile] = useState(null);
    const [croppedGalleryImage, setCroppedGalleryImage] = useState(null);
    const [errorGallery, setErrorGallery] = useState(null);
    const [loadingGallery, setLoadingGallery] = useState(false);
    const [statusGallery, setStatusGallery] = useState(null);
    const cropperRefGallery = useRef();
    const [successMessageGallery, setSuccessMessageGallery] = useState("");

    const handleFileChangeGallery = (event) => {
        setSelectedGalleryFile(event.target.files[0]);
        setCroppedGalleryImage(null);
    };

    const handleCropGallery = () => {
        if (!cropperRefGallery.current || !cropperRefGallery.current.cropper) {
            setErrorGallery("Cropper reference not properly defined");
            return;
        }
        const canvasGallery = cropperRefGallery.current.cropper.getCroppedCanvas({ width: 450, height: 600 });
        setCroppedGalleryImage(canvasGallery.toDataURL("image/jpeg", 0.8));
    };

    const handleUploadGallery = async () => {
        if (!croppedGalleryImage) {
            setErrorGallery("Please crop an image first");
            return;
        }
    
        setLoadingGallery(true);
        setStatusGallery(null);
    
        const formData = new FormData();
        formData.append('image_type', document.querySelector('select[name="image_type"]').value); // Get the selected value from the dropdown
        formData.append('image', dataURItoBlob(croppedGalleryImage), `${Date.now()}-${selectedGalleryFile.name}`);
        //console.log("formData: ", formData);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_menu_banner_upload`, {
                method: "POST",
                body: formData,
            });
    
            if (response.ok) {
                setSuccessMessageGallery("Image uploaded successfully");
                setTimeout(() => {
                    setSuccessMessageGallery("");
                }, 5000);
                setSelectedGalleryFile(null);
                setCroppedGalleryImage(null);
                window.location.reload();
            } else {
                setErrorGallery("There was an error uploading Gallery image");
            }
        } catch (errorGallery) {
            setErrorGallery("There was an error uploading Gallery image");
            setStatusGallery("errorGallery");
        }
    
        setLoadingGallery(false);
    };    

    const handleImageLoadGallery = () => {
        if (cropperRefGallery.current && !cropperRefGallery.current.cropper) {
            const cropper = new Cropper(cropperRefGallery.current, {
                aspectRatio: 3 / 4,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setErrorGallery(null);
                },
            });
            cropperRefGallery.current.cropper = cropper;
        }
    };

    // Gallery Retrieval
    const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
    const [captionInput, setCaptionInput] = useState('');

    useEffect(() => {
        const imageAPIURLGallery = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?site_category=menu_banners&id=1`;
        console.log("imageAPIURLGallery", imageAPIURLGallery);
        fetch(imageAPIURLGallery)
          .then(response => response.json())
          .then(data => setThumbImagesGallery(data))
          .catch(errorGallery => console.errorGallery(errorGallery));
      }, []);
      
      const handleCaptionChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].title = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleHyperlinkChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].hyperlink = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleSaveCaption = (index) => {
        const image = thumbImagesGallery[index];
        const updateCaptionURL = `${process.env.REACT_APP_API_ENDPOINT}update_caption`;
        fetch(updateCaptionURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: image.id,
            caption: image.title,
            feature_id: image.hyperlink,
            cat: 'menu_banner',
          }),
        })
          .then(response => response.json())
          .then(data => {
            // Handle the response, show success message, update status, etc.
            console.log('Caption saved:', data);
            alert("Caption Saved Successfully!");
            // Reload the page to refresh the images
            window.location.reload();
          })
          .catch(error => {
            // Handle the error, show error message, update status, etc.
            console.error('Error saving caption:', error);
            alert("There was a problem, contact your dev team!");
          });
      };

    function handleImageDeleteGallery(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=menu_banners`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(errorGallery => {
              console.errorGallery(errorGallery);
              alert("Failed to delete image");
            });
        }
      } 

    return (
        <div class="dashboard__main">

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Menu Bar Banners</h1>

                    </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div class="col-xl-12 col-lg-12">
                        <div class="col-12">
                            <div>
                                <div class="fw-500">Upload New Banner (These would be 3:4 ratio, 450 x 600 px recommended)</div>

                                <div class="row x-gap-20 y-gap-20 pt-15">

                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChangeGallery} />
                                        <p class="mt-20">Select Section:</p>
                                        <select name='image_type'>
                                            <option value="tour">Tour</option>
                                            <option value="hotels">Hotels</option>
                                            <option value="shop">Shop</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    {selectedGalleryFile && (
                                    <div>
                                        <img onLoad={handleImageLoadGallery} ref={cropperRefGallery} src={URL.createObjectURL(selectedGalleryFile)} alt="Crop preview" />
                                        <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCropGallery}>Crop Image</button>
                                    </div>
                                    )}
                                </div>
                                <div class="col-md-4">
                                    {croppedGalleryImage && (
                                    <div>
                                        <img src={croppedGalleryImage} alt="Cropped image preview" />
                                        <div class="d-grid gap-2">
                                            <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedGalleryImage(null)}>Reset</button>
                                            <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUploadGallery} disabled={loadingGallery}>{loadingGallery ? "Uploading..." : "Upload"}</button>
                                        </div>

                                        {successMessageGallery && (
                                        <div class="alert alert-success" role="alert">
                                            {successMessageGallery}
                                        </div>
                                        )}

                                        {errorGallery && <div class="alert alert-danger">{errorGallery}</div>}
                                        {statusGallery && <div class="alert alert-success">{statusGallery}</div>}
                                    </div>
                                    )}
                                </div>
                                </div>
                            </div>
                            <div class="mt-30">
                                <div class="fw-500">Existing Banners</div>

                                <div class="row x-gap-20 y-gap-20 pt-15">
                                {thumbImagesGallery.length > 0 ? (
                                    thumbImagesGallery.map((image, index) => (
                                        <div className="col-auto" key={index}>
                                            <div class="fw-400"><b>Section:</b> {image.section}</div>
                                            {image.image_path ? (
                                                <div className="d-flex ratio ratio-3:4 w-200" style={{ marginBottom: 10 }}>
                                                    
                                                        <img
                                                        style={{ height: 'unset' }}
                                                        src={`${process.env.REACT_APP_API_ENDPOINT}uploads/menu_banners/${image.image_path}`}
                                                        alt="image"
                                                        className="img-ratio rounded-4"
                                                        />
                                                    
                                                    <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        className="size-40 bg-white rounded-4"
                                                        onClick={() => handleImageDeleteGallery(image.id)}
                                                    >
                                                        <i className="icon-trash text-16 image_trash_icon"></i>
                                                    </div>
                                                    </div>
                                                </div>
                                            ) : 'No Banner Found!'}

                                        <div className="form-input">
                                            <div class="row">
                                                <div class="col-12">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Title"
                                                    title="Title"
                                                    className="ReactTags__tagInputField mb-10"
                                                    value={image.title || ''}
                                                    onChange={e => handleCaptionChange(e, index)}
                                                    />
                                                </div>
                                                <div class="col-12">
                                                    <input
                                                    type="text"
                                                    name="hyperlink"
                                                    placeholder="Hyperlink"
                                                    title="Hyperlink"
                                                    className="ReactTags__tagInputField mb-10"
                                                    value={image.hyperlink || ''}
                                                    onChange={e => handleHyperlinkChange(e, index)}
                                                    />
                                                </div>
                                                <div class="col-12">
                                                    <button onClick={() => handleSaveCaption(index)}>
                                                        <img className='caption_save' src={`/assets/img/general/save-green.png`} alt='Save' title='Save' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    ))
                                    ) : (
                                    <p>No Images Found</p>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminMenuBanners;