import React, { useState, useEffect } from 'react';
import AdminFooter from './AdminFooter';
import { useParams } from 'react-router-dom';
import { isAuthenticated } from '../middleware/admin_auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function AdminHotelGroupListings() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const { id, keyword, city } = useParams();
    const [hotelGroupListings, sethotelGroupListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Define totalPages variable

    const location = useLocation();
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        let page = id ? parseInt(id) : currentPage; // Use the value from the URL if present
        setCurrentPage(page);
      
        const searchParams = new URLSearchParams(location.search);
      
        if (keyword) {
          searchParams.set('keyword', keyword);
          searchParams.set('city', keyword);
        }
  
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_groups`;
        console.log("apiUrl: ", apiUrl);      
      
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              sethotelGroupListings(data);
              setTotalCount(data.length > 0 ? data[0].totalCount : 0);
              const totalPages = Math.ceil(data[0].totalCount / 20);
              setTotalPages(totalPages);
          })
          .catch((error) => console.log(error));
    }, [id, currentPage, keyword, totalCount]);  


    return (
        <div class="dashboard__main">
            <div class="dashboard__content bg-light-2">
            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">
    
                    <h1 class="text-30 lh-14 fw-600">Hotel Groups</h1>
    
                </div>
            </div>
    
    
            <div class="py-30 px-30 rounded-4 bg-white shadow-3">
    
                <div class="tabs__content pt-30 js-tabs-content">
    
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <div class="overflow-scroll scroll-bar-1">
                        <table class="table-4 -border-bottom col-12">
                        <thead class="bg-light-2">
                            <tr>
                                <th>Group Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
    
                        {hotelGroupListings.map(hotelGroup => (
                            <tr key={hotelGroup.hotel_group}>
                            <td className="text-blue-1 fw-500"><a target="_blank" 
                            href={`/hotels/group/${hotelGroup.hotel_group.toLowerCase().replace(/\s+/g, '-')}`}
                            >{hotelGroup.hotel_group}</a></td>
                            
                            <td>
                                <div className="row x-gap-10 y-gap-10 items-center">
                                
                                <div className="col-auto">
                                    <a href={`/admin/hotel-group/edit/${hotelGroup.hotel_group}`} >
                                        <button title="Edit" className="flex-center bg-light-2 rounded-4 size-35">
                                        <i className="icon-edit text-16 text-light-1"></i>
                                        </button>
                                    </a>
                                </div>

                                </div>
                            </td>
                            </tr>
                        ))}
    
                        </tbody>
                        </table>
                    </div>
                    </div>
    
                </div>
            </div>
    
    
            <AdminFooter />
            </div>
        </div>
    );
}

export default AdminHotelGroupListings;