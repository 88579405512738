import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubscribeRibbon } from './SubscribeRibbon';
import HeaderSearch from './HeaderSearch';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";

function HomamListings() {

    const location = useLocation();
    const navigate = useNavigate();
    
    const { id, keyword, state, title } = useParams();
    const [homamListings, setHomamListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [priceFilter, setPriceFilter] = useState("");
    const [durationFilter, setDurationFilter] = useState("");
    const [showWithoutPrice, setShowWithoutPrice] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    
    useEffect(() => {
      let page = id ? parseInt(id) : currentPage; // Use the value from the URL if present
      setCurrentPage(page);
    
      const searchParams = new URLSearchParams(location.search);
    
      if (keyword) {
        searchParams.set('keyword', keyword);
      }
      if (priceFilter) {
        searchParams.set('price', priceFilter);
      }
      if (durationFilter) {
        searchParams.set('duration', durationFilter);
      }
      if (showWithoutPrice) {
        searchParams.set('shp', '1');
      } else {
        searchParams.set('shp', '0');
      }

      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?page=${page}&${searchParams.toString()}${title ? `&category_type=${title}` : ''}${state ? `&state=${state}` : ''}`;
      console.log("apiUrl: ", apiUrl);      
    
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
            setHomamListings(data);
            setTotalCount(data.length > 0 ? data[0].totalCount : 0);
            const totalPages = Math.ceil(data[0].totalCount / 10);
            setTotalPages(totalPages);
        })
        .catch((error) => console.log(error));
    }, [id, currentPage, keyword, priceFilter, durationFilter, showWithoutPrice, title, state, totalCount]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
      
        const path = location.pathname;
        const currentQueryParams = new URLSearchParams(location.search);
        currentQueryParams.delete('page');
      
        let newPath = path;
      
        if (path.includes('/page/')) {
          newPath = path.replace(/\/page\/\d+/, `/page/${page}`);
        } else {
          newPath = `${path}/page/${page}`;
        }
      
        const newURL = `${newPath}${currentQueryParams.toString() ? `?${currentQueryParams.toString()}` : ''}`;
        navigate(newURL);
      };            
      

const handlePriceFilter = (price) => {
  setPriceFilter(price);
  appendFilterToURL('price', price);
};

const handleDurationFilter = (duration) => {
  setDurationFilter(duration);
  appendFilterToURL('duration', duration);
};

const handleShowWithoutPrice = (event) => {
  setShowWithoutPrice(event.target.checked);
  appendFilterToURL('shp', event.target.checked ? '1' : '0');
};

const appendFilterToURL = (filterType, value) => {
  const searchParams = new URLSearchParams(location.search);

  if (filterType === 'page') {
    searchParams.set(filterType, value);
  } else {
    if (searchParams.has(filterType)) {
      searchParams.delete(filterType);
    }

    searchParams.set(filterType, value);
  }

  const newURL = `${location.pathname}?${searchParams.toString()}`;
  navigate(newURL);
};

function updateURLParameter(url, param, value) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(param);
    searchParams.append(param, value);
    return `${url.split('?')[0]}?${searchParams.toString()}`;
  }  

// Fetching Homam Categories
const [homamCategories, setHomamCategories] = useState([]);

useEffect(() => {
    const homamCategoryUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?cat=homam`;
    fetch(homamCategoryUrl)
        .then(response => response.json())
        .then(data => setHomamCategories(data))
        .catch(error => console.log(error));
}, []);


// Fetching Astrology Categories
const [astrologyCategories, setAstrologyCategories] = useState([]);

useEffect(() => {
    const astrologyCategoryUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?cat=astrology`;
    fetch(astrologyCategoryUrl)
        .then(response => response.json())
        .then(data => setAstrologyCategories(data))
        .catch(error => console.log(error));
}, []);


// Fetching Homam Categories
const [statesFetch, setStatesFetch] = useState([]);

useEffect(() => {
    const statesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?states=all`;
    fetch(statesUrl)
        .then(response => response.json())
        .then(data => setStatesFetch(data))
        .catch(error => console.log(error));
}, []);


    return ( 
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Book Homam Online | Blessingsonthenet.com</title>
        </Helmet> 
        <div>
            <HeaderSearch />
            <section class="layout-pt-md layout-pb-lg">
            <div class="container">
            <div class="row y-gap-30">
                <div class="col-xl-3 col-lg-4 lg:d-none">
                <aside class="sidebar y-gap-40">

                    {/*
                    <div class="sidebar__item -no-border">
                    <h5 class="text-18 fw-500 mb-10 mt-10">Filter by Price</h5>
                    <div class="row x-gap-10 y-gap-10 pt-10">

                        <div class="col-auto">
                        <Link
                            to={updateURLParameter(location.search, 'price', '0-15000')}
                            className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                            onClick={() => handlePriceFilter('0-15000')}
                        >
                            Less than 15,000
                        </Link>
                        </div>

                        <div class="col-auto">
                        <Link
                            to={updateURLParameter(location.search, 'price', '15001-50000')}
                            className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                            onClick={() => handlePriceFilter('15001-50000')}
                        >
                            15,001 - 50,000
                        </Link>
                        </div>

                        <div class="col-auto">
                        <Link
                            to={updateURLParameter(location.search, 'price', '50000-100000')}
                            className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                            onClick={() => handlePriceFilter('50000-100000')}
                        >
                            50,000 - 1 Lakh
                        </Link>
                        </div>

                        <div class="col-auto">
                        <Link
                            to={updateURLParameter(location.search, 'price', '>100000')}
                            className="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100"
                            onClick={() => handlePriceFilter('>100000')}
                        >
                            More than 1 Lakh
                        </Link>
                        </div>

                        
                        <div class="row y-gap-10 items-center justify-between">
                        <div class="col-auto mt-10">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                    <input
                                        checked={showWithoutPrice}
                                        type="checkbox"
                                        onChange={handleShowWithoutPrice}
                                    />
                                    <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check"></div>
                                    </div>
                                </div>
                                <div class="text-15 ml-10">Show Homams without Price</div>
                            </div>
                        </div>
                        </div>
                        

                    </div>
                    </div>
                    */}

                    <div class="sidebar__item -no-border">
                        <h5 class="text-18 fw-500 mb-10 mt-10">Homam Categories</h5>
                        <div class="sidebar-checkbox">
                            <table class="table-4 w-1/1 blessings_categories">
                                <tbody>
                                {homamCategories.map(item => (
                                <tr key={item.id}>
                                    <td>    
                                    <a href={`/homam-astrology/category/${item.id}/${item.category_type.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <img src={`/assets/img/general/categories-arrow.png`} />
                                        Homam for <span style={{textTransform:'capitalize'}}>{item.category_type}</span>
                                    </a>
                                    </td>
                                </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/*
                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Vedic Astrology</h5>
                        <div class="sidebar-checkbox">
                            <table class="table-4 w-1/1 blessings_categories">
                                <tbody>
                                {astrologyCategories.map(item => (
                                <tr key={item.id}>
                                    <td>    
                                    <a href={`/homam-astrology/category/${item.id}/${item.category_type.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <img src={`/assets/img/general/categories-arrow.png`} />
                                        <span style={{textTransform:'capitalize'}}>{item.category_type}</span>
                                    </a>
                                    </td>
                                </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    */}
                    
                </aside>
                </div>

                <div class="col-xl-9 col-lg-8">
                <div class="row y-gap-10 items-center justify-between">
                    <div class="col-auto">
                    <div class="text-18"><span class="fw-500">We Found <span style={{color:'red'}}>{totalCount}</span> Homams</span></div>
                    </div>
                    {/*
                    <div class="col-auto">
                    <div class="row x-gap-20 y-gap-20">
                        <div class="col-auto">
                        <button class="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1">
                            <i class="icon-up-down text-14 mr-10"></i>
                            Price Low to High
                        </button>
                        </div>

                        <div class="col-auto d-none lg:d-block">
                        <button data-x-click="filterPopup" class="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1">
                            <i class="icon-up-down text-14 mr-10"></i>
                            Filter
                        </button>
                        </div>
                    </div>
                    </div>
                    */}
                </div>

                <div class="filterPopup bg-white" data-x="filterPopup" data-x-toggle="-is-active">
                    <aside class="sidebar -mobile-filter">
                    <div data-x-click="filterPopup" class="-icon-close">
                        <i class="icon-close"></i>
                    </div>

                    <div class="sidebar__item">
                        <div class="flex-center ratio ratio-15:9 js-lazy" data-bg="img/general/map.png">
                        <button class="button py-15 px-24 -blue-1 bg-white text-dark-1 absolute" data-x-click="mapFilter">
                            <i class="icon-destination text-22 mr-10"></i>
                            Show on map
                        </button>
                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Search by property name</h5>
                        <div class="single-field relative d-flex items-center py-10">
                        <input class="pl-50 border-light text-dark-1 h-50 rounded-8" type="email" placeholder="e.g. Best Western" />
                        <button class="absolute d-flex items-center h-full">
                            <i class="icon-search text-20 px-15 text-dark-1"></i>
                        </button>
                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Deals</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Free cancellation</div>
                            </div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Reserve now, pay at stay </div>
                            </div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Properties with special offers</div>
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Popular Filters</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Breakfast Included</div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Romantic</div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Airport Transfer</div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">WiFi Included </div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">5 Star</div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">679</div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item pb-30">
                        <h5 class="text-18 fw-500 mb-10">Nightly Price</h5>
                        <div class="row x-gap-10 y-gap-30">
                        <div class="col-12">
                            <div class="js-price-rangeSlider">
                            <div class="text-14 fw-500"></div>

                            <div class="d-flex justify-between mb-20">
                                <div class="text-15 text-dark-1">
                                <span class="js-lower"></span>
                                -
                                <span class="js-upper"></span>
                                </div>
                            </div>

                            <div class="px-5">
                                <div class="js-slider"></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Amenities</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Breakfast Included</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">WiFi Included </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Pool</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Restaurant </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Air conditioning </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">679</div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Star Rating</h5>
                        <div class="row y-gap-10 x-gap-10 pt-10">

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">1</a>
                        </div>

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">2</a>
                        </div>

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">3</a>
                        </div>

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">4</a>
                        </div>

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">5</a>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Guest Rating</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="form-radio d-flex items-center ">
                                <div class="radio">
                                <input type="radio" name="name" />
                                <div class="radio__mark">
                                    <div class="radio__icon"></div>
                                </div>
                                </div>
                                <div class="ml-10">Any</div>
                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="form-radio d-flex items-center ">
                                <div class="radio">
                                <input type="radio" name="name" />
                                <div class="radio__mark">
                                    <div class="radio__icon"></div>
                                </div>
                                </div>
                                <div class="ml-10">Wonderful 4.5+</div>
                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="form-radio d-flex items-center ">
                                <div class="radio">
                                <input type="radio" name="name" />
                                <div class="radio__mark">
                                    <div class="radio__icon"></div>
                                </div>
                                </div>
                                <div class="ml-10">Very good 4+</div>
                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="form-radio d-flex items-center ">
                                <div class="radio">
                                <input type="radio" name="name" />
                                <div class="radio__mark">
                                    <div class="radio__icon"></div>
                                </div>
                                </div>
                                <div class="ml-10">Good 3.5+ </div>
                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Style</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Budget</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Mid-range </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Luxury</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Family-friendly </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Business </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">679</div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Neighborhood</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Central London</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Guests&#39; favourite area </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Westminster Borough</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Kensington and Chelsea </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Oxford Street </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">679</div>
                            </div>
                        </div>

                        </div>
                    </div>
                    </aside>
                </div>

                <div class="mt-10"></div>

                <div class="row y-gap-30">
                {homamListings.map(list => (
                    <div class="col-12">
                        <div class="border-top-light pt-30">
                            <div class="row x-gap-20 y-gap-20">
                            <div class="col-md-auto">
                                <div class="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                                    <div class="cardImage__content">
                                        <a href={`/homam-astrology/${list.id}/${list.name.toLowerCase().replace(/\s+/g, '-')}`}>
                                            <img className="rounded-4 col-12" src={list.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${list.thumb_new}` : ''} alt="image" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <a href={`/homam-astrology/${list.id}/${list.name.toLowerCase().replace(/\s+/g, '-')}`}>
                                    <h3 class="text-18 lh-16 fw-500">
                                    {list.name}<br class="lg:d-none" />
                                    </h3>
                                </a>

                                <div class="row x-gap-10 y-gap-10 items-center pt-10">
                                <div class="col-auto">
                                    <p class="text-14" style={{textTransform:'capitalize'}}>{list.category}</p>
                                </div>
                                </div>

                                <div class="text-14 lh-15 mt-20">
                                    <div class="fw-500" dangerouslySetInnerHTML={{ __html: list.description.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').slice(0, 135) + "..." }} />

                                </div>

                                <div class="row x-gap-10 y-gap-10 pt-20">
                                    {list.category_type && (<div class="col-auto">
                                        <div class="border-light rounded-100 py-5 px-20 text-14 lh-14">Homam for {list.category_type}</div>
                                    </div>) }

                                    {list.deity && (<div class="col-auto">
                                        <div class="border-light rounded-100 py-5 px-20 text-14 lh-14">Deity: {list.deity}</div>
                                    </div>) }
                                </div>

                                <div class="text-14 text-green-2 lh-15 mt-40 border-top-light">
                                <div class="mt-15">Whatsapp <b>8452 040 404</b> to customize this homam.</div>
                                </div>

                            </div>
                            

                            <div class="col-md-auto text-right md:text-left">
                                <div class="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                                <div class="col-auto">
                                    {/*
                                    <div class="text-14 lh-14 fw-500">Exceptional</div>
                                    <div class="text-14 lh-14 text-light-1">302 reviews</div>
                                    */}
                                </div>
                                <div class="col-auto" style={{height:'55px'}}>
                                    {/*<div class="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1">4.8</div>*/}
                                </div>
                                </div>

                                <div class="">
                                {list.charges_1 && list.charges_1 !== 0 ? (
                                <div>
                                    <div className="text-14 text-light-1 mt-30 md:mt-20">Starts from</div>
                                    <div className="text-22 lh-12 fw-600">{list.charges_1}</div>
                                </div>
                                ) : (
                                <div className="text-14 text-light-1 mt-30 md:mt-20">Contact us for Price</div>
                                )}

                                <a 
                                    href={`/homam-astrology/${list.id}/${list.name.toLowerCase().replace(/\s+/g, '-')}`}
                                    className="button -md -dark-1 bg-blue-1 text-white mt-24"
                                    >
                                    View Details <div className="icon-arrow-top-right ml-15"></div>
                                </a>

                                {/*
                                <a href="https://wa.me/918452040404" class="button -md -dark-1 bg-yellow-1 text-dark-1 text-white mt-10">
                                    Enquire Now <div class="icon-chevron-right ml-15"></div>
                                </a>
                                */}

                                <a href="https://wa.me/918452040404" class="button -md -dark-1 bg-yellow-1 text-dark-1 text-white mt-10">
                                    Enquire Now <div class="icon-chevron-right ml-15"></div>
                                </a>

                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                ))}

                </div>
                

                <div class="border-top-light mt-10 pt-30">

                    <div class="pt-10">
                        <div class="row justify-between">
                            <div class="col-auto">

                            </div>

                            <div className="col-auto">
                                <div className="row x-gap-20 y-gap-20 items-center pagination">
                                    {currentPage !== 1 && (
                                    <div
                                        className="col-auto pagination_numbers"
                                        onClick={() => handlePageClick(1)}
                                    >
                                        <div className="size-40 flex-center rounded-full">1</div>
                                    </div>
                                    )}
                                    {currentPage > 3 && (
                                    <div className="col-auto">
                                        <div className="size-40 flex-center rounded-full">...</div>
                                    </div>
                                    )}
                                    {Array.from({ length: totalPages }, (_, index) => {
                                    const page = index + 1;
                                    if (page === totalPages) return null; // Skip the last page number
                                    if (
                                        page < currentPage - 1 ||
                                        page > currentPage + 1 ||
                                        (currentPage === 2 && page === 1) // Skip displaying 1 when on the second page
                                    )
                                        return null;
                                    return (
                                        <div
                                        key={index}
                                        className={`col-auto pagination_numbers ${
                                            page === currentPage ? "bg-dark-1 text-white" : ""
                                        }`}
                                        onClick={() => handlePageClick(page)}
                                        >
                                        <div className="size-40 flex-center rounded-full">{page}</div>
                                        </div>
                                    );
                                    })}
                                    {currentPage < totalPages - 2 && (
                                    <div className="col-auto">
                                        <div className="size-40 flex-center rounded-full">...</div>
                                    </div>
                                    )}
                                    {currentPage !== totalPages && (
                                    <div
                                        className="col-auto pagination_numbers"
                                        onClick={() => handlePageClick(totalPages)}
                                    >
                                        <div className="size-40 flex-center rounded-full">{totalPages}</div>
                                    </div>
                                    )}
                                </div>
                            </div>


                            <div class="col-auto">
                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </section>
            <SubscribeRibbon />
        </div>
        </>
    );
}

export default HomamListings;