import React, { useState, useEffect } from 'react';
import AdminFooter from './AdminFooter';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminPackageListings() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const [packagesData, setPackages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Define totalPages variable

    useEffect(() => {
        // Fetch cities data from API based on the current page
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour_listings?page=${currentPage}`;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setPackages(data);
                setTotalPages(data.length); // Update totalPages based on data length
            })
            .catch((error) => console.log(error));
    }, [currentPage]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };


    const [searchResults, setSearchResults] = useState([]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}search?q=${inputValue}`;
        
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => setPackages(data))
          .catch(error => console.error(error));
    };


    return (
        <div class="dashboard__main">
            <div class="dashboard__content bg-light-2">
            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">
    
                    <h1 class="text-30 lh-14 fw-600">Tour Packages</h1>
    
                </div>

                <div class="col-auto">
                    <div class="w-230 single-field relative d-flex items-center">
                        <input
                        className="pl-50 bg-white text-dark-1 h-50 rounded-8"
                        type="text"
                        placeholder="Search"
                        onChange={handleInputChange}
                        />
                        <ul className="autocomplete-results">
                        {searchResults.map(result => (
                            <li key={result.name}>{result.name}</li>
                        ))}
                        </ul>
                        <button class="absolute d-flex items-center h-full">
                            <i class="icon-search text-20 px-15 text-dark-1"></i>
                        </button>
                    </div>
                </div>
                
                <div class="col-auto">
                    <a href="/admin/package/create" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        Add New Package <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
            </div>
    
    
            <div class="py-30 px-30 rounded-4 bg-white shadow-3">
    
                <div class="tabs__content pt-30 js-tabs-content">
    
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <div class="overflow-scroll scroll-bar-1">
                        <table class="table-4 -border-bottom col-12">
                        <thead class="bg-light-2">
                            <tr>
                            <th>Package Name</th>
                            <th>Thumbnail</th>
                            <th>Featured</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
    
                        {packagesData.map(hpackage => (
                            <tr key={hpackage.id}>
                            <td className="text-blue-1 fw-500"><a target="_blank" 
                            href={`/travel-india/tour-package/id/${hpackage.id}/${hpackage.name.replace(/\s+/g, '-')}`}
                            >{hpackage.name}</a></td>
                            <td><img style={{width:50,borderRadius:5,}} src={`/img/uploads/tour/thumbs/${hpackage.thumb}`} /></td>
                            <td>{hpackage.featured === 1
                            ? <img src="/img/icons/actions_small/tick.png" alt="tick" />
                            : <img src="/img/icons/actions_small/publish_x.png" alt="cancel" />
                            }</td>
                            <td>
                                <div className="row x-gap-10 y-gap-10 items-center">
                                
                                <div className="col-auto">
                                    <a href={`/admin/package/plan/${hpackage.id}`} >
                                        <button title="Plans" className="flex-center bg-light-2 rounded-4 size-35">
                                        <i className="icon-globe text-16 text-light-1"></i>
                                        </button>
                                    </a>
                                </div>
                                <div className="col-auto">
                                    <a href={`/admin/package/edit/${hpackage.id}`} >
                                        <button title="Edit" className="flex-center bg-light-2 rounded-4 size-35">
                                        <i className="icon-edit text-16 text-light-1"></i>
                                        </button>
                                    </a>
                                </div>
                                <div className="col-auto">
                                    <button
                                        title="Delete"
                                        className="flex-center bg-light-2 rounded-4 size-35"
                                        onClick={() => {
                                        if (window.confirm("Are you sure you want to delete this package?")) {
                                            window.location.href = `/admin/package/delete/${hpackage.id}`;
                                        }
                                        }}
                                    >
                                        <i className="icon-trash-2 text-16 text-light-1"></i>
                                    </button>
                                </div>

                                </div>
                            </td>
                            </tr>
                        ))}
    
                        </tbody>
                        </table>
                    </div>
                    </div>
    
                </div>
    
                <div class="pt-30">
                    <div class="row justify-between">
                        <div class="col-auto">

                        </div>

                        <div class="col-auto">
                            <div className="row x-gap-20 y-gap-20 items-center pagination">
                                {currentPage > 4 &&
                                    <div
                                        className="col-auto pagination_numbers"
                                        onClick={() => handlePageClick(1)}
                                    >
                                        <div className="size-40 flex-center rounded-full">
                                            1
                                        </div>
                                    </div>
                                }
                                {currentPage > 5 &&
                                    <div className="col-auto">
                                        <div className="size-40 flex-center rounded-full">
                                            ...
                                        </div>
                                    </div>
                                }
                                {Array.from({ length: 7 }, (_, index) => {
                                    const page = index + currentPage - 3;
                                    if (page <= 0) return null; // Skip negative and zero pages
                                    //if (page > totalPages) return null; // Skip pages beyond total
                                    return (
                                        <div
                                        key={index}
                                        className={`col-auto pagination_numbers ${
                                            page === currentPage ? "bg-dark-1 text-white" : ""
                                        }`}
                                        >
                                        <div
                                            className="size-40 flex-center rounded-full"
                                            onClick={() => handlePageClick(page)}
                                        >
                                            {page}
                                        </div>
                                        </div>
                                    );
                                })}
                                {currentPage < totalPages - 4 &&
                                    <div className="col-auto">
                                        <div className="size-40 flex-center rounded-full">
                                            ...
                                        </div>
                                    </div>
                                }
                                {currentPage < totalPages - 3 &&
                                    <div
                                        className="col-auto pagination_numbers"
                                        onClick={() => handlePageClick(totalPages)}
                                    >
                                        <div className="size-40 flex-center rounded-full">
                                            {totalPages}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div class="col-auto">
                            
                        </div>
                    </div>
                </div>
            </div>
    
    
            <AdminFooter />
            </div>
        </div>
    );
}

export default AdminPackageListings;