import React, { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function PrimarySearchBox() {

    const currentUrl = window.location.href;
    console.log("currentUrl", currentUrl);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const today = new Date();
    const twoDaysFromNow = new Date(today.getTime() + 2 * 24 * 60 * 60 * 1000);
    const fourDaysFromNow = new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000);
    setStartDate(twoDaysFromNow);
    setEndDate(fourDaysFromNow);
  }, []);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // Tour Packages Search Feature
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';
    
    if (isBackspace) {
      setSearchValue('');
    } else {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}search?cat=tour_name_only&q=${inputValue}`;
      console.log("apiUrl: ", apiUrl);
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => setSearchResults(data))
        .catch(error => console.error(error));
    }
  };

  const handleResultClick = (result) => {
    //console.log("clicked");
    setSearchValue(result.name);
    setSearchResults([]); // Clear search results
    document.querySelector('.searchMenu-loc__field').style.opacity = 0;
  };  

  const handleSearchClick = () => {
    if (searchValue.trim() === '') {
      alert('Please enter a search value.');
      return;
    }
  
    const encodedKeyword = encodeURIComponent(searchValue);
    const redirectUrl = `/travels/travel/page/1?keyword=${encodedKeyword}`;
    window.location.href = redirectUrl;
  };


  // Hotel Search Feature
  const [searchHotelResults, setSearchHotelResults] = useState([]);
  const [searchHotelValue, setSearchHotelValue] = useState('');

  const handleHotelInputChange = (event) => {
    const inputHotelValue = event.target.value;
    const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';
    
    if (isBackspace) {
      setSearchHotelValue('');
    } else {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}search?cat=hotel_name_only&q=${inputHotelValue}`;
      console.log("apiUrl: ", apiUrl);
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => setSearchHotelResults(data))
        .catch(error => console.error(error));
    }
  };

  const handleHotelResultClick = (hotelResult) => {
    //console.log("clicked");
    setSearchHotelValue(hotelResult.name);
    setSearchHotelResults([]); // Clear search hotelResults
    document.querySelector('.searchMenu-loc__field').style.opacity = 0;
  };  

  const handleHotelSearchClick = () => {
    if (searchHotelValue.trim() === '') {
      alert('Please enter a search value.');
      return;
    }
  
    const encodedHotelKeyword = encodeURIComponent(searchHotelValue);
    const redirectUrl = `/hotels/page/1?keyword=${encodedHotelKeyword}&city=${encodedHotelKeyword}`;
    window.location.href = redirectUrl;
  };


  // Shopping Search Feature
  const [searchShoppingResults, setSearchShoppingResults] = useState([]);
  const [searchShoppingValue, setSearchShoppingValue] = useState('');

  const handleShoppingInputChange = (event) => {
    const inputShoppingValue = event.target.value;
    const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';
    
    if (isBackspace) {
      setSearchShoppingValue('');
    } else {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}search?cat=products_name_only&q=${inputShoppingValue}`;
      console.log("apiUrl: ", apiUrl);
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => setSearchShoppingResults(data))
        .catch(error => console.error(error));
    }
  };

  const handleShoppingResultClick = (shoppingResult) => {
    //console.log("clicked");
    setSearchShoppingValue(shoppingResult.product_name);
    setSearchShoppingResults([]); // Clear search shoppingResults
    document.querySelector('.searchMenu-loc__field').style.opacity = 0;
  };  

  const handleShoppingSearchClick = () => {
    if (searchShoppingValue.trim() === '') {
      alert('Please enter a search value.');
      return;
    }
  
    const encodedShoppingKeyword = encodeURIComponent(searchShoppingValue);
    const redirectUrl = `/products/page/1?keyword=${encodedShoppingKeyword}`;
    window.location.href = redirectUrl;
  };


  // Homam Search Feature
  const [searchHomamResults, setSearchHomamResults] = useState([]);
  const [searchHomamValue, setSearchHomamValue] = useState('');

  const handleHomamInputChange = (event) => {
    const inputHomamValue = event.target.value;
    const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';
    
    if (isBackspace) {
      setSearchHomamValue('');
    } else {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}search?cat=homam_astrology&q=${inputHomamValue}`;
      console.log("apiUrl: ", apiUrl);
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => setSearchHomamResults(data))
        .catch(error => console.error(error));
    }
  };

  const handleHomamResultClick = (homamResult) => {
    //console.log("clicked");
    setSearchHomamValue(homamResult.name);
    setSearchHomamResults([]); // Clear search homamResults
    document.querySelector('.searchMenu-loc__field').style.opacity = 0;
  };  

  const handleHomamSearchClick = () => {
    if (searchHomamValue.trim() === '') {
      alert('Please enter a search value.');
      return;
    }
  
    const encodedHomamKeyword = encodeURIComponent(searchHomamValue);
    const redirectUrl = `/homam-astrology/page/1?keyword=${encodedHomamKeyword}`;
    window.location.href = redirectUrl;
  };
  

  return (
        <div className="tabs -underline mt-30 js-tabs">
            <div className="tabs__controls d-flex x-gap-30 y-gap-20 justify-center sm:justify-start js-tabs-controls">

                <div className="">
                    <button className={`tabs__button text-15 fw-500 text-white pb-4 js-tabs-button ${currentUrl.includes('/store-home') || currentUrl.includes('/homam-home') ? '' : 'is-tab-el-active'}`} data-tab-target=".-tab-item-1">Holidays</button>
                </div>

                <div className="">
                    <button className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button" data-tab-target=".-tab-item-2">Hotels</button>
                </div>

                <div className="">
                    <button className={`tabs__button text-15 fw-500 text-white pb-4 js-tabs-button ${currentUrl.includes('/store-home') ? 'is-tab-el-active' : ''}`} data-tab-target=".-tab-item-3">Shopping</button>
                </div>

                <div className="">
                    <button className={`tabs__button text-15 fw-500 text-white pb-4 js-tabs-button ${currentUrl.includes('/homam-home') ? 'is-tab-el-active' : ''}`} data-tab-target=".-tab-item-4">Homam</button>
                </div>

                </div>
                <div class="row justify-center">
                    <div className="tabs__content mt-30 md:mt-20 js-tabs-content" style={{width:'inherit'}}>

                    <div className={`tabs__pane -tab-item-1 ${currentUrl.includes('/store-home') || currentUrl.includes('/homam-home') ? '' : 'is-tab-el-active'}`}>

                        <div className="mainSearch -w-900 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
                        <div className="button-grid items-center">

                            <div className="searchMenu-loc px-30 lg:py-20 lg:px-0">
                            <div data-x-dd-click="searchMenu-loc">
                                <h4 className="text-15 fw-500 ls-2 lh-16">Location</h4>
                                <div className="text-15 text-light-1 ls-2 lh-16">
                                {!searchValue && (
                                <input
                                    autoComplete="off"
                                    type="search"
                                    placeholder="Start typing..."
                                    className="js-search js-dd-focus"
                                    onChange={handleInputChange}
                                />
                                )}
                                {searchValue && (
                                <input
                                    autoComplete="off"
                                    type="search"
                                    placeholder="Start typing..."
                                    className="js-search js-dd-focus"
                                    onChange={handleInputChange}
                                    value={searchValue}
                                />
                                )}
                                </div>
                            </div>
                            <div
                                className="searchMenu-loc__field shadow-2"
                                style={{ opacity: searchResults.length > 0 ? 1 : 0 }}
                            >
                                <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                                <div className="y-gap-5">
                                    {searchResults.map((result) => (
                                    <div key={result.name}>
                                        <button className="-link d-block col-12 text-left rounded-4 px-20 py-15" onClick={() => handleResultClick(result)}>
                                        <div className="d-flex">
                                            <div className="icon-location-2 text-light-1 text-20 pt-4"></div>
                                            <div className="ml-10">
                                            <div className="text-15 lh-12 fw-500">{result.name}</div>
                                            <div className="text-14 lh-12 text-light-1 mt-5">
                                                Holiday Package
                                            </div>
                                            </div>
                                        </div>
                                        </button>
                                    </div>
                                    ))}
                                </div>
                                </div>
                            </div>
                            </div>


                            <div className="searchMenu-date px-15 lg:py-20 lg:px-0 js-form-dd js-calendar">

                            <div data-x-dd-click="searchMenu-date">
                                <h4 className="text-15 fw-500 ls-2 lh-16">Check in - Check out</h4>

                                <div className="text-14 text-light-1 ls-2 lh-16">
                                <span className="js-first-date">{startDate && startDate.toDateString()} </span>
                                - 
                                <span className="js-last-date"> {endDate && endDate.toDateString()}</span>
                                </div>
                            </div>


                            <div className="searchMenu-date__field shadow-2" style={{width:"302px", left:"60px"}} data-x-dd="searchMenu-date" data-x-dd-toggle="-is-active">
                                <div className="bg-white px-30 py-30 rounded-4">
                                <div className="overflow-hidden js-calendar-slider">
                                    <div className="swiper-wrapper" style={{minHeight:"285px"}}>
                                    <div className="swiper-slide">

                                        <DatePicker
                                        selected={startDate}
                                        onChange={onChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        inline
                                        />

                                    </div>

                                    </div>

                                </div>
                                </div>
                            </div>
                            </div>


                            <div className="searchMenu-guests px-15 lg:py-20 lg:px-0 js-form-dd js-form-counters">

                            <div data-x-dd-click="searchMenu-guests">
                                <h4 className="text-15 fw-500 ls-2 lh-16">Guest</h4>

                                <div className="text-15 text-light-1 ls-2 lh-16">
                                <span className="js-count-adult">2</span> adults
                                -
                                <span className="js-count-child">1</span> children
                                -
                                <span className="js-count-room">1</span> room
                                </div>
                            </div>


                            <div className="searchMenu-guests__field shadow-2" data-x-dd="searchMenu-guests" data-x-dd-toggle="-is-active">
                                <div className="bg-white px-30 py-30 rounded-4">

                                <div className="row y-gap-10 mb-10 justify-between items-center">
                                    <div className="col-auto text-center">
                                    <div className="text-15 fw-200"><i>Guest Options:</i></div>
                                    </div>

                                </div>

                                <div className="row y-gap-10 justify-between items-center">
                                    <div className="col-auto">
                                    <div className="text-15 fw-500">Adults</div>
                                    </div>

                                    <div className="col-auto">
                                    <div className="d-flex items-center js-counter" data-value-change=".js-count-adult">
                                        <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                        <i className="icon-minus text-12"></i>
                                        </button>

                                        <div className="flex-center size-20 ml-15 mr-15">
                                        <div className="text-15 js-count">2</div>
                                        </div>

                                        <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                        <i className="icon-plus text-12"></i>
                                        </button>
                                    </div>
                                    </div>
                                </div>

                                <div className="border-top-light mt-24 mb-24"></div>

                                <div className="row y-gap-10 justify-between items-center">
                                    <div className="col-auto">
                                    <div className="text-15 lh-12 fw-500">Children</div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">Ages 0 - 17</div>
                                    </div>

                                    <div className="col-auto">
                                    <div className="d-flex items-center js-counter" data-value-change=".js-count-child">
                                        <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                        <i className="icon-minus text-12"></i>
                                        </button>

                                        <div className="flex-center size-20 ml-15 mr-15">
                                        <div className="text-15 js-count">1</div>
                                        </div>

                                        <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                        <i className="icon-plus text-12"></i>
                                        </button>
                                    </div>
                                    </div>
                                </div>

                                <div className="border-top-light mt-24 mb-24"></div>

                                <div className="row y-gap-10 justify-between items-center">
                                    <div className="col-auto">
                                    <div className="text-15 fw-500">Rooms</div>
                                    </div>

                                    <div className="col-auto">
                                    <div className="d-flex items-center js-counter" data-value-change=".js-count-room">
                                        <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                        <i className="icon-minus text-12"></i>
                                        </button>

                                        <div className="flex-center size-20 ml-15 mr-15">
                                        <div className="text-15 js-count">1</div>
                                        </div>

                                        <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                        <i className="icon-plus text-12"></i>
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>


                            <div className="button-item">
                            <button className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white" onClick={handleSearchClick}>
                                <i className="icon-search text-20 mr-10"></i>
                                Search
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                
                                
                    <div className="tabs__pane -tab-item-2 ">

                        <div className="mainSearch -w-900 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
                        <div className="button-grid items-center">

                        <div className="searchMenu-loc px-30 lg:py-20 lg:px-0">
                        <div data-x-dd-click="searchMenu-loc">
                            <h4 className="text-15 fw-500 ls-2 lh-16">Location</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                            {!searchHotelValue && (
                            <input
                            autoComplete="off"
                            type="search"
                            placeholder="Start typing..."
                            className="js-search js-dd-focus"
                            onChange={handleHotelInputChange}
                            />
                            )}
                            {searchHotelValue && (
                            <input
                            autoComplete="off"
                            type="search"
                            placeholder="Start typing..."
                            className="js-search js-dd-focus"
                            onChange={handleHotelInputChange}
                            value={searchHotelValue}
                            />
                            )}
                            </div>
                        </div>
                        <div
                            className="searchMenu-loc__field shadow-2"
                            style={{ opacity: searchHotelResults.length > 0 ? 1 : 0 }}
                        >
                            <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                            <div className="y-gap-5">
                            {searchHotelResults.map((hotelResult) => (
                            <div key={hotelResult.name}>
                                <button className="-link d-block col-12 text-left rounded-4 px-20 py-15" onClick={() => handleHotelResultClick(hotelResult)}>
                                <div className="d-flex">
                                <div className="icon-location-2 text-light-1 text-20 pt-4"></div>
                                <div className="ml-10">
                                <div className="text-15 lh-12 fw-500">{hotelResult.name}</div>
                                <div className="text-14 lh-12 text-light-1 mt-5">
                                    Hotel
                                </div>
                                </div>
                                </div>
                                </button>
                            </div>
                            ))}
                            </div>
                            </div>
                        </div>
                        </div>


                        <div className="searchMenu-date px-15 lg:py-20 lg:px-0 js-form-dd js-calendar">

                        <div data-x-dd-click="searchMenu-date">
                            <h4 className="text-15 fw-500 ls-2 lh-16">Check in - Check out</h4>

                            <div className="text-14 text-light-1 ls-2 lh-16">
                            <span className="js-first-date">{startDate && startDate.toDateString()} </span>
                            - 
                            <span className="js-last-date"> {endDate && endDate.toDateString()}</span>
                            </div>
                        </div>


                        <div className="searchMenu-date__field shadow-2" style={{width:"302px", left:"60px"}} data-x-dd="searchMenu-date" data-x-dd-toggle="-is-active">
                            <div className="bg-white px-30 py-30 rounded-4">
                            <div className="overflow-hidden js-calendar-slider">
                            <div className="swiper-wrapper" style={{minHeight:"285px"}}>
                            <div className="swiper-slide">

                                <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                />

                            </div>

                            </div>

                            </div>
                            </div>
                        </div>
                        </div>


                        <div className="searchMenu-guests px-15 lg:py-20 lg:px-0 js-form-dd js-form-counters">

                        <div data-x-dd-click="searchMenu-guests">
                            <h4 className="text-15 fw-500 ls-2 lh-16">Guest</h4>

                            <div className="text-15 text-light-1 ls-2 lh-16">
                            <span className="js-count-adult">2</span> adults
                            -
                            <span className="js-count-child">1</span> children
                            -
                            <span className="js-count-room">1</span> room
                            </div>
                        </div>


                        <div className="searchMenu-guests__field shadow-2" data-x-dd="searchMenu-guests" data-x-dd-toggle="-is-active">
                            <div className="bg-white px-30 py-30 rounded-4">

                            <div className="row y-gap-10 mb-10 justify-between items-center">
                            <div className="col-auto text-center">
                            <div className="text-15 fw-200"><i>Guest Options:</i></div>
                            </div>

                            </div>

                            <div className="row y-gap-10 justify-between items-center">
                            <div className="col-auto">
                            <div className="text-15 fw-500">Adults</div>
                            </div>

                            <div className="col-auto">
                            <div className="d-flex items-center js-counter" data-value-change=".js-count-adult">
                                <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                <i className="icon-minus text-12"></i>
                                </button>

                                <div className="flex-center size-20 ml-15 mr-15">
                                <div className="text-15 js-count">2</div>
                                </div>

                                <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                <i className="icon-plus text-12"></i>
                                </button>
                            </div>
                            </div>
                            </div>

                            <div className="border-top-light mt-24 mb-24"></div>

                            <div className="row y-gap-10 justify-between items-center">
                            <div className="col-auto">
                            <div className="text-15 lh-12 fw-500">Children</div>
                            <div className="text-14 lh-12 text-light-1 mt-5">Ages 0 - 17</div>
                            </div>

                            <div className="col-auto">
                            <div className="d-flex items-center js-counter" data-value-change=".js-count-child">
                                <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                <i className="icon-minus text-12"></i>
                                </button>

                                <div className="flex-center size-20 ml-15 mr-15">
                                <div className="text-15 js-count">1</div>
                                </div>

                                <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                <i className="icon-plus text-12"></i>
                                </button>
                            </div>
                            </div>
                            </div>

                            <div className="border-top-light mt-24 mb-24"></div>

                            <div className="row y-gap-10 justify-between items-center">
                            <div className="col-auto">
                            <div className="text-15 fw-500">Rooms</div>
                            </div>

                            <div className="col-auto">
                            <div className="d-flex items-center js-counter" data-value-change=".js-count-room">
                                <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                                <i className="icon-minus text-12"></i>
                                </button>

                                <div className="flex-center size-20 ml-15 mr-15">
                                <div className="text-15 js-count">1</div>
                                </div>

                                <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                                <i className="icon-plus text-12"></i>
                                </button>
                            </div>
                            </div>
                            </div>
                            </div>
                        </div>
                        </div>


                        <div className="button-item">
                        <button className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white" onClick={handleHotelSearchClick}>
                            <i className="icon-search text-20 mr-10"></i>
                            Search
                        </button>
                        </div>
                        </div>
                        </div>
                    </div>

                    <div className={`tabs__pane -tab-item-3 ${currentUrl.includes('/store-home') ? 'is-tab-el-active' : ''}`}>

                        <div className="mainSearch -w-900 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
                        <div className="button-grid items-center">

                        <div className="searchMenu-loc px-30 lg:py-20 lg:px-0">
                            <div data-x-dd-click="searchMenu-loc">
                                <h4 className="text-15 fw-500 ls-2 lh-16">Search Shopping Item</h4>
                                <div className="text-15 text-light-1 ls-2 lh-16">
                                {!searchShoppingValue && (
                                <input
                                autoComplete="off"
                                type="search"
                                placeholder="Start typing..."
                                className="js-search js-dd-focus"
                                onChange={handleShoppingInputChange}
                                />
                                )}
                                {searchShoppingValue && (
                                <input
                                autoComplete="off"
                                type="search"
                                placeholder="Start typing..."
                                className="js-search js-dd-focus"
                                onChange={handleShoppingInputChange}
                                value={searchShoppingValue}
                                />
                                )}
                                </div>
                            </div>
                            <div className="searchMenu-loc__field shadow-2" style={{ opacity: searchShoppingResults.length > 0 ? 1 : 0 }}>
                                <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                                <div className="y-gap-5">
                                {searchShoppingResults.map((shoppingResult) => (
                                <div key={shoppingResult.product_name}>
                                    <button className="-link d-block col-12 text-left rounded-4 px-20 py-15" onClick={() => handleShoppingResultClick(shoppingResult)}>
                                    <div className="d-flex">
                                    <div className="icon-beach-umbrella text-light-1 text-20 pt-4"></div>
                                    <div className="ml-10">
                                    <div className="text-15 lh-12 fw-500">{shoppingResult.product_name}</div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">
                                        Products Shopping
                                    </div>
                                    </div>
                                    </div>
                                    </button>
                                </div>
                                ))}
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="px-15 lg:py-20 lg:px-0 ">

                        </div>


                        <div className="button-item">
                        <button className="mainSearch__submit button -dark-1 h-60 px-35 col-sm-12 col-md-8 offset-md-4 rounded-100 bg-blue-1 text-white" onClick={handleShoppingSearchClick}>
                            <i className="icon-search text-20 mr-10"></i>
                            Search
                        </button>
                        </div>
                        </div>
                        </div>
                    </div>

                    <div className={`tabs__pane -tab-item-4 ${currentUrl.includes('/homam-home') ? 'is-tab-el-active' : ''}`}>

                        <div className="mainSearch -w-900 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
                        <div className="button-grid items-center">

                        <div className="searchMenu-loc px-30 lg:py-20 lg:px-0">
                            <div data-x-dd-click="searchMenu-loc">
                                <h4 className="text-15 fw-500 ls-2 lh-16">Search Homam / Astrology</h4>
                                <div className="text-15 text-light-1 ls-2 lh-16">
                                {!searchHomamValue && (
                                <input
                                autoComplete="off"
                                type="search"
                                placeholder="Start typing..."
                                className="js-search js-dd-focus"
                                onChange={handleHomamInputChange}
                                />
                                )}
                                {searchHomamValue && (
                                <input
                                autoComplete="off"
                                type="search"
                                placeholder="Start typing..."
                                className="js-search js-dd-focus"
                                onChange={handleHomamInputChange}
                                value={searchHomamValue}
                                />
                                )}
                                </div>
                            </div>
                            <div className="searchMenu-loc__field shadow-2" style={{ opacity: searchHomamResults.length > 0 ? 1 : 0 }}>
                                <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                                <div className="y-gap-5">
                                {searchHomamResults.map((homamResult) => (
                                <div key={homamResult.name}>
                                    <button className="-link d-block col-12 text-left rounded-4 px-20 py-15" onClick={() => handleHomamResultClick(homamResult)}>
                                    <div className="d-flex">
                                    <div className="icon-star text-light-1 text-20 pt-4"></div>
                                    <div className="ml-10">
                                    <div className="text-15 lh-12 fw-500">{homamResult.name}</div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">
                                        Products Homam
                                    </div>
                                    </div>
                                    </div>
                                    </button>
                                </div>
                                ))}
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="px-15 lg:py-20 lg:px-0 ">

                        </div>


                        <div className="button-item">
                        <button className="mainSearch__submit button -dark-1 h-60 px-35 col-sm-12 col-md-8 offset-md-4 rounded-100 bg-blue-1 text-white" onClick={handleHomamSearchClick}>
                            <i className="icon-search text-20 mr-10"></i>
                            Search
                        </button>
                        </div>
                        </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
  );
}

export default PrimarySearchBox;
