import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import PrimarySearchBox from './PrimarySearchBox';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {Helmet} from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { SubscribeRibbon } from './SubscribeRibbon';

function HomeStore() {

  const [widgetData, setWidgetData] = useState([]);

  useEffect(() => {
    const setCustomAttributes = () => {
      const sectionSliders = document.querySelectorAll('.js-section-slider');
      sectionSliders.forEach((slider) => {
        if (!slider.getAttribute('data-gap')) {
          slider.setAttribute('data-gap', '30');
        }
        if (!slider.getAttribute('data-scrollbar')) {
          slider.setAttribute('data-scrollbar', '');
        }
        if (!slider.getAttribute('data-slider-cols')) {
          slider.setAttribute('data-slider-cols', 'base-2 xl-4 lg-3 md-2 sm-2 base-1');
        }
        if (!slider.getAttribute('data-anim')) {
          slider.setAttribute('data-anim', 'slide-up');
        }
      });
    };
  
    setCustomAttributes();
  
  }, [widgetData]);

  useEffect(() => {
    const fetchWidgetData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}admin_products_widgets`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData(data);
      } catch (error) {
        console.error('Error fetching widget data:', error);
      }
    };
  
    fetchWidgetData();
  }, []);
  
  const fetchHotelData = async (ids, widgetId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?id=${ids}`;
      console.log("apiUrl:", apiUrl);
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWidgetData((prevData) =>
        prevData.map((widget) =>
          widget.id === widgetId ? { ...widget, hotelData: data } : widget
        )
      );
    } catch (error) {
      console.error('Error fetching hotel data:', error);
    }
  };
  
  const fetchTourData = async (ids, widgetId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour_listings?id=${ids}`;
      console.log("Tour API: ", apiUrl);
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWidgetData((prevData) =>
        prevData.map((widget) =>
          widget.id === widgetId ? { ...widget, packageData: data } : widget
        )
      );
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };

  const fetchProductData = async (ids, widgetId) => {
    try {
      if(ids){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}product_listings?id=${ids}`;
        console.log("product API: ", apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData((prevData) =>
          prevData.map((widget) =>
            widget.id === widgetId ? { ...widget, productData: data } : widget
          )
        );
      }
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };

  const fetchHomamData = async (ids, widgetId) => {
    try {
      if(ids){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?id=${ids}`;
        console.log("Homam API", apiUrl);
        const response = await fetch(apiUrl);
        const data = await response.json();
        setWidgetData((prevData) =>
          prevData.map((widget) =>
            widget.id === widgetId ? { ...widget, homamData: data } : widget
          )
        );
      }
    } catch (error) {
      console.error('Error fetching tour data:', error);
    }
  };
  
  useEffect(() => {
    const fetchDataForWidgets = async () => {
      for (const widget of widgetData) {
        if (widget.widget_category === 'products' && !widget.productData) {
          await fetchProductData(widget.widget_ids, widget.id);
        } 
      }
    };
  
    fetchDataForWidgets();
  }, [widgetData]);

  const renderWidgetSections = () => {
    return widgetData.map((widget) => {
        if (widget.widget_category === 'products' && widget.productData) {
        return (
          <section key={widget.id} className="layout-pt-md layout-pb-md">
            <div data-anim="slide-up delay-1" className="container is-in-view">
              <div className="row y-gap-10 justify-between items-end">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">{widget.widget_name}</h2>
                  </div>
                </div>
      
                <div className="col-auto md:d-none">
      
                  <a href="/products/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Products <div className="icon-arrow-top-right ml-15"></div>
                  </a>
      
                </div>
              </div>
      
              <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={30}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    slidesPerView={4}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {widget.productData.map((hotel) => (
                      <SwiperSlide>
                        <div className="swiper-slide swiper-slide-visible swiper-slide-active" style={{marginRight:"30px"}} key={hotel.id}>
            
                          <a href={`/product/${hotel.product_name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} className="hotelsCard -type-1 ">
                            <div className="hotelsCard__image">
            
                              <div className="cardImage ratio ratio-1:1">
                                <div className="cardImage__content">
                                  <img
                                  src={`${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${hotel.image_src !== null ? hotel.image_src.replace(/ /g, '%20') : ''}`}
                                  alt="image"
                                  />
            
                                </div>
            
                              </div>
            
                            </div>
            
                            <div className="hotelsCard__content mt-10 text-center">
                              <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                <span>{hotel.product_name}</span>
                              </h4>
            
                              <div className="mt-5">
                                <div className="fw-500">
                                  {!hotel.price || hotel.price === 0
                                    ? ""
                                    : ''}
                                  <span className="text-blue-1">
                                  {!hotel.price || hotel.price === 0
                                    ? "Contact us for Price"
                                    : `₹${hotel.price}/-`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
            
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
      
              </div>
            </div>
          </section>
        );
      } else {
        return null; // Widget category not recognized
      }
    });
  };


  //Header image slider
  const [swiperData, setSwiperData] = useState([]);
  
  useEffect(() => {
    const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?site_category=home_banners&id=1`;
    console.log('microDataUrl:', microDataUrl);
    // Replace with your API URL
    fetch(microDataUrl)
      .then((response) => response.json())
      .then((data) => setSwiperData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);


  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch shopping categories
    fetch(`${process.env.REACT_APP_API_ENDPOINT}shopping_categories`)
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error(error));
  }, []);

  const [productListings, setProductListings] = useState({});

  const getProductListings = (categoryId) => {
    // Fetch product listings for a specific category
    fetch(`${process.env.REACT_APP_API_ENDPOINT}product_listings?cat=${categoryId}&limit=3`)
      .then(response => response.json())
      .then(data => {
        // Update the product listings state
        setProductListings(prevState => ({ ...prevState, [categoryId]: data }));
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    // Run the second API for all categories when categories change
    categories.forEach(category => getProductListings(category.id));
  }, [categories]);

  const renderProducts = (category) => {
    const products = productListings[category.id];

    if (!products) {
      // Product listings are not yet available
      return <p>Loading...</p>;
    }

    return (
      <div>
        {products.map(product => (
          <a key={product.id} href={`/product/${product.product_name.toLowerCase().replace(/\s+/g, '-')}/${product.id}`} className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-5 py-5 bg-light-2 rounded-4 mb-10">
            <div className="ml-10">
              <p className="text-16">{product.product_name}</p>
            </div>
          </a>
        ))}
        <a href={`/products/type/${category.category_name.toLowerCase().replace(/\s+/g, '-')}/${category.id}`} className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-5 py-5 bg-light-2 rounded-4 mb-10">
          <div className="ml-10">
            <p className="text-16">View All Products</p>
          </div>
        </a>
      </div>
    );
  };

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Buy Premium Gift Products Online | Blessingsonthenet.com</title>
    </Helmet> 
    <section data-anim-wrap className="masthead -type-1 z-5">
      <div data-anim-child="fade" className="swiper-wrapper masthead__bg">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img src={`${process.env.REACT_APP_API_ENDPOINT}uploads/home_banners/${item.image_path}`} alt={`Slide ${item.id}`} className="js-lazy" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      
      <div className="container">
        <div className="row justify-center">
          <div className="col-auto">
            <div className="text-center mb-40">
              <h1 data-anim-child="slide-up delay-1" className="text-40 lg:text-40 md:text-30 text-white">Blessings Online Store</h1>
              <p data-anim-child="slide-up delay-2" className="text-white mt-6 md:mt-10 text-22">Blessingsonthenet is largest Online Shop for Idols of Hindu Gods & Goddesses ,Yantras, Paintings,
Divine Rudraksha, spiritual accessories, Premium Creations-lifestyle Products & more.</p>
            </div>

            <PrimarySearchBox />
          </div>
        </div>
      </div>
      
    </section>
    <section className="z-5" style={{position:'relative', marginTop:'-80px'}}>
      <div style={{height:'60px'}} className="swiper-wrapper masthead__bg">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img style={{display:'none'}} src={`${process.env.REACT_APP_API_ENDPOINT}uploads/home_banners/${item.image_path}`} alt={`Slide ${item.id}`} className="js-lazy" />
              {item.caption != null ?
              (<center>
                <a target="_blank" href={item.caption}>
                  <button class="view_offer_btn float-right mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white">View this Offer &nbsp;<span className='icon-play'></span></button>
                </a>
              </center>) : null
              }
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      
    </section>

    {widgetData.length > 0 ? renderWidgetSections() : <p className='text-center'>Loading data, please wait...</p>}


    <section class="layout-pt-lg layout-pb-md relative" id="secondSection">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row y-gap-20 justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">OUR CATALOG</h2>
              {/*<p class="sectionTitle__text mt-5 sm:mt-0">Here are list of nearby places that you might be intersted in</p>*/}
            </div>
          </div>
        </div>

        <div class="row x-gap-10 y-gap-10 pt-40 sm:pt-20">
            {categories.map(category => (
              <div key={category.id} className="col-xl-6 col-lg-3 col-sm-12 is-in-view px-20">
                <div className="col-12 mb-10 catalog_heading">
                  <h5>{category.category_name}</h5>
                </div>
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                    {renderProducts(category)}
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-md-block d-sm-none d-xs-none">
                    {category.thumb && <img src={`${process.env.REACT_APP_API_ENDPOINT}uploads/others/${category.thumb}`} alt={`${category.category_name} thumb`} />}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div class="row x-gap-10 y-gap-10 pt-40 sm:pt-20">
          {/*
          <div data-anim-child="slide-up delay-2 catalog_container" class="col-xl-6 col-lg-3 col-sm-12 is-in-view px-20">
            <div class="col-12 mb-10 catalog_heading">
              <h5>Gold Leafing</h5>
            </div>
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <a href="/indian-temple/id/47/chandi-devi" class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-5 py-5 bg-light-2 rounded-4 mb-10">
                  <div class="ml-10"><p class="text-16">Shri Mahamrityunjay Yantra</p></div>
                </a>
                <a href="/indian-temple/id/114/kedarnath-temple" class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-5 py-5 bg-light-2 rounded-4 mb-10">
                  <div class="ml-10"><p class="text-16">The Balaji with Lakshmi Green Tanjore Painting</p></div>
                </a>
                <a href="/indian-temple/id/158/mansa-devi-temple-haridwar" class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-5 py-5 bg-light-2 rounded-4 mb-10">
                  <div class="ml-10"><p class="text-16">Radha Krishna maha puja</p></div>
                </a>
                <a href="/indian-temple/id/20/badrinath-temple-divya-desam" class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-5 py-5 bg-light-2 rounded-4 mb-10">
                  <div class="ml-10"><p class="text-16">View All Products</p></div>
                </a>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-md-block d-sm-none d-xs-none">
                <img src="assets/img/general/idols.png" />
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </section>

    {/*
    <section className="layout-pt-md layout-pb-md">
      <div className="container">
        <div className="row y-gap-20">
          <div data-anim="slide-up" className="col-md-6">

            <div className="ctaCard -type-1 rounded-4 ">
              <div className="ctaCard__image ratio ratio-63:55">
                <img className="img-ratio js-lazy" src="#" data-src="/assets/img/backgrounds/1.png" alt="image" />
              </div>

              <div className="ctaCard__content py-70 px-70 lg:py-30 lg:px-30">


                <h4 className="text-40 lg:text-26 text-white">Hotel Sun & Sand<br /> Shirdi</h4>

                <div className="d-inline-block mt-30">
                  <a href="#" className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1">View Details</a>
                </div>
              </div>
            </div>

          </div>

          <div data-anim="slide-up delay-1" className="col-md-6">

            <div className="ctaCard -type-1 rounded-4 ">
              <div className="ctaCard__image ratio ratio-63:55">
                <img className="img-ratio js-lazy" src="#" data-src="/assets/img/holidays/2.png" alt="image" />
              </div>

              <div className="ctaCard__content py-70 px-70 lg:py-30 lg:px-30">

                <div className="text-15 fw-500 text-white mb-10">Enjoy Summer Deals</div>


                <h4 className="text-40 lg:text-26 text-white">Up to 70% Discount!</h4>

                <div className="d-inline-block mt-30">
                  <a href="#" className="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1">Learn More</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


    <section style={{paddingTop:120}} className="layout-pt-lg layout-pb-lg bg-blue-2">
      <div data-anim-wrap className="container">
        <div className="row y-gap-40 justify-between">
          <div data-anim-child="slide-up delay-1" className="col-xl-5 col-lg-6">
            <h2 className="text-30">What our customers are<br /> saying about us?</h2>
            <p className="mt-20">We have been serving holiday packages on various categories for over 15 years now with most of the client with happy faces.</p>

            <div className="row y-gap-30 pt-60 lg:pt-40">
              <div className="col-sm-5 col-6">
                <div className="text-30 lh-15 fw-600">5k+</div>
                <div className="text-light-1 lh-15">Happy Customers</div>
              </div>

              <div className="col-sm-5 col-6">
                <div className="text-30 lh-15 fw-600">4.88</div>
                <div className="text-light-1 lh-15">Overall rating</div>

                <div className="d-flex x-gap-5 items-center pt-10">

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                  <div className="icon-star text-blue-1 text-10"></div>

                </div>
              </div>
            </div>
          </div>

          <div data-anim-child="slide-up delay-1" className="col-lg-6">
            <div className="overflow-hidden js-testimonials-slider-3" data-scrollbar>
              <div className="swiper-wrapper">

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatars/1.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Anitha Rajan</h5>
                      <div className="text-15 text-light-1 lh-15">Working Profession</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">Blessingsonthenet team helped us getting best holiday package and we are very happy with their service. I would highly recommend to my friends. Give it a try and experience their service once.</p>
                </div>

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatars/1.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Annette Black</h5>
                      <div className="text-15 text-light-1 lh-15">UX / UI Designer</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">The place is in a great location in Gumbet. The area is safe and beautiful. The apartment was comfortable and the host was kind and responsive to our requests.</p>
                </div>

                <div className="swiper-slide">
                  <div className="row items-center x-gap-30 y-gap-20">
                    <div className="col-auto">
                      <img src="#" data-src="/assets/img/avatars/1.png" alt="image" className="js-lazy" />
                    </div>

                    <div className="col-auto">
                      <h5 className="text-16 fw-500">Annette Black</h5>
                      <div className="text-15 text-light-1 lh-15">UX / UI Designer</div>
                    </div>
                  </div>

                  <p className="text-18 fw-500 text-dark-1 mt-30 sm:mt-20">The place is in a great location in Gumbet. The area is safe and beautiful. The apartment was comfortable and the host was kind and responsive to our requests.</p>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="layout-pt-md layout-pb-md bg-dark-2">
      <div className="container">
        <div className="row y-gap-30 justify-between items-center">
          <div className="col-auto">
            <div className="row y-gap-20  flex-wrap items-center">
              <div className="col-auto">
                <div className="icon-newsletter text-60 sm:text-40 text-white"></div>
              </div>

              <div className="col-auto">
                <h4 className="text-26 text-white fw-600">Your Travel Journey Starts Here</h4>
                <div className="text-white">Sign up and we'll send the best deals to you</div>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="single-field -w-410 d-flex x-gap-10 y-gap-20">
              <div>
                <input className="bg-white h-60" type="text" placeholder="Your Email" />
              </div>

              <div>
                <button className="button -md h-60 bg-blue-1 text-white">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    */}
        <SubscribeRibbon />
    </>
  );
}

export default HomeStore;