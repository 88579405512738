import React, { useState, useEffect } from 'react';
import AdminFooter from './AdminFooter';
import { useParams } from 'react-router-dom';
import { isAuthenticated } from '../middleware/admin_auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function AdminGroupMicrositesListings() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const { id, keyword, city } = useParams();
    const [micrositeListings, setMicrositeListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Define totalPages variable

    const location = useLocation();
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState(0);

    const [searchValue, setSearchValue] = useState([]);

    useEffect(() => {
        let page = id ? parseInt(id) : currentPage; // Use the value from the URL if present
        setCurrentPage(page);
      
        const searchParams = new URLSearchParams(location.search);
      
        if (keyword) {
          searchParams.set('keyword', keyword);
          searchParams.set('city', keyword);
        }
  
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/all?cat=group`;
        console.log("apiUrl: ", apiUrl);      
      
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setMicrositeListings(data);
              setTotalCount(data.length > 0 ? data[0].totalCount : 0);
              const totalPages = Math.ceil(data[0].totalCount / 20);
              setTotalPages(totalPages);
          })
          .catch((error) => console.log(error));
    }, [id, currentPage, keyword, totalCount]);
  
    const handlePageClick = (page) => {
        setCurrentPage(page);
      
        const path = location.pathname;
        const currentQueryParams = new URLSearchParams(location.search);
        if(searchValue){
            currentQueryParams.set('keyword', searchValue); // Append the keyword to the URL
            currentQueryParams.set('city', searchValue); // Append the keyword to the URL
        }
      
        let newPath = path;
      
        if (path.includes('/page/')) {
          newPath = path.replace(/\/page\/\d+/, `/page/${page}`);
        } else {
          newPath = `${path}/page/${page}`;
        }
      
        const newURL = `${newPath}?${currentQueryParams.toString()}`;
        navigate(newURL);
      
        // Scroll to the top of the page
        window.scrollTo({
          top: 200,
          behavior: 'smooth',
        });
    };      


    const appendFilterToURL = (filterType, value) => {
        const searchParams = new URLSearchParams(location.search);
      
        if (filterType === 'page') {
          searchParams.set(filterType, value);
        } else {
          if (searchParams.has(filterType)) {
            searchParams.delete(filterType);
          }
      
          searchParams.set(filterType, value);
        }
      
        const newURL = `${location.pathname}?${searchParams.toString()}`;
        navigate(newURL);
    };
    
    function updateURLParameter(url, param, value) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(param);
        searchParams.append(param, value);
        return `${url.split('?')[0]}?${searchParams.toString()}`;
    } 


    const [searchResults, setSearchResults] = useState([]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';
      
        if (isBackspace) {
          setSearchValue('');
          window.location.href = '/admin/individual-microsites/page/1';
        } else {
          const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsites_listings?page=${currentPage}&keyword=${inputValue}&city=${inputValue}`;
          console.log("apiSearchURL:", apiUrl);
          fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
              setMicrositeListings(data);
            })
            .catch(error => console.error(error));
          setSearchValue(inputValue);
          console.log("searchValue: ", searchValue);
        }
    };      

    


    return (
        <div class="dashboard__main">
            <div class="dashboard__content bg-light-2">
            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">
    
                    <h1 class="text-30 lh-14 fw-600">Group Microsites</h1>
    
                </div>
                {/*
                <div class="col-auto">
                    <div class="w-230 single-field relative d-flex items-center">
                        <input
                        className="pl-50 bg-white text-dark-1 h-50 rounded-8"
                        type="text"
                        placeholder="Search"
                        onChange={handleInputChange}
                        value={searchValue}
                        />
                        <ul className="autocomplete-results">
                        {searchResults.map(result => (
                            <li key={result.name}>{result.name}</li>
                        ))}
                        </ul>
                        <button class="absolute d-flex items-center h-full">
                            <i class="icon-search text-20 px-15 text-dark-1"></i>
                        </button>
                    </div>
                </div>
                */}
                <div class="col-auto">
                    {/*
                    <a href="/admin/hotel/create" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        Add New Hotel <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                    */}
                </div>
                
            </div>
    
    
            <div class="py-30 px-30 rounded-4 bg-white shadow-3">
    
                <div class="tabs__content pt-30 js-tabs-content">
    
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <div class="overflow-scroll scroll-bar-1">
                        <table class="table-4 -border-bottom col-12">
                        <thead class="bg-light-2">
                            <tr>
                            <th>Group Microsite Name</th>
                            <th>Domain</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
    
                        {micrositeListings.map(microsite => (
                            <tr key={microsite.id}>
                                <td className="text-blue-1 fw-500">
                                    <a target="_blank" href={`https://${microsite.domain}`}>{microsite.name}</a>
                                </td>
                                
                                <td>{microsite.domain}</td>
                                <td>
                                    <div className="row x-gap-10 y-gap-10 items-center">
                                    
                                    <div className="col-auto">
                                        <a target="_blank" href={`https://${microsite.domain}`} >
                                            <button title="Visit Website" className="flex-center bg-light-2 rounded-4 size-35">
                                            <i className="icon-globe text-16 text-light-1"></i>
                                            </button>
                                        </a>
                                    </div>
                                    <div className="col-auto">
                                        <a href={`/admin/micro/group/edit/${microsite.id}`} >
                                            <button title="Edit" className="flex-center bg-light-2 rounded-4 size-35">
                                            <i className="icon-edit text-16 text-light-1"></i>
                                            </button>
                                        </a>
                                    </div>
                                    <div className="col-auto">
                                        <a href={`/admin/micro/group/temples/${microsite.id}`} >
                                            <button title="Sites List" className="flex-center bg-light-2 rounded-4 size-35">
                                            <i className="icon-menu text-16 text-light-1"></i>
                                            </button>
                                        </a>
                                    </div>
                                    {/*
                                    <div className="col-auto">
                                        <button
                                            title="Delete"
                                            className="flex-center bg-light-2 rounded-4 size-35"
                                            onClick={() => {
                                            if (window.confirm("Are you sure you want to delete this microsite?")) {
                                                window.location.href = `/admin/micro/delete/${microsite.id}`;
                                            }
                                            }}
                                        >
                                            <i className="icon-trash-2 text-16 text-light-1"></i>
                                        </button>
                                    </div>
                                    */}

                                    </div>
                                </td>
                            </tr>
                        ))}
    
                        </tbody>
                        </table>
                    </div>
                    </div>
    
                </div>
            </div>
    
    
            <AdminFooter />
            </div>
        </div>
    );
}

export default AdminGroupMicrositesListings;