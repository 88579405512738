import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';

function App() {
  return (
    <body>
      <div class="js-preloader">
        <div class="preloader__wrap">
        </div>
      </div>
      <main>
        <Header />
          <Sidebar />
          <Router>
            <Routes />
          </Router>
        <Footer />
      </main>
    </body>
  );
}

export default App;
