import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSearch from './HeaderSearch';
import { SubscribeRibbon } from './SubscribeRibbon';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import {Helmet} from "react-helmet";

function HotelContactInfo() {
  const { id, cityName, title } = useParams();
  const [hotelData, setHotelData] = useState({});
  const { 
    name
    ,short_description
    ,description
    ,hotel_image
    ,feature_images
    ,home_feature_images
    ,image_featured
    ,home_image_featured
    ,thumbnail_image
    ,city
    ,state
    ,neighborhood
    ,nighborhoodcity
    ,star_rating
    ,group
    ,logo_image
    ,location
    ,latitude
    ,longitude
    ,address
    ,zipcode
    ,type
    ,status
    ,interest_type
    ,category_type
    ,budget_type
    ,premium
    ,room
    ,available_date
    ,price
    ,roomtype
    ,available
    ,videos
    ,article
    ,featured
    ,contact_person
    ,contact_number
    ,contact_numbertw
    ,contact_address
    ,contact_person_hotel
    ,contact_number_hotel
    ,Percentage_commission_hotel
    ,special_rate
    ,email_id_hotel
    ,Address_hotel
    ,booking_office_number
    ,mobile_number_book
    ,booking_office_contact_name
    ,bank_details
    ,b2b
    ,convenience_charge
    ,convenience_charge_type
    ,facility
    ,keywords
    ,iskeyword
    ,note1
    ,note2
    ,metadesc
    ,metakey_tariff
    ,metadesc_tariff
    ,metakey_contact
    ,metadesc_contact
    ,metakey_enquiry
    ,metadesc_enquiry
    ,highlights
    ,special_notes
    ,delete_status
   } = hotelData;

  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel/${id}`;
  console.log("api_url", apiUrl);

  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setHotelData(data))
      .catch(error => console.error(error));
  }, []);  

  let refined_url = name ? name.toLowerCase().replace(/\s+/g, '-') : '';


  const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
  useEffect(() => {
    const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=hotel`;
    console.log("imageAPIURLGallery: ", imageAPIURLGallery);
    fetch(imageAPIURLGallery)
      .then(response => response.json())
      .then(data => setThumbImagesGallery(data))
      .catch(errorGallery => console.errorGallery(errorGallery));
  }, []);

  
  const [thumbImages, setThumbImages] = useState([]);

  useEffect(() => {
    const imageAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=thumb&site_category=hotel`;
    fetch(imageAPIURL)
      .then(response => response.json())
      .then(data => setThumbImages(data))
      .catch(error => console.error(error));
  }, []);

  const [cityState, setCityState] = useState([]);

  useEffect(() => {
    if (city) {
      const cityStateAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}cities?city_id=${city}`;
      console.log("cityStateAPIURL: ", cityStateAPIURL);
      fetch(cityStateAPIURL)
        .then(response => response.json())
        .then(data => setCityState(data))
        .catch(error => console.error(error));
    }
  }, [city]);


  let oldImage;
  if(hotel_image == ''){
    oldImage = 0;    
  } else{
    oldImage = 1;
  }
  //console.log("oldImage: ", oldImage);

  console.log("city: ", city);
    
    //console.log("parent_id: ", parent_id);
    const [hotelTypes, setHotelTypes] = useState([]);
    useEffect(() => {
        const hotelTypesUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_types/${id}`;
        console.log("hotelTypesUrl: ", hotelTypesUrl);
        fetch(hotelTypesUrl)
          .then(response => response.json())
          .then(data => setHotelTypes(data))
          .catch(error => console.error(error));
    }, [id]);


    const [similarHotels, setSimilarHotels] = useState([]);
    useEffect(() => {
        const similarHotelsUrl = `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${city}&item=hotels&neglect=${id}`;
        console.log("similarHotelsUrl: ", similarHotelsUrl);
        fetch(similarHotelsUrl)
          .then(response => response.json())
          .then(data => setSimilarHotels(data))
          .catch(error => console.error(error));
    }, [id, city]);

    //console.log("tourPlans: ", tourPlans);


    const [open, setOpen] = React.useState(false);

    const [openRoomTypes, setOpenRoomTypes] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [lightboxSlides, setLightboxSlides] = useState([]);

    const handleThumbnailClick = (roomId) => {
      const slidesForRoom = thumbImagesGallery
        .filter((image) => Number(image.room_type) === roomId)
        .map((filteredImage) => ({
          src: filteredImage.hotel_image.includes('aim_')
            ? `/img/uploads/hotels/roomgallery/${filteredImage.hotel_image}`
            : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${filteredImage.hotel_image}`,
          description: filteredImage.name,
        }));

      setLightboxIndex(0); // Reset the index when opening the lightbox
      setLightboxSlides(slidesForRoom);
      setOpenRoomTypes(true);
    };
  


    const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [numAdults, setNumAdults] = useState(2);
  const [numChildren, setNumChildren] = useState(0);
  const [numRooms, setNumRooms] = useState(1);
  const [roomType, setRoomType] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const defaultCheckInDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    const defaultCheckOutDate = new Date(currentDate.getTime() + 4 * 24 * 60 * 60 * 1000);

    setCheckInDate(defaultCheckInDate.toISOString().split('T')[0]);
    setCheckOutDate(defaultCheckOutDate.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    calculateTotalCost();
  }, [checkInDate, checkOutDate, numAdults, numChildren, numRooms, roomType]);

  useEffect(() => {
    if (hotelTypes.length > 0) {
      setRoomType(hotelTypes[0]?.room_type);
    }
  }, [hotelTypes]);

  const handleCheckInDate = (e) => {
    setCheckInDate(e.target.value);
  };

  const handleCheckOutDate = (e) => {
    setCheckOutDate(e.target.value);
  };

  const handleNumChildrenChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 0) {
      setNumChildren(value);
    }
  };

  const handleNumAdultsChange = (e) => {
    const adults = parseInt(e.target.value);
    const maxAdults = numRooms * 4; // Maximum adults allowed in the selected number of rooms
  
    if (adults <= maxAdults) {
      setNumAdults(adults);
    } else {
      // If the number of adults exceeds the maximum, adjust the number of rooms accordingly
      setNumAdults(maxAdults);
      setNumRooms(Math.ceil(maxAdults / 4));
    }
  };
  
  const handleNumRoomsChange = (e) => {
    const rooms = parseInt(e.target.value);
    const maxAdults = rooms * 4; // Maximum adults allowed in the selected number of rooms
  
    if (numAdults > maxAdults) {
      setNumAdults(maxAdults);
    }
    if (!isNaN(rooms) && rooms >= 1) {
      setNumRooms(rooms);
    }
  };

  const handleRoomTypeChange = (e) => {
    setRoomType(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform checkout logic here
  };

  const [convenienceChargeAmount, setConvenienceChargeAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [taxPercent, setTaxPercent] = useState(0);
  const [selectedRoomTypePrice, setSelectedRoomTypePrice] = useState(0);

  const calculateTotalCost = () => {
    const selectedRoomType = hotelTypes.find((type) => type.room_type === roomType);
  
    if (selectedRoomType && checkInDate && checkOutDate) {
      const checkIn = new Date(checkInDate);
      const checkOut = new Date(checkOutDate);
      const numNights = Math.ceil((checkOut - checkIn) / (1000 * 60 * 60 * 24)); // Use Math.ceil to round up to the nearest whole night
  
      const basePrice = selectedRoomType.price;
      setSelectedRoomTypePrice(basePrice);
      const extraPersonCharge = selectedRoomType.extra_person_charge;
      const tax = selectedRoomType.tax;
      setTaxPercent(tax);
  
      const totalBaseCost = basePrice * numRooms * numNights;
      const totalExtraPersonCost = extraPersonCharge * Math.max(numAdults - 2, 0) + extraPersonCharge * Math.max(numChildren - 1, 0); // Update calculation for extra person cost
      const totalCost = totalBaseCost + totalExtraPersonCost;
      console.log("totalCost: ", totalCost);
      setSelectedRoomTypePrice(totalCost);
  
      const taxAmount = (totalCost * tax) / 100;
      setTaxAmount(taxAmount);
      let totalCostWithTax = totalCost + taxAmount;

      // Addition of convenience fee
      if(convenience_charge_type === 'rupees'){
        totalCostWithTax = parseFloat(totalCostWithTax) + parseFloat(convenience_charge);
        setConvenienceChargeAmount(parseFloat(convenience_charge));
      } else if (convenience_charge_type === 'percentage') {
        const convenienceChargeAmount = (parseFloat(convenience_charge) * parseFloat(totalCost)) / 100;
        totalCostWithTax += convenienceChargeAmount;
        setConvenienceChargeAmount(convenienceChargeAmount);
      }
  
      setTotalPrice(totalCostWithTax);
    }
  };     


const [showTooltip, setShowTooltip] = useState(false);

const toggleTooltip = () => {
  setShowTooltip(!showTooltip);
};

const mealTypesLabels = ['European Plan', 'American Plan', 'Modified American Plan', 'Continental Plan'];

const getMealTypeLabel = (mealTypes) => {
  if (mealTypes) {
    const mealTypeIndices = mealTypes.split(',');
    for (let i = 0; i < mealTypeIndices.length; i++) {
      if (mealTypeIndices[i] > 0) {
        return mealTypesLabels[i];
      }
    }
  }
  return '';
};



const handleCheckout = () => {

  const newItem = {
      id: id,
      checkInDate: checkInDate,
      checkOutDate: checkOutDate,
      category: 'hotel',
      numAdults: numAdults,
      numChildren: numChildren,
      numRooms: numRooms,
      roomType: roomType,
      selectedRoomTypePrice: selectedRoomTypePrice,
      taxAmount: taxAmount,
      convenienceChargeAmount: convenienceChargeAmount,
      totalPrice: totalPrice
  };

  // Retrieve existing cart items from local storage or initialize an empty array
  const existingItems = JSON.parse(localStorage.getItem('cartItems')) || [];

  // Add the new item to the existing array of cart items
  const updatedItems = [...existingItems, newItem];

  // Store the updated array back into local storage
  localStorage.setItem('cartItems', JSON.stringify(updatedItems));

  window.location.href = "/cart";
};



const [formData, setFormData] = useState({
  fullName: '',
  email: '',
  mobileNumber: '',
  noOfPersons: '',
  hotelType: '',
  startDate: '',
  endDate: '',
  message: '',
  graduationDay: ''
});
const [isSubmitted, setIsSubmitted] = useState(false);

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};

const handleEnquirySubmit = (e) => {
  e.preventDefault();

  if (formData.graduationDay !== '') {
    // A value is present in the honeypot field, likely a bot
    return;
  }

  if (
    formData.fullName.trim() === '' ||
    formData.email.trim() === '' ||
    formData.mobileNumber.trim() === '' ||
    formData.noOfPersons.trim() === ''
  ) {
    alert('Please fill in all required fields.');
  } else {
    // Prepare the form data for submission
    const data = {
      fullName: formData.fullName,
      email: formData.email,
      mobileNumber: formData.mobileNumber,
      noOfPersons: formData.noOfPersons,      
      hotelType: '',
      startDate: formData.startDate,
      endDate: formData.endDate,
      message: formData.message,
      website: 'Blessingsonthenet.com',
      page_title: name
    };

    console.log("enquiry data: ", data);

    // Replace 'your-api-endpoint' with your actual API endpoint
    fetch(`${process.env.REACT_APP_API_ENDPOINT}enquiry_submission`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // Handle a successful response here
          setIsSubmitted(true);
        } else {
          // Handle errors here
          console.error('Form submission failed');
        }
      })
      .catch((error) => {
        console.error('Error submitting the form:', error);
      });
  }
};


// Calculate the minimum selectable date (2 days from today)
const checkInToday = new Date();
checkInToday.setDate(checkInToday.getDate() + 2);
const minCheckInDate = checkInToday.toISOString().split('T')[0];

const checkOutToday = new Date();
checkOutToday.setDate(checkOutToday.getDate() + 3);
const minCheckOutDate = checkOutToday.toISOString().split('T')[0];


//Popup Form
const [showPopupForm, setshowPopupForm] = useState(false);

useEffect(() => {
  const timeout = setTimeout(() => {
    // Check if the popup was previously closed within the last 24 hours
    const popupClosedTime = localStorage.getItem('popupHotelClosedTime');
    if (popupClosedTime) {
      const now = new Date().getTime();
      const elapsedTime = now - parseInt(popupClosedTime);
      if (elapsedTime >= 12 * 60 * 60 * 1000) {
        // If more than 12 hours have passed since the popup was closed, show it again
        setshowPopupForm(true);
      }
    } else {
      // If the popup has not been closed before, show it after 20 seconds
      setshowPopupForm(true);
    }
  }, 20000);

  return () => clearTimeout(timeout);
}, []);

const handleClosePopup = () => {
  setshowPopupForm(false);
  // Store in local storage that the user has closed the popup
  localStorage.setItem('popupHotelClosed', 'true');
  // Store the time when the popup was closed
  const now = new Date().getTime();
  localStorage.setItem('popupHotelClosedTime', now.toString());
};

function openPopup() {
  setshowPopupForm(true);
}

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${name} | Blessingsonthenet.com`}</title>
    </Helmet>

    {showPopupForm && (
        <div className="position-fixed top-0 bottom-0 d-flex justify-content-center align-items-center w-75 popup_form">
          <div className="px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Explore Our Exclusive Hotel Deals!</div>
                <small>Fill your details below and we'll call you in a while.</small>
                <button type="button" className="close popup_form_close" aria-label="Close" onClick={handleClosePopup}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="row y-gap-15 pt-20">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="noOfPersons"
                        value={formData.noOfPersons}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">No. of Person*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        min={minCheckInDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        min={minCheckOutDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="1"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Message (If Any)</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Get a Callback
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
        </div>
    )}

    <div class="floating-section">
        <div class="floating-option">
          <a style={{cursor:'pointer'}} onClick={openPopup}>
            <img src={`/assets/img/general/telephone.png`} />
            Get a Callback
          </a>
        </div>
        <div class="floating-option">
            <a href="https://wa.me/919224444455" target="_blank" rel="noopener noreferrer">
              <img src={`/assets/img/general/whatsapp.png`} />
              Chat on WhatsApp
            </a>
        </div>
    </div>

    <div class="mt-35">
      <HeaderSearch />

      <section class="py-10 d-flex items-center bg-light-2">
        <div class="container">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div class="col-auto">
                  <div class="">Home</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="">Hotels</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="text-dark-1">{name}</div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              {/*<a href="#" class="text-14 text-blue-1 underline">View Hotels Involving Dwarka</a>*/}
            </div>
          </div>
        </div>
      </section>

      <section class="pt-40">
        <div class="container">
          <div class="row y-gap-20 justify-between items-end">
            <div class="col-auto">
              <div class="row x-gap-20  items-center">
                <div class="col-auto">
                  <h1 class="text-30 sm:text-25 fw-600" style={{maxWidth:750,}}>{name}</h1>
                </div>
                <div class="col-auto">

                  <i class="icon-star text-10 text-yellow-1"></i>

                  <i class="icon-star text-10 text-yellow-1"></i>

                  <i class="icon-star text-10 text-yellow-1"></i>

                  <i class="icon-star text-10 text-yellow-1"></i>

                  <i class="icon-star text-10 text-yellow-1"></i>

                </div>
              </div>

              <div class="row x-gap-20 y-gap-20 items-center">
                <div class="col-auto">
                  <div class="d-flex items-center text-15 text-light-1">
                    {cityState.length > 0 && `${cityState[0].city}, ${cityState[0].state}`} (<b>{star_rating} Star Hotel</b>)
                  </div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="row x-gap-15 y-gap-15 items-center">
                <div class="col-auto">
                  <div class="text-14">
                  {price !== null && price !== '0' ? (
                    <>
                      Price Starts From{' '}
                      <span className="text-22 text-dark-1 fw-500">₹{price}</span>
                    </>
                  ) : (
                    <a onClick={openPopup} style={{cursor:'pointer'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                      Contact Us
                    </a>
                  )}
                  </div>
                </div>
                
                {price !== null && price !== '0' ? (
                <div class="col-auto">
                  <a href="#book-now" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                    Book Now <div class="icon-arrow-top-right ml-15"></div>
                  </a>
                </div>
                ) : ''}


              </div>
            </div>
          </div>

          <div class="galleryGrid -type-1 pt-30">
            {/*
            {oldImage == 1 ? (
                    <div className="galleryGrid__item">
                        <img
                        style={{height:'unset'}}
                        src={`/img/uploads/hotel/${hotel_image}`}
                        alt="image"
                        className="rounded-4"
                        />
                    </div>
                ) : (
                null
            )}
            */}
            {thumbImagesGallery.length > 0 ? (
              thumbImagesGallery.slice(0, 4).map((image, index) => (
                <div className="galleryGrid__item" key={index}>
                  <img
                    src={image.hotel_image.includes('aim_')
                    ? `/img/uploads/hotels/roomgallery/${image.hotel_image}`
                    : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`}
                    alt="image"
                    className="rounded-4"
                    onClick={() => setOpen(true)}
                  />
                </div>
              ))
            ) : (
              //Showing old images if no gallery images exits
              <div className="galleryGrid__item">
                <img
                  src={`/img/uploads/hotels/roomgallery/${hotel_image}`}
                  alt="image"
                  className="rounded-4"
                  onClick={() => setOpen(true)}
                />
              </div>
            )}

            {thumbImages.length > 0 ? (
                thumbImages.slice(0, 1).map((image, index) => (
                    <div className="galleryGrid__item relative d-flex" key={index}>
                        <img
                        src={image.hotel_image.includes('aim_')
                        ? `/img/uploads/hotels/roomgallery/${image.hotel_image}`
                        : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`}
                        alt="image"
                        className="rounded-4"
                        />
                        <div class="absolute px-10 py-10 col-12 h-full d-flex justify-end items-end">
                        <button type="button" onClick={() => setOpen(true)} className="button -blue-1 px-24 py-15 bg-white text-dark-1 js-gallery">
                          See All Photos
                        </button>
                          <Lightbox
                            plugins={[Captions]}
                            open={open}
                            close={() => setOpen(false)}
                            slides={thumbImagesGallery.map((image) => ({ src: `${image.hotel_image.includes('aim_') ? `/img/uploads/hotels/roomgallery/${image.hotel_image}` : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`}`, description: image.name }))}
                          />

                          {/* Specific room types */}
                          <Lightbox
                            plugins={[Captions]}
                            open={openRoomTypes}
                            close={() => setOpenRoomTypes(false)}
                            currentIndex={lightboxIndex}
                            slides={lightboxSlides}
                          />
                        </div>
                    </div>
                ))
                ) : (
                null
            )}
          </div>
        </div>
      </section>

      <section class="pt-30">
        <div class="container">
          <div class="row y-gap-30">
            <div class="col-xl-8">
              <div class="row y-gap-40 dark_text">
                <p class=" sectionTitle__text mt-20 sm:mt-0"><b class="text-22">Address of the Hotel:</b> <span dangerouslySetInnerHTML={{ __html: address }} /></p>
                <p>Call Us for Enquiry: <b><a href="https://wa.me/919224444455">+91 9224 444 455</a> / <a href="https://wa.me/918452040404">+91 8452 040 404</a></b></p>
              </div>
            </div>

            <div class="col-xl-4">

              <div class="ml-50 lg:ml-0" style={{marginBottom:'20px'}}>
                  <div class="px-30 pt-20 pb-20 border-light rounded-4 shadow-4" id="book-now">
                      <div class="sidebar__item" style={{borderTop:'none'}}>
                          <h5 class="text-20 fw-500 mb-10">Quick Links</h5>
                          <div class="sidebar-checkbox">
                              <table class="table-4 w-1/1 blessings_categories">
                                  <tbody>
                                      <tr>
                                          <td>    
                                              <a href={`/hotel/${refined_url}/${id}`}>
                                                  <img src={`/assets/img/general/categories-arrow.png`} />
                                                  Overview
                                              </a>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>    
                                              <a href={`/hotels/facility/${refined_url}/${cityState[0]?.city}/${id}`}>
                                                  <img src={`/assets/img/general/categories-arrow.png`} />
                                                  Facilities
                                              </a>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>    
                                              <a href={`/hotels/room-type/${refined_url}/${id}`}>
                                                  <img src={`/assets/img/general/categories-arrow.png`} />
                                                  Room Types
                                              </a>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>    
                                              <a href={`/hotels/contactinfo/${refined_url}/${id}`}>
                                                  <img src={`/assets/img/general/categories-arrow.png`} />
                                                  Contact Details/Map
                                              </a>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>    
                                              <a href={`/hotels/accommodation-enquiry/${refined_url}/${id}`}>
                                                  <img src={`/assets/img/general/categories-arrow.png`} />
                                                  Accommodation Enquiry
                                              </a>
                                          </td>
                                      </tr>

                                      
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="ml-50 lg:ml-0">

                <div class="px-30 pt-20 pb-20 border-light rounded-4 shadow-4" id="book-now">
                  <div class="d-flex items-center justify-between">
                    <div class="">
                      <span class="text-20 fw-500">Book Now</span>
                    </div>
                  </div>
                  
                  {!isNaN(price) && price > 0 ? (
                  <div class="row y-gap-20 pt-10">
                      <div className="col-12">
                        <div className="px-20 py-10 border-light rounded-4 -right">
                          <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Check in Date</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span className="js-first-date">
                                <input type="date" id="checkInDate" value={checkInDate} min={minCheckInDate} onChange={handleCheckInDate} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="px-20 py-10 border-light rounded-4 -right">
                          <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Check out Date</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span className="js-first-date">
                                <input type="date" id="checkOutDate" value={checkOutDate} min={minCheckOutDate} onChange={handleCheckOutDate} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className={`row px-20 py-10 border-light rounded-4 room_input`}>

                          <div className='col-4'>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Adults</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span><input type="number" id="numAdults" value={isNaN(numAdults) ? '' : numAdults} min={1} onChange={handleNumAdultsChange} /></span>
                            </div>
                          </div>

                          <div className='col-4'>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Children</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span><input type="number" id="numChildren" value={isNaN(numChildren) ? '' : numChildren} min={0} onChange={handleNumChildrenChange} /></span>
                            </div>
                          </div>

                          <div className='col-4'>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Rooms</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span><input type="number" id="numRooms" value={isNaN(numRooms) ? '' : numRooms} min={1} onChange={handleNumRoomsChange} /></span>
                            </div>
                          </div>

                          <div className={`tooltip_booking ${showTooltip ? 'tooltip-disabled' : 'tooltip-active'}`}>
                            <button className="close-button" onClick={toggleTooltip}>
                              x
                            </button>
                            <p>
                              <b>Important Notes:</b>
                            </p>
                            <ul>
                              <li>Kids below 5 years old are complimentary</li>
                              <li>Kids between 6 to 10 years will be considered as children</li>
                              <li>Kids more than 10 years old will be considered as adults</li>
                            </ul>
                          </div>
                        </div>

                        <div className="row px-20 pt-10 border-light rounded-4 mt-20 room_input">
                          <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Room Type</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <select className="hotel_select_tag" id="roomType" value={roomType} onChange={handleRoomTypeChange}>
                                {hotelTypes.length > 0 &&
                                  hotelTypes.map((page) => (
                                    <option key={page.room_type} value={page.room_type}>
                                      {page.room_type}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="text-center mt-10 row">
                          <span class="text-14 text-light-1 text-left col-6">Sub-Total: </span>
                          <span class="text-15 fw-500 text-right col-6">₹{selectedRoomTypePrice}</span>
                        </div>

                        {taxAmount > 0 && (
                          <div className="text-center row">
                            <span className="text-14 text-light-1 text-left col-6">GST ({taxPercent}%)</span>
                            <span className="text-15 fw-500 text-right col-6">₹{taxAmount}</span>
                          </div>
                        )}

                        {convenienceChargeAmount > 0 && (
                        <div className="text-center row">
                          <span class="text-14 text-light-1 text-left col-6">Convenience Fee: </span>
                          <span class="text-15 fw-500 text-right col-6">₹{convenienceChargeAmount}</span>
                        </div>
                        )}

                        <div className="text-center mt-10 row">
                          <span class="text-14 text-light-1 text-left col-6">Total Price: </span>
                          <span class="text-20 fw-500 text-right col-6">₹{totalPrice}</span>
                        </div>

                      </div>

                      <div class="col-12">
                        <button type="submit" class="button -dark-1 px-35 h-60 col-12 bg-blue-1 text-white" onClick={handleCheckout}>
                          Checkout
                        </button>
                      </div>

                      <div class="col-12">
                        <button onClick={openPopup} style={{cursor:'pointer'}} class="button -blue-1 px-35 h-60 col-12 -outline-blue-1 text-blue-1">Customize Hotel</button>
                      </div>

                  </div>
                  ) : (<div className="text-center">
                    <br />
                    <h6 style={{color:'#FF0000'}}>Contact for Immediate Response</h6>
                    <br />
                    {/*
                    <a href="https://wa.me/918452040404">+91 8452 040 404</a><br />
                    <a href="https://wa.me/919224444455">+91 9224 444 455</a>
                    */}
                    <a href="https://wa.me/918452040404">+91 8452 040 404</a><br />
                    <a href="https://wa.me/919224444455">+91 9224 444 455</a>
                    <br /><br />
                    <h6 style={{color:'#FF0000'}}>Send Us Email</h6>
                    <a href="mailto:travel@blessingsonthenet.com">travel@blessingsonthenet.com</a><br /><br />
                    </div>)
                  }

                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    <section id="accommodation-enquiry" class="layout-pt-lg layout-pb-md pt-80">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Enquire with Us</h2>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div style={{backgroundColor: '#13357B', color: '#fff',}} class="mt-50 px-40 pt-30 pb-30 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color: '#fff',}} class="card-title">WhatsApp Us</h4><br />
                  <p style={{color: '#fff',}} class="card-text">Request a quote, or just chat with us. We're always happy to help!</p><br />
                  {/*
                  <a target="_blank" href="https://wa.me/918452040404"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 8452 040 404</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                  */}
                  <a target="_blank" href="https://wa.me/918452040404"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 8452 040 404</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                </div>
              </div>
              <div style={{backgroundColor: '#ff894c', color:'#051036'}} class="mt-50 px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color:'#051036'}} class="card-title">Write to Us</h4><br />
                  <p style={{color:'#051036'}} class="card-text">Do you like to customize this hotel stay even more or be it an enquiry, feedback or a simple suggestion, write to us.</p><br />
                  <a target="_blank" href="mailto:travel@blessingsonthenet.com"><h5 style={{color:'#051036'}} class="card-text"><img src="/assets/img/icons/email.png" style={{width:'25px'}} /> travel@blessingsonthenet.com</h5></a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
            <div className="mt-50 px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Enter your details for hotel enquiry.</div>
                <div className="row y-gap-15 pt-20">
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="noOfPersons"
                        value={formData.noOfPersons}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">No. of Person*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        min={minCheckInDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        min={minCheckOutDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="2"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Message (If Any)</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Send a Message
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>

      <section class="layout-pt-md layout-pb-lg">
        <div class="container">
          <div class="row justify-center text-center">
            <div class="col-auto">
              <div class="sectionTitle -md">
                <h2 class="sectionTitle__title">Other Hotels that might interest you</h2>
                <p class=" sectionTitle__text mt-5 sm:mt-0">Some of the exciting hotels that are similar</p>
              </div>
            </div>
          </div>

          <div class="row y-gap-30 pt-40 sm:pt-20">

          {similarHotels.length > 0 && similarHotels.map(hotel => (
            <div class="col-xl-3 col-lg-3 col-sm-6">
              <a href={`/hotel/${hotel.name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} class="hotelsCard -type-1">
                <div class="hotelsCard__image">
                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">
                      <img class="rounded-4 col-12" src={
                        hotel.thumb_new
                          ? (hotel.thumb_new.includes('aim_')
                            ? `/img/uploads/hotels/gallery/${hotel.thumb_new}`
                            : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${hotel.thumb_new}`)
                          : '' // Empty string if thumb_new is null or undefined
                      } alt="image" />
                    </div>
                  </div>
                </div>
                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>{hotel.name}</span>
                  </h4>
                  <p class="text-light-1 lh-14 text-14 mt-5">{cityState.length > 0 && `${cityState[0].city}, ${cityState[0].state}`}</p>
                  <div class="mt-5">
                    <div class="fw-500">
                      {hotel.price > 0 ?
                        <span dangerouslySetInnerHTML={{ __html: `Starting from <span class="text-blue-1">₹${hotel.price}</span>` }}></span> : 'Contact us for Price'
                      }
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}



          </div>
        </div>
      </section>
      <SubscribeRibbon />
    </div>
    </>
  );
}

export default HotelContactInfo;