import React, { useState, useEffect } from 'react';

function Header() {
  const isAdmin = window.location.pathname.includes('/admin');

    // Check if the URL contains 'travel' or 'hotel'
    const isTravelPage = window.location.pathname.includes('/travel');
    const isHotelPage = window.location.pathname.includes('/hotel');

    // Determine the header class based on the page
    const headerClass = `header ${isTravelPage || isHotelPage ? 'sm:mt-60 md:mt-35 mt-35' : ''} bg-green`;

    // Fetching Holiday Categories
    const [holidayCategories, setHolidayCategories] = useState([]);

    useEffect(() => {
        const interestsUrl = `${process.env.REACT_APP_API_ENDPOINT}interest_types?data=1`;
        fetch(interestsUrl)
            .then(response => response.json())
            .then(data => setHolidayCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Holiday Categories
    const [statesFetch, setStatesFetch] = useState([]);

    useEffect(() => {
        const statesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?states=all`;
        fetch(statesUrl)
            .then(response => response.json())
            .then(data => setStatesFetch(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Hotel Categories
    const [hotelCategories, setHotelCategories] = useState([]);

    useEffect(() => {
        const categoryUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_categories?data=1`;
        fetch(categoryUrl)
            .then(response => response.json())
            .then(data => setHotelCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Hotel Groups
    const [groupsFetch, setGroupsFetch] = useState([]);

    useEffect(() => {
        const groupsUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_groups`;
        fetch(groupsUrl)
            .then(response => response.json())
            .then(data => setGroupsFetch(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Product Categories
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        const categoryUrl = `${process.env.REACT_APP_API_ENDPOINT}shopping_categories`;
        fetch(categoryUrl)
            .then(response => response.json())
            .then(data => setProductCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Product Categories
    const [materialsFetch, setMaterialsFetch] = useState([]);

    useEffect(() => {
        const materialsUrl = `${process.env.REACT_APP_API_ENDPOINT}shopping_materials`;
        fetch(materialsUrl)
            .then(response => response.json())
            .then(data => setMaterialsFetch(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Homam Categories
    const [homamCategories, setHomamCategories] = useState([]);

    useEffect(() => {
        const homamCategoryUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?cat=homam`;
        fetch(homamCategoryUrl)
            .then(response => response.json())
            .then(data => setHomamCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Astrology Categories
    const [astrologyCategories, setAstrologyCategories] = useState([]);

    useEffect(() => {
        const astrologyCategoryUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?cat=astrology`;
        fetch(astrologyCategoryUrl)
            .then(response => response.json())
            .then(data => setAstrologyCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetch Menu Banners
    const [menuBannerData, setMenuBannerData] = useState([]);
    useEffect(() => {
      const menuBannersUrl = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?site_category=menu_banners&id=1`;
      fetch(menuBannersUrl)
        .then(response => response.json())
        .then(data => setMenuBannerData(data))
        .catch(error => console.error('Error fetching data:', error));
    }, []);


  if(isAdmin){
    return (
      <header data-add-bg="" class="header -dashboard bg-white js-header" data-x="header" data-x-toggle="is-menu-opened">
        <div class="header__container px-30 sm:px-20">
          <div class="-left-side">
            <a href="/" class="header-logo" data-x="header-logo" data-x-toggle="is-logo-dark">
              <img src="/assets/img/general/logo-original.png" alt="logo icon" />
            </a>
          </div>

          <div class="row justify-between items-center pl-60 lg:pl-20">
            <div class="col-auto">

            </div>

            <div class="col-auto">
              <div class="d-flex items-center">

                <div class="header-menu" data-x="mobile-menu" data-x-toggle="is-menu-active">
                  <div class="mobile-overlay"></div>

                  <div class="header-menu__content">
                    <div class="mobile-bg js-mobile-bg"></div>

                    {/*
                    <div class="menu js-navList">
                      <ul class="menu__nav text-dark-1 fw-500 -is-active">

                        <li class="menu-item-has-children">
                          <a data-barba href="#">
                            <span class="mr-10">Home</span>
                            <i class="icon icon-chevron-sm-down"></i>
                          </a>

                          <ul class="subnav">
                            <li class="subnav__backBtn js-nav-list-back">
                              <a href="#"><i class="icon icon-chevron-sm-down"></i> Home</a>
                            </li>

                            <li><a href="index.html">Home 1</a></li>

                            <li><a href="home-2.html">Home 2</a></li>

                            <li><a href="home-3.html">Home 3</a></li>

                            <li><a href="home-4.html">Home 4</a></li>

                            <li><a href="home-5.html">Home 5</a></li>

                            <li><a href="home-6.html">Home 6</a></li>

                            <li><a href="home-7.html">Home 7</a></li>

                            <li><a href="home-8.html">Home 8</a></li>

                            <li><a href="home-9.html">Home 9</a></li>

                            <li><a href="home-10.html">Home 10</a></li>

                          </ul>

                        </li>


                        <li class="menu-item-has-children -has-mega-menu">
                          <a data-barba href="#">
                            <span class="mr-10">Categories</span>
                            <i class="icon icon-chevron-sm-down"></i>
                          </a>

                          <div class="mega">
                            <div class="tabs -underline-2 js-tabs">
                              <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 pb-30 js-tabs-controls">

                                <div class="col-auto">
                                  <button class="tabs__button text-light-1 fw-500 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Hotel</button>
                                </div>

                                <div class="col-auto">
                                  <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-tab-item-2">Tour</button>
                                </div>

                                <div class="col-auto">
                                  <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-tab-item-3">Activity</button>
                                </div>

                                <div class="col-auto">
                                  <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-tab-item-4">Holiday Rentals</button>
                                </div>

                                <div class="col-auto">
                                  <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-tab-item-5">Car</button>
                                </div>

                                <div class="col-auto">
                                  <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-tab-item-6">Cruise</button>
                                </div>

                                <div class="col-auto">
                                  <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-tab-item-7">Flights</button>
                                </div>

                              </div>

                              <div class="tabs__content js-tabs-content">
                                <div class="tabs__pane -tab-item-1 is-tab-el-active">
                                  <div class="mega__content">
                                    <div class="mega__grid">

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Hotel List</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="hotel-list-1.html">Hotel List v1</a></div>

                                          <div><a href="hotel-list-2.html">Hotel List v2</a></div>

                                          <div><a href="hotel-half-map.html">Hotel List v3</a></div>

                                          <div><a href="hotel-grid-1.html">Hotel List v4</a></div>

                                          <div><a href="hotel-grid-2.html">Hotel List v5</a></div>

                                        </div>
                                      </div>

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Hotel Single</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="hotel-single-1.html">Hotel Single v1</a></div>

                                          <div><a href="hotel-single-2.html">Hotel Single v2</a></div>

                                        </div>
                                      </div>

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Hotel Booking</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="booking-pages.html">Booking Page</a></div>

                                        </div>
                                      </div>

                                    </div>

                                    <div class="mega__image d-flex relative">
                                      <img src="#" data-src="assets/img/backgrounds/7.png" alt="image" class="rounded-4 js-lazy" />

                                      <div class="absolute w-full h-full px-30 py-24">
                                        <div class="text-22 fw-500 lh-15 text-white">Things to do on <br /> your trip</div>
                                        <button class="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">Experinces</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="tabs__pane -tab-item-2">
                                  <div class="mega__content">
                                    <div class="mega__grid">

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Tour List</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="tour-list-1.html">Tour List v1</a></div>

                                          <div><a href="tour-grid-1.html">Tour List v2</a></div>

                                        </div>
                                      </div>

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Tour Pages</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="tour-map.html">Tour Map</a></div>

                                          <div><a href="tour-single.html">Tour Single</a></div>

                                        </div>
                                      </div>

                                    </div>

                                    <div class="mega__image d-flex relative">
                                      <img src="assets/img/backgrounds/7.png" alt="image" class="rounded-4" />

                                      <div class="absolute w-full h-full px-30 py-24">
                                        <div class="text-22 fw-500 lh-15 text-white">Things to do on <br /> your trip</div>
                                        <button class="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">Experinces</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="tabs__pane -tab-item-3">
                                  <div class="mega__content">
                                    <div class="mega__grid">

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Activity List</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="activity-list-1.html">Activity List v1</a></div>

                                          <div><a href="activity-grid-1.html">Activity List v2</a></div>

                                        </div>
                                      </div>

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Activity Pages</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="activity-map.html">Activity Map</a></div>

                                          <div><a href="activity-single.html">Activity Single</a></div>

                                        </div>
                                      </div>

                                    </div>

                                    <div class="mega__image d-flex relative">
                                      <img src="assets/img/backgrounds/7.png" alt="image" class="rounded-4" />

                                      <div class="absolute w-full h-full px-30 py-24">
                                        <div class="text-22 fw-500 lh-15 text-white">Things to do on <br /> your trip</div>
                                        <button class="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">Experinces</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="tabs__pane -tab-item-4">
                                  <div class="mega__content">
                                    <div class="mega__grid">

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Rental List</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="rental-list-1.html">Rental List v1</a></div>

                                          <div><a href="rental-grid-1.html">Rental List v2</a></div>

                                        </div>
                                      </div>

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Rental Pages</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="rental-map.html">Rental Map</a></div>

                                          <div><a href="rental-single.html">Rental Single</a></div>

                                        </div>
                                      </div>

                                    </div>

                                    <div class="mega__image d-flex relative">
                                      <img src="assets/img/backgrounds/7.png" alt="image" class="rounded-4" />

                                      <div class="absolute w-full h-full px-30 py-24">
                                        <div class="text-22 fw-500 lh-15 text-white">Things to do on <br /> your trip</div>
                                        <button class="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">Experinces</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="tabs__pane -tab-item-5">
                                  <div class="mega__content">
                                    <div class="mega__grid">

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Car List</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="car-list-1.html">Car List v1</a></div>

                                          <div><a href="car-grid-1.html">Car List v2</a></div>

                                        </div>
                                      </div>

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Car Pages</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="car-map.html">Car Map</a></div>

                                          <div><a href="car-single.html">Car Single</a></div>

                                        </div>
                                      </div>

                                    </div>

                                    <div class="mega__image d-flex relative">
                                      <img src="assets/img/backgrounds/7.png" alt="image" class="rounded-4" />

                                      <div class="absolute w-full h-full px-30 py-24">
                                        <div class="text-22 fw-500 lh-15 text-white">Things to do on <br /> your trip</div>
                                        <button class="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">Experinces</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="tabs__pane -tab-item-6">
                                  <div class="mega__content">
                                    <div class="mega__grid">

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Cruise List</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="cruise-list-1.html">Cruise List v1</a></div>

                                          <div><a href="cruise-grid-1.html">Cruise List v2</a></div>

                                        </div>
                                      </div>

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Cruise Pages</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="cruise-map.html">Cruise Map</a></div>

                                          <div><a href="cruise-single.html">Cruise Single</a></div>

                                        </div>
                                      </div>

                                    </div>

                                    <div class="mega__image d-flex relative">
                                      <img src="assets/img/backgrounds/7.png" alt="image" class="rounded-4" />

                                      <div class="absolute w-full h-full px-30 py-24">
                                        <div class="text-22 fw-500 lh-15 text-white">Things to do on <br /> your trip</div>
                                        <button class="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">Experinces</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="tabs__pane -tab-item-7">
                                  <div class="mega__content">
                                    <div class="mega__grid">

                                      <div class="mega__item">
                                        <div class="text-15 fw-500">Flight List</div>
                                        <div class="y-gap-5 text-15 pt-5">

                                          <div><a href="flights-list.html">Flight list v1</a></div>

                                        </div>
                                      </div>

                                    </div>

                                    <div class="mega__image d-flex relative">
                                      <img src="assets/img/backgrounds/7.png" alt="image" class="rounded-4" />

                                      <div class="absolute w-full h-full px-30 py-24">
                                        <div class="text-22 fw-500 lh-15 text-white">Things to do on <br /> your trip</div>
                                        <button class="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">Experinces</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <ul class="subnav mega-mobile">
                            <li class="subnav__backBtn js-nav-list-back">
                              <a href="#"><i class="icon icon-chevron-sm-down"></i> Category</a>
                            </li>

                            <li class="menu-item-has-children">
                              <a data-barba href="#">
                                <span class="mr-10">Hotel</span>
                                <i class="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul class="subnav">
                                <li class="subnav__backBtn js-nav-list-back">
                                  <a href="#"><i class="icon icon-chevron-sm-down"></i> Hotel</a>
                                </li>


                                <li><a href="hotel-list-1.html">Hotel List v1</a></li>

                                <li><a href="hotel-list-2.html">Hotel List v2</a></li>

                                <li><a href="hotel-single-1.html">Hotel Single v1</a></li>

                                <li><a href="hotel-single-2.html">Hotel Single v2</a></li>

                                <li><a href="booking-pages.html">Booking Page</a></li>

                              </ul>
                            </li>

                            <li class="menu-item-has-children">
                              <a data-barba href="#">
                                <span class="mr-10">Tour</span>
                                <i class="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul class="subnav">
                                <li class="subnav__backBtn js-nav-list-back">
                                  <a href="#"><i class="icon icon-chevron-sm-down"></i> Tour</a>
                                </li>

                                <li><a href="tour-list-1.html">Tour List v1</a></li>

                                <li><a href="tour-grid-1.html">Tour List v2</a></li>

                                <li><a href="tour-map.html">Tour Map</a></li>

                                <li><a href="tour-single.html">Tour Single</a></li>

                              </ul>
                            </li>

                            <li class="menu-item-has-children">
                              <a data-barba href="#">
                                <span class="mr-10">Activity</span>
                                <i class="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul class="subnav">
                                <li class="subnav__backBtn js-nav-list-back">
                                  <a href="#"><i class="icon icon-chevron-sm-down"></i> Activity</a>
                                </li>

                                <li><a href="activity-list-1.html">Activity List v1</a></li>

                                <li><a href="activity-grid-1.html">Activity List v2</a></li>

                                <li><a href="activity-map.html">Activity Map</a></li>

                                <li><a href="activity-single.html">Activity Single</a></li>

                              </ul>
                            </li>

                            <li class="menu-item-has-children">
                              <a data-barba href="#">
                                <span class="mr-10">Rental</span>
                                <i class="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul class="subnav">
                                <li class="subnav__backBtn js-nav-list-back">
                                  <a href="#"><i class="icon icon-chevron-sm-down"></i> Rental</a>
                                </li>

                                <li><a href="rental-list-1.html">Rental List v1</a></li>

                                <li><a href="rental-grid-1.html">Rental List v2</a></li>

                                <li><a href="rental-map.html">Rental Map</a></li>

                                <li><a href="rental-single.html">Rental Single</a></li>

                              </ul>
                            </li>

                            <li class="menu-item-has-children">
                              <a data-barba href="#">
                                <span class="mr-10">Car</span>
                                <i class="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul class="subnav">
                                <li class="subnav__backBtn js-nav-list-back">
                                  <a href="#"><i class="icon icon-chevron-sm-down"></i> Car</a>
                                </li>

                                <li><a href="car-list-1.html">Car List v1</a></li>

                                <li><a href="car-grid-1.html">Car List v2</a></li>

                                <li><a href="car-map.html">Car Map</a></li>

                                <li><a href="car-single.html">Car Single</a></li>

                              </ul>
                            </li>

                            <li class="menu-item-has-children">
                              <a data-barba href="#">
                                <span class="mr-10">Cruise</span>
                                <i class="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul class="subnav">
                                <li class="subnav__backBtn js-nav-list-back">
                                  <a href="#"><i class="icon icon-chevron-sm-down"></i> Cruise</a>
                                </li>

                                <li><a href="cruise-list-1.html">Cruise List v1</a></li>

                                <li><a href="cruise-grid-1.html">Cruise List v2</a></li>

                                <li><a href="cruise-map.html">Cruise Map</a></li>

                                <li><a href="cruise-single.html">Cruise Single</a></li>

                              </ul>
                            </li>

                            <li class="menu-item-has-children">
                              <a data-barba href="#">
                                <span class="mr-10">Flights</span>
                                <i class="icon icon-chevron-sm-down"></i>
                              </a>

                              <ul class="subnav">
                                <li class="subnav__backBtn js-nav-list-back">
                                  <a href="#"><i class="icon icon-chevron-sm-down"></i> Flights</a>
                                </li>

                                <li><a href="flights-list.html">Flights List v1</a></li>

                              </ul>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <a href="destinations.html">
                            Destinations
                          </a>
                        </li>


                        <li class="menu-item-has-children">
                          <a data-barba href="#">
                            <span class="mr-10">Blog</span>
                            <i class="icon icon-chevron-sm-down"></i>
                          </a>

                          <ul class="subnav">
                            <li class="subnav__backBtn js-nav-list-back">
                              <a href="#"><i class="icon icon-chevron-sm-down"></i> Blog</a>
                            </li>

                            <li><a href="blog-list-1.html">Blog list v1</a></li>

                            <li><a href="blog-list-2.html">Blog list v2</a></li>

                            <li><a href="blog-single.html">Blog single</a></li>

                          </ul>

                        </li>


                        <li class="menu-item-has-children">
                          <a data-barba href="#">
                            <span class="mr-10">Pages</span>
                            <i class="icon icon-chevron-sm-down"></i>
                          </a>

                          <ul class="subnav">
                            <li class="subnav__backBtn js-nav-list-back">
                              <a href="#"><i class="icon icon-chevron-sm-down"></i> Pages</a>
                            </li>

                            <li><a href="404.html">404</a></li>

                            <li><a href="about.html">About</a></li>

                            <li><a href="become-expert.html">Become expert</a></li>

                            <li><a href="help-center.html">Help center</a></li>

                            <li><a href="login.html">Login</a></li>

                            <li><a href="signup.html">Register</a></li>

                            <li><a href="terms.html">Terms</a></li>

                            <li><a href="invoice.html">Invoice</a></li>

                            <li><a href="ui-elements.html">UI elements</a></li>

                          </ul>

                        </li>


                        <li class="menu-item-has-children">
                          <a data-barba href="#">
                            <span class="mr-10">Dashboard</span>
                            <i class="icon icon-chevron-sm-down"></i>
                          </a>

                          <ul class="subnav">
                            <li class="subnav__backBtn js-nav-list-back">
                              <a href="#"><i class="icon icon-chevron-sm-down"></i> Dashboard</a>
                            </li>

                            <li><a href="db-dashboard.html">Dashboard</a></li>

                            <li><a href="db-booking.html">Booking</a></li>

                            <li><a href="db-settings.html">Settings</a></li>

                            <li><a href="db-wishlist.html">Wishlist</a></li>

                            <li><a href="db-vendor-dashboard.html">Vendor dashboard</a></li>

                            <li><a href="db-vendor-add-hotel.html">Vendor add hotel</a></li>

                            <li><a href="db-vendor-booking.html">Vendor booking</a></li>

                            <li><a href="db-vendor-hotels.html">Vendor hotels</a></li>

                            <li><a href="db-vendor-recovery.html">Vendor recovery</a></li>

                          </ul>

                        </li>


                        <li>
                          <a href="contact.html">Contact</a>
                        </li>
                      </ul>
                    </div>
                    */}

                    <div class="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                    </div>
                  </div>
                </div>

                {/*
                <div class="row items-center x-gap-5 y-gap-20 pl-20 lg:d-none">
                  <div class="col-auto">
                    <button class="button -blue-1-05 size-50 rounded-22 flex-center">
                      <i class="icon-email-2 text-20"></i>
                    </button>
                  </div>

                  <div class="col-auto">
                    <button class="button -blue-1-05 size-50 rounded-22 flex-center">
                      <i class="icon-notification text-20"></i>
                    </button>
                  </div>
                </div>
                */}

                <div class="pl-15">
                  <img src="/assets/img/general/user.png" alt="image" class="size-50 rounded-22 object-cover" />
                </div>

                <div class="d-none xl:d-flex x-gap-20 items-center pl-20" data-x="header-mobile-icons" data-x-toggle="text-white">
                  <div><button class="d-flex items-center icon-menu text-20" data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu"></button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
  else{

  return (
    <>
      {isTravelPage && (
        <section style={{ position: 'fixed', zIndex: '2000' }} className="header-banner py-5 bg-blue-1">
          <div class="container">
            <div class="row items-center justify-between">
              <div class="col-12 text-center">
                <div class="items-center jusify-between">
                  <div class="col-auto text-white text-14">
                    Need to Book a Tour Package? Call/Whatsapp: <a href="https://wa.me/919892002006" class="text-white">9892002006</a> / <a href="https://wa.me/919224444455" class="text-white">9224444455</a> / <a href="https://wa.me/919987404440" class="text-white">9987404440</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {isHotelPage && (
        <section style={{ position: 'fixed', zIndex: '2000' }} className="header-banner py-5 bg-blue-1">
          <div class="container">
            <div class="row items-center justify-between">
              <div class="col-12 text-center">
                <div class="items-center jusify-between">
                  <div class="col-auto text-white text-14">
                    Need to Book a Hotel? Call/Whatsapp: <a href="https://wa.me/918452040404" class="text-white">8452040404</a> / <a href="https://wa.me/919224444455" class="text-white">9224444455</a> / <a href="https://wa.me/919987404440" class="text-white">9987404440</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    <header data-add-bg="bg-dark-1" className={headerClass} data-x="header" data-x-toggle="is-menu-opened">
      <div class="header__container px-30 sm:px-20">
        <div class="row justify-between items-center">
          
          <div class="col-auto">
            <div class="d-flex items-center">
              <a href="/" class="header-logo mr-20" data-x="header-logo" data-x-toggle="is-logo-dark">
                <img src="/assets/img/general/logo-light.png" alt="logo icon" />
                <img src="/assets/img/general/logo-original.png" alt="logo icon" />
              </a>


              <div class="header-menu " data-x="mobile-menu" data-x-toggle="is-menu-active">
                <div class="mobile-overlay"></div>

                <div class="header-menu__content">
                  <div class="mobile-bg js-mobile-bg"></div>

                  <div class="menu js-navList">
                    <ul class="menu__nav text-white -is-active">

                      <li>
                        <a data-barba href="/">
                          <span class="mr-10">Home</span>
                        </a>

                      </li>


                      <li class="menu-item-has-children -has-mega-menu">
                        <a data-barba href="#">
                          <span class="mr-10">Holidays</span>
                          <i class="icon icon-chevron-sm-down"></i>
                        </a>

                        <div class="mega">
                          <div class="tabs -underline-2 js-tabs">
                            <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 pb-30 js-tabs-controls">

                              <div class="col-auto">
                                <button class="tabs__button text-light-1 fw-500 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Holiday Categories</button>
                              </div>

                              <div class="col-auto">
                                <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-tab-item-2">Holiday Destinations</button>
                              </div>

                            </div>

                            <div class="tabs__content js-tabs-content">
                              <div class="tabs__pane -tab-item-1 is-tab-el-active">
                                <div class="mega__content">
                                  <div class="mega__grid">

                                    <div class="mega__item">
                                      <div class="col-md-12 y-gap-5 text-15 pt-5">
                                        <div className='row menus_custom' style={{flexWrap:'wrap'}}>
                                        {holidayCategories.map(item => (
                                          <div className='col-md-6' key={item.id}>   
                                            <a href={`/travel-india/tour-package/activity/id/${item.id}/${item.type.toLowerCase().replace(/\s+/g, '-')}-tour`}>
                                              {item.type}
                                            </a>
                                          </div>
                                        ))}
                                        </div>
                                      </div>
                                      <a href="/travels/travel/page/1">
                                        <div class="text-15 fw-500 mt-10">View More <img width="10" src={`/assets/img/general/categories-arrow.png`} /></div>
                                      </a>
                                    </div>

                                  </div>
                                  
                                  {/* Holiday Menu Banner */}
                                  {menuBannerData.map(item => item.section === "tour" && item.image_path && (
                                    <div key={item.id} className="mega__image d-flex relative">
                                      <img src={`${process.env.REACT_APP_API_ENDPOINT}uploads/menu_banners/${item.image_path}`} alt="image" className="rounded-4 js-lazy loaded" data-ll-status="loaded" />
                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">{item.title}</div>
                                        <a target="_blank" href={item.hyperlink}><button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">View Details</button></a>
                                      </div>
                                    </div>
                                  ))}
                                  
                                </div>
                              </div>

                              <div class="tabs__pane -tab-item-2">
                                <div class="mega__content">
                                  <div class="mega__grid">

                                    <div class="mega__item">
                                      <div class="col-md-12 y-gap-5 text-15 pt-5">
                                        <div className='row menus_custom' style={{flexWrap:'wrap'}}>
                                          {statesFetch.map(item => (
                                            <div className='col-md-6' key={item.id}>   
                                              <a href={`/travel-india/destination/state/${item.state.toLowerCase().replace(/\s+/g, '-')}`}>
                                                {item.state}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      <a href="/travels/travel/page/1">
                                        <div class="text-15 fw-500 mt-10">View More <img width="10" src={`/assets/img/general/categories-arrow.png`} /></div>
                                      </a>
                                    </div>

                                  </div>
                                  
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <ul class="subnav mega-mobile">
                          <li class="subnav__backBtn js-nav-list-back">
                            <a href="#"><i class="icon icon-chevron-sm-down"></i> Holiday Categories</a>
                          </li>

                          {holidayCategories.map(item => (
                            <li key={item.id}>   
                              <a href={`/travel-india/tour-package/activity/id/${item.id}/${item.type.toLowerCase().replace(/\s+/g, '-')}-tour`}>
                                {item.type}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li class="menu-item-has-children -has-mega-menu">
                        <a data-barba href="#">
                          <span class="mr-10">Hotels</span>
                          <i class="icon icon-chevron-sm-down"></i>
                        </a>

                        <div class="mega">
                          <div class="tabs -underline-2 js-tabs" style={{maxHeight:'90%'}}>
                            <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 pb-30 js-tabs-controls">

                              <div class="col-auto">
                                <button class="tabs__button text-light-1 fw-500 js-tabs-button is-tab-el-active" data-tab-target=".-hotel-item-1">Hotel Categories</button>
                              </div>

                              <div class="col-auto">
                                <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-hotel-item-2">Hotel Destinations</button>
                              </div>

                              <div class="col-auto">
                                <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-hotel-item-3">Hotel Groups</button>
                              </div>

                            </div>

                            <div class="tabs__content js-tabs-content">
                              <div class="tabs__pane -hotel-item-1 is-tab-el-active">
                                <div class="mega__content">
                                  <div class="mega__grid">

                                    <div class="mega__item">
                                      <div class="col-md-12 y-gap-5 text-15 pt-5">
                                        <div className='row menus_custom' style={{flexWrap:'wrap'}}>
                                        {hotelCategories.map(item => (
                                            <div className='col-md-6' key={item.id}>   
                                              <a href={`/hotels/type/${item.type.toLowerCase().replace(/\s+/g, '-')}/${item.id}/0`}>
                                                {item.type}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      <a href="/hotels/page/1">
                                        <div class="text-15 fw-500 mt-10">View More <img width="10" src={`/assets/img/general/categories-arrow.png`} /></div>
                                      </a>
                                    </div>

                                  </div>

                                  {/* Hotels Menu Banner */}
                                  {menuBannerData.map(item => item.section === "hotels" && item.image_path && (
                                    <div key={item.id} className="mega__image d-flex relative">
                                      <img src={`${process.env.REACT_APP_API_ENDPOINT}uploads/menu_banners/${item.image_path}`} alt="image" className="rounded-4 js-lazy loaded" data-ll-status="loaded" />
                                      <div className="absolute w-full h-full px-30 py-24">
                                        <div className="text-22 fw-500 lh-15 text-white">{item.title}</div>
                                        <a target="_blank" href={item.hyperlink}><button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">View Details</button></a>
                                      </div>
                                    </div>
                                  ))}
                                  
                                </div>
                              </div>

                              <div class="tabs__pane -hotel-item-2">
                                <div class="mega__content">
                                  <div class="mega__grid">

                                    <div class="mega__item">
                                      <div class="col-md-12 y-gap-5 text-15 pt-5">
                                        <div className='row menus_custom' style={{flexWrap:'wrap'}}>
                                          {statesFetch.map(item => (
                                            <div className='col-md-6' key={item.id}>   
                                              <a href={`/hotels/state/${item.state.toLowerCase().replace(/\s+/g, '-')}`}>
                                                {item.state}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      <a href="/hotels/page/1">
                                        <div class="text-15 fw-500 mt-10">View More <img width="10" src={`/assets/img/general/categories-arrow.png`} /></div>
                                      </a>
                                    </div>

                                  </div>

                                </div>
                              </div>

                              <div class="tabs__pane -hotel-item-3">
                                <div class="mega__content">
                                  <div class="mega__grid">

                                    <div class="mega__item">
                                      <div class="col-md-12 y-gap-5 text-15 pt-5" style={{overflow:'scroll', maxHeight:'400px'}}>
                                        <div className='row menus_custom' style={{flexWrap:'wrap'}}>
                                          {groupsFetch.map(item => (
                                            <div className='col-md-6' key={item.id}>   
                                              <a href={`/hotels/group/${item.hotel_group.toLowerCase().replace(/\s+/g, '-')}`}>
                                                {item.hotel_group}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      <a href="/hotels/page/1">
                                        <div class="text-15 fw-500 mt-10">View More <img width="10" src={`/assets/img/general/categories-arrow.png`} /></div>
                                      </a>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <ul class="subnav mega-mobile">
                          <li class="subnav__backBtn js-nav-list-back">
                            <a href="#"><i class="icon icon-chevron-sm-down"></i> Hotel Categories</a>
                          </li>

                          {hotelCategories.map(item => (
                            <li key={item.id}>   
                              <a href={`/hotels/type/${item.type.toLowerCase().replace(/\s+/g, '-')}/${item.id}/0`}>
                                {item.type}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li class="menu-item-has-children -has-mega-menu">
                        <a data-barba href="#">
                          <span class="mr-10">Shop</span>
                          <i class="icon icon-chevron-sm-down"></i>
                        </a>

                        <div class="mega">
                          <div class="tabs -underline-2 js-tabs">
                            <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 pb-30 js-tabs-controls">

                              <div class="col-auto">
                                <button class="tabs__button text-light-1 fw-500 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Product Categories</button>
                              </div>

                              <div class="col-auto">
                                <button class="tabs__button text-light-1 fw-500 js-tabs-button " data-tab-target=".-tab-item-2">Shop by Material</button>
                              </div>

                            </div>

                            <div class="tabs__content js-tabs-content">
                              <div class="tabs__pane -tab-item-1 is-tab-el-active">
                                <div class="mega__content">
                                  <div class="mega__grid">

                                    <div class="mega__item">
                                      <div class="col-md-12 y-gap-5 text-15 pt-5">
                                        <div className='row menus_custom' style={{flexWrap:'wrap'}}>
                                          <div className='col-md-6'>   
                                            <a href="/store-home">
                                              <b>Store Homepage</b>
                                            </a>
                                          </div>
                                        {productCategories.map(item => (
                                          <div className='col-md-6' key={item.id}>   
                                            <a href={`/products/type/${item.category_name.toLowerCase().replace(/\s+/g, '-')}/${item.id}`}>
                                              {item.category_name}
                                            </a>
                                          </div>
                                        ))}
                                        </div>
                                      </div>
                                      <a href="/products/page/1">
                                        <div class="text-15 fw-500 mt-10">View More <img width="10" src={`/assets/img/general/categories-arrow.png`} /></div>
                                      </a>
                                    </div>

                                    {/* Shop Menu Banner */}
                                    {menuBannerData.map(item => item.section === "shop" && item.image_path && (
                                      <div key={item.id} className="mega__image d-flex relative">
                                        <img src={`${process.env.REACT_APP_API_ENDPOINT}uploads/menu_banners/${item.image_path}`} alt="image" className="rounded-4 js-lazy loaded" data-ll-status="loaded" />
                                        <div className="absolute w-full h-full px-30 py-24">
                                          <div className="text-22 fw-500 lh-15 text-white">{item.title}</div>
                                          <a target="_blank" href={item.hyperlink}><button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">View Details</button></a>
                                        </div>
                                      </div>
                                    ))}

                                  </div>

                                  
                                </div>
                              </div>

                              <div class="tabs__pane -tab-item-2">
                                <div class="mega__content">
                                  <div class="mega__grid">

                                    <div class="mega__item">
                                      <div class="col-md-12 y-gap-5 text-15 pt-5">
                                        <div className='row menus_custom' style={{flexWrap:'wrap'}}>
                                          {materialsFetch.map(item => (
                                            <div className='col-md-6' key={item.id}>   
                                              <a href={`/products/material/${item.material_name.toLowerCase().replace(/\s+/g, '-')}/${item.id}`}>
                                                {item.material_name}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      <a href="/products/page/1">
                                        <div class="text-15 fw-500 mt-10">View More <img width="10" src={`/assets/img/general/categories-arrow.png`} /></div>
                                      </a>
                                    </div>

                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <ul class="subnav mega-mobile">
                          <li class="subnav__backBtn js-nav-list-back">
                            <a href="#"><i class="icon icon-chevron-sm-down"></i> Shopping Categories</a>
                          </li>

                          {productCategories.map(item => (
                            <li key={item.id}>   
                              <a href={`/products/type/${item.category_name.toLowerCase().replace(/\s+/g, '-')}/${item.id}`}>
                                {item.category_name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>

                      

                      <li class="menu-item-has-children">
                        <a data-barba href="#">
                          <span class="mr-10">Homam</span>
                          <i class="icon icon-chevron-sm-down"></i>
                        </a>

                        <ul class="subnav menus_custom">
                          <li class="subnav__backBtn js-nav-list-back">
                            <a href="#"><i class="icon icon-chevron-sm-down"></i> Blog</a>
                          </li>

                            <li>  
                              <a href="/homam-home">
                                  <b>Homam Homepage</b>
                              </a>
                            </li>
                          
                          {homamCategories.map(item => (
                            <li key={item.id}>  
                              <a href={`/homam-astrology/category/${item.id}/${item.category_type.toLowerCase().replace(/\s+/g, '-')}`}>
                                  Homam for {item.category_type}
                              </a>
                            </li>
                          ))}

                        </ul>

                      </li>

                      {/*
                      <li class="menu-item-has-children">
                        <a data-barba href="#">
                          <span class="mr-10">Astrology</span>
                          <i class="icon icon-chevron-sm-down"></i>
                        </a>

                        <ul class="subnav menus_custom">
                          <li class="subnav__backBtn js-nav-list-back">
                            <a href="#"><i class="icon icon-chevron-sm-down"></i> Blog</a>
                          </li>
                          {astrologyCategories.map(item => (
                            <li key={item.id}>  
                              <a style={{textTransform:'capitalize'}} href={`/homam-astrology/category/${item.id}/${item.category_type.toLowerCase().replace(/\s+/g, '-')}`}>
                                  {item.category_type}
                              </a>
                            </li>
                          ))}
                        </ul>

                      </li>
                      */}

                      <li>
                        <a href="/indian-temple">Temples</a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </div>

                  <div class="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div class="col-auto">
            <div class="d-flex items-center">


              <div class="row x-gap-20 items-center xxl:d-none">

                <div class="col-auto">
                  <a href='/cart' class="d-flex items-center text-14 text-white" data-x-click="lang">
                    <img src="/assets/img/general/lang.png" alt="image" class="mr-10" />
                    <span class="js-language-mainTitle">My Cart</span>
                  </a>
                </div>

                <div class="col-auto">
                  <div class="w-1 h-20 bg-white-20"></div>
                </div>

              </div>


              <div class="d-flex items-center ml-20 is-menu-opened-hide md:d-none">
                
                <a href="/payonline" class="button px-30 fw-400 text-14 -blue-1 bg-white h-50 text-dark-1 mr-10">Pay Online</a>
                
                <a href="/cart" class="button px-30 fw-400 text-14 border-white -outline-white h-50 text-white">Sign In / Register</a>
              </div>

              <div class="d-none xl:d-flex x-gap-20 items-center pl-30 text-white" data-x="header-mobile-icons" data-x-toggle="text-white">
                <div>
                  <a href="/cart" class="d-flex items-center text-inherit text-22">
                    <img src="/assets/img/general/lang.png" alt="image" class="mr-10" />
                  </a>
                </div>
                {/*
                <div><a href="/cart" class="d-flex items-center icon-user text-inherit text-22"></a></div>
                */}
                <div><button class="d-flex items-center icon-menu text-inherit text-20" data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu"></button></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </header>
    </>
  );
  }
}

export default Header;