import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSearch from './HeaderSearch';
import { SubscribeRibbon } from './SubscribeRibbon';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";

function Attraction() {
  const { id } = useParams();
  const [attractionData, setattractionData] = useState({});
  const { 
    title
    ,content
    ,category
    ,city_id
   } = attractionData;

  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}attraction/${id}`;
  //console.log("api_url", apiUrl);

  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setattractionData(data))
      .catch(error => console.error(error));
  }, []);  

  const [cityState, setCityState] = useState({});   

    useEffect(() => {
        if (city_id) {
          const cityStateAPIURL = `${process.env.REACT_APP_API_ENDPOINT}cities?city_id=${city_id}`;
          console.log("cityStateAPIURL: ", cityStateAPIURL);
          fetch(cityStateAPIURL)
            .then(response => response.json())
            .then(data => {
              const [cityData] = data; // Extract the first element from the array
              setCityState(cityData || {});
            })
            .catch(error => console.error(error));
        }
      }, [city_id]);   

    const [nearbyHotels, setnearbyHotels] = useState([]);
      useEffect(() => {
          const nearbyHotelsUrl = `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${city_id}&item=hotels&neglect=0`;
          console.log("nearbyHotelsUrl: ", nearbyHotelsUrl);
          fetch(nearbyHotelsUrl)
            .then(response => response.json())
            .then(data => setnearbyHotels(data))
            .catch(error => console.error(error));
    }, [id, city_id]);

    const [nearbyTours, setnearbyTours] = useState([]);
      useEffect(() => {
          const nearbyToursUrl = `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${city_id}&item=tours&neglect=0`;
          console.log("nearbyToursUrl: ", nearbyToursUrl);
          fetch(nearbyToursUrl)
            .then(response => response.json())
            .then(data => setnearbyTours(data))
            .catch(error => console.error(error));
    }, [id, city_id]);

  return (
    <div>
      <HeaderSearch />

      <section class="py-10 d-flex items-center bg-light-2">
        <div class="container">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div class="col-auto">
                  <div class="">Home</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="">Attractions</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="text-dark-1">{title}</div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              {/*<a href="#" class="text-14 text-blue-1 underline">View attractions Involving Dwarka</a>*/}
            </div>
          </div>
        </div>
      </section>

      <section class="pt-40">
        <div class="container">
          <div class="row y-gap-20 justify-center text-center">
            <div class="col-auto">
              <div class="row x-gap-20  items-center">
                <div class="col-auto">
                  <h1 class="text-30 sm:text-25 fw-600" style={{maxWidth:750,}}>{title}</h1>
                </div>
              </div>

              <div class="row x-gap-20 y-gap-20 items-center justify-center">
                <div class="col-auto">
                  <div class="d-flex items-center text-15 text-light-1">
                    {cityState.city}, {cityState.state}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-30">
        <div class="container">
          <div class="row y-gap-30">
            <div class="col-xl-8">
              <div class="row y-gap-40 dark_text">

                <div id="overview" class="col-12">
                  <p class="text-dark-1 text-15 mt-20" dangerouslySetInnerHTML={{ __html: content }} />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="layout-pt-md layout-pb-md">
        <div class="container">
            <div class="row y-gap-10 justify-between items-end">
                <div class="col-auto">
                    <div class="sectionTitle -md"><h2 class="sectionTitle__title">Hotels Nearby</h2></div>
                </div>
                <div class="col-auto md:d-none">
                    <a href="/hotels/page/1" class="button -md -blue-1 bg-blue-1-05 text-blue-1">
                        View All Hotels
                        <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
            </div>

          <div class="row y-gap-30 pt-40 sm:pt-20">

            {nearbyHotels.length > 0 && nearbyHotels.map(hotel => (
                <div class="col-xl-3 col-lg-3 col-sm-6">

                  <a href={`/hotel/${hotel.name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} class="hotelsCard -type-1">

                  <div class="hotelsCard__image">
  
                    <div class="cardImage ratio ratio-1:1">
                      <div class="cardImage__content">
  
                        <img class="rounded-4 col-12" src={`/img/uploads/hotels/gallery/${hotel.thumb_new}`} alt="image" />
  
                      </div>
  
                    </div>
  
                  </div>
  
                  <div class="hotelsCard__content mt-10">
                    <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                      <span>{hotel.name}
                        </span>
                    </h4>
  
                    <p class="text-light-1 lh-14 text-14 mt-5">{cityState.length > 0 && `${cityState[0].city}, ${cityState[0].state}`}</p>
  
                    <div class="mt-5">
                      <div class="fw-500">
                        {hotel.price > 0 ?
                          <span dangerouslySetInnerHTML={{ __html: `Starting from <span class="text-blue-1">₹${hotel.price}</span>` }}></span> : 'Contact us for Price'
                        }
                      </div>
                    </div>
                  </div>
                </a>
  
              </div>
            ))}

          </div>
        </div>
      </section>

      <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
                <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
                <div className="col-auto">
                    <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">Similar Tour Packages</h2>
                    </div>
                </div>

                <div className="col-auto md:d-none">

                    <a href="/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                    View All Holidays <div className="icon-arrow-top-right ml-15"></div>
                    </a>

                </div>
                </div>

                <div className="relative pt-40 sm:pt-20 swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden is-in-view">
                <div className="swiper-wrapper">

                    {nearbyTours.length > 0 && nearbyTours.map(tour => (
                    <div className="swiper-slide swiper-slide-visible swiper-slide-active" 
                    style={{width: '300px', marginRight: '30px'}}>

                        <a href={`/travel-india/tour-package/id/${tour.id}/${tour.name.replace(/\s+/g, '-')}`} className="citiesCard -type-1 d-block rounded-4 ">
                        <div className="citiesCard__image ratio ratio-4:3">
                            <img
                            src={tour.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/${tour.thumb_new}` : `/img/uploads/tour/thumbs/${tour.thumb}`}
                            data-src={tour.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/${tour.thumb_new}` : `/img/uploads/tour/thumbs/${tour.thumb}`}
                            alt="image"
                            className="js-lazy"
                            />
                        </div>

                        <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                            <div className="citiesCard__bg"></div>

                            <div className="citiesCard__top">
                            <div className="text-14 text-white">{tour.nights} Night {tour.days} Days</div>
                            </div>

                            <div className="citiesCard__bottom">
                            <h4 className="text-16 md:text-20 lh-13 text-white mb-20">{tour.name}</h4>
                            <p className="starts_from">Starts from ₹{tour.price}/-</p>
                            <button className="button col-12 h-40 -blue-1 bg-white text-dark-1">View Details</button>
                            </div>
                        </div>
                        </a>

                    </div>
                    ))}
                    
                </div>

                <div className="row pt-20 d-none md:d-block">
                    <div className="col-auto">
                    <div className="d-inline-block">

                        <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                        View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                        </a>

                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
      <SubscribeRibbon />
    </div>
  );
}

export default Attraction;
