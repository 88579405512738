import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import 'react-quill/dist/quill.snow.css';
import "cropperjs/dist/cropper.min.css";
import { isAuthenticated } from '../middleware/admin_auth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AdminMicroArticleEdit() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];
    
    const { id } = useParams();
    const [article, setArticle] = useState();

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?inner_article_id=${id}&admin=1`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data) && data.length > 0) {
              setArticle(data[0]);
            }
          })
          .catch(error => console.error(error));
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    
    
    const handleSaveDetails = () => {
        console.log("article", article);
        const packageUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_article_update`;
        fetch(packageUpdate1, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(article),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };
    
    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Edit Article ID {id}</h1>

                    </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:200}}>

                    <div class="col-12">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={article?.title || ''}
                            name="title"
                            onChange={(event) => setArticle(prevState => ({ ...prevState, title: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Title</label>
                        </div>
                    </div>

                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={article?.subtitle || ''}
                            name="subtitle"
                            onChange={(event) => setArticle(prevState => ({ ...prevState, subtitle: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Subtitle</label>
                        </div>
                    </div>

                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={article?.priority || ''}
                            name="priority"
                            onChange={(event) => setArticle(prevState => ({ ...prevState, priority: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Priority Order</label>
                        </div>
                    </div>

                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={article?.sublink || ''}
                            name="sublink"
                            onChange={(event) => setArticle(prevState => ({ ...prevState, sublink: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Sublink</label>
                        </div>
                    </div>

                    <div className="col-12 pt-30 mb-30">
                        <div className="d-flex items-center">
                            <div className="form-checkbox">
                            <input
                                type="checkbox"
                                name="enquiry"
                                onChange={(event) =>
                                    setArticle((prevState) => ({
                                    ...prevState,
                                    enquiry: event.target.checked ? "1" : "0",
                                }))
                                }
                                checked={article?.enquiry === "1"}
                            />
                            <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                            </div>
                            </div>
                            <div className="text-15 lh-11 ml-10">Enable Enquiry Form</div>
                        </div>
                    </div>

                    <div className="col-12 pb-30">
                        <div className="fw-500">Description</div>
                        <div className="form-input" style={{ maxHeight: 300 }}>
                            <ReactQuill
                                value={article?.content}
                                onChange={(value) => setArticle(prevState => ({...prevState, content: value}))}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                    </div>

                    <div className="col-12 pt-50 pb-30">
                        <div className="fw-500">Short Description</div>
                        <div className="form-input" style={{ maxHeight: 300 }}>
                            <ReactQuill
                                value={article?.scontent}
                                onChange={(value) => setArticle(prevState => ({...prevState, scontent: value}))}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                    </div>

                    <div className="col-12 pt-50 pb-30">
                        <div className="fw-500">Meta Keywords</div>
                        <div className="form-input" style={{ maxHeight: 300 }}>
                            <ReactQuill
                                value={article?.keywords}
                                onChange={(value) => setArticle(prevState => ({...prevState, keywords: value}))}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                    </div>

                    <div className="col-12 pt-50 pb-30">
                        <div className="fw-500">Meta Description</div>
                        <div className="form-input" style={{ maxHeight: 300 }}>
                            <ReactQuill
                                value={article?.metadesc}
                                onChange={(value) => setArticle(prevState => ({...prevState, metadesc: value}))}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                    </div>

                    <div class="col-12 room_input pt-50">
                        <div className='border-light rounded-4 admin_dropdown_inner'>
                            <h4 class="text-15 fw-500 ls-2 lh-16">Content Type</h4>
                            <div class="text-15 text-light-1 ls-2 lh-16">
                                <select
                                value={article?.content_type}
                                onChange={(event) =>
                                    setArticle((prevState) => ({
                                    ...prevState,
                                    content_type: event.target.value,
                                    }))
                                }
                                >
                                <option value="" disabled>Select an option</option>
                                <option value="null">None</option>
                                <option value="tour">Tour</option>
                                <option value="article">Article</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                            <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                            </a>
                        </div>
                        <div style={{clear:'both'}}></div>
                    </div>

                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminMicroArticleEdit;