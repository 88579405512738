import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSearch from './HeaderSearch';
import { SubscribeRibbon } from './SubscribeRibbon';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import PersonDetail from './PersonDetail';
import {Helmet} from "react-helmet";

function CommonPage() {
  const { name} = useParams();

  const [article, setArticle] = useState();

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}common_pages_listings?name=${name}`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data) && data.length > 0) {
              setArticle(data[0]);
            }
          })
          .catch(error => console.error(error));
    }, []);

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{name} | Blessingsonthenet.com</title>
    </Helmet> 
    <div>
      <HeaderSearch />

      <section class="py-10 d-flex items-center bg-light-2">
        <div class="container">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div class="col-auto">
                  <div class="">Home</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="text-dark-1">{name}</div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              {/*<a href="#" class="text-14 text-blue-1 underline">View Commons Involving Dwarka</a>*/}
            </div>
          </div>
        </div>
      </section>

      <section class="pt-40">
        <div class="container">
          <div class="row y-gap-20 justify-between items-end">
            <div class="col-12">
              <div class="row x-gap-20  items-center">
                <div class="col-auto mb-50">
                    <div dangerouslySetInnerHTML={{ __html: article?.value }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SubscribeRibbon />
    </div>
    </>
  );
}

export default CommonPage;
