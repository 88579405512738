import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSearch from './HeaderSearch';
import { SubscribeRibbon } from './SubscribeRibbon';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import PersonDetail from './PersonDetail';
import {Helmet} from "react-helmet";

function Holiday() {
  const { id, title } = useParams();
  const [homamData, setHomamData] = useState({});
  const { 
    name
    ,description
    ,deity
    ,category
    ,price
    ,no_of_priests_1
    ,no_of_chants_1
    ,duration_1
    ,charges_1
    ,no_of_priests_2
    ,no_of_chants_2
    ,duration_2
    ,charges_2
    ,no_of_priests_3
    ,no_of_chants_3
    ,duration_3
    ,charges_3
    ,delete_status
   } = homamData;

  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}homam/${id}`;
  //console.log("api_url", apiUrl);

  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setHomamData(data))
      .catch(error => console.error(error));
  }, []);  

  let refined_url = name ? name.replace(/\s+/g, '-') : '';

  const [parentData, setParentData] = useState([]);

  useEffect(() => {
    if (id !== 0) {
      const parentURL = `${process.env.REACT_APP_API_ENDPOINT}homam/${id}`;
      fetch(parentURL)
        .then(response => response.json())
        .then(data => {
          const updatedData = { ...data, name_url: data.name.replace(/\s+/g, '-') };
          setParentData(updatedData);
        })
        .catch(error => console.error(error));
    }
  }, [id]);

  const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
  useEffect(() => {
    const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=homam`;
    console.log("imageAPIURLGallery: ", imageAPIURLGallery);
    fetch(imageAPIURLGallery)
      .then(response => response.json())
      .then(data => setThumbImagesGallery(data))
      .catch(errorGallery => console.errorGallery(errorGallery));
  }, []);

  const images = thumbImagesGallery.map((image) => ({
    original: `${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${image.image_src}`,
    thumbnail: `${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${image.image_src}`,
  }));

  
  





const [selectedOption, setSelectedOption] = useState(1);
const [homamInDVDChecked, setHomamInDVDChecked] = useState(false);
const [calculatedCost, setCalculatedCost] = useState(0);

const handleOptionSelect = (option) => {
  setSelectedOption(option);
  calculateOverallCost(option, peopleDetails.length, homamInDVDChecked);
};

const [peopleDetails, setPeopleDetails] = useState([{ id: 1}]);

const handleAddPerson = () => {
  setPeopleDetails([...peopleDetails, { id: peopleDetails.length + 1}]);
  calculateOverallCost(selectedOption, peopleDetails.length + 1, homamInDVDChecked);
};

const handlePersonChange = (index, field, value) => {
  setPeopleDetails((prevPeopleDetails) => {
    const updatedDetails = [...prevPeopleDetails];
    updatedDetails[index - 1][field] = value;
    return updatedDetails;
  });
};

//console.log("selectedOption: ",selectedOption);

const calculateOverallCost = (selectedOption, peopleCount, homamInDVDChecked) => {
  let cost = 0;
  
  // Add charges based on the selected option
  if (selectedOption === 1) {
    cost += charges_1;
    console.log("charge1");
  } else if (selectedOption === 2) {
    cost += charges_2;
    console.log("charge2");
  } else if (selectedOption === 3) {
    cost += charges_3;
  }

  console.log("cost: ", cost);

  // Multiply by the number of people
  cost *= peopleCount;

  // Add 2000 if homam_in_dvd is checked
  if (homamInDVDChecked) {
    cost += 2000;
  }

  // Update the overall cost state
  setCalculatedCost(cost);
};

  const handleHomamInDVDCheckboxChange = (event) => {
    setHomamInDVDChecked(event.target.checked);
    calculateOverallCost(selectedOption, peopleDetails.length, event.target.checked);
  };

  const calculateFormData = () => {
    // Get the values of the required fields
    const homamDate = document.getElementById('homam_date').value;
    const additionalInfo = document.getElementById('additional_info').value;
  
    // Check if homamDate is empty
    if (!homamDate.trim()) {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000); // Hide the message after 3 seconds
      return null; // Exit the function early if homamDate is empty
    }
  
    // If both required fields are filled, create and return the formData object
    return {
      pageId: id,
      selectedOption,
      homamInDVDChecked,
      calculatedCost,
      peopleDetails,
      homamDate,
      additionalInfo,
    };
  };
  
  const handleHomamSubmit = (event) => {
    event.preventDefault();

    let homam_for_name_1 = '';
    let sel_star_1 = '';
    let sel_rasi_1 = '';
    let homam_for_name_2 = '';
    let sel_star_2 = '';
    let sel_rasi_2 = '';
    let homam_for_name_3 = '';
    let sel_star_3 = '';
    let sel_rasi_3 = '';
    let homam_for_name_4 = '';
    let sel_star_4 = '';
    let sel_rasi_4 = '';
    let homam_for_name_5 = '';
    let sel_star_5 = '';
    let sel_rasi_5 = '';

    const homamDateI = document.getElementById('homam_date');
    const homamDate = homamDateI.value;

    const homam_in_dvdI = document.getElementById('homam_in_dvd');
    const homam_in_dvd = homam_in_dvdI.value;

    const additional_infoI = document.getElementById('additional_info');
    const additional_info = additional_infoI.value;

    let homam_for_name_1I = document.getElementById('homam_for_name_1');
    let homam_for_name_2I = document.getElementById('homam_for_name_2');
    let homam_for_name_3I = document.getElementById('homam_for_name_3');
    let homam_for_name_4I = document.getElementById('homam_for_name_4');
    let homam_for_name_5I = document.getElementById('homam_for_name_5');

    let sel_star_1I = document.getElementById('sel_star_1');
    let sel_star_2I = document.getElementById('sel_star_2');
    let sel_star_3I = document.getElementById('sel_star_3');
    let sel_star_4I = document.getElementById('sel_star_4');
    let sel_star_5I = document.getElementById('sel_star_5');

    let sel_rasi_1I = document.getElementById('sel_rasi_1');
    let sel_rasi_2I = document.getElementById('sel_rasi_2');
    let sel_rasi_3I = document.getElementById('sel_rasi_3');
    let sel_rasi_4I = document.getElementById('sel_rasi_4');
    let sel_rasi_5I = document.getElementById('sel_rasi_5');

    /*
    console.log("homamDate", homamDate);
    console.log("homam_in_dvd", homam_in_dvd);
    console.log("additional_info", additional_info);
    console.log("homam_for_name_1", homam_for_name_1);
    console.log("sel_star_1", sel_star_1);
    console.log("sel_rasi_1", sel_rasi_1);
    */

    if(homam_for_name_1I !== null){
        homam_for_name_1 = homam_for_name_1I.value;
        sel_star_1 = sel_star_1I.value;
        sel_rasi_1 = sel_rasi_1I.value;
      //console.log("homam_for_name_1", homam_for_name_1);
    }

    if(homam_for_name_2I !== null){
        homam_for_name_2 = homam_for_name_2I.value;
        sel_star_2 = sel_star_2I.value;
        sel_rasi_2 = sel_rasi_2I.value;
    }

    if(homam_for_name_3I !== null){
        homam_for_name_3 = homam_for_name_3I.value;
        sel_star_3 = sel_star_3I.value;
        sel_rasi_3 = sel_rasi_3I.value;
    }

    if(homam_for_name_4I !== null){
        homam_for_name_4 = homam_for_name_4I.value;
        sel_star_4 = sel_star_4I.value;
        sel_rasi_4 = sel_rasi_4I.value;
    }

    if(homam_for_name_5I !== null){
        homam_for_name_5 = homam_for_name_5I.value;
        sel_star_5 = sel_star_5I.value;
        sel_rasi_5 = sel_rasi_5I.value;
    }
  
    // Check if required fields are filled
    if (homamDate === '' || homam_for_name_1 === '') {
      setShowErrorMessage(true);
  
      // Hide the error message after 3 seconds
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
  
      return;
    }

    // Collect the form data and perform any action, e.g., send it to a server.
    const newItem = {
      id: id,
      category: 'homam',
      homamDate: homamDate,
      homam_in_dvd: homam_in_dvd,
      additional_info: additional_info,
      selectedOption: selectedOption,
      calculatedCost: calculatedCost,
      homam_for_name_1: homam_for_name_1,
      sel_star_1: sel_star_1,
      sel_rasi_1: sel_rasi_1,
      homam_for_name_2: homam_for_name_2,
      sel_star_2: sel_star_2,
      sel_rasi_2: sel_rasi_2,
      homam_for_name_3: homam_for_name_3,
      sel_star_3: sel_star_3,
      sel_rasi_3: sel_rasi_3,
      homam_for_name_4: homam_for_name_4,
      sel_star_4: sel_star_4,
      sel_rasi_4: sel_rasi_4,
      homam_for_name_5: homam_for_name_5,
      sel_star_5: sel_star_5,
      sel_rasi_5: sel_rasi_5
    };
    
    // Retrieve existing cart items from local storage or initialize an empty array
    const existingItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    // Add the new item to the existing array of cart items
    const updatedItems = [...existingItems, newItem];

    // Store the updated array back into local storage
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));

    window.location.href = "/cart";

  };  

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  

  // Astrology items
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('male'); // "male" or "female"
  const [dob, setDob] = useState('');
  const [timeOfBirth, setTimeOfBirth] = useState(''); // "hh:mm" format
  const [timeOfBirthMin, setTimeOfBirthMin] = useState(''); // "hh:mm" format
  const [placeOfBirth, setPlaceOfBirth] = useState('');
  const [location, setLocation] = useState('');
  const [countryOfBirth, setCountryOfBirth] = useState('India');
  const [maritalStatus, setMaritalStatus] = useState(''); // "married", "unmarried", or "do not wish to say"
  const [additionalInfo, setAdditionalInfo] = useState('');

  const handleAstrologySubmit = (event) => {
    event.preventDefault();
  
    // Check if required fields are filled
    if (fullName === '' || dob === '') {
      setShowErrorMessage(true);
  
      // Hide the error message after 3 seconds
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
  
      return;
    }
  
    // Collect the form data and perform any action, e.g., send it to a server.
    const newItem = {
      id: id,
      price: price,
      fullName: fullName,
      gender: gender,
      dob: dob,
      timeOfBirth: timeOfBirth,
      timeOfBirthMin: timeOfBirthMin,
      placeOfBirth: placeOfBirth,
      location: location,
      countryOfBirth: countryOfBirth,
      maritalStatus: maritalStatus,
      additionalInfo: additionalInfo,
      category: 'astrology'
    };
    
    // Retrieve existing cart items from local storage or initialize an empty array
    const existingItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    // Add the new item to the existing array of cart items
    const updatedItems = [...existingItems, newItem];

    // Store the updated array back into local storage
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));

    window.location.href = "/cart";
  };  


  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    noOfPersons: '',
    hotelType: '',
    startDate: '',
    endDate: '',
    message: '',
    graduationDay: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleEnquirySubmit = (e) => {
    e.preventDefault();
  
    if (formData.graduationDay !== '') {
      // A value is present in the honeypot field, likely a bot
      return;
    }
  
    if (
      formData.fullName.trim() === '' ||
      formData.email.trim() === '' ||
      formData.mobileNumber.trim() === ''
    ) {
      alert('Please fill in all required fields.');
    } else {
      // Prepare the form data for submission
      const data = {
        fullName: formData.fullName,
        email: formData.email,
        mobileNumber: formData.mobileNumber,
        noOfPersons: formData.noOfPersons,
        hotelType: formData.hotelType,
        startDate: formData.startDate,
        endDate: formData.endDate,
        message: formData.message,
        website: 'Blessingsonthenet.com',
        page_title: parentData.name ? parentData.name : name
      };
  
      console.log("enquiry data: ", data);
  
      // Replace 'your-api-endpoint' with your actual API endpoint
      fetch(`${process.env.REACT_APP_API_ENDPOINT}enquiry_submission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            // Handle a successful response here
            setIsSubmitted(true);
          } else {
            // Handle errors here
            console.error('Form submission failed');
          }
        })
        .catch((error) => {
          console.error('Error submitting the form:', error);
        });
    }
  };


  // Calculate the minimum selectable date (2 days from today)
const checkInToday = new Date();
checkInToday.setDate(checkInToday.getDate() + 2);
const minCheckInDate = checkInToday.toISOString().split('T')[0];

const checkOutToday = new Date();
checkOutToday.setDate(checkOutToday.getDate() + 3);
const minCheckOutDate = checkOutToday.toISOString().split('T')[0];

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{parentData.name ? parentData.name : name}</title>
    </Helmet> 
    <div>
      <HeaderSearch />

      <section class="py-10 d-flex items-center bg-light-2">
        <div class="container">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div class="col-auto">
                  <div class="">Home</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="">Holidays</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="text-dark-1">{parentData.name ? parentData.name : name}</div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              {/*<a href="#" class="text-14 text-blue-1 underline">View Homams Involving Dwarka</a>*/}
            </div>
          </div>
        </div>
      </section>

      <section class="pt-40">
        <div class="container">
          <div class="row y-gap-20 justify-between items-end">
            <div class="col-6">
              <div class="row x-gap-20  items-center">
                <div class="col-auto">
                  <h1 class="text-30 sm:text-25 fw-600">{parentData.name ? parentData.name : name}</h1>
                </div>
              </div>

              <div class="row x-gap-20 y-gap-20 items-center">
                <div class="col-auto">
                  <div class="d-flex items-center text-15 text-light-1">
                    {deity ? <b>Deity:&nbsp;</b> : null}
                    {deity ? ' ' + deity : ''}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 mb-10">
              <div class="row x-gap-15 y-gap-15 items-center">
                  <div class="col-auto offset-lg-5">
                    <div class="text-14">
                      {category === "homam" ? (
                        <>
                          Price Starts From &nbsp;
                          <span className="text-22 text-dark-1 fw-500">₹{charges_1}</span>
                        </>
                      ) : (
                        <>
                          {/* Replace 'price' with the variable that holds the price value for astrology */}
                          Price Starts From &nbsp;
                          <span className="text-22 text-dark-1 fw-500">₹{price}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div class="col-auto">
                    <a href="#book-now" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">Book Now 
                      <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                  </div>
              </div>
            </div>

            <div class="col-lg-6">
              <ImageGallery items={images} slideInterval={6000} thumbnailPosition="left" />
            </div>

            <div class="col-lg-6 custom_bullets">
              {description?.length > 200 ? 
              (<div class="justify-end" style={{height:500, overflowY:'scroll', overflow: 'auto'}} dangerouslySetInnerHTML={{ __html: `${description}` }}></div>) : 
              (<div class="justify-end" style={{height:500}} dangerouslySetInnerHTML={{ __html: `${description}` }}></div>)
              }

              {/*
              <div class="col-auto border-top-light mt-20 pt-20 pb-20">
                <div class="row x-gap-15 y-gap-15 items-center">
                  <div class="col-auto offset-lg-5">
                    <div class="text-14">
                      Price Starts From &nbsp;
                      <span className="text-22 text-dark-1 fw-500">₹{price}</span>
                    </div>
                  </div>
                
                  <div class="col-auto">
                    <a href="#book-now" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">Book Now 
                      <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                  </div>
                  
                </div>
              </div>
              */}
            </div>
          </div>
        </div>
      </section>

      <section class="pt-30">
        <div class="container">
          <div class="row y-gap-30">
            {/*
            <div class="col-xl-12">
              <div class="row y-gap-40 dark_text">

                <div id="overview" class="col-12">
                  <h3 class="text-22 fw-500 pt-40 border-top-light">Overview</h3>
                  <p class="text-dark-1 text-15 mt-20">
                    {description}
                  </p>
                  <a href="#" class="d-block text-14 text-blue-1 fw-500 underline mt-10">Show More</a>
                </div>

              </div>
            </div>
              */}
            <div class="col-xl-12 mt-30">
              <div class="lg:ml-0">

                {category === 'homam' ? ( 
                <div class="px-30 pt-20 pb-30 border-light rounded-4 shadow-4">
                  
                  <form onSubmit={handleHomamSubmit}>
                    <div class="d-flex items-center justify-between">
                      <div class="">
                        <span id="book-now" class="text-20 fw-500 mt-10">Book Now</span>
                      </div>
                    </div>

                    <div className="row pt-10 room_input mb-10">
                      <div className='col-md-4'>
                        <div className={`border-light rounded-4 ml-10 mr-10 py-20 text-center ${selectedOption === 1 ? 'selected-option' : 'non-selected-option'}`}>
                          <h3 className="text-20 fw-500 ls-2 lh-16 mb-10">
                            Option 1
                          </h3>
                          <p>No.of Priests : <b>{no_of_priests_1}</b><br />
                          No.of Chants : <b>{no_of_chants_1}</b><br />
                          Time: <b>{duration_1}</b><br />
                          Charges : <b>₹{charges_1}/-</b>
                          </p>
                          <div className="col-auto">
                            <button className={`button -md ${selectedOption === 1 ? '-blue-1 bg-dark-3 text-white' : '-outline-blue-1 text-blue-1'} mt-10`} style={{ margin: 'auto' }} onClick={() => handleOptionSelect(1)}>
                              {selectedOption === 1 ? 'Selected' : 'Select'}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className={`border-light rounded-4 ml-10 mr-10 py-20 text-center ${selectedOption === 2 ? 'selected-option' : 'non-selected-option'}`}>
                          <h3 className="text-20 fw-500 ls-2 lh-16 mb-10">
                            Option 2
                          </h3>
                          <p>No.of Priests : <b>{no_of_priests_2}</b><br />
                          No.of Chants : <b>{no_of_chants_2}</b><br />
                          Time: <b>{duration_2}</b><br />
                          Charges : <b>₹{charges_2}/-</b>
                          </p>
                          <div className="col-auto">
                            <button className={`button -md ${selectedOption === 2 ? '-blue-1 bg-dark-3 text-white' : '-outline-blue-1 text-blue-1'} mt-10`} style={{ margin: 'auto' }} onClick={() => handleOptionSelect(2)}>
                              {selectedOption === 2 ? 'Selected' : 'Select'}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className={`border-light rounded-4 ml-10 mr-10 py-20 text-center ${selectedOption === 3 ? 'selected-option' : 'non-selected-option'}`}>
                          <h3 className="text-20 fw-500 ls-2 lh-16 mb-10">
                            Option 3
                          </h3>
                          <p>No.of Priests : <b>{no_of_priests_3}</b><br />
                          No.of Chants : <b>{no_of_chants_3}</b><br />
                          Time: <b>{duration_3}</b><br />
                          Charges : <b>₹{charges_3}/-</b>
                          </p>
                          <div className="col-auto">
                            <button className={`button -md ${selectedOption === 3 ? '-blue-1 bg-dark-3 text-white' : '-outline-blue-1 text-blue-1'} mt-10`} style={{ margin: 'auto' }} onClick={() => handleOptionSelect(3)}>
                              {selectedOption === 3 ? 'Selected' : 'Select'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="row y-gap-20 pt-10">

                          {peopleDetails.map((person, index) => (
                            <PersonDetail key={person.id} index={person.id} onPersonChange={handlePersonChange} />
                          ))}

                          <div class="col-12">
                            <button class="button -md -blue-1 bg-blue-1-05 text-blue-1" style={{margin:'auto'}} onClick={handleAddPerson}>+ Add Another Person</button>
                          </div>

                          <div className="col-6">
                            <div className="px-20 py-10 border-light rounded-4 -right">
                              <div>
                                <h4 className="text-15 fw-500 ls-2 lh-16">Select Date to Perform Homam</h4>

                                <div className="text-15 text-light-1 ls-2 lh-16">
                                  <span className="js-first-date"><input type="date" min={minCheckInDate} id="homam_date" /></span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="px-20 py-10 border-light rounded-4 -right">
                              <div>
                                <h4 className="text-15 fw-500 ls-2 lh-16">Additional Info</h4>
                                <div className="text-15 text-light-1 ls-2 lh-16">
                                  <span className="js-first-date"><input type="text" id="additional_info" /></span>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="col-12" style={{marginTop:'-15px'}}>
                            <p className='px-10'><b>Note:</b> If the star and rasi are unknown, please enter the place, time and date of birth in the space provided for additional info. Please enter details of additional names, Nakshatra and rasi to be included in the sankalp</p>
                          </div>

                          <div class="col-12">
                            <div class="px-20 py-10 border-light rounded-4">
                              <div>
                                <input className="room_checkbox" type="checkbox" id="homam_in_dvd" checked={homamInDVDChecked} onChange={handleHomamInDVDCheckboxChange} />
                                <label htmlFor="homam_in_dvd">I need recorded Homam video in Downloadable Link (Rs. 2000/-)</label>
                              </div>

                              <div>
                                <input className="room_checkbox" type="checkbox" checked id="terms_checked" />
                                <label htmlFor="terms_checked">I agree to the Terms & Policies</label>
                              </div>
                            </div>

                            <div className="text-center mt-10 text-right">
                              <h5><span style={{fontSize:14}}>Total Cost</span>: ₹{calculatedCost === 0 ? charges_1 : calculatedCost}</h5>
                            </div>
                          </div>

                          <div class="offset-md-4 col-md-4">
                            <button class="button -blue-1 px-35 h-60 col-12 -outline-blue-1 text-blue-1">Customize Homam</button>
                          </div>

                          <div class="col-md-4">
                            <button type="submit" class="button -dark-1 px-35 h-60 col-12 bg-blue-1 text-white">
                              Checkout
                            </button>
                            {showErrorMessage && (
                              <div className="col-md-12 mt-2">
                                <p className="text-red" style={{ fontSize: 15, color: 'red' }}>
                                  Ensure to fill Name & Homam Date before submitting
                                </p>
                              </div>
                            )}
                          </div>
                    </div>
                  </form>
                </div>) :
                  (
                  <>
                    <div class="px-30 pt-20 pb-30 border-light rounded-4 shadow-4">
                  
                      <form onSubmit={handleAstrologySubmit}>
                        <div class="d-flex items-center justify-between">
                          <div class="">
                            <span id="book-now" class="text-20 fw-500 mt-10">Book Now</span>
                          </div>
                        </div>
                      
                        <div class="row y-gap-20 pt-10">

                          <div class="col-4">
                            <div class="px-20 border-light rounded-4 -right" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
                              <div>
                              <h4 class="text-15 fw-500 ls-2 lh-16">Enter Full Name</h4>
                              <div class="text-15 text-light-1 ls-2 lh-16">
                                <span class="js-first-date">
                                <input type="text" value={fullName} id="fullName" onChange={(event) => setFullName(event.target.value)} />
                                </span>
                              </div>
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-4">
                            <div class="border-light rounded-4 admin_dropdown_inner">
                              <h4 class="text-15 fw-500 ls-2 lh-16">Gender</h4>
                              <div class="text-15 text-light-1 ls-2 lh-16">
                                <select name="gender" id="gender" class="field-drop-bg">
                                  <option value="male" checked={gender === 'male'} onChange={() => setGender('male')}>Male</option>
                                  <option value="female" checked={gender === 'female'} onChange={() => setGender('female')}>Female</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="px-20 border-light rounded-4 -right" style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                              <div>
                                <h4 className="text-15 fw-500 ls-2 lh-16">Date of Birth</h4>

                                <div className="text-15 text-light-1 ls-2 lh-16">
                                  <input type="date" value={dob} id="dob" onChange={(event) => setDob(event.target.value)} />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="px-20 border-light rounded-4 -right" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
                              <div>
                              <h4 class="text-15 fw-500 ls-2 lh-16">Place of Birth</h4>
                              <div class="text-15 text-light-1 ls-2 lh-16">
                                <input type="text" value={placeOfBirth} id="placeOfBirth" onChange={(event) => setPlaceOfBirth(event.target.value)} />
                              </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="px-20 border-light rounded-4 -right" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
                              <div>
                              <h4 class="text-15 fw-500 ls-2 lh-16">Time of Birth</h4>
                              <div class="text-15 text-light-1 ls-2 lh-16">
                                <div class="row">
                                  <div className='col-3'>
                                    Hrs:
                                  </div>
                                  <div className='col-3'>
                                    <select value={timeOfBirth} id="timeOfBirth" onChange={(event) => setTimeOfBirth(event.target.value)}>
                                      <option value="00">00</option>
                                      <option value="01">01</option>
                                      <option value="02">02</option>
                                      <option value="03">03</option>
                                      <option value="04">04</option>
                                      <option value="05">05</option>
                                      <option value="06">06</option>
                                      <option value="07">07</option>
                                      <option value="08">08</option>
                                      <option value="09">09</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                      <option value="12">12</option>
                                      <option value="13">13</option>
                                      <option value="14">14</option>
                                      <option value="15">15</option>
                                      <option value="16">16</option>
                                      <option value="17">17</option>
                                      <option value="18">18</option>
                                      <option value="19">19</option>
                                      <option value="20">20</option>
                                      <option value="21">21</option>
                                      <option value="22">22</option>
                                      <option value="23">23</option>
                                    </select>
                                  </div>
                                  <div className='col-3'>Mins: </div>
                                  <div className='col-3'>
                                    <select value={timeOfBirthMin} id={timeOfBirthMin} onChange={(event) => setTimeOfBirthMin(event.target.value)}>
                                      <option value="00">00</option>
                                      <option value="01">01</option>
                                      <option value="02">02</option>
                                      <option value="03">03</option>
                                      <option value="04">04</option>
                                      <option value="05">05</option>
                                      <option value="06">06</option>
                                      <option value="07">07</option>
                                      <option value="08">08</option>
                                      <option value="09">09</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                      <option value="12">12</option>
                                      <option value="13">13</option>
                                      <option value="14">14</option>
                                      <option value="15">15</option>
                                      <option value="16">16</option>
                                      <option value="17">17</option>
                                      <option value="18">18</option>
                                      <option value="19">19</option>
                                      <option value="20">20</option>
                                      <option value="21">21</option>
                                      <option value="22">22</option>
                                      <option value="23">23</option>
                                      <option value="24">24</option>
                                      <option value="25">25</option>
                                      <option value="26">26</option>
                                      <option value="27">27</option>
                                      <option value="28">28</option>
                                      <option value="29">29</option>
                                      <option value="30">30</option>
                                      <option value="31">31</option>
                                      <option value="32">32</option>
                                      <option value="33">33</option>
                                      <option value="34">34</option>
                                      <option value="35">35</option>
                                      <option value="36">36</option>
                                      <option value="37">37</option>
                                      <option value="38">38</option>
                                      <option value="39">39</option>
                                      <option value="40">40</option>
                                      <option value="41">41</option>
                                      <option value="42">42</option>
                                      <option value="43">43</option>
                                      <option value="44">44</option>
                                      <option value="45">45</option>
                                      <option value="46">46</option>
                                      <option value="47">47</option>
                                      <option value="48">48</option>
                                      <option value="49">49</option>
                                      <option value="50">50</option>
                                      <option value="51">51</option>
                                      <option value="52">52</option>
                                      <option value="53">53</option>
                                      <option value="54">54</option>
                                      <option value="55">55</option>
                                      <option value="56">56</option>
                                      <option value="57">57</option>
                                      <option value="58">58</option>
                                      <option value="59">59</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="px-20 border-light rounded-4 -right" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
                              <div>
                              <h4 class="text-15 fw-500 ls-2 lh-16">Location</h4>
                              <div class="text-15 text-light-1 ls-2 lh-16">
                                <input type="text" value={location} id={location} onChange={(event) => setLocation(event.target.value)} />
                              </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="px-20 border-light rounded-4 -right" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
                              <div>
                              <h4 class="text-15 fw-500 ls-2 lh-16">Country of Birth</h4>
                              <div class="text-15 text-light-1 ls-2 lh-16">
                                <select value={countryOfBirth} id={countryOfBirth} onChange={(event) => setCountryOfBirth(event.target.value)}>
                                <option value="India">India</option>
                                <option value="Albania">Albania</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antigua &amp; Barbuda">Antigua &amp; Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azores">Azores</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bonaire">Bonaire</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Virgin Islands">British Virgin Islands</option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canary Islands">Canary Islands</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Channel Islands">Channel Islands</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Congo">Congo</option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curacao">Curacao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czech Republic</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="England">England</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Faeroe Islands">Faeroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Holland">Holland</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Ivory Coast">Ivory Coast</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Kosrae">Kosrae</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Laos">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedonia">Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Madeira">Madeira</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Poland">Poland</option>
                                <option value="Ponape">Ponape</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Reunion">Reunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Rota">Rota</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saba">Saba</option>
                                <option value="Saipan">Saipan</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Scotland">Scotland</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Korea">South Korea</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syria</option>
                                <option value="Tahiti">Tahiti</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Tinian">Tinian</option>
                                <option value="Togo">Togo</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Tortola">Tortola</option>
                                <option value="Trinidad &amp;Tobago">Trinidad &amp;Tobago</option>
                                <option value="Truk">Truk</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="Union Island">Union Island</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="US Virgin Islands">US Virgin Islands</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Virgin Gorda">Virgin Gorda</option>
                                <option value="Wake Island">Wake Island</option>
                                <option value="Wales">Wales</option>
                                <option value="Western Samoa">Western Samoa</option>
                                <option value="Yap">Yap</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zaire">Zaire</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                              </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="px-20 border-light rounded-4 -right" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
                              <div>
                                <h4 class="text-15 fw-500 ls-2 lh-16">Marital Status</h4>
                                <div class="text-15 text-light-1 ls-2 lh-16">
                                <select value={maritalStatus} id={maritalStatus} onChange={(event) => setMaritalStatus(event.target.value)}>
                                  <option value="">Select Marital Status</option>
                                  <option value="married">Married</option>
                                  <option value="unmarried">Unmarried</option>
                                  <option value="do not wish to say">Do not wish to say</option>
                                </select>
                              </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="px-20 border-light rounded-4 -right" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
                              <div>
                              <h4 class="text-15 fw-500 ls-2 lh-16">Additional Info</h4>
                              <div class="text-15 text-light-1 ls-2 lh-16">
                                <input type="text" value={additionalInfo} id={additionalInfo} onChange={(event) => setAdditionalInfo(event.target.value)} />
                              </div>
                              </div>
                            </div>
                          </div>

                              <div class="col-12">
                                <div class="px-20 py-10 border-light rounded-4">
                                  <div>
                                    <input className="room_checkbox" type="checkbox" checked id="terms_checked" />
                                    <label htmlFor="terms_checked">I agree to the Terms & Policies</label>
                                  </div>
                                </div>

                                <div className="text-center mt-10 text-right">
                                  <h5><span style={{fontSize:14}}>Total Cost</span>: ₹{price}</h5>
                                </div>
                              </div>

                              <div class="offset-md-4 col-md-4">
                                <button class="button -blue-1 px-35 h-60 col-12 -outline-blue-1 text-blue-1">Customize Astrology</button>
                              </div>

                              <div class="col-md-4">
                                <button type="submit" class="button -dark-1 px-35 h-60 col-12 bg-blue-1 text-white">
                                  Checkout
                                </button>
                                {showErrorMessage && (
                                  <div className="col-md-12 mt-2">
                                    <p className="text-red" style={{ fontSize: 15, color: 'red' }}>
                                      Ensure to fill all details before submitting
                                    </p>
                                  </div>
                                )}
                              </div>
                        </div>
                      </form>
                    </div>
                </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="layout-pt-lg layout-pb-md mt-50">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Enquire with Us</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">Have any questions? feel free to get in touch with us and we will answer all your questions.</p>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div style={{backgroundColor: '#13357B', color: '#fff',}} class="mt-50 px-40 pt-20 pb-20 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color: '#fff',}} class="card-title">WhatsApp Us</h4><br />
                  <p style={{color: '#fff',}} class="card-text">Request a quote, or just chat about this product. We're always happy to help!</p><br />
                  {/*
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                  */}
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                </div>
              </div>
              <div style={{backgroundColor: '#ff894c', color:'#051036'}} class="mt-50 px-40 pt-20 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color:'#051036'}} class="card-title">Write to Us</h4><br />
                  <p style={{color:'#051036'}} class="card-text">Do you have any question or be it an enquiry, feedback or a simple suggestion, write to us.</p><br />
                  <a target="_blank" href="mailto:travel@blessingsonthenet.com"><h5 style={{color:'#051036'}} class="card-text"><img src="/assets/img/icons/email.png" style={{width:'25px'}} /> travel@blessingsonthenet.com</h5></a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
            <div className="mt-50 px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Enter your details for enquiry</div>
                <div className="row y-gap-15 pt-20">
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    name="noOfPersons"
                    value="1"
                    onChange={handleChange}
                    required
                  />
                  {/*
                  <div className="col-6">
                    <div className="form-input" style={{minHeight:'70px'}}>
                    <label className="lh-1 text-16 text-light-1" style={{top:'12px'}}>Hotel Type</label>
                      <select
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius:'4px',
                          padding: '0 15px',
                          paddingTop:'25px'}}
                        name="hotelType"
                        value={formData.hotelType}
                        onChange={handleChange}
                      >
                      <option value="3 Star">3 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="5 Star">5 Star</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  */}
                  <div className="col-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="3"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Your Question / Message</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Send Message
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
      
      {/*
      <section class="layout-pt-md layout-pb-lg">
        <div class="container">
          <div class="row justify-center text-center">
            <div class="col-auto">
              <div class="sectionTitle -md">
                <h2 class="sectionTitle__title">Other Homams that might interest you</h2>
                <p class=" sectionTitle__text mt-5 sm:mt-0">Some of the exciting homams that are similar</p>
              </div>
            </div>
          </div>

          <div class="row y-gap-30 pt-40 sm:pt-20">

            <div class="col-xl-3 col-lg-3 col-sm-6">

              <a href="hotel-single-1.html" class="hotelsCard -type-1 ">
                <div class="hotelsCard__image">

                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">

                      <img class="rounded-4 col-12" src="/assets/img/hotels/1.png" alt="image" />


                    </div>

                    <div class="cardImage__wishlist">
                      <button class="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>

                  </div>

                </div>

                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>Adventure Tour Kolad
                      </span>
                  </h4>

                  <p class="text-light-1 lh-14 text-14 mt-5">From Mumbai/Pune</p>

                  <div class="mt-5">
                    <div class="fw-500">
                      Starting from <span class="text-blue-1">₹35,400</span>
                    </div>
                  </div>
                </div>
              </a>

            </div>

            <div class="col-xl-3 col-lg-3 col-sm-6">

              <a href="hotel-single-1.html" class="hotelsCard -type-1 ">
                <div class="hotelsCard__image">

                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">


                      <div class="cardImage-slider rounded-4 overflow-hidden js-cardImage-slider">
                        <div class="swiper-wrapper">

                          <div class="swiper-slide">
                            <img class="col-12" src="/assets/img/hotels/2.png" alt="image" />
                          </div>

                          <div class="swiper-slide">
                            <img class="col-12" src="/assets/img/hotels/1.png" alt="image" />
                          </div>

                          <div class="swiper-slide">
                            <img class="col-12" src="/assets/img/hotels/3.png" alt="image" />
                          </div>

                        </div>

                        <div class="cardImage-slider__pagination js-pagination"></div>

                        <div class="cardImage-slider__nav -prev">
                          <button class="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev">
                            <i class="icon-chevron-left text-10"></i>
                          </button>
                        </div>

                        <div class="cardImage-slider__nav -next">
                          <button class="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next">
                            <i class="icon-chevron-right text-10"></i>
                          </button>
                        </div>
                      </div>

                    </div>

                    <div class="cardImage__wishlist">
                      <button class="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>


                  </div>

                </div>

                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>Ladakh Top Of The World</span>
                  </h4>

                  <p class="text-light-1 lh-14 text-14 mt-5">Mumbai 2 Nights 3 Days</p>

                  <div class="mt-5">
                    <div class="fw-500">
                      Starting from <span class="text-blue-1">₹22,800</span>
                    </div>
                  </div>
                </div>
              </a>

            </div>

            <div class="col-xl-3 col-lg-3 col-sm-6">

              <a href="hotel-single-1.html" class="hotelsCard -type-1 ">
                <div class="hotelsCard__image">

                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">

                      <img class="rounded-4 col-12" src="/assets/img/gallery/1/2.png" alt="image" />


                    </div>

                    <div class="cardImage__wishlist">
                      <button class="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>

                  </div>

                </div>

                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>Paragliding In Kamshet</span>
                  </h4>

                  <p class="text-light-1 lh-14 text-14 mt-5">Delhi NCR, Delhi</p>

                  <div class="mt-5">
                    <div class="fw-500">
                      Starting from <span class="text-blue-1">₹31,400</span>
                    </div>
                  </div>
                </div>
              </a>

            </div>

            <div class="col-xl-3 col-lg-3 col-sm-6">

              <a href="hotel-single-1.html" class="hotelsCard -type-1 ">
                <div class="hotelsCard__image">

                  <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">


                      <div class="cardImage-slider rounded-4 overflow-hidden js-cardImage-slider">
                        <div class="swiper-wrapper">

                          <div class="swiper-slide">
                            <img class="col-12" src="/assets/img/hotels/8.png" alt="image" />
                          </div>

                          <div class="swiper-slide">
                            <img class="col-12" src="/assets/img/hotels/1.png" alt="image" />
                          </div>

                          <div class="swiper-slide">
                            <img class="col-12" src="/assets/img/hotels/3.png" alt="image" />
                          </div>

                        </div>

                        <div class="cardImage-slider__pagination js-pagination"></div>

                        <div class="cardImage-slider__nav -prev">
                          <button class="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev">
                            <i class="icon-chevron-left text-10"></i>
                          </button>
                        </div>

                        <div class="cardImage-slider__nav -next">
                          <button class="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next">
                            <i class="icon-chevron-right text-10"></i>
                          </button>
                        </div>
                      </div>

                    </div>

                    <div class="cardImage__wishlist">
                      <button class="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>


                  </div>

                </div>

                <div class="hotelsCard__content mt-10">
                  <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>Tour Imagica And Water
                      Kingdom
                      </span>
                  </h4>

                  <p class="text-light-1 lh-14 text-14 mt-5">Gujarat, Punjab</p>

                  <div class="mt-5">
                    <div class="fw-500">
                      Starting from <span class="text-blue-1">₹25,800</span>
                    </div>
                  </div>
                </div>
              </a>

            </div>

          </div>
        </div>
      </section>
      */}
      <SubscribeRibbon />
    </div>
    </>
  );
}

export default Holiday;
