import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminHotelEdit() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];
    
    const { id } = useParams();
    const [hotelData, setHotelData] = useState({});
    const { 
        name
        ,short_description
        ,description
        ,city
        ,state
        ,star_rating
        ,hotel_group
        ,address
        ,zipcode
        ,interest_type
        ,price
        ,featured
        ,contact_number
        ,contact_numbertw
        ,contact_address
        ,contact_person_hotel
        ,contact_number_hotel
        ,Percentage_commission_hotel
        ,email_id_hotel
        ,Address_hotel
        ,booking_office_number
        ,mobile_number_book
        ,booking_office_contact_name
        ,bank_details
        ,b2b
        ,convenience_charge
        ,convenience_charge_type
        ,keywords
        ,note1
        ,note2
        ,metadesc
        ,special_notes
        ,temples
    } = hotelData;

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel/${id}`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
        .then(response => response.json())
        .then(data => setHotelData(data))
        .catch(error => console.error(error));
    }, []);


    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    

    const handleSaveDetails = () => {
        const updatedHotelData = {
            ...hotelData,
            name
            ,short_description
            ,description
            ,city
            ,state: cityState.id
            ,star_rating
            ,hotel_group
            ,address
            ,zipcode
            ,price
            ,featured
            ,contact_number
            ,contact_numbertw
            ,contact_address
            ,contact_person_hotel
            ,contact_number_hotel
            ,Percentage_commission_hotel
            ,email_id_hotel
            ,Address_hotel
            ,booking_office_number
            ,mobile_number_book
            ,booking_office_contact_name
            ,bank_details
            ,b2b
            ,convenience_charge
            ,convenience_charge_type
            ,keywords
            ,note1
            ,note2
            ,metadesc
            ,special_notes
            ,temples: checkedTemples.join(',')
            ,interest_type: checkedInterests.join(',')
            
        };
        console.log("updatedHotelData: ",JSON.stringify(updatedHotelData));
        const hotelUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_hotel_update`;
        fetch(hotelUpdate1, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedHotelData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };

    const [cityState, setCityState] = useState({});   

    useEffect(() => {
        if (city) {
          const cityStateAPIURL = `${process.env.REACT_APP_API_ENDPOINT}cities?city_id=${city}`;
          console.log("cityStateAPIURL: ", cityStateAPIURL);
          fetch(cityStateAPIURL)
            .then(response => response.json())
            .then(data => {
              const [cityData] = data; // Extract the first element from the array
              setCityState(cityData || {});
            })
            .catch(error => console.error(error));
        }
      }, [city]);     
      
      
    const [allCities, setAllCities] = useState([]);

    useEffect(() => {
        const citiesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?cities=all`;
        fetch(citiesUrl)
          .then(response => response.json())
          .then(data => setAllCities(data))
          .catch(error => console.log(error));
    }, []);

    // Fetching Hotel Groups
    const [groupsFetch, setGroupsFetch] = useState([]);

    useEffect(() => {
        const groupsUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_groups`;
        fetch(groupsUrl)
            .then(response => response.json())
            .then(data => setGroupsFetch(data))
            .catch(error => console.log(error));
    }, []);
      



    // Second Tab

    const [secondTabCities, setSecondTabCities] = useState([]);

    useEffect(() => {
        const citiesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?cities=all`;
        fetch(citiesUrl)
          .then(response => response.json())
          .then(data => setSecondTabCities(data))
          .catch(error => console.log(error));
      }, []);

    const [secondTabStates, setSecondTabStates] = useState([]);

    useEffect(() => {
        const statesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?states=all`;
        fetch(statesUrl)
          .then(response => response.json())
          .then(data => setSecondTabStates(data))
          .catch(error => console.log(error));
      }, []);

    const [secondTabTemples, setSecondTabTemples] = useState([]);

    useEffect(() => {
        const templesUrl = `${process.env.REACT_APP_API_ENDPOINT}temples_listings?temples=all`;
        fetch(templesUrl)
          .then(response => response.json())
          .then(data => setSecondTabTemples(data))
          .catch(error => console.log(error));
      }, []);

    const [secondTabInterests, setSecondTabInterests] = useState([]);

    useEffect(() => {
        const interestsUrl = `${process.env.REACT_APP_API_ENDPOINT}interest_types`;
        fetch(interestsUrl)
          .then(response => response.json())
          .then(data => setSecondTabInterests(data))
          .catch(error => console.log(error));
      }, []);

    const [checkedInterests, setCheckedInterests] = useState([]);

    useEffect(() => {
        if (interest_type) {
            const interestIds = interest_type.split(',');
            setCheckedInterests(interestIds);
        }
    }, [interest_type]);

    const handleInterestCheckboxChange = (e, interestId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
        setCheckedInterests(prevState => [...prevState, interestId.toString()]);
        } else {
        setCheckedInterests(prevState => prevState.filter(id => id !== interestId.toString()));
        }
    };

    const [checkedCities, setCheckedCities] = useState([]);


    const [checkedTemples, setCheckedTemples] = useState([]);

    useEffect(() => {
        if (temples) {
            const templeIds = temples.split(',');
            setCheckedTemples(templeIds);
        }
    }, [temples]);

    const handleTempleCheckboxChange = (e, templeId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
          setCheckedTemples(prevState => [...prevState, templeId.toString()]);
        } else {
          setCheckedTemples(prevState => prevState.filter(id => id !== templeId.toString()));
        }
    };

    // 3rd Tab
    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const cropperRef = useRef();
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setCroppedImage(null);
    };

    const handleCrop = () => {
        if (!cropperRef.current || !cropperRef.current.cropper) {
            setError("Cropper reference not properly defined");
            return;
        }
        const canvas = cropperRef.current.cropper.getCroppedCanvas({ width: 250, height: 250 });
        setCroppedImage(canvas.toDataURL("image/jpeg", 0.8));
    };

    const handleUpload = async () => {
        if (!croppedImage) {
            setError("Please crop an image first");
            return;
        }
        setLoading(true);
        setStatus(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'thumb');
        formData.append("image", dataURItoBlob(croppedImage), `${Date.now()}-${selectedFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_hotel_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessage("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessage("");
                }, 5000);
                setSelectedFile(null);
                setCroppedImage(null);
                window.location.reload();
            } else {
                setError("There was an error uploading the image");
            }
        } catch (error) {
            setError("There was an error uploading the image");
            setStatus("error");
        }
        setLoading(false);
    };    

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    const handleImageLoad = () => {
        if (cropperRef.current && !cropperRef.current.cropper) {
            const cropper = new Cropper(cropperRef.current, {
                aspectRatio: 1 / 1,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setError(null);
                },
            });
            cropperRef.current.cropper = cropper;
        }
    };

    // Thumbs Retrieval
    const [thumbImages, setThumbImages] = useState([]);

    useEffect(() => {
      const imageAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=thumb&site_category=hotel`;
      console.log("imageAPIURL: ", imageAPIURL);
      fetch(imageAPIURL)
        .then(response => response.json())
        .then(data => setThumbImages(data))
        .catch(error => console.error(error));
    }, []);

    function handleImageDelete(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=thumb&site_category=hotel`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(error => {
              console.error(error);
              alert("Failed to delete image");
            });
        }
      }


    //Gallery items

    const [selectedGalleryFile, setSelectedGalleryFile] = useState(null);
    const [croppedGalleryImage, setCroppedGalleryImage] = useState(null);
    const [errorGallery, setErrorGallery] = useState(null);
    const [loadingGallery, setLoadingGallery] = useState(false);
    const [statusGallery, setStatusGallery] = useState(null);
    const cropperRefGallery = useRef();
    const [successMessageGallery, setSuccessMessageGallery] = useState("");

    const handleFileChangeGallery = (event) => {
        setSelectedGalleryFile(event.target.files[0]);
        setCroppedGalleryImage(null);
    };

    const handleCropGallery = () => {
        if (!cropperRefGallery.current || !cropperRefGallery.current.cropper) {
            setErrorGallery("Cropper reference not properly defined");
            return;
        }
        const canvasGallery = cropperRefGallery.current.cropper.getCroppedCanvas({ width: 1200, height: 900 });
        setCroppedGalleryImage(canvasGallery.toDataURL("image/jpeg", 0.8));
    };

    const handleUploadGallery = async () => {
        if (!croppedGalleryImage) {
            setErrorGallery("Please crop an image first");
            return;
        }
        setLoadingGallery(true);
        setStatusGallery(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'gallery');
        formData.append("image", dataURItoBlob(croppedGalleryImage), `${Date.now()}-${selectedGalleryFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_hotel_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessageGallery("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessageGallery("");
                }, 5000);
                setSelectedGalleryFile(null);
                setCroppedGalleryImage(null);
                window.location.reload();
            } else {
                setErrorGallery("There was an error uploading Gallery image");
            }
        } catch (errorGallery) {
            setErrorGallery("There was an error uploading Gallery image");
            setStatusGallery("errorGallery");
        }
        setLoadingGallery(false);
    };

    const handleImageLoadGallery = () => {
        if (cropperRefGallery.current && !cropperRefGallery.current.cropper) {
            const cropper = new Cropper(cropperRefGallery.current, {
                aspectRatio: 4 / 3,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setErrorGallery(null);
                },
            });
            cropperRefGallery.current.cropper = cropper;
        }
    };

    // Gallery Retrieval
    const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
    const [captionInput, setCaptionInput] = useState('');

    useEffect(() => {
        const imageAPIURLGallery = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=hotel`;
        console.log("imageAPIURLGallery: ",imageAPIURLGallery);
        fetch(imageAPIURLGallery)
          .then(response => response.json())
          .then(data => setThumbImagesGallery(data))
          .catch(errorGallery => console.errorGallery(errorGallery));
    }, []);
      
    const handleCaptionChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].name = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleFeatureIdChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].feature_id = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };

    const handleRoomTypeChange = (e, index) => {
        const { value } = e.target;
        setThumbImagesGallery((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[index] = {
            ...updatedImages[index],
            room_type: value,
        };
        return updatedImages;
        });
    };    
      
      const handleSaveCaption = (index) => {
        const image = thumbImagesGallery[index];
        const selectedRoomType = image.room_type;

        const updateCaptionURL = `${process.env.REACT_APP_API_ENDPOINT}update_caption`;
        fetch(updateCaptionURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: image.id,
            caption: image.name,
            feature_id: image.feature_id,
            room_type: selectedRoomType,
            cat: 'hotel',
          }),
        })
          .then(response => response.json())
          .then(data => {
            // Handle the response, show success message, update status, etc.
            console.log('Caption saved:', data);
            alert("Caption Saved Successfully!");
          })
          .catch(error => {
            // Handle the error, show error message, update status, etc.
            console.error('Error saving caption:', error);
            alert("There was a problem, contact your dev team!");
          });
      };

    function handleImageDeleteGallery(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=gallery&site_category=hotel`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(errorGallery => {
              console.errorGallery(errorGallery);
              alert("Failed to delete image");
            });
        }
      }


    // Old Database Images Retrieval
    const [oldimages, setOldImages] = useState([]);

    useEffect(() => {
      const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=old&site_category=hotel`;
      console.log("imageAPIURLGallery: ", imageAPIURLGallery);
      fetch(imageAPIURLGallery)
        .then(response => response.json())
        .then(data => setOldImages(data))
        .catch(errorOldImages => console.errorOldImages(errorOldImages));
    }, []);

    function handleOldImageDelete(id, imageSec) {
        if (window.confirm("Are you sure you want to delete this image?")) {
            // Call API to delete the image
            fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=old&image_sec=${imageSec}&site_category=hotel`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Failed to delete image");
                    }
                    // Reload the page to refresh the images
                    window.location.reload();
                })
                .catch(errorGallery => {
                    console.errorGallery(errorGallery);
                    alert("Failed to delete image");
                });
        }
    }    


    const [roomTypes, setRoomTypes] = useState([]);

    const roomTypesFetch = `${process.env.REACT_APP_API_ENDPOINT}hotel_room_types/${id}`;
    //console.log("roomTypesFetch", roomTypesFetch);

    useEffect(() => {
        fetch(roomTypesFetch)
        .then(response => response.json())
        .then(data => setRoomTypes(data))
        .catch(error => console.error(error));
    }, []);    

    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">

                    <h1 class="text-30 lh-14 fw-600">Edit Hotel</h1>

                </div>

                <div class="col-auto">
                    <a target="_blank" style={{float:'left'}} href={`/admin/hotel/room/types/${id}`} 
                    class="button h-50 px-24 -blue-1 bg-dark-3 text-white pull-left mr-10">
                        Room Types <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                    <a target="_blank" href={`/hotel/${name ? name.toLowerCase().replace(/\s+/g, '-') : ''}/${id}`} 
                    class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        View Page <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">
                <div class="tabs -underline-2 js-tabs">
                    <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">1. Content</button>
                    </div>

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-2">2. Relations</button>
                    </div>

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-3">3. Images</button>
                    </div>

                    <div class="col-auto">
                        <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-4">4. Business & SEO</button>
                    </div>

                    </div>

                    <div class="tabs__content pt-30 js-tabs-content">
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                        <div class="col-xl-10">
                            <div class="text-18 fw-500 mb-10">Tour Hotel Content</div>
                            <div class="row x-gap-20 y-gap-20">

                                <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={hotelData.name}
                                        name="name"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            name: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Hotel Name</label>
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Short Description</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={short_description}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, short_description: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Description / Details</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={description}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, description: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>
                                    
                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={cityState.city}
                                        name="city"
                                        onChange={event => {
                                            const cityValue = event.target.value;
                                            setCityState(prevState => ({
                                            ...prevState,
                                            city: cityValue,
                                            state: ''
                                            }));
                                            setHotelData(prevState => ({
                                            ...prevState,
                                            city: cityValue
                                            }));
                                        }}
                                        required
                                        />

                                        {!hotelData.city && (
                                        <ul className="hotel_edit_autocomplete">
                                            {allCities
                                            .filter(city => city.city.toLowerCase().includes(cityState.city.toLowerCase()))
                                            .map(city => (
                                                <li
                                                key={city.id}
                                                onMouseDown={event => {
                                                    event.preventDefault(); // Prevent input field from losing focus
                                                    setHotelData(prevState => ({
                                                    ...prevState,
                                                    city: city.id,
                                                    state: city.id
                                                    }));
                                                    setCityState(prevState => ({
                                                    ...prevState,
                                                    city: city.id,
                                                    state: city.id
                                                    }));
                                                }}
                                                >
                                                {`${city.city}, ${city.state}`}
                                                </li>
                                            ))}
                                        </ul>
                                        )}

                                        <label className="lh-1 text-16 text-light-1">City</label>
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={cityState.state}
                                        name="state"
                                        onChange={event =>
                                            setHotelData(prevState => ({
                                            ...prevState,
                                            state: event.target.value
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">State</label>
                                    </div>
                                    </div>

                                    
                                    <div class="col-12 room_input">
                                        <div className='border-light rounded-4 admin_dropdown_inner'>
                                            <h4 class="text-15 fw-500 ls-2 lh-16">Star Rating</h4>
                                            <div class="text-15 text-light-1 ls-2 lh-16">
                                                <select
                                                    value={star_rating}
                                                    onChange={(event) =>
                                                        setHotelData((prevState) => ({
                                                        ...prevState,
                                                        star_rating: event.target.value,
                                                        }))
                                                    }
                                                    >
                                                    <option value="5">5 Star</option>
                                                    <option value="4">4 Star</option>
                                                    <option value="3">3 Star</option>
                                                    <option value="2">2 Star</option>
                                                    <option value="1">1 Star</option>
                                                    <option value="0">0 Star</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 room_input">
                                        <div className='border-light rounded-4 admin_dropdown_inner'>
                                            <h4 class="text-15 fw-500 ls-2 lh-16">Hotel Group</h4>
                                            <div class="text-15 text-light-1 ls-2 lh-16">
                                                <select
                                                value={hotel_group}
                                                onChange={(event) =>
                                                    setHotelData((prevState) => ({
                                                    ...prevState,
                                                    hotel_group: event.target.value,
                                                    }))
                                                }
                                                >
                                                <option value="">No Group</option>
                                                {groupsFetch.map((option, index) =>
                                                    option.hotel_group !== "" ? (
                                                    <option key={index} value={option.hotel_group}>
                                                        {option.hotel_group}
                                                    </option>
                                                    ) : null
                                                )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Address</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={address}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, address: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={hotelData.zipcode}
                                        name="zipcode"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            zipcode: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Zipcode</label>
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={hotelData.price}
                                        name="price"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            price: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Price</label>
                                    </div>
                                    </div>

                                    <div className="col-12 mb-30">
                                    <div className="d-flex items-center">
                                        <div className="form-checkbox">
                                        <input
                                            type="checkbox"
                                            name="featured"
                                            onChange={(event) =>
                                            setHotelData((prevState) => ({
                                                ...prevState,
                                                featured: event.target.checked ? 1 : 0,
                                            }))
                                            }
                                            checked={hotelData.featured === 1}
                                        />
                                        <div className="form-checkbox__mark">
                                            <div className="form-checkbox__icon icon-check"></div>
                                        </div>
                                        </div>
                                        <div className="text-15 lh-11 ml-10">Featured Hotel</div>
                                    </div>
                                    </div>

                                    <div class="col-12 mb-50">
                                    <div class="fw-500">Special Notes</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={special_notes}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, special_notes: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                            </div>

                        </div>

                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>

                    </div>

                    <div class="tabs__pane -tab-item-2">
                        <div class="col-xl-10">
                            <div class="row x-gap-20 y-gap-20">

                                <div class="col-lg-5 col-sm-6 offset-lg-1">
                                    <div class="text-18 fw-500 mb-10">Temples related to this Hotel</div>
                                    <div className='scrollable_checkboxes'>
                                        <div class="row y-gap-15">
                                            {secondTabTemples.map(item => (
                                                <div className="col-12" key={item.id}>
                                                    <div className="d-flex items-center">
                                                    <div className="form-checkbox ">
                                                        <input
                                                        type="checkbox"
                                                        value={item.id}
                                                        name="temples"
                                                        checked={checkedTemples.includes(item.id.toString())}
                                                        onChange={(e) => handleTempleCheckboxChange(e, item.id)}
                                                        />
                                                        <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                        </div>
                                                    </div>
                                                    <div className="text-15 lh-11 ml-10">{item.name}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-sm-6 offset-lg-1">
                                    <div class="text-18 fw-500 mb-10">Interests related to this Package</div>
                                    <div className='scrollable_checkboxes'>
                                        <div class="row y-gap-15">
                                            {secondTabInterests.map(item => (
                                                <div className="col-12" key={item.id}>
                                                    <div className="d-flex items-center">
                                                    <div className="form-checkbox ">
                                                        <input
                                                        type="checkbox"
                                                        value={item.id}
                                                        name="interests"
                                                        checked={checkedInterests.includes(item.id.toString())}
                                                        onChange={(e) => handleInterestCheckboxChange(e, item.id)}
                                                        />
                                                        <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                        </div>
                                                    </div>
                                                    <div className="text-15 lh-11 ml-10">{item.type}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>
                    </div>

                    <div class="tabs__pane -tab-item-3">
                        <div class="col-xl-12 col-lg-12">
                            <div class="col-12">

                                

                                <div>
                                    <div class="fw-500">Upload New Thumbnail Images (These would be 250 x 250 px)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChange} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedFile && (
                                        <div>
                                            <img onLoad={handleImageLoad} ref={cropperRef} src={URL.createObjectURL(selectedFile)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCrop}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedImage && (
                                        <div>
                                            <img src={croppedImage} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedImage(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUpload} disabled={loading}>{loading ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessage && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessage}
                                            </div>
                                            )}

                                            {error && <div class="alert alert-danger">{error}</div>}
                                            {status && <div class="alert alert-success">{status}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30">
                                    <div class="fw-500">Existing Thumbnail Images</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">
                                        {thumbImages.length > 0 ? (
                                            thumbImages.map((image, index) => (
                                                <div className="col-auto" key={index}>
                                                <div className="d-flex ratio ratio-1:1 w-200">
                                                    <img
                                                    src={
                                                        image.hotel_image.includes('aim_')
                                                        ? `/img/uploads/hotels/roomgallery/${image.hotel_image}`
                                                        : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`
                                                    }
                                                    alt="image"
                                                    className="img-ratio rounded-4"
                                                    />
                                                    <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        className="size-40 bg-white rounded-4"
                                                        onClick={() => handleImageDelete(image.id)}
                                                    >
                                                        <i className="icon-trash text-16 image_trash_icon"></i>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            ))
                                            ) : (
                                            <p>No Images Found</p>
                                            )}

                                    </div>
                                </div>

                                <div class="border-top-light mt-30 mb-30"></div>

                                <div>
                                    <div class="fw-500">Upload New Gallery Images (These would be 4:3 ratio, 1200 x 900 px recommended)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChangeGallery} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedGalleryFile && (
                                        <div>
                                            <img onLoad={handleImageLoadGallery} ref={cropperRefGallery} src={URL.createObjectURL(selectedGalleryFile)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCropGallery}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedGalleryImage && (
                                        <div>
                                            <img src={croppedGalleryImage} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedGalleryImage(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUploadGallery} disabled={loadingGallery}>{loadingGallery ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessageGallery && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessageGallery}
                                            </div>
                                            )}

                                            {errorGallery && <div class="alert alert-danger">{errorGallery}</div>}
                                            {statusGallery && <div class="alert alert-success">{statusGallery}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30">
                                    <div class="fw-500">Existing Gallery Images</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">
                                    {thumbImagesGallery.length > 0 ? (
                                        thumbImagesGallery.map((image, index) => (
                                            <div className="col-auto" key={index}>
                                            <div className="d-flex ratio ratio-1:1 w-400" style={{ marginBottom: -90 }}>
                                                <img
                                                style={{ height: 'unset' }}
                                                src={
                                                    image.hotel_image.includes('aim_')
                                                    ? `/img/uploads/hotels/roomgallery/${image.hotel_image}`
                                                    : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`
                                                }
                                                alt="image"
                                                className="img-ratio rounded-4"
                                                />
                                                <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    className="size-40 bg-white rounded-4"
                                                    onClick={() => handleImageDeleteGallery(image.id)}
                                                >
                                                    <i className="icon-trash text-16 image_trash_icon"></i>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="form-input">
                                                <input
                                                type="text"
                                                name="caption"
                                                title="Caption"
                                                placeholder="Caption"
                                                className="ReactTags__tagInputField caption_input"
                                                value={image.name || ''}
                                                onChange={e => handleCaptionChange(e, index)}
                                                />
                                                <input
                                                    type="text"
                                                    name="feature_id"
                                                    title="Feature ID [Add numerical values 1 to 10]"
                                                    className="ReactTags__tagInputField feature_input"
                                                    value={image.feature_id || ''}
                                                    onChange={e => handleFeatureIdChange(e, index)}
                                                />
                                                <select
                                                name="roomType"
                                                value={image.room_type || ''}
                                                onChange={(e) => handleRoomTypeChange(e, index)}
                                                >
                                                <option value="">Select Room Type</option>
                                                {roomTypes.map((roomType) => (
                                                    <option
                                                    key={roomType.id}
                                                    value={roomType.id}
                                                    selected={roomType.id.toString() === image.room_type}
                                                    >
                                                    {roomType.room_type}
                                                    </option>
                                                ))}
                                                </select>
                                                <button onClick={() => handleSaveCaption(index)}>
                                                    <img className='caption_save' src={`/assets/img/general/save-green.png`} alt='Save' title='Save' />
                                                </button>
                                            </div>
                                            </div>
                                        ))
                                        ) : (
                                        <p>No Images Found</p>
                                    )}

                                    </div>
                                </div>


                                <div class="border-top-light mt-30 mb-30"></div>

                                <div class="mt-30">
                                    <div class="fw-500">Old Database Images</div>
                                    {oldimages.length > 0 ? (
                                        oldimages.map((image, index) => (
                                            <div className="row x-gap-20 y-gap-20 pt-15" key={index}>
                                                {!!image.hotel_image && (
                                                    <div className="col-auto">
                                                        <div className="d-flex ratio ratio-1:1 w-200">
                                                            <img
                                                                style={{ height: "unset" }}
                                                                src={`/img/uploads/hotels/${image.hotel_image}`}
                                                                alt="image"
                                                                className="img-ratio rounded-4"
                                                            />
                                                            <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                                <div
                                                                    style={{ cursor: "pointer" }}
                                                                    className="size-40 bg-white rounded-4"
                                                                    onClick={() => handleOldImageDelete(image.id, 'image')}
                                                                >
                                                                    <i className="icon-trash text-16 image_trash_icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {!!image.thumbnail_image && (
                                                    <div className="col-auto">
                                                        <div className="d-flex ratio ratio-1:1 w-200">
                                                            <img
                                                                style={{ height: "unset" }}
                                                                src={`/img/uploads/hotels/thumbs/${image.thumbnail_image}`}
                                                                alt="image"
                                                                className="img-ratio rounded-4"
                                                            />
                                                            <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                                <div
                                                                    style={{ cursor: "pointer" }}
                                                                    className="size-40 bg-white rounded-4"
                                                                    onClick={() => handleOldImageDelete(image.id, 'thumb')}
                                                                >
                                                                    <i className="icon-trash text-16 image_trash_icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No Images Found</p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tabs__pane -tab-item-4">
                        <div class="col-xl-10">
                            <div class="text-18 fw-500 mb-10">Tour Hotel Content</div>
                            <div class="row x-gap-20 y-gap-20">
                                {/*
                                <div class="col-12 mb-50">
                                    <div class="fw-500">Cancellation Policy</div>
                                    <div class="form-input" style={{maxHeight:300,}}>
                                        <ReactQuill
                                            value={cancellation_policy}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, cancellation_policy: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                </div>
                                */}
                                <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={contact_number}
                                        name="contactNumber"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            contact_number: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Contact Number</label>
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={contact_numbertw}
                                        name="contactNumberTW"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            contact_numbertw: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Contact Number TW</label>
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Contact Address</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={contact_address}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, contact_address: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={contact_person_hotel}
                                        name="contactPersonHotel"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            contact_person_hotel: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Contact Person (Hotel)</label>
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={contact_number_hotel}
                                        name="contactNumberHotel"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            contact_number_hotel: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Contact Number (Hotel)</label>
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={Percentage_commission_hotel}
                                        name="percentageCommissionHotel"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            Percentage_commission_hotel: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">
                                        Percentage Commission (Hotel)
                                        </label>
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={email_id_hotel}
                                        name="emailIDHotel"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            email_id_hotel: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Email ID (Hotel)</label>
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Address (Hotel)</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={Address_hotel}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, Address_hotel: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={booking_office_number}
                                        name="bookingOfficeNumber"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            booking_office_number: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">
                                        Booking Office Number
                                        </label>
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={mobile_number_book}
                                        name="mobileNumberBook"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            mobile_number_book: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Mobile Number (Book)</label>
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Booking Office Contact Name</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={booking_office_contact_name}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, booking_office_contact_name: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Bank Details</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={bank_details}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, bank_details: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">B2B</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={b2b}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, b2b: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12">
                                    <div className="form-input">
                                        <input
                                        type="text"
                                        value={convenience_charge}
                                        name="convenienceCharge"
                                        onChange={(event) =>
                                            setHotelData((prevState) => ({
                                            ...prevState,
                                            convenience_charge: event.target.value,
                                            }))
                                        }
                                        required
                                        />
                                        <label className="lh-1 text-16 text-light-1">Convenience Charge</label>
                                    </div>
                                    </div>

                                    <div class="col-12 room_input">
                                        <div className='border-light rounded-4 admin_dropdown_inner'>
                                            <h4 class="text-15 fw-500 ls-2 lh-16">Convenience Charge Type</h4>
                                            <div class="text-15 text-light-1 ls-2 lh-16">
                                                <select
                                                value={convenience_charge_type}
                                                onChange={(event) =>
                                                    setHotelData((prevState) => ({
                                                    ...prevState,
                                                    convenience_charge_type: event.target.value,
                                                    }))
                                                }
                                                >
                                                <option value="" disabled>Select an option</option>
                                                <option value="rupees">Rupees</option>
                                                <option value="percentage">Percentage</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Keywords</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={keywords}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, keywords: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Note 1</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={note1}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, note1: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Note 2</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={note2}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, note2: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                    <div className="fw-500">Meta Description</div>
                                    <div className="form-input" style={{ maxHeight: 300 }}>
                                        <ReactQuill
                                            value={metadesc}
                                            onChange={(value) => setHotelData(prevState => ({...prevState, metadesc: value}))}
                                            modules={{ toolbar: toolbarOptions }}
                                        />
                                    </div>
                                    </div>
                                
                            </div>

                        </div>
                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminHotelEdit;