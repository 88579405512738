import React, { useState, useEffect } from 'react';
import AdminFooter from './AdminFooter';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminInterests() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const [interestsData, setInterests] = useState([]);

    useEffect(() => {
        // Fetch cities data from API based on the current page
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}interest_types`;
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setInterests(data);
            })
            .catch((error) => console.log(error));
    }, []);

    return (
        <div class="dashboard__main">
            <div class="dashboard__content bg-light-2">
            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">
    
                <h1 class="text-30 lh-14 fw-600">Interests</h1>
    
                </div>
    
                <div class="col-auto">
    
    
                <a href="/admin/interest/create" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                    Add New Interest <div class="icon-arrow-top-right ml-15"></div>
                </a>
    
    
                </div>
            </div>
    
    
            <div class="py-30 px-30 rounded-4 bg-white shadow-3">
    
                <div class="tabs__content pt-30 js-tabs-content">
    
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                    <div class="overflow-scroll scroll-bar-1">
                        <table class="table-4 -border-bottom col-12">
                        <thead class="bg-light-2">
                            <tr>
                            <th>Interest</th>
                            <th>Featured</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
    
                        {interestsData.map(interest => (
                            <tr key={interest.id}>
                            <td className="text-blue-1 fw-500">{interest.type}</td>
                            <td>{interest.featured === '0'
                            ? null
                            : interest.featured
                            }</td>
                            <td>
                                <div className="row x-gap-10 y-gap-10 items-center">
                                    <div className="col-auto">
                                        <a href={`/admin/interest/edit/${interest.id}`} >
                                            <button title="Edit" className="flex-center bg-light-2 rounded-4 size-35">
                                            <i className="icon-edit text-16 text-light-1"></i>
                                            </button>
                                        </a>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            title="Delete"
                                            className="flex-center bg-light-2 rounded-4 size-35"
                                            onClick={() => {
                                            if (window.confirm("Are you sure you want to delete this interest?")) {
                                                window.location.href = `/admin/interest/delete/${interest.id}`;
                                            }
                                            }}
                                        >
                                            <i className="icon-trash-2 text-16 text-light-1"></i>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            </tr>
                        ))}
    
                        </tbody>
                        </table>
                    </div>
                    </div>
    
                </div>
            </div>
    
    
            <AdminFooter />
            </div>
        </div>
    );
}

export default AdminInterests;