import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import 'react-quill/dist/quill.snow.css';
import "cropperjs/dist/cropper.min.css";
import { isAuthenticated } from '../middleware/admin_auth';

function AdminOrdersView() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);
    
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState({ 
        id: null, 
        user_name: '', 
        email_address: '', 
        user_phone_number: '', 
        address_line_1: '', 
        user_city: '',
        user_state: '', 
        user_pin_code: '', 
        user_special_requests: '', 
        overall_price: '', 
        order_id: '', 
        final_cart_items: '', 
        payment_status: '', 
        order_date_time: '' 
    });

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}orders_listings?id=${id}`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data) && data.length > 0) {
              setOrderDetails(data[0]);
            }
          })
          .catch(error => console.error(error));
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    
    
    const handleSaveDetails = () => {
        console.log("orderDetails", orderDetails);
        const packageUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_OrderDetails_update`;
        fetch(packageUpdate1, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(orderDetails),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };


    let priceToPay = 0;
    const [overallProductPrice, setOverallProductPrice] = useState([0]);
    const [overallTourPrice, setOverallTourPrice] = useState([0]);
    const [overallHotelPrice, setOverallHotelPrice] = useState([0]);
    const [overallAstrologyPrice, setOverallAstrologyPrice] = useState([0]);
    const [overallHomamPrice, setOverallHomamPrice] = useState([0]);
    const [thereIsProduct, setThereIsProduct] = useState([0]);
    const [cartItems, setCartItems] = useState({});
    const productShippingPrice = 80;

    let totalProductPriceWithTax = 0;
    let totalTourPriceWithTax = 0;
    let totalHotelPrice = 0;
    let totalAstrologyPriceWithTax = 0;
    let totalHomamPriceWithTax = 0;

    useEffect(() => {
        const cartItems = orderDetails?.final_cart_items;
        //const cartItems = '[{"id":"2","quantity":"2","category":"ecommerce","price":450}]';
        console.log("cartItems: ", cartItems);
        setCartItems(cartItems);
      
        if (cartItems) {
          const parsedItems = JSON.parse(cartItems);
      
          // Filter items with category "ecommerce"
          const ecommerceItems = parsedItems.filter(item => item.category === 'ecommerce');
    
          if (ecommerceItems.length > 0) {
      
            // Extract the IDs of ecommerce items
            const itemIds = ecommerceItems.map(item => item.id);
        
            // Construct the API URL
            const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}product_listings?id=${itemIds.join(',')}`;
            console.log("apiUrl: ",apiUrl);
            // Fetch data from the API
            fetch(apiUrl)
              .then(response => response.json())
              .then(data => {
                let totalPrice = 0; // Initialize total price to zero
        
                // Process the retrieved data and render the div structure
                data.forEach(item => {
                  // Extract the required values from the item
                  const { id, image_src, product_name, category_name, price } = item;
    
                  console.log("ecommerceItems: ",ecommerceItems);
                  console.log("ecommerceId: ",id);
        
                  // Retrieve the quantity from the cart item
                  const correspondingCartItem = ecommerceItems.find(cartItem => cartItem.id === String(id));
    
                  console.log("correspondingCartItem: ",id);
                  
                  const quantity = correspondingCartItem && correspondingCartItem.quantity ? parseInt(correspondingCartItem.quantity) : 0;
                  //const quantity = 1;
        
                  console.log('Quantity for item', id, ':', quantity);
        
                  // Calculate the item price (price * quantity)
                  const itemPrice = price * quantity;
    
                  const productTax = parseFloat(itemPrice * 0.18).toFixed(2);
                  totalProductPriceWithTax = parseInt(productTax) + parseInt(itemPrice) + productShippingPrice;
    
                  setOverallProductPrice(totalProductPriceWithTax);
                  setThereIsProduct(1);
        
                  // Add the item price to the total price
                  totalPrice += itemPrice;
        
                  // Check if the item has already been rendered
                  const existingItem = document.getElementById(`cartItem_${id}`);
                  if (existingItem) {
                    return; // Skip rendering if the item is already in the cart
                  }
        
                  // Render the div structure with the retrieved values
                  const divElement = document.createElement('div');
                  divElement.className = 'row x-gap-15 y-gap-10';
                  divElement.id = `cartItem_${id}`;
                  divElement.innerHTML = `
                    <div class="border-top-light mt-20 mb-10"></div>
                    <div class="col-auto">
                      <img src="${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${image_src}" alt="image" class="size-80 rounded-4 object-cover" />
                    </div>
                    <div class="col">
                      <div class="lh-17 fw-500">${product_name}</div>
                      <div class="row">
                        <div class="col-6 text-14 lh-15">Shopping</div>
                        <div class="col-6 text-14 remove-item mt-5 text-right" data-id="${id}"></div>
                      </div>
                      <div class="col-12">
                          <div class="d-flex items-center">
                            <div class="flex-center bg-blue-1 rounded-4" style="padding: 2px 10px;">
                              <div class="text-14 fw-600 text-white">Quantity: ${quantity}</div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="row y-gap-20 justify-between items-center">
                      <div class="col-auto">
                        <div class="text-15">Price: </div>
                        <div class="text-15">Tax (18% GST):</div>
                        <!--<div class="text-15 fw-500">Total Price:</div>-->
                      </div>
    
                      <div class="col-auto">
                        <div class="text-15">₹${price}/item</div>
                        <div class="text-15">₹${productTax}</div>
                        <!--<div class="text-15 fw-500">₹${totalProductPriceWithTax}</div>-->
                      </div>
                    </div>
                  `;
        
                  // Append the div element to the desired container
                  document.getElementById('cartItemsContainer').appendChild(divElement);
                });
        
                // Calculate the tax and shipping price
                const taxPercentage = 0.18;
                const taxAmount = totalPrice * taxPercentage;
                const shippingPrice = 80;
        
                // Calculate the final total price including tax and shipping
                const totalPriceWithTaxAndShipping = totalPrice + taxAmount + shippingPrice;
        
                // Display the tax, shipping price, and total price
                const totalTaxElement = document.getElementById('totalTax');
                totalTaxElement.innerText = `₹${taxAmount.toFixed(2)}`;
        
                const totalShippingPriceElement = document.getElementById('totalShippingPrice');
                totalShippingPriceElement.innerText = `₹${shippingPrice}`;
        
                const totalPriceElement = document.getElementById('totalPrice');
                totalPriceElement.innerText = `₹${totalPriceWithTaxAndShipping.toFixed(2)}`;
      
              })
              .catch(error => console.error(error));
          
          }
    
    
          // Filter items with category "tour"
          const tourItems = parsedItems.filter(item => item.category === 'tour');
    
          if (tourItems.length > 0) {
      
            // Extract the IDs of tour items
            const tourItemIds = tourItems.map(item => item.id);
        
            // Construct the API URL
            const tourApiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour_listings?id=${tourItemIds.join(',')}`;
            console.log("tourApiUrl: ",tourApiUrl);
            // Fetch data from the API
            fetch(tourApiUrl)
              .then(response => response.json())
              .then(data => {
                let totalPrice = 0; // Initialize total price to zero
        
                // Process the retrieved data and render the div structure
                data.forEach(item => {
                  // Extract the required values from the item
                  const { id, thumb_new, name, category_name, price } = item;
    
                  console.log("tourItems: ",tourItems);
                  console.log("tourId: ",id);
        
                  // Retrieve the quantity from the cart item
                  const correspondingTourItem = tourItems.find(cartItem => cartItem.id === String(id));
    
                  console.log("correspondingTourItem: ",id);
    
                  console.log("correspondingTourItem: ", correspondingTourItem);
                  
                  const quantity = correspondingTourItem && correspondingTourItem.quantity ? parseInt(correspondingTourItem.quantity) : 0;
                  const departureDate = correspondingTourItem && correspondingTourItem.departureDate ? correspondingTourItem.departureDate : 0;
                  const vehicleType = correspondingTourItem && correspondingTourItem.vehicleType ? correspondingTourItem.vehicleType : 0;
                  const numAdults = correspondingTourItem && correspondingTourItem.numAdults ? correspondingTourItem.numAdults : 0;
                  const numChildren = correspondingTourItem && correspondingTourItem.numChildren ? correspondingTourItem.numChildren : 0;
                  const numRooms = correspondingTourItem && correspondingTourItem.numRooms ? correspondingTourItem.numRooms : 0;
                  const childBed = correspondingTourItem && correspondingTourItem.childBed ? correspondingTourItem.childBed : 0;
                  const includeMeals = correspondingTourItem && correspondingTourItem.includeMeals ? correspondingTourItem.includeMeals : 0;
                  const needGuide = correspondingTourItem && correspondingTourItem.needGuide ? correspondingTourItem.needGuide : 0;
                  const costPerPerson = correspondingTourItem && correspondingTourItem.costPerPerson ? correspondingTourItem.costPerPerson : 0;
                  const totalTourPrice = correspondingTourItem && correspondingTourItem.price ? correspondingTourItem.price : 0;
    
                  const formattedTotalPrice = totalTourPrice.replace(/,/g, '');
                  const tourTax = formattedTotalPrice * 0.05;
                  totalTourPriceWithTax = parseInt(formattedTotalPrice) + parseInt(tourTax);
                  setOverallTourPrice(totalTourPriceWithTax);
                  //const quantity = 1;
    
                  console.log("departureDate: ", departureDate);
                  console.log("vehicleType: ", vehicleType);
        
                  console.log('Quantity for item', id, ':', quantity);
        
                  // Calculate the item price (price * quantity)
                  const itemPrice = price * quantity;
        
                  // Add the item price to the total price
                  totalPrice += itemPrice;
        
                  // Check if the item has already been rendered
                  const existingItem = document.getElementById(`cartItem_${id}`);
                  if (existingItem) {
                    return; // Skip rendering if the item is already in the cart
                  }
        
                  // Render the div structure with the retrieved values
                  const divElement = document.createElement('div');
                  divElement.className = 'row x-gap-15 y-gap-10';
                  divElement.id = `cartItem_${id}`;
                  divElement.innerHTML = `
                    <div class="border-top-light mt-20 mb-10"></div>
                    <div class="col-auto">
                      <img src="${process.env.REACT_APP_API_ENDPOINT}uploads/${thumb_new}" alt="image" class="size-80 rounded-4 object-cover" />
                    </div>
                    <div class="col">
                      <div class="lh-17 fw-500">${name}</div>
                      <div class="row">
                        <div class="col-6 text-14 lh-15 mt-5">Tour Package</div>
                        <div class="col-6 text-14 remove-item mt-5 text-right" data-id="${id}"></div>
                      </div>
                    </div>
                    <div class="row y-gap-20 justify-between items-center">
                      <div class="col-auto">
                        <div class="text-16 fw-500">You've Chosen</div>
                        <div class="text-15">Departure Date: </div>
                        <div class="text-15">Vehicle Type:</div>
                        <div class="text-15">No. of Adults:</div>
                        <div class="text-15">No. of Children:</div>
                        <div class="text-15">No. of Rooms:</div>
                        <div class="text-15">Child Bed:</div>
                        <div class="text-15">Include Meals:</div>
                        <div class="text-15">Need Guide:</div>
                        <div class="text-15">Cost per Person:</div>
                        <div class="text-15">Price:</div>
                        <div class="text-15">Tax (18% GST):</div>
                        <div class="text-15 fw-500">Total Price:</div>
                      </div>
    
                      <div class="col-auto">
                        <div class="text-16">&nbsp;</div>
                        <div class="text-15">${departureDate}</div>
                        <div class="text-15">${vehicleType}</div>
                        <div class="text-15">${numAdults}</div>
                        <div class="text-15">${numChildren}</div>
                        <div class="text-15">${numRooms}</div>
                        <div class="text-15">${childBed === true ? 'Yes' : 'No' }</div>
                        <div class="text-15">${includeMeals === true ? 'Yes' : 'No' }</div>
                        <div class="text-15">${needGuide === true ? 'Yes' : 'No' }</div>
                        <div class="text-15">₹${costPerPerson}</div>
                        <div class="text-15">₹${totalTourPrice}</div>
                        <div class="text-15">₹${tourTax}</div>
                        <div class="text-15 fw-500">₹${totalTourPriceWithTax}</div>
                      </div>
                    </div>
                  `;
        
                  // Append the div element to the desired container
                  document.getElementById('cartItemsContainer').appendChild(divElement);
                });
        
              })
              .catch(error => console.error(error));
            
          }
    
    
    
          // Filter items with category "Hotel"
          const hotelItems = parsedItems.filter(item => item.category === 'hotel');
    
          if (hotelItems.length > 0) {
      
            // Extract the IDs of Hotel items
            const hotelItemIds = hotelItems.map(item => item.id);
        
            // Construct the API URL
            const hotelApiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?id=${hotelItemIds.join(',')}`;
            console.log("hotelApiUrl: ",hotelApiUrl);
            // Fetch data from the API
            fetch(hotelApiUrl)
              .then(response => response.json())
              .then(data => {
                let totalPrice = 0; // Initialize total price to zero
        
                // Process the retrieved data and render the div structure
                data.forEach(item => {
                  // Extract the required values from the item
                  const { id, hotel_image, hotel_image_new, name, category_name, price, city, state } = item;
    
                  console.log("hotelItems: ",hotelItems);
                  console.log("hotelId: ",id);
        
                  // Retrieve the quantity from the cart item
                  const correspondingHotelItem = hotelItems.find(cartItem => cartItem.id === String(id));
    
                  console.log("correspondingHotelItem: ",id);
    
                  console.log("correspondingHotelItem: ", correspondingHotelItem);
                  
                  const quantity = correspondingHotelItem && correspondingHotelItem.quantity ? parseInt(correspondingHotelItem.quantity) : 0;
                  const checkInDate = correspondingHotelItem && correspondingHotelItem.checkInDate ? correspondingHotelItem.checkInDate : 0;
                  const checkOutDate = correspondingHotelItem && correspondingHotelItem.checkOutDate ? correspondingHotelItem.checkOutDate : 0;
                  const numAdults = correspondingHotelItem && correspondingHotelItem.numAdults ? correspondingHotelItem.numAdults : 0;
                  const numChildren = correspondingHotelItem && correspondingHotelItem.numChildren ? correspondingHotelItem.numChildren : 0;
                  const numRooms = correspondingHotelItem && correspondingHotelItem.numRooms ? correspondingHotelItem.numRooms : 0;
                  const roomType = correspondingHotelItem && correspondingHotelItem.roomType ? correspondingHotelItem.roomType : 0;
                  const selectedRoomTypePrice = correspondingHotelItem && correspondingHotelItem.selectedRoomTypePrice ? correspondingHotelItem.selectedRoomTypePrice : 0;
                  const taxAmount = correspondingHotelItem && correspondingHotelItem.taxAmount ? correspondingHotelItem.taxAmount : 0;
                  const convenienceChargeAmount = correspondingHotelItem && correspondingHotelItem.convenienceChargeAmount ? correspondingHotelItem.convenienceChargeAmount : 0;
                  totalHotelPrice = correspondingHotelItem && correspondingHotelItem.totalPrice ? correspondingHotelItem.totalPrice : 0;
                  //const quantity = 1;
                  setOverallHotelPrice(totalHotelPrice);
        
                  console.log('Quantity for item', id, ':', quantity);
        
                  // Calculate the item price (price * quantity)
                  const itemPrice = price * quantity;
        
                  // Add the item price to the total price
                  totalPrice += itemPrice;
        
                  // Check if the item has already been rendered
                  const existingItem = document.getElementById(`cartItem_${id}`);
                  if (existingItem) {
                    return; // Skip rendering if the item is already in the cart
                  }
        
                  // Render the div structure with the retrieved values
                  const divElement = document.createElement('div');
                  divElement.className = 'row x-gap-15 y-gap-10';
                  divElement.id = `cartItem_${id}`;
                  divElement.innerHTML = `
                    <div class="border-top-light mt-20 mb-10"></div>
                    <div class="col-auto">
                      <img src="${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${hotel_image_new}" alt="image" class="size-80 rounded-4 object-cover" />
                    </div>
                    <div class="col">
                      <div class="lh-17 fw-500">${name}</div>
                      <div class="row">
                        <div class="col-6 text-14 lh-15 mt-5">Hotel - ${city ? city : ''}${state ? ', '+state : ''}</div>
                        <div class="col-6 text-14 remove-item mt-5 text-right" data-id="${id}"></div>
                      </div>
                    </div>
                    <div class="row y-gap-20 justify-between items-center">
                      <div class="col-auto">
                        <div class="text-16 fw-500">You've Chosen</div>
                        <div class="text-15">Check-in Date: </div>
                        <div class="text-15">Check-out Date:</div>
                        <div class="text-15">No. of Adults:</div>
                        <div class="text-15">No. of Children:</div>
                        <div class="text-15">No. of Rooms:</div>
                        <div class="text-15">Room Type:</div>
                        <div class="text-15">Sub-Total:</div>
                        <div class="text-15">Tax (18% GST):</div>
                        <div class="text-15">Convenience Fee:</div>
                        <div class="text-15">Total Price:</div>
                      </div>
    
                      <div class="col-auto">
                        <div class="text-16">&nbsp;</div>
                        <div class="text-15">${checkInDate}</div>
                        <div class="text-15">${checkOutDate}</div>
                        <div class="text-15">${numAdults}</div>
                        <div class="text-15">${numChildren}</div>
                        <div class="text-15">${numRooms}</div>
                        <div class="text-15">${roomType}</div>
                        <div class="text-15">${selectedRoomTypePrice}</div>
                        <div class="text-15">₹${taxAmount}</div>
                        <div class="text-15">₹${convenienceChargeAmount}</div>
                        <div class="text-15 fw-500">₹${totalHotelPrice}</div>
                      </div>
                    </div>
                  `;
        
                  // Append the div element to the desired container
                  document.getElementById('cartItemsContainer').appendChild(divElement);
                });
        

              })
              .catch(error => console.error(error));
          
          }
    
    
    
          // Filter items with category "astrology"
          const astrologyItems = parsedItems.filter(item => item.category === 'astrology');
    
          if (astrologyItems.length > 0) {
      
            // Extract the IDs of astrology items
            const astrologyItemIds = astrologyItems.map(item => item.id);
        
            // Construct the API URL
            const astrologyApiUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?id=${astrologyItemIds.join(',')}`;
            console.log("astrologyApiUrl: ",astrologyApiUrl);
            // Fetch data from the API
            fetch(astrologyApiUrl)
              .then(response => response.json())
              .then(data => {
                let totalPrice = 0; // Initialize total price to zero
        
                // Process the retrieved data and render the div structure
                data.forEach(item => {
                  // Extract the required values from the item
                  const { id, thumb_new, name, category_name, price } = item;
    
                  console.log("astrologyItems: ",astrologyItems);
                  console.log("astrologyId: ",id);
        
                  // Retrieve the quantity from the cart item
                  const correspondingastrologyItem = astrologyItems.find(cartItem => cartItem.id === String(id));
    
                  console.log("correspondingastrologyItem: ",id);
    
                  console.log("correspondingastrologyItem: ", correspondingastrologyItem);
                  
                  const astrologyPrice = correspondingastrologyItem && correspondingastrologyItem.price ? parseInt(correspondingastrologyItem.price) : 0;
                  const fullName = correspondingastrologyItem && correspondingastrologyItem.fullName ? correspondingastrologyItem.fullName : 0;
                  const gender = correspondingastrologyItem && correspondingastrologyItem.gender ? correspondingastrologyItem.gender : 0;
                  const dob = correspondingastrologyItem && correspondingastrologyItem.dob ? correspondingastrologyItem.dob : 0;
                  const timeOfBirth = correspondingastrologyItem && correspondingastrologyItem.timeOfBirth ? correspondingastrologyItem.timeOfBirth : 0;
                  const timeOfBirthMin = correspondingastrologyItem && correspondingastrologyItem.timeOfBirthMin ? correspondingastrologyItem.timeOfBirthMin : 0;
                  const placeOfBirth = correspondingastrologyItem && correspondingastrologyItem.placeOfBirth ? correspondingastrologyItem.placeOfBirth : 0;
                  const location = correspondingastrologyItem && correspondingastrologyItem.location ? correspondingastrologyItem.location : 0;
                  const countryOfBirth = correspondingastrologyItem && correspondingastrologyItem.countryOfBirth ? correspondingastrologyItem.countryOfBirth : 0;
                  const maritalStatus = correspondingastrologyItem && correspondingastrologyItem.maritalStatus ? correspondingastrologyItem.maritalStatus : 0;
                  const additionalInfo = correspondingastrologyItem && correspondingastrologyItem.additionalInfo ? correspondingastrologyItem.additionalInfo : 0;
                  const quantity = 1;
        
                  // Calculate the item price (price * quantity)
                  const itemPrice = price * quantity;
    
                  const astrologyTax = itemPrice * 0.05;
                  totalAstrologyPriceWithTax = parseInt(itemPrice) + parseInt(astrologyTax);
    
                  setOverallAstrologyPrice(totalAstrologyPriceWithTax);
                 
                  // Add the item price to the total price
                  totalPrice += itemPrice;
        
                  // Check if the item has already been rendered
                  const existingItem = document.getElementById(`cartItem_${id}`);
                  if (existingItem) {
                    return; // Skip rendering if the item is already in the cart
                  }
        
                  // Render the div structure with the retrieved values
                  const divElement = document.createElement('div');
                  divElement.className = 'row x-gap-15 y-gap-10';
                  divElement.id = `cartItem_${id}`;
                  divElement.innerHTML = `
                    <div class="border-top-light mt-20 mb-10"></div>
                    <div class="col-auto">
                      <img src="${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${thumb_new}" alt="image" class="size-80 rounded-4 object-cover" />
                    </div>
                    <div class="col">
                      <div class="lh-17 fw-500">${name}</div>
                      <div class="row">
                        <div class="col-6 text-14 lh-15 mt-5">Astrology</div>
                        <div class="col-6 text-14 remove-item mt-5 text-right" data-id="${id}"></div>
                      </div>
                    </div>
                    <div class="row y-gap-20 justify-between items-center">
                      <div class="col-auto">
                        <div class="text-16 fw-500">You've Chosen</div>
                        <div class="text-15">Full Name: </div>
                        <div class="text-15">Gender:</div>
                        <div class="text-15">Date of Birth:</div>
                        <div class="text-15">Time of Birth:</div>
                        <div class="text-15">Place of Birth:</div>
                        <div class="text-15">Location:</div>
                        <div class="text-15">Country of Birth:</div>
                        <div class="text-15">Marital Status:</div>
                        <div class="text-15">Price:</div>
                        <div class="text-15">Tax (5% GST):</div>
                        <div class="text-15 fw-500">Total Price:</div>
                      </div>
    
                      <div class="col-auto">
                        <div class="text-16">&nbsp;</div>
                        <div class="text-15">${fullName}</div>
                        <div class="text-15">${gender}</div>
                        <div class="text-15">${dob}</div>
                        <div class="text-15">${timeOfBirth}Hrs ${timeOfBirthMin} mins</div>
                        <div class="text-15">${placeOfBirth}</div>
                        <div class="text-15">${location}</div>
                        <div class="text-15">${countryOfBirth}</div>
                        <div class="text-15">${maritalStatus}</div>
                        <div class="text-15">₹${astrologyPrice}</div>
                        <div class="text-15">₹${astrologyTax}</div>
                        <div class="text-15 fw-500">₹${totalAstrologyPriceWithTax}</div>
                        
    
                      </div>
                    </div>
                  `;
        
                  // Append the div element to the desired container
                  document.getElementById('cartItemsContainer').appendChild(divElement);
                });
        
              })
              .catch(error => console.error(error));
            
          }
    
    
    
          // Filter items with category "homam"
          const homamItems = parsedItems.filter(item => item.category === 'homam');
    
          if (homamItems.length > 0) {
      
            // Extract the IDs of homam items
            const homamItemIds = homamItems.map(item => item.id);
        
            // Construct the API URL
            const homamApiUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?id=${homamItemIds.join(',')}`;
            console.log("homamApiUrl: ",homamApiUrl);
            // Fetch data from the API
            fetch(homamApiUrl)
              .then(response => response.json())
              .then(data => {
                let totalPrice = 0; // Initialize total price to zero
        
                // Process the retrieved data and render the div structure
                data.forEach(item => {
                  // Extract the required values from the item
                  const { id, thumb_new, name, category_name, price } = item;
    
                  console.log("homamItems: ",homamItems);
                  console.log("homamId: ",id);
        
                  // Retrieve the quantity from the cart item
                  const correspondinghomamItem = homamItems.find(cartItem => cartItem.id === String(id));
    
                  console.log("correspondinghomamItem: ",id);
    
                  console.log("correspondinghomamItem: ", correspondinghomamItem);
                  
                  const homamDate = correspondinghomamItem && correspondinghomamItem.homamDate ? parseInt(correspondinghomamItem.homamDate) : 0;
                  const homam_in_dvd = correspondinghomamItem && correspondinghomamItem.homam_in_dvd ? correspondinghomamItem.homam_in_dvd : 0;
                  const additional_info = correspondinghomamItem && correspondinghomamItem.additional_info ? correspondinghomamItem.additional_info : 0;
                  const selectedOption = correspondinghomamItem && correspondinghomamItem.selectedOption ? correspondinghomamItem.selectedOption : 0;
                  const calculatedCost = correspondinghomamItem && correspondinghomamItem.calculatedCost ? correspondinghomamItem.calculatedCost : 0;
                  const homam_for_name_1 = correspondinghomamItem && correspondinghomamItem.homam_for_name_1 ? correspondinghomamItem.homam_for_name_1 : 0;
                  const sel_star_1 = correspondinghomamItem && correspondinghomamItem.sel_star_1 ? correspondinghomamItem.sel_star_1 : 0;
                  const sel_rasi_1 = correspondinghomamItem && correspondinghomamItem.sel_rasi_1 ? correspondinghomamItem.sel_rasi_1 : 0;
                  const homam_for_name_2 = correspondinghomamItem && correspondinghomamItem.homam_for_name_2 ? correspondinghomamItem.homam_for_name_2 : 0;
                  const sel_star_2 = correspondinghomamItem && correspondinghomamItem.sel_star_2 ? correspondinghomamItem.sel_star_2 : 0;
                  const sel_rasi_2 = correspondinghomamItem && correspondinghomamItem.sel_rasi_2 ? correspondinghomamItem.sel_rasi_2 : 0;
                  const homam_for_name_3 = correspondinghomamItem && correspondinghomamItem.homam_for_name_3 ? correspondinghomamItem.homam_for_name_3 : 0;
                  const sel_star_3 = correspondinghomamItem && correspondinghomamItem.sel_star_3 ? correspondinghomamItem.sel_star_3 : 0;
                  const sel_rasi_3 = correspondinghomamItem && correspondinghomamItem.sel_rasi_3 ? correspondinghomamItem.sel_rasi_3 : 0;
                  const homam_for_name_4 = correspondinghomamItem && correspondinghomamItem.homam_for_name_4 ? correspondinghomamItem.homam_for_name_4 : 0;
                  const sel_star_4 = correspondinghomamItem && correspondinghomamItem.sel_star_4 ? correspondinghomamItem.sel_star_4 : 0;
                  const sel_rasi_4 = correspondinghomamItem && correspondinghomamItem.sel_rasi_4 ? correspondinghomamItem.sel_rasi_4 : 0;
                  const homam_for_name_5 = correspondinghomamItem && correspondinghomamItem.homam_for_name_5 ? correspondinghomamItem.homam_for_name_5 : 0;
                  const sel_star_5 = correspondinghomamItem && correspondinghomamItem.sel_star_5 ? correspondinghomamItem.sel_star_5 : 0;
                  const sel_rasi_5 = correspondinghomamItem && correspondinghomamItem.sel_rasi_5 ? correspondinghomamItem.sel_rasi_5 : 0;
                  const quantity = 1;
        
                  // Calculate the item price (price * quantity)
                  const itemPrice = price * quantity;
    
                  const homamTax = itemPrice * 0.05;
                  totalHomamPriceWithTax = parseInt(itemPrice) + parseInt(homamTax);
    
                  setOverallHomamPrice(totalHomamPriceWithTax);
        
                  // Add the item price to the total price
                  totalPrice += itemPrice;
        
                  // Check if the item has already been rendered
                  const existingItem = document.getElementById(`cartItem_${id}`);
                  if (existingItem) {
                    return; // Skip rendering if the item is already in the cart
                  }
        
                  // Render the div structure with the retrieved values
                  const divElement = document.createElement('div');
                  divElement.className = 'row x-gap-15 y-gap-10';
                  divElement.id = `cartItem_${id}`;
                  divElement.innerHTML = `
                    <div class="border-top-light mt-20 mb-10"></div>
                    <div class="col-auto">
                      <img src="${process.env.REACT_APP_API_ENDPOINT}uploads/homam/${thumb_new}" alt="image" class="size-80 rounded-4 object-cover" />
                    </div>
                    <div class="col">
                      <div class="lh-17 fw-500">${name}</div>
                      <div class="row">
                        <div class="col-6 text-14 lh-15 mt-5">homam</div>
                        <div class="col-6 text-14 remove-item mt-5 text-right" data-id="${id}"></div>
                      </div>
                    </div>
                    <div class="row y-gap-20 justify-between items-center">
                      <div class="col-auto">
                        <div class="text-16 fw-500">You've Chosen</div>
                        <div class="text-15">Full Name: </div>
                        <div class="text-13">Nakshatra:</div>
                        <div class="text-13">Rasi:</div>
    
                        ${homam_for_name_2 ? (
                          `<div class="text-15">Person 2 Full Name: </div>
                          <div class="text-13">Person 2 Nakshatra:</div>
                          <div class="text-13">Person 2 Rasi:</div>`) : ''
                        }
    
                        ${homam_for_name_3 ? (
                          `<div class="text-15">Person 3 Full Name: </div>
                          <div class="text-13">Person 3 Nakshatra:</div>
                          <div class="text-13">Person 3 Rasi:</div>`) : ''
                        }
    
                        ${homam_for_name_4 ? (
                          `<div class="text-15">Person 4 Full Name: </div>
                          <div class="text-13">Person 4 Nakshatra:</div>
                          <div class="text-13">Person 4 Rasi:</div>`) : ''
                        }
    
                        ${homam_for_name_5 ? (
                          `<div class="text-15">Person 5 Full Name: </div>
                          <div class="text-13">Person 5 Nakshatra:</div>
                          <div class="text-13">Person 5 Rasi:</div>`) : ''
                        }
      
                          <div class="text-15">Selected Option:</div>
                          <div class="text-15">Homam Date:</div>
                          <div class="text-15">Homam in DVD:</div>
                          <div class="text-15">Price:</div>
                          <div class="text-15">Tax (5% GST):</div>
                          <div class="text-15 fw-500">Total Price:</div>
                        </div>
      
                        <div class="col-auto">
                          <div class="text-16">&nbsp;</div>
                          <div class="text-15">${homam_for_name_1}</div>
                          <div class="text-13">${sel_star_1}</div>
                          <div class="text-13">${sel_rasi_1}</div>
      
                          ${homam_for_name_2 ? (
                            `<div class="text-15">${homam_for_name_2}</div>
                            <div class="text-13">${sel_star_2}</div>
                            <div class="text-13">${sel_rasi_2}</div>`) : ''
                          }
    
                          ${homam_for_name_3 ? (
                            `<div class="text-15">${homam_for_name_3}</div>
                            <div class="text-13">${sel_star_3}</div>
                            <div class="text-13">${sel_rasi_3}</div>`) : ''
                          }
    
                          ${homam_for_name_4 ? (
                            `<div class="text-15">${homam_for_name_4}</div>
                            <div class="text-13">${sel_star_4}</div>
                            <div class="text-13">${sel_rasi_4}</div>`) : ''
                          }
    
                          ${homam_for_name_5 ? (
                            `<div class="text-15">${homam_for_name_5}</div>
                            <div class="text-13">${sel_star_5}</div>
                            <div class="text-13">${sel_rasi_5}</div>`) : ''
                          }
      
                          <div class="text-15">${selectedOption}</div>
                          <div class="text-15">${homamDate}</div>
                          <div class="text-15">${homam_in_dvd === 'on' ? 'Yes' : 'No'}</div>
                          <div class="text-15">₹${calculatedCost}</div>
                          <div class="text-15">₹${homamTax}</div>
                          <div class="text-15 fw-500">₹${totalHomamPriceWithTax}</div>
                      </div>
                    </div>
                  `;
        
                  // Append the div element to the desired container
                  document.getElementById('cartItemsContainer').appendChild(divElement);
                });
        
              })
              .catch(error => console.error(error));
            
          }
    
        } else {
          // Display "Your Cart is Empty!" text if there are no items
          const emptyCartElement = document.createElement('div');
          emptyCartElement.innerText = '';
          document.getElementById('cartItemsContainer').appendChild(emptyCartElement);
        }
      
      }, [orderDetails?.final_cart_items]); 

    
    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Order Details</h1>

                    </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:200}}>
                    <div class="col-12"><p>Order ID: <b>{orderDetails?.order_id}</b></p></div>
                    <div class="col-12"><p>User Name: <b>{orderDetails?.user_name}</b></p></div>
                    <div class="col-12"><p>Email Address: <b>{orderDetails?.email_address}</b></p></div>
                    <div class="col-12"><p>Phone Number: <b>{orderDetails?.user_phone_number}</b></p></div>
                    <div class="col-12"><p>Address: <b>{orderDetails?.address_line_1}</b></p></div>
                    <div class="col-12"><p>City: <b>{orderDetails?.user_city}</b></p></div>
                    <div class="col-12"><p>State: <b>{orderDetails?.user_state}</b></p></div>
                    <div class="col-12"><p>Pin Code: <b>{orderDetails?.user_pin_code}</b></p></div>
                    <div class="col-12"><p>Special requests: <b>{orderDetails?.user_special_requests}</b></p></div>
                    <div class="col-12"><p>Overall Price: <b>₹{orderDetails?.overall_price}</b></p></div>
                    <div class="col-12"><p>Payment Status: <b>{orderDetails?.payment_status}</b></p></div>
                    <div class="col-12"><p>Order Date/Time: <b>{orderDetails?.order_date_time}</b></p></div>
                    <div class="col-12 mt-10"><p>Cart Items: </p></div>



                    <div class="row x-gap-15 y-gap-20 cart_row">
                        <div id="cartItemsContainer"></div>
                        <div class="totals-container border-top-light">
                        {thereIsProduct === 1 ? (
                            <>
                            <div className="total-text">Shipping Price:</div>
                            <div id="totalShippingPrice" className="total-value">
                            ₹{productShippingPrice}
                            </div>
                            </>
                        ) : null}
                        {priceToPay != 0 ? (
                            <>
                            <div class="total-text"><b>Overall Price:</b></div>
                            <div id="priceToPay" class="total-value">₹{priceToPay}</div>
                            </>
                        ) : null}
                        
                        </div>
                    </div>



                    {/*
                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={orderDetails?.user_phone_number || ''}
                            name="user_phone_number_position"
                            onChange={(event) => setOrderDetails(prevState => ({ ...prevState, user_phone_number: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Admin Comment</label>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                            <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                            </a>
                        </div>
                        <div style={{clear:'both'}}></div>
                    </div>
                    */}

                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminOrdersView;