import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminHotelGroupEdit() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];
    
    const { id } = useParams();
    const [hotelGroupData, sethotelGroupData] = useState({});
    const { 
        name
        ,domain
        ,logo
        ,content
    } = hotelGroupData;

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_group/${id}`;
    console.log("hotel_group URL:", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
        .then(response => response.json())
        .then(data => sethotelGroupData(data))
        .catch(error => console.error(error));
    }, []);


    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    

    const handleSaveDetails = () => {
        const updatedhotelGroupData = {
            ...hotelGroupData,
            id
            ,domain
            ,content
        };
        console.log("updatedhotelGroupData: ",JSON.stringify(updatedhotelGroupData));
        const templeUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_hotel_group_update`;
        fetch(templeUpdate1, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedhotelGroupData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };

    // 2nd Tab
    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const cropperRef = useRef();
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setCroppedImage(null);
    };

    const handleCrop = () => {
        if (!cropperRef.current || !cropperRef.current.cropper) {
            setError("Cropper reference not properly defined");
            return;
        }
        const canvas = cropperRef.current.cropper.getCroppedCanvas({ width: 400, height: 300 });
        setCroppedImage(canvas.toDataURL("image/jpeg", 0.8));
    };

    const handleUpload = async () => {
        if (!croppedImage) {
            setError("Please crop an image first");
            return;
        }
        setLoading(true);
        setStatus(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append("image", dataURItoBlob(croppedImage), `${Date.now()}-${selectedFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_hotel_group_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessage("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessage("");
                }, 5000);
                setSelectedFile(null);
                setCroppedImage(null);
                window.location.reload();
            } else {
                setError("There was an error uploading the image");
            }
        } catch (error) {
            setError("There was an error uploading the image");
            setStatus("error");
        }
        setLoading(false);
    };    

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    const handleImageLoad = () => {
        if (cropperRef.current && !cropperRef.current.cropper) {
            const cropper = new Cropper(cropperRef.current, {
                aspectRatio: 4 / 3,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setError(null);
                },
            });
            cropperRef.current.cropper = cropper;
        }
    };

    function handleImageDelete(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=logo&site_category=hotel_group`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(error => {
              console.error(error);
              alert("Failed to delete image");
            });
        }
    }
    

    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">

                    <h1 class="text-30 lh-14 fw-600">Edit Hotel Group</h1>

                </div>

                <div class="col-auto">
                    <a target="_blank" href={`/hotels/group/${id ? id.toLowerCase().replace(/\s+/g, '-') : ''}`} 
                    class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        View Hotel Group Page <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">
                <div class="tabs -underline-2 js-tabs">
                    <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div class="col-auto">
                            <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">1. Content</button>
                        </div>

                        <div class="col-auto">
                            <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-2">2. Images</button>
                        </div>

                    </div>

                    <div class="tabs__content pt-30 js-tabs-content">
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                        <div class="col-xl-10">
                            <div class="text-18 fw-500 mb-10">Hotel Group Name: {id}</div>
                            <div class="row x-gap-20 y-gap-20">

                                    <div className="col-12 mb-50 mt-20">
                                        <div className="fw-500">Content</div>
                                        <div className="form-input" style={{ maxHeight: 300 }}>
                                            <ReactQuill
                                                value={content}
                                                onChange={(value) => sethotelGroupData(prevState => ({...prevState, content: value}))}
                                                modules={{ toolbar: toolbarOptions }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            value={hotelGroupData.domain}
                                            name="name"
                                            onChange={(event) =>
                                                sethotelGroupData((prevState) => ({
                                                ...prevState,
                                                domain: event.target.value,
                                                }))
                                            }
                                            required
                                            />
                                            <label className="lh-1 text-16 text-light-1">Website URL (Optional)</label>
                                        </div>
                                    </div>

                            </div>

                        </div>

                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>

                    </div>

                    <div class="tabs__pane -tab-item-2">
                        <div class="col-xl-12 col-lg-12">
                            
                            <div class="col-12">
                                <div>
                                    <div class="fw-500">Upload New Logo (These would be 400 x 300 px)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChange} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedFile && (
                                        <div>
                                            <img onLoad={handleImageLoad} ref={cropperRef} src={URL.createObjectURL(selectedFile)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCrop}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedImage && (
                                        <div>
                                            <img src={croppedImage} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedImage(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUpload} disabled={loading}>{loading ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessage && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessage}
                                            </div>
                                            )}

                                            {error && <div class="alert alert-danger">{error}</div>}
                                            {status && <div class="alert alert-success">{status}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30 pb-50">
                                    <div class="fw-500">Existing Logo</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">
                                        <div className="col-auto">
                                            <div className="d-flex ratio ratio-1:1 w-200">
                                                {hotelGroupData.logo ? (
                                                    <div>
                                                        <img
                                                            src={`${process.env.REACT_APP_API_ENDPOINT}uploads/hotel_groups/${hotelGroupData.logo}`}
                                                            alt="image"
                                                            className="img-ratio rounded-4"
                                                        />
                                                        <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                            <div
                                                                style={{ cursor: 'pointer' }}
                                                                className="size-40 bg-white rounded-4"
                                                                onClick={() => handleImageDelete(hotelGroupData.id)}
                                                            >
                                                                <i className="icon-trash text-16 image_trash_icon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : 'No Logo Found'}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <AdminFooter />
            </div>
        </div>    
    );
}

export default AdminHotelGroupEdit;