import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminHomeBanners() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    const [selectedGalleryFile, setSelectedGalleryFile] = useState(null);
    const [croppedGalleryImage, setCroppedGalleryImage] = useState(null);
    const [errorGallery, setErrorGallery] = useState(null);
    const [loadingGallery, setLoadingGallery] = useState(false);
    const [statusGallery, setStatusGallery] = useState(null);
    const cropperRefGallery = useRef();
    const [successMessageGallery, setSuccessMessageGallery] = useState("");

    const handleFileChangeGallery = (event) => {
        setSelectedGalleryFile(event.target.files[0]);
        setCroppedGalleryImage(null);
    };

    const handleCropGallery = () => {
        if (!cropperRefGallery.current || !cropperRefGallery.current.cropper) {
            setErrorGallery("Cropper reference not properly defined");
            return;
        }
        const canvasGallery = cropperRefGallery.current.cropper.getCroppedCanvas({ width: 1720, height: 860 });
        setCroppedGalleryImage(canvasGallery.toDataURL("image/jpeg", 0.8));
    };

    const handleUploadGallery = async () => {
        if (!croppedGalleryImage) {
            setErrorGallery("Please crop an image first");
            return;
        }
        setLoadingGallery(true);
        setStatusGallery(null);
        const formData = new FormData();
        //formData.append('id', '1');
        formData.append('image_type', 'homepage');
        formData.append("image", dataURItoBlob(croppedGalleryImage), `${Date.now()}-${selectedGalleryFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_home_banner_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessageGallery("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessageGallery("");
                }, 5000);
                setSelectedGalleryFile(null);
                setCroppedGalleryImage(null);
                window.location.reload();
            } else {
                setErrorGallery("There was an error uploading Gallery image");
            }
        } catch (errorGallery) {
            setErrorGallery("There was an error uploading Gallery image");
            setStatusGallery("errorGallery");
        }
        setLoadingGallery(false);
    };

    const handleImageLoadGallery = () => {
        if (cropperRefGallery.current && !cropperRefGallery.current.cropper) {
            const cropper = new Cropper(cropperRefGallery.current, {
                aspectRatio: 2 / 1,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setErrorGallery(null);
                },
            });
            cropperRefGallery.current.cropper = cropper;
        }
    };

    // Gallery Retrieval
    const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
    const [captionInput, setCaptionInput] = useState('');

    useEffect(() => {
        const imageAPIURLGallery = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?site_category=home_banners&id=1`;
        console.log("imageAPIURLGallery", imageAPIURLGallery);
        fetch(imageAPIURLGallery)
          .then(response => response.json())
          .then(data => setThumbImagesGallery(data))
          .catch(errorGallery => console.errorGallery(errorGallery));
      }, []);
      
      const handleCaptionChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].caption = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleFeatureIdChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].feature_id = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleSaveCaption = (index) => {
        const image = thumbImagesGallery[index];
        const updateCaptionURL = `${process.env.REACT_APP_API_ENDPOINT}update_caption`;
        fetch(updateCaptionURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: image.id,
            caption: image.caption,
            feature_id: image.feature_id,
            cat: 'homepage_banner',
          }),
        })
          .then(response => response.json())
          .then(data => {
            // Handle the response, show success message, update status, etc.
            console.log('Caption saved:', data);
            alert("Caption Saved Successfully!");
          })
          .catch(error => {
            // Handle the error, show error message, update status, etc.
            console.error('Error saving caption:', error);
            alert("There was a problem, contact your dev team!");
          });
      };

    function handleImageDeleteGallery(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=homepage`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(errorGallery => {
              console.errorGallery(errorGallery);
              alert("Failed to delete image");
            });
        }
      } 

    return (
        <div class="dashboard__main">

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Homepage Banners</h1>

                    </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div class="col-xl-12 col-lg-12">
                        <div class="col-12">
                            <div>
                                <div class="fw-500">Upload New Gallery Images (These would be 2:1 ratio, 1720 x 860 px recommended)</div>

                                <div class="row x-gap-20 y-gap-20 pt-15">

                                <div class="col-md-4">
                                    <div class="mb-3">
                                    <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChangeGallery} />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    {selectedGalleryFile && (
                                    <div>
                                        <img onLoad={handleImageLoadGallery} ref={cropperRefGallery} src={URL.createObjectURL(selectedGalleryFile)} alt="Crop preview" />
                                        <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCropGallery}>Crop Image</button>
                                    </div>
                                    )}
                                </div>
                                <div class="col-md-4">
                                    {croppedGalleryImage && (
                                    <div>
                                        <img src={croppedGalleryImage} alt="Cropped image preview" />
                                        <div class="d-grid gap-2">
                                            <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedGalleryImage(null)}>Reset</button>
                                            <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUploadGallery} disabled={loadingGallery}>{loadingGallery ? "Uploading..." : "Upload"}</button>
                                        </div>

                                        {successMessageGallery && (
                                        <div class="alert alert-success" role="alert">
                                            {successMessageGallery}
                                        </div>
                                        )}

                                        {errorGallery && <div class="alert alert-danger">{errorGallery}</div>}
                                        {statusGallery && <div class="alert alert-success">{statusGallery}</div>}
                                    </div>
                                    )}
                                </div>
                                </div>
                            </div>
                            <div class="mt-30">
                                <div class="fw-500">Existing Gallery Images</div>

                                <div class="row x-gap-20 y-gap-20 pt-15">
                                {thumbImagesGallery.length > 0 ? (
                                    thumbImagesGallery.map((image, index) => (
                                        <div className="col-auto" key={index}>
                                        <div className="d-flex ratio ratio-1:1 w-200" style={{ marginBottom: -80 }}>
                                            <img
                                            style={{ height: 'unset' }}
                                            src={`${process.env.REACT_APP_API_ENDPOINT}uploads/home_banners/${image.image_path}`}
                                            alt="image"
                                            className="img-ratio rounded-4"
                                            />
                                            <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                className="size-40 bg-white rounded-4"
                                                onClick={() => handleImageDeleteGallery(image.id)}
                                            >
                                                <i className="icon-trash text-16 image_trash_icon"></i>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            name="caption"
                                            placeholder="Link"
                                            title="Link"
                                            className="ReactTags__tagInputField caption_input"
                                            value={image.caption || ''}
                                            onChange={e => handleCaptionChange(e, index)}
                                            />
                                            <input
                                                type="text"
                                                name="feature_id"
                                                title="Feature ID [Add numerical values 1 to 10]"
                                                className="ReactTags__tagInputField feature_input"
                                                value={image.feature_id || ''}
                                                onChange={e => handleFeatureIdChange(e, index)}
                                            />
                                            <button onClick={() => handleSaveCaption(index)}>
                                                <img className='caption_save' src={`/assets/img/general/save-green.png`} alt='Save' title='Save' />
                                            </button>
                                        </div>
                                        </div>
                                    ))
                                    ) : (
                                    <p>No Images Found</p>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminHomeBanners;