import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';

function AdminEateryCreate() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];


    const [eateryData, setEateryData] = useState({});
    const { 
        title
        ,content
        ,category
        ,city_id
    } = eateryData;

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    

    const handleSaveDetails = () => {
        const updatedEateryData = {
            ...eateryData,
            title
            ,content
            ,category: 'eatery'
            ,city_id
        };
        console.log("updatedEateryData: ",JSON.stringify(updatedEateryData));
        const eateryUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_attractions_eateries_create`;
        fetch(eateryUpdate1, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedEateryData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
                window.location = "/admin/eateries";
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };
    
      
    const [allCities, setAllCities] = useState([]);
    const [cityState, setCityState] = useState({});   

    useEffect(() => {
        const citiesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?cities=all`;
        //console.log("citiesUrl: ", citiesUrl);
        fetch(citiesUrl)
          .then(response => response.json())
          .then(data => setAllCities(data))
          .catch(error => console.log(error));
    }, []);

    const [showOptions, setShowOptions] = useState(false);

    
    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">

                    <h1 class="text-30 lh-14 fw-600">Create New Eatery</h1>

                </div>

                <div class="col-auto">
                    {/*
                    <a target="_blank" href={`/eatery/${title ? title.toLowerCase().replace(/\s+/g, '-') : ''}/${id}`} 
                    class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                        View Page <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                    */}
                </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">

                    <div class="col-xl-10">
                        <div class="text-18 fw-500 mb-10">Tour Eatery Content</div>
                        <div class="row x-gap-20 y-gap-20">

                            <div className="col-12">
                                <div className="form-input">
                                    <input
                                    type="text"
                                    value={eateryData.title}
                                    title="title"
                                    onChange={(event) =>
                                        setEateryData((prevState) => ({
                                        ...prevState,
                                        title: event.target.value,
                                        }))
                                    }
                                    required
                                    />
                                    <label className="lh-1 text-16 text-light-1">Eatery Name</label>
                                </div>
                                </div>

                                <div className="col-12 mb-50">
                                <div className="fw-500">Description</div>
                                <div className="form-input" style={{ maxHeight: 300 }}>
                                    <ReactQuill
                                        value={content}
                                        onChange={(value) => setEateryData(prevState => ({...prevState, content: value}))}
                                        modules={{ toolbar: toolbarOptions }}
                                    />
                                </div>
                                </div>
                                
                                <div className="col-12">
                                <div className="form-input">
                                    <input
                                    type="text"
                                    value={cityState.city}
                                    title="city"
                                    onChange={event => {
                                        const cityValue = event.target.value;
                                        setCityState(prevState => ({
                                        ...prevState,
                                        city: cityValue,
                                        state: ''
                                        }));
                                        setShowOptions(true); // Show options when input value changes
                                    }}
                                    onFocus={() => setShowOptions(true)} // Show options when input is focused
                                    onBlur={() => setShowOptions(false)} // Hide options when input loses focus
                                    required
                                    />

                                    {showOptions && cityState.city && ( // Render options only if showOptions is true and input value is not empty
                                    <ul className="hotel_edit_autocomplete">
                                        {allCities
                                        .filter(city => city.city && cityState.city && city.city.toLowerCase().includes(cityState.city.toLowerCase()))
                                        .map(city => (
                                            <li
                                            key={city.id}
                                            onMouseDown={event => {
                                                event.preventDefault();
                                                const cityStateAPIURL = `${process.env.REACT_APP_API_ENDPOINT}cities?city_id=${city.id}`;
                                                console.log("cityStateAPIURL: ", cityStateAPIURL);
                                                fetch(cityStateAPIURL)
                                                .then(response => response.json())
                                                .then(data => {
                                                    const [cityData] = data; // Extract the first element from the array
                                                    setCityState(cityData || {});
                                                })
                                                .catch(error => console.error(error));
                                                setEateryData(prevState => ({
                                                ...prevState,
                                                city: city.id,
                                                state: city.state
                                                }));
                                                setCityState(prevState => ({
                                                ...prevState,
                                                city: city.city,
                                                state: city.state
                                                }));
                                                setShowOptions(false); // Hide options after selecting an option
                                            }}
                                            >
                                            {`${city.city}, ${city.state}`}
                                            </li>
                                        ))}
                                    </ul>
                                    )}

                                    <label className="lh-1 text-16 text-light-1">City</label>
                                    </div>
                                    </div>

                        </div>

                    </div>

                    <div className='col-md-12'>
                        <div onClick={() => handleSaveDetails()} class="d-inline-block pt-30" style={{float:'right'}}>
                            <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                            </a>
                        </div>
                        <div style={{clear:'both'}}></div>
                    </div>

                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminEateryCreate;