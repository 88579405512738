import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSearch from './HeaderSearch';
import { SubscribeRibbon } from './SubscribeRibbon';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import {Helmet} from "react-helmet";

function Holiday() {

  const { id, title } = useParams();
  const [packageData, setPackageData] = useState({});
  const { 
    name
    ,subtitle
    ,brief
    ,description
    ,featured
    ,image_featured
    ,home_image_featured
    ,image
    ,micro_itinerary
    ,thumb
    ,location
    ,temples
    ,hoteles
    ,cities
    ,days
    ,nights
    ,tourtype
    ,price
    ,highlights
    ,transport_details
    ,includes_excludes
    ,reporting_dropping
    ,cancellation_policy
    ,important_info
    ,special_notes
    ,tourist_attractions
    ,eateries_shopping
    ,parent_id
    ,plan_name
    ,delete_status
   } = packageData;

  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour/${id}`;
  //console.log("api_url", apiUrl);

  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setPackageData(data))
      .catch(error => console.error(error));
  }, []);  

  let refined_url = name ? name.replace(/\s+/g, '-') : '';

  const [parentData, setParentData] = useState([]);

  useEffect(() => {
    if (id !== 0) {
      const parentURL = `${process.env.REACT_APP_API_ENDPOINT}tour/${parent_id === 0 ? id : parent_id}`;
      fetch(parentURL)
        .then(response => response.json())
        .then(data => {
          const updatedData = { ...data, name_url: data.name.replace(/\s+/g, '-') };
          setParentData(updatedData);
        })
        .catch(error => console.error(error));
    }
  }, [id, parent_id]);

  //const cleanedBrief = brief ? brief.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ') : "";
  //const cleanedParentBrief = parentData.brief ? parentData.brief.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ') : "";

  const cleanedBrief = brief ? brief : "";
  const cleanedParentBrief = parentData.brief ? parentData.brief : "";

  const cleanedDescription = description ? description
  .replace(/<p[^>]*>/g, '<p>')
  .replace(/<\/p[^>]*>/g, '</p>')
  .replace(/<(?!\/?(p|br)\b)[^>]+>/g, '')
  .replace(/&nbsp;/g, ' ') : "";


  const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
  useEffect(() => {
    const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=tour`;
    fetch(imageAPIURLGallery)
      .then(response => response.json())
      .then(data => setThumbImagesGallery(data))
      .catch(errorGallery => console.errorGallery(errorGallery));
  }, []);

  
  const [thumbImages, setThumbImages] = useState([]);

  useEffect(() => {
    const imageAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=thumb&site_category=tour`;
    fetch(imageAPIURL)
      .then(response => response.json())
      .then(data => setThumbImages(data))
      .catch(error => console.error(error));
  }, []);

  let oldImage;
  if(image == ''){
    oldImage = 0;    
  } else{
    oldImage = 1;
  }
  //console.log("oldImage: ", oldImage);

  const [highlightsFetch, setHighlightsFetch] = useState([]);

  useEffect(() => {
      const highlightsUrl = `${process.env.REACT_APP_API_ENDPOINT}highlights_list`;
      fetch(highlightsUrl)
        .then(response => response.json())
        .then(data => setHighlightsFetch(data))
        .catch(error => console.log(error));
    }, []);

  const [checkedHighlights, setCheckedHighlights] = useState([]);

  useEffect(() => {
      if (highlights) {
          const highlightIds = highlights.split(',');
          setCheckedHighlights(highlightIds);
      }
  }, [highlights]);


  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    if (hoteles) {
      const hotelsUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?id=${hoteles}`;
      console.log("hotelsUrl: ", hotelsUrl);
      fetch(hotelsUrl)
        .then(response => response.json())
        .then(data => setHotels(data))
        .catch(error => console.error(error));
    }
  }, [hoteles]);

  
  const [hotelImages, setHotelImages] = useState([]);

  useEffect(() => {
    if (hoteles) {
      const hotelImagesUrl = `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${hoteles}&image_type=gallery&site_category=hotel`;
      console.log("hotelsUrl: ", hotelImagesUrl);
      fetch(hotelImagesUrl)
        .then(response => response.json())
        .then(data => setHotelImages(data))
        .catch(error => console.error(error));
    }
  }, [hoteles]);
  
  console.log("temples: ", temples);

  const [templesList, setTemplesList] = useState([]);
  const [attractionsList, setAttractionsList] = useState([]);
  const [eateriesList, setEateriesList] = useState([]);

  useEffect(() => {

    const templesUrl = cities ? `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${cities}&item=temples&neglect=0` : '';
      console.log("templesUrl: ", templesUrl);
      const getTemplesList = async () => {
        try {
          const response = await fetch(templesUrl);
          const data = await response.json();
          setTemplesList(data);
        } catch (error) {
          console.error(error);
        }
    };

    const attractionsUrl = cities ? `${process.env.REACT_APP_API_ENDPOINT}attractions_eateries?page=1&cat=attraction&city_id=${cities}` : '';
      console.log("attractionsUrl: ", attractionsUrl);
      const getAttractionsList = async () => {
        try {
          const response = await fetch(attractionsUrl);
          const data = await response.json();
          setAttractionsList(data);
        } catch (error) {
          console.error(error);
        }
    };
    
    const eateriesUrl = cities ? `${process.env.REACT_APP_API_ENDPOINT}attractions_eateries?page=1&cat=eatery&city_id=${cities}` : '';
    console.log("eateriesUrl: ", eateriesUrl);
    const getEateriesList = async () => {
      try {
        const response = await fetch(eateriesUrl);
        const data = await response.json();
        setEateriesList(data);
      } catch (error) {
        console.error(error);
      }
    };

    getTemplesList();
    getAttractionsList();
    getEateriesList();
  }, [
    parentData.temples,
    parent_id,
    temples, 
    tourist_attractions, 
    eateries_shopping]);

    //console.log("parent_id: ", parent_id);
    const [tourPlans, setTourPlans] = useState([]);
    useEffect(() => {
      if (parent_id !== undefined) {
        const plansUrl = `${process.env.REACT_APP_API_ENDPOINT}plans/${parent_id === 0 || parent_id === null ? id : parent_id}`;
        console.log("plansUrl: ", plansUrl);
        fetch(plansUrl)
          .then(response => response.json())
          .then(data => setTourPlans(data))
          .catch(error => console.error(error));
      }
    }, [parent_id, id]);

    //console.log("tourPlans: ", tourPlans);


    const [open, setOpen] = React.useState(false);

  


const [departureDate, setDepartureDate] = useState("");
const [numAdults, setNumAdults] = useState(2);
const [numChildren, setNumChildren] = useState(0);
const [numRooms, setNumRooms] = useState(1);
const [includeMeals, setIncludeMeals] = useState(false);
const [childBed, setchildBed] = useState(false);
const [needGuide, setNeedGuide] = useState(false);
const [costPerPerson, setCostPerPerson] = useState(0);
const [overallCost, setOverallCost] = useState(0);

const [vehicleType, setVehicleType] = useState("Sedan");
const [showPopup, setShowPopup] = useState(false);
const [selectedVehicle, setSelectedVehicle] = useState({
  name: "Sedan",
  image: "sedan.png",
});
console.log("costPerPerson", costPerPerson);
const vehicles = [
  { name: "Sedan", image: "sedan.png" },
  { name: "Ertiga", image: "ertiga.png" },
  { name: "Innova", image: "innova.png" },  
  { name: "Innova Crysta", image: "innova-crysta.png" },  
  { name: "Tempo", image: "tempo.png" },  
  { name: "Coach", image: "bus.png" },
];

console.log("plan_name: ", plan_name);

const [disableRoomsInput, setDisableRoomsInput] = useState(false);

const calculateCost = () => {

  // Check if id and parent_id are set
  if (id || parent_id) {
    const costingURL = `${process.env.REACT_APP_API_ENDPOINT}tour_cost?adults=${numAdults}&children=${numChildren}&rooms=${numRooms}&room_type=${plan_name}&vehicle_type=${vehicleType}&meals=${includeMeals ? 1 : 0}&need_guide=${needGuide ? 1 : 0}&extra_bed=${childBed ? 1 : 0}&supplement_vehicle=0&page_id=${parent_id === 0 ? id : parent_id}`;
    
    console.log("costingURL", costingURL);

    fetch(costingURL)
      .then((response) => response.json())
      .then((data) => {
        setCostPerPerson(Math.floor(data.cost_per_person).toLocaleString('en-IN'));
        setOverallCost(Math.floor(data.overall_cost).toLocaleString('en-IN'));
        // Check if the response is in the second pattern
        console.log("data.rooms", data.rooms);
        if (data.rooms === undefined) {
            setDisableRoomsInput(true);
        } else {
            setDisableRoomsInput(false);
        }
      })
      .catch((error) => console.error(error));
  } else {
    const costingURL = `${process.env.REACT_APP_API_ENDPOINT}tour_cost?adults=${numAdults}&children=${numChildren}&rooms=${numRooms}&room_type=Semi Deluxe&vehicle_type=${vehicleType}&meals=${includeMeals ? 1 : 0}&need_guide=${needGuide ? 1 : 0}&extra_bed=${childBed ? 1 : 0}&supplement_vehicle=0&page_id=${id}`;
    
    console.log("costingURL", costingURL);

    fetch(costingURL)
      .then((response) => response.json())
      .then((data) => {
        setCostPerPerson(Math.floor(data.cost_per_person).toLocaleString('en-IN'));
        setOverallCost(Math.floor(data.overall_cost).toLocaleString('en-IN'));
        // Check if the response is in the second pattern
        console.log("data.rooms", data.rooms);
        if (data.rooms === undefined) {
            setDisableRoomsInput(true);
        } else {
            setDisableRoomsInput(false);
        }
      })
      .catch((error) => console.error(error));
  }
};

useEffect(() => {
  calculateCost();
}, [numAdults, numChildren, numRooms, includeMeals, childBed, needGuide, vehicleType, plan_name, id, parent_id]);

console.log("numAdults", numAdults);
const handleNumAdultsChange = (e) => {
  const value = parseInt(e.target.value);
  if (!isNaN(value) && value >= 1) {
    setNumAdults(value);

    // Dynamically calculate the number of rooms based on the number of adults
    let calculatedNumRooms = 1;

    if (value > 3 && value <= 6) {
      calculatedNumRooms = 2;
    } else if (value > 6 && value <= 9) {
      calculatedNumRooms = 3;
    } else if (value > 9 && value <= 12) {
      calculatedNumRooms = 4;
    } else if (value > 12 && value <= 15) {
      calculatedNumRooms = 5;
    } else if (value > 15 && value <= 18) {
      calculatedNumRooms = 6;
    } else if (value > 18 && value <= 21) {
      calculatedNumRooms = 7;
    } else if (value > 21 && value <= 24) {
      calculatedNumRooms = 8;
    } else if (value > 24 && value <= 27) {
      calculatedNumRooms = 9;
    } else if (value > 27) {
      calculatedNumRooms = 10;
    }
    console.log("value: ", value);
    setNumRooms(calculatedNumRooms);
  }
};

const handleNumChildrenChange = (e) => {
  const value = parseInt(e.target.value);
  if (!isNaN(value) && value >= 0) {
    setNumChildren(value);
  }
};

const handleNumRoomsChange = (e) => {
  const value = parseInt(e.target.value);
  if (!isNaN(value) && value >= 1) {
    setNumRooms(value);
  }
};

const handleIncludeMealsChange = (e) => {
  setIncludeMeals(e.target.checked);
};

const handleChildBedChange = (e) => {
  setchildBed(e.target.checked);
};

const handleNeedGuideChange = (e) => {
  setNeedGuide(e.target.checked);
};

const handleDateChange = (e) => {
  setDepartureDate(e.target.value);
};

const handleVehicleSelect = (vehicle) => {
  setSelectedVehicle(vehicle);
  setShowPopup(false);
  setVehicleType(vehicle.name);
};

useEffect(() => {
  // Function to determine the appropriate vehicle type based on numAdults
  const calculateVehicleType = () => {
    if (numAdults <= 3) {
      return "Sedan";
    } else if (numAdults >= 4 && numAdults <= 6) {
      return "Innova";
    } else if (numAdults >= 7 && numAdults <= 17) {
      return "Tempo";
    } else {
      return "Coach";
    }
  };

  // Update vehicleType and selectedVehicle based on the numAdults value
  const newVehicleType = calculateVehicleType();
  setVehicleType(newVehicleType);

  // Find the corresponding vehicle from the vehicles array
  const newSelectedVehicle = vehicles.find((vehicle) => vehicle.name === newVehicleType);
  if (newSelectedVehicle) {
    setSelectedVehicle(newSelectedVehicle);
  }
}, [numAdults]);

const handleCheckout = () => {
  const departureDateI = document.getElementById('departureDate');
  const departureDate = departureDateI.value;

  const selectedDate = new Date(departureDate);
  const formattedDate = `${selectedDate.getDate()}-${selectedDate.getMonth() + 1}-${selectedDate.getFullYear()}`;

  const newItem = {
      id: id,
      departureDate: formattedDate,
      vehicleType: vehicleType,
      category: 'tour',
      numAdults: numAdults,
      numChildren: numChildren,
      numRooms: numRooms,
      childBed: childBed,
      includeMeals: includeMeals,
      needGuide: needGuide,
      costPerPerson: costPerPerson,
      price: overallCost
  };

  // Retrieve existing cart items from local storage or initialize an empty array
  const existingItems = JSON.parse(localStorage.getItem('cartItems')) || [];

  // Add the new item to the existing array of cart items
  const updatedItems = [...existingItems, newItem];

  // Store the updated array back into local storage
  localStorage.setItem('cartItems', JSON.stringify(updatedItems));

  window.location.href = "/cart";
};

      
const [showTooltip, setShowTooltip] = useState(false);

const toggleTooltip = () => {
  setShowTooltip(!showTooltip);
};

let parentCategory = 'Semi-Deluxe';
if(parentData.plan_name == ''){
  parentCategory = 'Semi-Deluxe';
} else if(parentData.plan_name != ''){
  parentCategory = parentData.plan_name;
}

// Page Title & Meta Description
useEffect(() => {
  let title = '';
  if (parentData.name) {
    title = parentData.name;
  } else {
    title = name;
  }
  document.title = title + ' | Blessingsonthenet.com';

}, [parentData.name, name]);

useEffect(() => {
  if(parentData.brief && brief){
    const metaDescriptionTag = document.createElement('meta');
    metaDescriptionTag.name = 'description';

    if (parentData.brief) {
      metaDescriptionTag.content = parentData.brief;
    } else {
      metaDescriptionTag.content = brief;
    }

    document.head.appendChild(metaDescriptionTag);
  }
}, [parentData.brief, brief]);
// Page Title & Meta Description End

const [formData, setFormData] = useState({
  fullName: '',
  email: '',
  mobileNumber: '',
  noOfPersons: '',
  hotelType: '',
  startDate: '',
  endDate: '',
  message: '',
  graduationDay: ''
});
const [isSubmitted, setIsSubmitted] = useState(false);

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};

const handleEnquirySubmit = (e) => {
  e.preventDefault();

  if (formData.graduationDay !== '') {
    // A value is present in the honeypot field, likely a bot
    return;
  }

  if (
    formData.fullName.trim() === '' ||
    formData.email.trim() === '' ||
    formData.mobileNumber.trim() === '' ||
    formData.noOfPersons.trim() === ''
  ) {
    alert('Please fill in all required fields.');
  } else {
    // Prepare the form data for submission
    const data = {
      fullName: formData.fullName,
      email: formData.email,
      mobileNumber: formData.mobileNumber,
      noOfPersons: formData.noOfPersons,
      hotelType: formData.hotelType,
      startDate: formData.startDate,
      endDate: formData.endDate,
      message: formData.message,
      website: 'Blessingsonthenet.com',
      page_title: parentData.name ? parentData.name : name
    };

    console.log("enquiry data: ", data);

    // Replace 'your-api-endpoint' with your actual API endpoint
    fetch(`${process.env.REACT_APP_API_ENDPOINT}enquiry_submission`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // Handle a successful response here
          setIsSubmitted(true);
        } else {
          // Handle errors here
          console.error('Form submission failed');
        }
      })
      .catch((error) => {
        console.error('Error submitting the form:', error);
      });
  }
};

const [nearbyTours, setnearbyTours] = useState([]);
useEffect(() => {
    const nearbyToursUrl = `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${cities}&item=tours&neglect=0`;
    console.log("nearbyToursUrl: ", nearbyToursUrl);
    fetch(nearbyToursUrl)
      .then(response => response.json())
      .then(data => setnearbyTours(data))
      .catch(error => console.error(error));
}, [id, cities]);

// Calculate the minimum selectable date (2 days from today)
const checkInToday = new Date();
checkInToday.setDate(checkInToday.getDate() + 2);
const minCheckInDate = checkInToday.toISOString().split('T')[0];

const checkOutToday = new Date();
checkOutToday.setDate(checkOutToday.getDate() + 3);
const minCheckOutDate = checkOutToday.toISOString().split('T')[0];

//Popup Form
const [showPopupForm, setshowPopupForm] = useState(false);

useEffect(() => {
  const timeout = setTimeout(() => {
    // Check if the popup was previously closed within the last 24 hours
    const popupClosedTime = localStorage.getItem('popupClosedTime');
    if (popupClosedTime) {
      const now = new Date().getTime();
      const elapsedTime = now - parseInt(popupClosedTime);
      if (elapsedTime >= 12 * 60 * 60 * 1000) {
        // If more than 12 hours have passed since the popup was closed, show it again
        setshowPopupForm(true);
      }
    } else {
      // If the popup has not been closed before, show it after 20 seconds
      setshowPopupForm(true);
    }
  }, 20000);

  return () => clearTimeout(timeout);
}, []);

const handleClosePopup = () => {
  setshowPopupForm(false);
  // Store in local storage that the user has closed the popup
  localStorage.setItem('popupClosed', 'true');
  // Store the time when the popup was closed
  const now = new Date().getTime();
  localStorage.setItem('popupClosedTime', now.toString());
};

function openPopup() {
  setshowPopupForm(true);
}

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${parentData?.name ? parentData?.name : name} Tour Package | Blessingsonthenet.com`}</title>
    </Helmet> 

    {showPopupForm && (
        <div className="position-fixed top-0 bottom-0 d-flex justify-content-center align-items-center w-75 popup_form">
          <div className="px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Get the Best Holiday Planned by Our Experts!</div>
                <small>Fill your details below and we'll call you in a while.</small>
                <button type="button" className="close popup_form_close" aria-label="Close" onClick={handleClosePopup}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="row y-gap-15 pt-20">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="noOfPersons"
                        value={formData.noOfPersons}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">No. of Person*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input" style={{minHeight:'70px'}}>
                    <label className="lh-1 text-16 text-light-1" style={{top:'12px'}}>Hotel Type</label>
                      <select
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius:'4px',
                          padding: '0 15px',
                          paddingTop:'25px'}}
                        name="hotelType"
                        value={formData.hotelType}
                        onChange={handleChange}
                      >
                      <option value="3 Star">3 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="5 Star">5 Star</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        min={minCheckInDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        min={minCheckOutDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="1"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Message (If Any)</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Get a Callback
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
        </div>
    )}

    <div class="floating-section">
        <div class="floating-option">
          <a style={{cursor:'pointer'}} onClick={openPopup}>
            <img src={`/assets/img/general/telephone.png`} />
            Get a Callback
          </a>
        </div>
        <div class="floating-option">
            <a href="https://wa.me/919892002006" target="_blank" rel="noopener noreferrer">
              <img src={`/assets/img/general/whatsapp.png`} />
              Chat on WhatsApp
            </a>
        </div>
    </div>

    <div class="mt-35">
      <HeaderSearch />

      <section class="py-10 d-flex items-center bg-light-2">
        <div class="container">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div class="col-auto">
                  <div class="">Home</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="">Holidays</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="text-dark-1">{parentData.name ? parentData.name : name}</div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              {/*<a href="#" class="text-14 text-blue-1 underline">View Packages Involving Dwarka</a>*/}
            </div>
          </div>
        </div>
      </section>

      <section class="pt-40">
        <div class="container">
          <div class="row y-gap-20 justify-between items-end">
            <div class="col-auto">
              <div class="row x-gap-20  items-center">
                <div class="col-auto">
                  <h1 class="text-30 sm:text-25 fw-600" style={{maxWidth:750,}}>{parentData.name ? parentData.name : name} - {plan_name == '' ? 'Semi Deluxe' : plan_name} Plan</h1>
                </div>
              </div>

              <div class="row x-gap-20 y-gap-20 items-center">
                <div class="col-auto">
                  <div class="d-flex items-center text-15 text-light-1">
                    {parentData.days ? parentData.days : days} Days {parentData.nights ? parentData.nights : nights} Nights Trip
                  </div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="row x-gap-15 y-gap-15 items-center">
                <div class="col-auto">
                  <div class="text-14">
                  {price !== null && price !== '0' && costPerPerson ? (
                    <>
                      {plan_name === '' ? 'Semi Deluxe' : plan_name} Plan Starts From{' '}
                      <span className="text-22 text-dark-1 fw-500">₹{price || costPerPerson}</span>
                    </>
                  ) : (
                    'Contact Us for Pricing'
                  )}
                  </div>
                </div>

                <div class="col-auto">

                  <div class="select js-select js-liveSearch" data-select-value="digital &amp; creative">
                    <button style={{height: '50px',backgroundColor:'#3554D1', color:'#fff'}} class="select__button js-button">
                      <span style={{fontWeight: '500'}} class="js-button-title">Choose Other Plans</span>
                      <i class="icon-chevron-sm-down text-7 ml-15"></i>
                    </button>
      
                    <div class="select__dropdown js-dropdown">
                      <input type="text" placeholder="Search" style={{display:'none'}} class="select__search js-search" />
      
                      <div class="select__options js-options">
                      {id !== 0 && (
                        <a href={`/travel-india/tour-package/id/${parentData.id ? parentData.id : id}/${parentData.name_url ? parentData.name_url : refined_url}`} class="select__options__button">{parentCategory}</a>
                      )}
                      {tourPlans.length > 0 && tourPlans.map(page => (
                        <a href={`/travel-india/tour-package/id/${page.id}/${parentData.name_url === undefined ? name.replace(/\s+/g, '-') : parentData.name_url}-${page.plan_name.replace(/\s+/g, '-')}`} class="select__options__button">{page.plan_name}</a>
                      ))}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="galleryGrid -type-1 pt-30">
            {/*
            {oldImage == 1 ? (
                    <div className="galleryGrid__item">
                        <img
                        style={{height:'unset'}}
                        src={`/img/uploads/tour/${image}`}
                        alt="image"
                        className="rounded-4"
                        />
                    </div>
                ) : (
                null
            )}
            */}
            {thumbImagesGallery.length > 0 ? (
              thumbImagesGallery.slice(0, 4).map((image, index) => (
                <div className="galleryGrid__item" key={index}>
                  <img
                    src={`${process.env.REACT_APP_API_ENDPOINT}uploads/${image.image_path}`}
                    alt="image"
                    className="rounded-4"
                    onClick={() => setOpen(true)}
                  />
                </div>
              ))
            ) : (
              //Showing hotel images if no gallery images exits
              hotelImages.slice(0, 4).map((image, index) => (
                <div className="galleryGrid__item" key={index}>
                  <img
                    src={image.hotel_image.includes('aim_')
                    ? `/img/uploads/hotels/roomgallery/${image.hotel_image}`
                    : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`}
                    alt="image"
                    className="rounded-4"
                    onClick={() => setOpen(true)}
                  />
                </div>
              ))
            )}

            {hotelImages.length > 0 ? (
                hotelImages.slice(0, 1).map((image, index) => (
                    <div className="galleryGrid__item relative d-flex" key={index}>
                        <img
                          src={image.hotel_image.includes('aim_')
                          ? `/img/uploads/hotels/roomgallery/${image.hotel_image}`
                          : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`}
                          alt="image"
                          className="rounded-4"
                          onClick={() => setOpen(true)}
                        />
                        <div class="absolute px-10 py-10 col-12 h-full d-flex justify-end items-end">
                        <button type="button" onClick={() => setOpen(true)} className="button -blue-1 px-24 py-15 bg-white text-dark-1 js-gallery">
                          See All Photos
                        </button>
                        <Lightbox
                            plugins={[Captions]}
                            open={open}
                            close={() => setOpen(false)}
                            slides={[
                              ...thumbImagesGallery.map((image) => ({
                                src: `${process.env.REACT_APP_API_ENDPOINT}uploads/${image.image_path}`,
                                description: image.caption
                              })),
                              ...hotelImages.map((image) => ({
                                src: image.hotel_image.includes('aim_')
                                ? `/img/uploads/hotels/roomgallery/${image.hotel_image}`
                                : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`,
                                description: image.name
                              }))
                            ]}
                          />
                        </div>
                    </div>
                ))
                ) : (
                null
            )}
          </div>
        </div>
      </section>

      <section class="pt-30">
        <div class="container">
          <div class="row y-gap-30">
            <div class="col-xl-8">
              <div class="row y-gap-40 dark_text">
                <div class="col-12">
                  <h3 class="text-22 fw-500">Package highlights</h3>
                  <div class="row y-gap-20 pt-30">

                  {highlightsFetch.map(item => (
                    checkedHighlights.includes(item.id.toString()) && (
                      <div className="col-lg-2 col-6" key={item.id}>
                        <div className="text-center">
                          <i className={`${item.css_class} text-24 text-blue-1`}></i>
                          <div className="text-15 lh-1 mt-10">{item.highlight_name}</div>
                        </div>
                      </div>
                    )
                  ))}

                  </div>
                </div>

                <div id="overview" class="col-12">
                  <h3 class="text-22 fw-500 pt-40 border-top-light">Overview</h3>
                  <div class="text-dark-1 text-15 mt-20" dangerouslySetInnerHTML={{ __html: parent_id == 0 || parent_id === null ? cleanedBrief : cleanedParentBrief }} />
                  {/*
                  <p class="text-dark-1 text-15 mt-20">
                    {parent_id == 0 || parent_id === null ? cleanedBrief : cleanedParentBrief}
                  </p>
                  */}
                  <br />
                  <p><b style={{color:'red'}}>Please Note:</b> This is not a group tour, This is an exclusive tour by car or bigger vehicle as per number of your family member travelling</p>
                  {/*<a href="#" class="d-block text-14 text-blue-1 fw-500 underline mt-10">Show More</a>*/}
                </div>

                <div class="col-12">
                  <h3 class="text-22 fw-500 pt-40 border-top-light">Itinerary</h3><br />
                  {/*<div dangerouslySetInnerHTML={{ __html: cleanedDescription }} />*/}
                  {parent_id == 0 || parent_id === null ? (<div dangerouslySetInnerHTML={{ __html: description }} />) : (<div dangerouslySetInnerHTML={{ __html: parentData.description }} />)}
                  
                </div>


              </div>
            </div>

            <div class="col-xl-4">
              <div class="ml-50 lg:ml-0">
                <div class="px-30 pt-10 pb-20 border-light rounded-4 shadow-4">
                  <div class="d-flex items-center justify-between">
                    <div class="">
                      <span class="text-20 fw-500">Book Now</span>
                    </div>
                  </div>
                  
                  {costPerPerson != 0 || overallCost != 0 ? (
                  <div class="row y-gap-20 pt-10">
                    
                        <div class="col-12">

                          <div class="px-20 py-10 border-light rounded-4 -right">

                            <div>
                              <h4 class="text-15 fw-500 ls-2 lh-16">Choose Departure Date</h4>

                              <div class="text-15 text-light-1 ls-2 lh-16">
                                <span class="js-first-date"><input type="date" id="departureDate" min={minCheckInDate} value={departureDate} onChange={handleDateChange} /></span>
                              </div>
                            </div>


                          </div>

                        </div>

                        <div class="col-12" style={{marginTop:'-20px'}}>

                        <div className={`row px-20 py-10 border-light rounded-4 mt-20 room_input`}>
                          <div className='col-4'>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Adults</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span><input type="number" id="numAdults" value={isNaN(numAdults) ? '' : numAdults} min={1} onChange={handleNumAdultsChange} /></span>
                            </div>
                          </div>

                          <div className='col-4'>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Children</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span><input type="number" id="numChildren" value={isNaN(numChildren) ? '' : numChildren} min={0} onChange={handleNumChildrenChange} /></span>
                            </div>
                          </div>

                          <div className='col-4'>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Rooms</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span><input type="number" id="numRooms" value={isNaN(numRooms) ? '' : numRooms} min={1} onChange={handleNumRoomsChange} disabled={disableRoomsInput} /></span>
                            </div>
                          </div>
                          {numChildren > 0 ? (
                            <div style={{ marginTop: 10 }}>
                              <input className="room_checkbox" type="checkbox" id="childBed" checked={childBed} onChange={handleChildBedChange} />
                              <label htmlFor="childBed">Need extra bed for child</label>
                            </div>
                          ) : null}
                          <div className={`tooltip_booking ${showTooltip ? 'tooltip-disabled' : 'tooltip-active'}`}>
                            <button className="close-button" onClick={toggleTooltip}>
                              x
                            </button>
                            <p><b>Important Notes:</b></p>
                            <ul>
                              <li>This is not a group tour, This is an exclusive tour by car or bigger vehicle as per number of your family member travelling</li>
                              <li>Kids below 5 years old are complimentary</li>
                              <li>Kids between 6 to 10 years will be considered as children</li>
                              <li>Kids more than 10 years old will be considered as adults</li>
                            </ul>
                          </div>
                        </div>

                          <div className="row px-20 pt-10 border-light rounded-4 mt-20 room_input">
                            <div>
                              <h4 className="text-15 fw-500 ls-2 lh-16">
                                Vehicle Type: {vehicleType}
                              </h4>
                              <p
                                className="vehicle_change_text"
                                onClick={() => setShowPopup(true)}
                                style={{cursor:'pointer'}}
                              >
                                Change
                              </p>
                              <img src={`/assets/img/general/${selectedVehicle.image}`} />
                            </div>

                            {showPopup && (
                              <div className="vehicle-selector-popup">
                                <h5 style={{color: 'rgb(53, 84, 209)'}}>Other Vehicle Options</h5>
                                {vehicles.map((vehicle) => (
                                  <div
                                    className="vehicle-option"
                                    key={vehicle.name}
                                    onClick={() => handleVehicleSelect(vehicle)}
                                  >
                                    <p>{vehicle.name}</p>
                                    <img style={{cursor:'pointer'}} src={`/assets/img/general/${vehicle.image}`} />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <div class="px-20 py-10 border-light rounded-4 mt-20">
                            <div>
                              <input className="room_checkbox" type="checkbox" id="includeMeals" checked={includeMeals} onChange={handleIncludeMealsChange} />
                              <label htmlFor="includeMeals">Include Dinner</label>
                            </div>

                            <div>
                              <input className="room_checkbox" type="checkbox" id="needGuide" checked={needGuide} onChange={handleNeedGuideChange} />
                              <label htmlFor="needGuide">I need a guide</label>
                            </div>
                          </div>

                          <div className="text-center mt-10">
                            <h5>₹{costPerPerson} <span style={{fontSize:14}}>per person</span></h5>
                          </div>
                          <div className="text-center mt-10">
                            <h5>₹{overallCost} <span style={{fontSize:14}}>Total Cost</span></h5>
                          </div>
                          

                        </div>

                        <div class="col-12">
                          <button type="submit" class="button -dark-1 px-35 h-60 col-12 bg-blue-1 text-white" onClick={handleCheckout}>
                            Checkout
                          </button>
                        </div>

                        <div class="col-12">
                          <button class="button -blue-1 px-35 h-60 col-12 -outline-blue-1 text-blue-1">Customize Package</button>
                        </div>

                  </div>
                  ) : (<div className="text-center">
                    <br />
                    <h6 style={{color:'#FF0000'}}>Contact for Immediate Response and Customization</h6>
                    <br />
                    {/*
                    <a href="https://wa.me/919892002006">+91 9892 002 006</a><br />
                    <a href="https://wa.me/919224444455">+91 9224 444 455</a><br />
                    <a href="https://wa.me/919987404440">+91 9987 404 440</a>
                    */}
                    <a href="https://wa.me/919892002006">+91 9892 002 006</a><br />
                    <a href="https://wa.me/919224444455">+91 9224 444 455</a><br />
                    <a href="https://wa.me/919987404440">+91 9987 404 440</a>
                    <br /><br />
                    <h6 style={{color:'#FF0000'}}>Send Us Email</h6>
                    <a href="mailto:travel@blessingsonthenet.com">travel@blessingsonthenet.com</a><br /><br />
                    </div>)
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="rooms" class="pt-50">
        <div class="container">
          <div class="row pb-20">
            <div class="col-auto">
              <h3 class="text-22 fw-500">Package Details</h3>
            </div>
          </div>

          <div class="tabs -pills-3 pt-10 js-tabs">
            <div class="tabs__controls row x-gap-10 y-gap-10 js-tabs-controls">

              <div class="col-auto">
                <button class="tabs__button text-14 fw-500 px-20 py-10 rounded-4 bg-light-2 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">Hotel Details</button>
              </div>

              <div class="col-auto">
                <button class="tabs__button text-14 fw-500 px-20 py-10 rounded-4 bg-light-2 js-tabs-button" data-tab-target=".-tab-item-2">Transport Details</button>
              </div>

              <div class="col-auto">
                <button class="tabs__button text-14 fw-500 px-20 py-10 rounded-4 bg-light-2 js-tabs-button" data-tab-target=".-tab-item-3">Cost Includes/Excludes</button>
              </div>

              <div class="col-auto">
                <button class="tabs__button text-14 fw-500 px-20 py-10 rounded-4 bg-light-2 js-tabs-button" data-tab-target=".-tab-item-4">Reporting & Dropping</button>
              </div>

              <div class="col-auto">
                <button class="tabs__button text-14 fw-500 px-20 py-10 rounded-4 bg-light-2 js-tabs-button" data-tab-target=".-tab-item-5">Cancellation Policy</button>
              </div>

              <div class="col-auto">
                <button class="tabs__button text-14 fw-500 px-20 py-10 rounded-4 bg-light-2 js-tabs-button" data-tab-target=".-tab-item-6">Important Info</button>
              </div>

            </div>

            <div class="tabs__content pt-15 js-tabs-content border-light rounded-4 px-30 py-10 sm:px-20 sm:py-20">

              <div class="tabs__pane -tab-item-1 is-tab-el-active">
                <p class="text-15 text-dark-1">
                  <table class="table-4 w-1/1 mt-30">
                    <thead class="bg-light-2">
                      <tr>
                        <th>City</th>
                        <th>Hotel Choices</th>
                      </tr>
                    </thead>
                    <tbody>
                    {hotels && hotels.map((hotel) => (
                      <tr key={hotel.id}>
                        <td><b>{hotel.city}</b></td>
                        <td>{hotel.name}</td>
                      </tr>
                    ))}
                      
                    </tbody>
                  </table>
                </p>
              </div>

              {parent_id == 0 || parent_id === null ? (<div className="tabs__pane -tab-item-2" dangerouslySetInnerHTML={{ __html: transport_details }} />) : (<div className="tabs__pane -tab-item-2" dangerouslySetInnerHTML={{ __html: parentData.transport_details }} />)}

              {parent_id == 0 || parent_id === null ? (<div className="tabs__pane -tab-item-3" dangerouslySetInnerHTML={{ __html: includes_excludes }} />) : (<div className="tabs__pane -tab-item-3" dangerouslySetInnerHTML={{ __html: parentData.includes_excludes }} />)}

              {parent_id == 0 || parent_id === null ? (<div className="tabs__pane -tab-item-4" dangerouslySetInnerHTML={{ __html: reporting_dropping }} />) : (<div className="tabs__pane -tab-item-4" dangerouslySetInnerHTML={{ __html: parentData.reporting_dropping }} />)}

              {parent_id == 0 || parent_id === null ? (<div className="tabs__pane -tab-item-5" dangerouslySetInnerHTML={{ __html: cancellation_policy }} />) : (<div className="tabs__pane -tab-item-5" dangerouslySetInnerHTML={{ __html: parentData.cancellation_policy }} />)}

              {parent_id == 0 || parent_id === null ? (<div className="tabs__pane -tab-item-6" dangerouslySetInnerHTML={{ __html: important_info }} />) : (<div className="tabs__pane -tab-item-6" dangerouslySetInnerHTML={{ __html: parentData.important_info }} />)}

            </div>
          </div>
        </div>
      </section>
                      
      {special_notes && (
      <section class="pt-40">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="px-24 py-20 rounded-4 bg-light-2">
                <div class="row x-gap-20 y-gap-20 items-center">
                  <div class="col-auto">
                    <div class="flex-center size-60 rounded-full bg-white">
                      <img src="/assets/img/icons/health.svg" alt="icon" />
                    </div>
                  </div>

                  <div class="col-auto">
                    <h4 class="text-18 lh-15 fw-500">Special Notes</h4>
                      {parent_id == 0 || parent_id === null ? (<div className="text-15 lh-15" dangerouslySetInnerHTML={{ __html: special_notes }} />) : (<div className="text-15 lh-15" dangerouslySetInnerHTML={{ __html: parentData.special_notes }} />)}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}

    <section id="accommodation-enquiry" class="layout-pt-lg layout-pb-md mt-50">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Enquire with Us</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">Have any questions? feel free to get in touch with us and we will answer all your questions.</p>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div style={{backgroundColor: '#13357B', color: '#fff',}} class="mt-50 px-40 pt-30 pb-30 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color: '#fff',}} class="card-title">WhatsApp Us</h4><br />
                  <p style={{color: '#fff',}} class="card-text">Request a quote, or just chat about your next vacation. We're always happy to help!</p><br />
                  {/*
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                  */}
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                </div>
              </div>
              <div style={{backgroundColor: '#ff894c', color:'#051036'}} class="mt-50 px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color:'#051036'}} class="card-title">Write to Us</h4><br />
                  <p style={{color:'#051036'}} class="card-text">Do you like to customize this hotel stay even more or be it an enquiry, feedback or a simple suggestion, write to us.</p><br />
                  <a target="_blank" href="mailto:travel@blessingsonthenet.com"><h5 style={{color:'#051036'}} class="card-text"><img src="/assets/img/icons/email.png" style={{width:'25px'}} /> travel@blessingsonthenet.com</h5></a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
            <div className="mt-50 px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Enter your journey details for travel enquiry.</div>
                <div className="row y-gap-15 pt-20">
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="noOfPersons"
                        value={formData.noOfPersons}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">No. of Person*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input" style={{minHeight:'70px'}}>
                    <label className="lh-1 text-16 text-light-1" style={{top:'12px'}}>Hotel Type</label>
                      <select
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius:'4px',
                          padding: '0 15px',
                          paddingTop:'25px'}}
                        name="hotelType"
                        value={formData.hotelType}
                        onChange={handleChange}
                      >
                      <option value="3 Star">3 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="5 Star">5 Star</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        min={minCheckInDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        min={minCheckOutDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="2"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Message (If Any)</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Send a Message
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>

      <section class="layout-pt-lg layout-pb-md relative" id="secondSection">
        <div data-anim-wrap="" class="container animated">
          <div data-anim-child="slide-up delay-1" class="row y-gap-20 justify-center text-center is-in-view">
            <div class="col-auto">
              <div class="sectionTitle -md">
                <h2 class="sectionTitle__title">Whats Nearby?</h2>
                <p class=" sectionTitle__text mt-5 sm:mt-0">Here are list of nearby places that you might be intersted in</p>
              </div>
            </div>
          </div>

          <div class="row x-gap-10 y-gap-10 pt-40 sm:pt-20">

            <div data-anim-child="slide-up delay-2" class="col-xl-4 col-lg-3 col-6 is-in-view">
              <a href="#" class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4">
                <img src="/assets/img/icons/temple.png" style={{width:'30px'}} />

                <div class="ml-10 sm:ml-0 sm:mt-10">
                  <h4 class="text-16 fw-500">Temples</h4>
                </div>
              </a>
            </div>

            <div data-anim-child="slide-up delay-3" class="col-xl-4 col-lg-3 col-6 is-in-view">
              <a href="#" class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4">
                <i style={{height:'30px'}} class="icon-destination text-24"></i>

                <div class="ml-10 sm:ml-0 sm:mt-10">
                  <h4 class="text-16 fw-500">Tourist Attractions</h4>
                </div>
              </a>
            </div>

            <div data-anim-child="slide-up delay-4" class="col-xl-4 col-lg-3 col-6 is-in-view">
              <a href="#" class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4">
                <img src="/assets/img/icons/restaurant.png" style={{width:'30px'}} />

                <div class="ml-10 sm:ml-0 sm:mt-10">
                  <h4 class="text-16 fw-500">Top Eateries/Shopping</h4>
                </div>
              </a>
            </div>

              <div data-anim-child="slide-up delay-2" class="col-xl-4 col-lg-3 col-6 is-in-view">
                {/*
                {templesList.map((temple) => (
                  <a target="_blank" href={`/attractions/${temple.name.toLowerCase().replace(/\s+/g, '-')}/${temple.id}`} key={temple.id} class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4 mb-10">
                    <div className="ml-10 sm:ml-0 sm:mt-10">
                      <p className="text-16">{temple.name}</p>
                    </div>
                  </a>
                ))}
                */}
                {templesList.map((temple) => (
                  <a href={`/indian-temple/id/${temple.id}/${temple.name.toLowerCase().replace(/\s+/g, '-')}`} key={temple.id} class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4 mb-10">
                    <div className="ml-10 sm:ml-0 sm:mt-10">
                      <p className="text-16">{temple.name}</p>
                    </div>
                  </a>
                ))}
              </div>
              <div data-anim-child="slide-up delay-2" class="col-xl-4 col-lg-3 col-6 is-in-view">
                {attractionsList.map((attraction) => (
                  <a target="_blank" href={`/attractions/${attraction.title.toLowerCase().replace(/\s+/g, '-')}/${attraction.id}`} key={attraction.id} class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4 mb-10">
                    <div className="ml-10 sm:ml-0 sm:mt-10">
                      <p className="text-16">{attraction.title}</p>
                    </div>
                  </a>
                ))}
              </div>

              <div data-anim-child="slide-up delay-2" class="col-xl-4 col-lg-3 col-6 is-in-view">
                {eateriesList.map((eateries) => (
                  <a target="_blank" href={`/eateries/${eateries.title.toLowerCase().replace(/\s+/g, '-')}/${eateries.id}`} key={eateries.id} class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4 mb-10">
                    <div className="ml-10 sm:ml-0 sm:mt-10">
                      <p className="text-16">{eateries.title}</p>
                    </div>
                  </a>
                ))}
              </div>
          </div>
        </div>
      </section>

      <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
                {nearbyTours.length > 0 &&
                <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
                  <div className="col-auto">
                      <div className="sectionTitle -md">
                      <h2 className="sectionTitle__title">Similar Tour Packages</h2>
                      </div>
                  </div>

                  <div className="col-auto md:d-none">

                      <a href="/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                      View All Holidays <div className="icon-arrow-top-right ml-15"></div>
                      </a>

                  </div>
                </div>
                }

                <div className="relative pt-40 sm:pt-20 swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden is-in-view">
                <div className="swiper-wrapper">

                    {nearbyTours.length > 0 && nearbyTours.map(tour => (
                    <div className="swiper-slide swiper-slide-visible swiper-slide-active" 
                    style={{width: '300px', marginRight: '30px'}}>

                        <a href={`/travel-india/tour-package/id/${tour.id}/${tour.name.replace(/\s+/g, '-')}`} className="citiesCard -type-1 d-block rounded-4 ">
                        <div className="citiesCard__image ratio ratio-4:3">
                            <img
                            src={tour.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/${tour.thumb_new}` : `/img/uploads/tour/thumbs/${tour.thumb}`}
                            data-src={tour.thumb_new ? `${process.env.REACT_APP_API_ENDPOINT}uploads/${tour.thumb_new}` : `/img/uploads/tour/thumbs/${tour.thumb}`}
                            alt="image"
                            className="js-lazy"
                            />
                        </div>

                        <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                            <div className="citiesCard__bg"></div>

                            <div className="citiesCard__top">
                            <div className="text-14 text-white">{tour.nights} Night {tour.days} Days</div>
                            </div>

                            <div className="citiesCard__bottom">
                            <h4 className="text-16 md:text-20 lh-13 text-white mb-10">{tour.name}</h4>
                            <p className="starts_from">Starts from ₹{tour.price}/-</p>
                            <button className="button col-12 h-40 -blue-1 bg-white text-dark-1">View Details</button>
                            </div>
                        </div>
                        </a>

                    </div>
                    ))}
                    
                </div>

                <div className="row pt-20 d-none md:d-block">
                    <div className="col-auto">
                    <div className="d-inline-block">

                        <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                        View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                        </a>

                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
      <SubscribeRibbon />
    </div>
    </>
  );
}

export default Holiday;
