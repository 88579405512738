import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubscribeRibbon} from './SubscribeRibbon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {Helmet} from "react-helmet";

function Temple() {

    const { id, title } = useParams();
  const [templeData, setTempleData] = useState([]);

  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}temple_listings?id=${id}`;
  console.log("api_url", apiUrl);

  useEffect(() => {
    fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
            setTempleData(data);
        })
        .catch((error) => console.log(error));
  }, []); 
  
  // Fetching Temple Categories
    const [templeArticles, setTempleArticles] = useState([]);

    useEffect(() => {
        const articlesUrl = `${process.env.REACT_APP_API_ENDPOINT}article/1?temple_id=${id}&cat=all`;
        fetch(articlesUrl)
            .then(response => response.json())
            .then(data => setTempleArticles(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Featured Temples
    const [featuredTemples, setfeaturedTemples] = useState([]);

    useEffect(() => {
        const categoryUrl = `${process.env.REACT_APP_API_ENDPOINT}featured_temples`;
        fetch(categoryUrl)
            .then(response => response.json())
            .then(data => setfeaturedTemples(data))
            .catch(error => console.log(error));
    }, []);

  //console.log("templedata name:", templeData[0].name);

    const [similarHotels, setSimilarHotels] = useState([]);
    useEffect(() => {
        if(templeData[0]?.location){
            const similarHotelsUrl = `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${templeData[0]?.location}&item=hotels&neglect=${id}`;
            console.log("similarHotelsUrl: ", similarHotelsUrl);
            fetch(similarHotelsUrl)
            .then(response => response.json())
            .then(data => setSimilarHotels(data))
            .catch(error => console.error(error));
        }
    }, [id, templeData[0]?.location]);

    const [similarTours, setSimilarTours] = useState([]);
    useEffect(() => {
        if(templeData[0]?.location){
            const similarToursUrl = `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${templeData[0]?.location}&item=tours&neglect=${id}`;
            console.log("similarToursUrl: ", similarToursUrl);
            fetch(similarToursUrl)
            .then(response => response.json())
            .then(data => setSimilarTours(data))
            .catch(error => console.error(error));
        }
    }, [id, templeData[0]?.location]);

    const [cityState, setCityState] = useState([]);

    useEffect(() => {
        if (templeData[0]?.location) {
          const cityStateAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}cities?city_id=${templeData[0]?.location}`;
          console.log("cityStateAPIURL: ", cityStateAPIURL);
          fetch(cityStateAPIURL)
            .then(response => response.json())
            .then(data => setCityState(data))
            .catch(error => console.error(error));
        }
    }, [templeData[0]?.location]);

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${templeData[0]?.name} | Blessingsonthenet.com`}</title>
        </Helmet>
        <div class="sm:mt-5 md:mt-15 mt-15">
            <section class="py-10 d-flex items-center bg-light-2">
                <div class="container">
                    <div class="row y-gap-10 items-center justify-between">
                        <div class="col-auto">
                        <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                            <div class="col-auto">
                            <div class="">Home</div>
                            </div>
                            <div class="col-auto">
                            <div class="">&gt;</div>
                            </div>
                            <div class="col-auto">
                            <div class="">Temples</div>
                            </div>
                            <div class="col-auto">
                            <div class="">&gt;</div>
                            </div>
                            <div class="col-auto">
                            <div class="text-dark-1">{templeData[0]?.name}</div>
                            </div>
                        </div>
                        </div>

                        <div class="col-auto">
                        {/*<a href="#" class="text-14 text-blue-1 underline">View Hotels Involving Dwarka</a>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-40">
                <div class="container">
                    <div class="row y-gap-20 justify-between items-end">
                        <div class="col-auto">
                            <div class="row x-gap-20  items-center">
                                <div class="col-auto">
                                <h1 class="text-30 sm:text-25 fw-600" style={{maxWidth:750,}}>{templeData[0]?.name}</h1>
                                </div>
                            </div>
                            <div class="row x-gap-20 y-gap-20 items-center">
                                <div class="col-auto">
                                    <div class="d-flex items-center text-15 text-light-1">{templeData[0]?.city}, {templeData[0]?.state}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-auto">
                            <div class="row x-gap-15 y-gap-15 items-center">
                                <div class="col-auto">
                                    <div class="text-14">
                                        <a href={`https://blessingsonthenet.com/hotels/page/1?keyword=${templeData[0]?.city}&city=${templeData[0]?.city}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                            View Hotels in {templeData[0]?.city}
                                            <div class="icon-arrow-top-right ml-15"></div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div>
                        <img
                        class="mt-20"
                        style={{ width: '100%' }}
                        src={
                            templeData[0]?.temple_image_new
                            ? `${process.env.REACT_APP_API_ENDPOINT}uploads/temples/${templeData[0]?.temple_image_new}`
                            : `/img/uploads/${templeData[0]?.image}`
                        }
                        alt="Temple Image"
                        />
                    </div>

                </div>
            </section>

            <section class="pt-30">
                <div class="container">
                <div class="row y-gap-30">
                    <div class="col-xl-8">
                        <div class="row y-gap-40 dark_text">
                            <div id="overview" class="col-12 mb-50">
                                <div dangerouslySetInnerHTML={{ __html: templeData[0]?.content }} />     
                            </div>
                        </div>

                        {similarHotels.length > 0 &&
                        <div class="row justify-center text-center">
                            <div class="col-auto">
                            <div class="sectionTitle -md">
                                <h2 class="sectionTitle__title">Hotels that might interest you</h2>
                                <p class=" sectionTitle__text mt-5 sm:mt-0">Some of the exciting hotels for you to stay</p>
                            </div>
                            </div>
                        </div>
                        }

                        <div class="row y-gap-30 pt-40 sm:pt-20">

                            {similarHotels.length > 0 && similarHotels.map(hotel => (
                                <div class="col-xl-3 col-lg-3 col-sm-6">

                                <a href={`/hotel/${hotel.name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} class="hotelsCard -type-1">

                                <div class="hotelsCard__image">
                
                                    <div class="cardImage ratio ratio-1:1">
                                        <div class="cardImage__content">
                    
                                            <img class="rounded-4 col-12" 
                                                src={
                                                    hotel?.thumb_new.includes('aim_')
                                                    ? `/img/uploads/hotels/gallery/${hotel?.thumb_new}`
                                                    : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${hotel?.thumb_new}`
                                                } 
                                                alt="image" />
                                        </div>
                                    </div>
                
                                </div>
                
                                <div class="hotelsCard__content mt-10">
                                    <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                    <span>{hotel.name}
                                        </span>
                                    </h4>
                
                                    <p class="text-light-1 lh-14 text-14 mt-5">{cityState.length > 0 && `${cityState[0].city}, ${cityState[0].state}`}</p>
                
                                    <div class="mt-5">
                                    <div class="fw-500">
                                        {hotel.price > 0 ?
                                        <span dangerouslySetInnerHTML={{ __html: `Starting from <span class="text-blue-1">₹${hotel.price}</span>` }}></span> : 'Contact us for Price'
                                        }
                                    </div>
                                    </div>
                                </div>
                                </a>
                
                            </div>
                            ))}

                        </div>

                        {similarTours.length > 0 &&
                        <div class="row justify-center text-center mt-50">
                            <div class="col-auto">
                            <div class="sectionTitle -md">
                                <h2 class="sectionTitle__title">Tour Packages that might interest you</h2>
                                <p class=" sectionTitle__text mt-5 sm:mt-0">Some of the exciting tour packages involving this place</p>
                            </div>
                            </div>
                        </div>
                        }

                        <div class="row y-gap-30 pt-40 sm:pt-20 mb-50">

                            {similarTours.length > 0 && similarTours.map(hotel => (
                                <div class="col-xl-3 col-lg-3 col-sm-6">

                                <a href={`/hotel/${hotel.name.toLowerCase().replace(/\s+/g, '-')}/${hotel.id}`} class="hotelsCard -type-1">

                                <div class="hotelsCard__image">
                
                                    <div class="cardImage ratio ratio-1:1">
                                    <div class="cardImage__content">
                
                                        <img class="rounded-4 col-12" src={`${process.env.REACT_APP_API_ENDPOINT}/uploads/${hotel.thumb_new}`} alt="image" />
                
                                    </div>
                
                                    </div>
                
                                </div>
                
                                <div class="hotelsCard__content mt-10">
                                    <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                    <span>{hotel.name}
                                        </span>
                                    </h4>
                                    {/*
                                    <p class="text-light-1 lh-14 text-14 mt-5">{cityState.length > 0 && `${cityState[0].city}, ${cityState[0].state}`}</p>
                                    */}
                                    <div class="mt-5">
                                    <div class="fw-500">
                                        {hotel.price > 0 ?
                                        <span dangerouslySetInnerHTML={{ __html: `Starting from <span class="text-blue-1">₹${hotel.price}</span>` }}></span> : 'Contact us for Price'
                                        }
                                    </div>
                                    </div>
                                </div>
                                </a>
                
                            </div>
                            ))}

                        </div>
                    </div>

                    <div class="col-xl-4">
                        
                        <div class="ml-50 lg:ml-0" style={{marginBottom:'20px'}}>
                            <div class="px-30 pt-20 pb-20 mb-50 border-light rounded-4 shadow-4" id="book-now">
                                <div class="sidebar__item" style={{borderTop:'none'}}>
                                    <h5 class="text-20 fw-500 mb-10">Related Pages</h5>
                                    <div class="sidebar-checkbox">
                                        <table class="table-4 w-1/1 blessings_categories">
                                            <tbody>
                                            {templeArticles.map(item => (
                                            <tr key={item.id}>
                                                <td>    
                                                <a href={`/indian-temple/article/${item.id}/${item.title.toLowerCase().replace(/\s+/g, '-')}`}>
                                                    <img src={`/assets/img/general/categories-arrow.png`} />
                                                    {item.title}
                                                </a>
                                                </td>
                                            </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="sidebar__item mt-20">
                                    <h5 class="text-18 fw-500 mt-30 mb-10">Featured Temples</h5>
                                    <div class="sidebar-checkbox">
                                        <table class="table-4 w-1/1 blessings_categories">
                                            <tbody>
                                            {featuredTemples.map(item => (
                                            <tr key={item.id}>
                                                <td>    
                                                <a href={`/indian-temple/id/${item.id}/${item.name.toLowerCase().replace(/\s+/g, '-')}-${item.place.toLowerCase().replace(/\s+/g, '-')}`}>
                                                    <img src={`/assets/img/general/categories-arrow.png`} />
                                                    {item.name}
                                                </a>
                                                </td>
                                            </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            
            {/*
            <section class="layout-pt-md layout-pb-lg">
                <div class="container">
                    {similarHotels.length > 0 && (
                    <div class="row justify-center text-center">
                        <div class="col-auto">
                        <div class="sectionTitle -md">
                            <h2 class="sectionTitle__title">Other Hotels that might interest you</h2>
                            <p class=" sectionTitle__text mt-5 sm:mt-0">Some of the exciting hotels that are similar</p>
                        </div>
                        </div>
                    </div>
                    )}

                <div class="row y-gap-30 pt-40 sm:pt-20">

                    {similarHotels.length > 0 && similarHotels.map(hotel => (
                        <div class="col-xl-3 col-lg-3 col-sm-6">

                        <a href={`/${domainName === 'shirdisaitemple' ? 'shirdi-hotel' : 'hotel'}/id/${hotel.id}/${hotel.name.toLowerCase().replace(/\s+/g, '-')}`} class="hotelsCard -type-1">

                        <div class="hotelsCard__image">
        
                            <div class="cardImage ratio ratio-1:1">
                            <div class="cardImage__content">
                                
                                <img
                                className="rounded-4 col-12"
                                src={
                                    hotel.thumb_new && hotel.thumb_new.includes('aim_')
                                    ? `https://www.blessingsonthenet.com/img/uploads/hotels/gallery/${hotel.thumb_new}`
                                    : `https://api.blessingsonthenet.com/uploads/hotels/${hotel.thumb_new}`
                                }
                                alt="image"
                                />
                            </div>
        
                            </div>
        
                        </div>
        
                        <div class="hotelsCard__content mt-10">
                            <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                            <span>{hotel.name}
                                </span>
                            </h4>
        
                            <p class="text-light-1 lh-14 text-14 mt-5">{cityState.length > 0 && `${cityState[0].city}, ${cityState[0].state}`}</p>
        
                            <div class="mt-5">
                            <div class="fw-500">
                                {hotel.price > 0 ?
                                <span dangerouslySetInnerHTML={{ __html: `Starting from <span class="text-blue-1">₹${hotel.price}</span>` }}></span> : 'Contact us for Price'
                                }
                            </div>
                            </div>
                        </div>
                        </a>
        
                    </div>
                    ))}

                </div>
                </div>
            </section>
            */}
            <SubscribeRibbon />
        </div>
        </>
    );
}

export default Temple;