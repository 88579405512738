import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PrimarySearchBox from './PrimarySearchBox';

function HeaderSearch() {
  const { id, title } = useParams();

  return (
      <section class="section-bg pt-40 pb-40 relative z-5">
        <div class="section-bg__item col-12">
          <img src="/assets/img/misc/bg-1.png" alt="image" class="w-full h-full object-cover" />
        </div>

        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <h1 class="text-30 fw-600 text-white">Find Your Dream Tour Package</h1>
              </div>

                <PrimarySearchBox />
            </div>
          </div>
        </div>
      </section>
  );
}

export default HeaderSearch;
