import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import 'react-quill/dist/quill.snow.css';
import "cropperjs/dist/cropper.min.css";
import { isAuthenticated } from '../middleware/admin_auth';

function AdminDirectPaymentView() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);
    
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState({ 
        id: null, 
        guestName: '', 
        email: '', 
        mobileNumber: '', 
        amount: '', 
        paidFor: '',
        startDate: '', 
        endDate: '', 
        bookingDetails: '', 
        idolDetails: '', 
        order_id: '', 
        submit_date: '', 
        payment_status: '', 
        submit_date: '' 
    });

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}direct_payments_list?id=${id}`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data) && data.length > 0) {
              setOrderDetails(data[0]);
            }
          })
          .catch(error => console.error(error));
    }, []);


    
    return (
        <div class="dashboard__main">

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Direct Payment Details</h1>

                    </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:200}}>
                    <div class="col-12"><p>Order ID: <b>{orderDetails?.order_id}</b></p></div>
                    <div class="col-12"><p>Guest Name: <b>{orderDetails?.guestName}</b></p></div>
                    <div class="col-12"><p>Email Address: <b>{orderDetails?.email}</b></p></div>
                    <div class="col-12"><p>Phone Number: <b>{orderDetails?.mobileNumber}</b></p></div>
                    <div class="col-12"><p>Amount: <b>₹{orderDetails?.amount}</b></p></div>
                    <div class="col-12"><p>Paid For: <b>{orderDetails?.paidFor}</b></p></div>
                    <div class="col-12"><p>Start Date: <b>{orderDetails?.startDate}</b></p></div>
                    <div class="col-12"><p>End Date: <b>{orderDetails?.endDate}</b></p></div>
                    <div class="col-12"><p>Booking Details: <b>{orderDetails?.bookingDetails}</b></p></div>
                    <div class="col-12"><p>Idol Details: <b>{orderDetails?.idolDetails}</b></p></div>
                    <div class="col-12"><p>Payment Status: <b>{orderDetails?.payment_status}</b></p></div>
                    <div class="col-12"><p>Order Date/Time: <b>{orderDetails?.submit_date}</b></p></div>
                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminDirectPaymentView;