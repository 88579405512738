import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import 'react-quill/dist/quill.snow.css';
import "cropperjs/dist/cropper.min.css";
import { isAuthenticated } from '../middleware/admin_auth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AdminMicroGroupEdit() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];
    
    const { id } = useParams();
    const [article, setArticle] = useState();

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/${id}?cat=group&byId=1&admin=1`;
    //console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data) && data.length > 0) {
              setArticle(data[0]);
            }
          })
          .catch(error => console.error(error));
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    
    
    const handleSaveDetails = () => {
        const articleUpdatedData = {
            ...article,
            city: checkedCities.join(','),
            interest: checkedInterests.join(',')
        };
        console.log("article", articleUpdatedData);
        const packageUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_group_microsite_update`;
        fetch(packageUpdate1, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(articleUpdatedData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };

    const [secondTabCities, setSecondTabCities] = useState([]);

    useEffect(() => {
        const citiesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?cities=all`;
        fetch(citiesUrl)
          .then(response => response.json())
          .then(data => setSecondTabCities(data))
          .catch(error => console.log(error));
    }, []);

    const [checkedCities, setCheckedCities] = useState([]);

    useEffect(() => {
        if (article?.city) {
            const cityIds = article?.city.split(',');
            setCheckedCities(cityIds);
        }
    }, [article?.city]);

    const handleCityCheckboxChange = (e, cityId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
        setCheckedCities(prevState => [...prevState, cityId.toString()]);
        } else {
        setCheckedCities(prevState => prevState.filter(id => id !== cityId.toString()));
        }
    };

    console.log("checkedCities: ", checkedCities);

    const [secondTabInterests, setSecondTabInterests] = useState([]);

    useEffect(() => {
        const interestsUrl = `${process.env.REACT_APP_API_ENDPOINT}interest_types`;
        fetch(interestsUrl)
          .then(response => response.json())
          .then(data => setSecondTabInterests(data))
          .catch(error => console.log(error));
    }, []);

    const [checkedInterests, setCheckedInterests] = useState([]);

    useEffect(() => {
        if (article?.interest) {
            const interestIds = article?.interest.split(',');
            setCheckedInterests(interestIds);
        }
    }, [article?.interest]);

    const handleInterestCheckboxChange = (e, interestId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
        setCheckedInterests(prevState => [...prevState, interestId.toString()]);
        } else {
        setCheckedInterests(prevState => prevState.filter(id => id !== interestId.toString()));
        }
    };
    
    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                    <div class="col-auto">

                        <h1 class="text-30 lh-14 fw-600">Edit Group</h1>

                    </div>
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3" style={{marginBottom:200}}>

                    <div class="col-12">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={article?.name || ''}
                            name="name"
                            onChange={(event) => setArticle(prevState => ({ ...prevState, name: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Title</label>
                        </div>
                    </div>

                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={article?.domain || ''}
                            name="domain"
                            onChange={(event) => setArticle(prevState => ({ ...prevState, domain: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Domain</label>
                        </div>
                    </div>

                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={article?.analytics || ''}
                            name="analytics"
                            onChange={(event) => setArticle(prevState => ({ ...prevState, analytics: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Analytics Code</label>
                        </div>
                    </div>

                    <div class="col-12 mt-20">
                        <div class="form-input ">
                            <input
                            type="text"
                            value={article?.webmaster || ''}
                            name="webmaster"
                            onChange={(event) => setArticle(prevState => ({ ...prevState, webmaster: event.target.value }))}
                            required
                            />
                            <label class="lh-1 text-16 text-light-1">Google Search Console Code</label>
                        </div>
                    </div>

                    <div className="col-12 pb-30 pt-30">
                        <div className="fw-500">Description</div>
                        <div className="form-input" style={{ maxHeight: 300 }}>
                            <ReactQuill
                                value={article?.description}
                                onChange={(value) => setArticle(prevState => ({...prevState, description: value}))}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                    </div>

                    <div className="col-12 pt-50 pb-30">
                        <div className="fw-500">Meta Description</div>
                        <div className="form-input" style={{ maxHeight: 300 }}>
                            <ReactQuill
                                value={article?.metadesc}
                                onChange={(value) => setArticle(prevState => ({...prevState, metadesc: value}))}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                    </div>

                    <div className="col-12 pt-50 pb-30">
                        <div className="fw-500">Meta Keywords</div>
                        <div className="form-input" style={{ maxHeight: 300 }}>
                            <ReactQuill
                                value={article?.keywords}
                                onChange={(value) => setArticle(prevState => ({...prevState, keywords: value}))}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                    </div>

                    <div class="col-xl-10 py-50">
                        <div class="row x-gap-20 y-gap-20">
                            <div class="col-lg-5 col-sm-6 offset-lg-1">
                                <div class="text-18 fw-500 mb-10">Cities related with Packages</div>
                                <div className='scrollable_checkboxes'>
                                    <div className="row y-gap-15">
                                    {secondTabCities.map(item => (
                                        <div className="col-12" key={item.id}>
                                            <div className="d-flex items-center">
                                            <div className="form-checkbox ">
                                                <input
                                                type="checkbox"
                                                value={item.id}
                                                name="cities"
                                                checked={checkedCities.includes(item.id.toString())}
                                                onChange={(e) => handleCityCheckboxChange(e, item.id)}
                                                />
                                                <div className="form-checkbox__mark">
                                                <div className="form-checkbox__icon icon-check"></div>
                                                </div>
                                            </div>
                                            <div className="text-15 lh-11 ml-10">{item.city}</div>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-5 col-sm-6 offset-lg-1">
                                <div class="text-18 fw-500 mb-10">Interests</div>
                                <div className='scrollable_checkboxes'>
                                    <div class="row y-gap-15">
                                        {secondTabInterests.map(item => (
                                            <div className="col-12" key={item.id}>
                                                <div className="d-flex items-center">
                                                <div className="form-checkbox ">
                                                    <input
                                                    type="checkbox"
                                                    value={item.id}
                                                    name="interests"
                                                    checked={checkedInterests.includes(item.id.toString())}
                                                    onChange={(e) => handleInterestCheckboxChange(e, item.id)}
                                                    />
                                                    <div className="form-checkbox__mark">
                                                    <div className="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>
                                                <div className="text-15 lh-11 ml-10">{item.type}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                            <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                            </a>
                        </div>
                        <div style={{clear:'both'}}></div>
                    </div>

                    

                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminMicroGroupEdit;